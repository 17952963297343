/* eslint-disable */
import api, { baseURL } from '../../../api/api';
import Axios from 'axios';
import { toasterMessage } from '../../../@utils/utils';
import {
  GET_CATEGORY,
  GET_PRODUCTDETAILS,
  GET_BRAND,
  GET_MODEL,
  GET_DISTRIBUTOR,
  SEARCHED_LIST,
  SET_LOADING,
  GET_SPECIFIC_MODEL,
  GET_SUPER_CATOGERY,
} from './types';
var CancelToken = Axios.CancelToken;
var cancel;

export const getSuperCategory = cb => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });

  await api
    .get('category/getSuperCategory')
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: GET_SUPER_CATOGERY, payload: response.data.data });
      cb && cb();
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      error.response &&
        error.response.data &&
        toasterMessage('error', error.response.data.message);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const getCategory = (payload, cb) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });

  await api
    .post('category/getCategory', payload)
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: GET_CATEGORY, payload: response.data.data });
      cb && cb();
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });

      error.response &&
        error.response.data &&
        toasterMessage('error', error.response.data.message);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const getBrand = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });

  await api
    .post('inventory/getBrand', payload)
    .then(response => {
      dispatch({ type: GET_BRAND, payload: response.data.data });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 404) {
        dispatch({ type: GET_BRAND, payload: [] });
        dispatch({ type: GET_MODEL, payload: [] });
      }

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const getModel = (payload, cb) => async dispatch => {
  api
    .post('inventory/getModels', payload)
    .then(response => {
      cb && cb(false);
      dispatch({ type: GET_MODEL, payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      dispatch({ type: GET_MODEL, payload: [] });
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'something bad happend');
    });
};

export const getSpecificModel = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });

  api
    .post('inventory/getSpecificModels', payload)
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: GET_SPECIFIC_MODEL, payload: response.data.data });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: GET_SPECIFIC_MODEL, payload: [] });
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const updateQty = (payload, cb) => async dispatch => {
  api
    .post('inventory/updateQuantity', payload)
    .then(response => {
      toasterMessage('success', response.data.message);
      cb && cb(true);
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'something bad happend');
    });
};

export const getDistributor = cb => async dispatch => {
  api
    .get('distributor/getDistributor')
    .then(response => {
      cb && cb(false);
      dispatch({ type: GET_DISTRIBUTOR, payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'something bad happend');
    });
};

export const addCategory = (payload, cb) => async dispatch => {
  api
    .post('category/addCategory', payload)
    .then(response => {
      dispatch(getCategory(cb));
      dispatch({ type: SET_LOADING, payload: false });

      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const addBrand = (payload, cb) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });

  await api
    .post('inventory/addBrand', payload)
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      cb && cb();

      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const addModel = (payload, cb) => dispatch => {
  dispatch({ type: 'SET_LOADING', payload: true });
  api
    .post('inventory/addModel', payload)
    .then(response => {
      dispatch({ type: 'SET_LOADING', payload: false });
      cb && cb(response.data.data);

      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: 'SET_LOADING', payload: false });
    })
    .catch(() => {
      toasterMessage('error', 'something bad happened');
    });
};

export const getProductDetails = (payload, cb) => async dispatch => {
  if (!payload) {
    dispatch({ type: GET_PRODUCTDETAILS, payload: null });
    cb && cb(false);
  } else {
    api
      .post('inventory/getProductDetails', payload)
      .then(response => {
        cb && cb(false);
        dispatch({ type: GET_PRODUCTDETAILS, payload: response.data.data[0] });
      })
      .catch(error => {
        cb && cb(false);
        toasterMessage('error', error.response.data.error);
      })
      .catch(() => {
        cb && cb(false);
        toasterMessage('error', 'something bad happend');
      });
  }
};

export const searchProduct = payload => async dispatch => {
  cancel && cancel();
  if (payload.name.length < 1) {
    dispatch({ type: SEARCHED_LIST, payload: [] });
  } else {
    dispatch({ type: SET_LOADING, payload: true });
    Axios({
      method: 'POST',
      url: `${baseURL.baseURL}inventory/searchProduct`,
      data: payload,
      headers: {
        'Content-type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      cancelToken: new CancelToken(c => {
        cancel = c;
      }),
    })
      .then(response => {
        dispatch({ type: SET_LOADING, payload: false });
        dispatch({ type: SEARCHED_LIST, payload: response.data.data });
      })
      .catch(error => {
        dispatch({ type: SET_LOADING, payload: false });
        dispatch({ type: SEARCHED_LIST, payload: [] });
      })
      .catch(() => {
        toasterMessage('error', 'something bad happend');
        dispatch({ type: SEARCHED_LIST, payload: [] });
      });
  }
};

export const scanProduct = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('inventory/scan', payload)
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: GET_PRODUCTDETAILS, payload: response.data.data });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
    });
};

export const clearSearch = () => dispatch => {
  dispatch({ type: SEARCHED_LIST, payload: [] });
};

export const clearModal = () => async dispatch => {
  dispatch({ type: GET_PRODUCTDETAILS, payload: null });
};
