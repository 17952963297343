import React, { useState } from 'react';
import { useEffect } from 'react';
import { postSurvey } from '../../../store/RetailerWebStore/RetailerAction/earnReward';
// import PDFViewer from 'pdf-viewer-reactjs'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toasterMessage } from '../../../@utils/utils';
import Loader from '../../Loader';

const DownloadInvoice = ({ postSurvey }) => {
  const [url, setUrl] = useState({});
  const [loading, setLoading] = useState(true);
  const { invoiceToken } = useParams();

  useEffect(() => {
    postSurvey({ invoiceToken })
      .then(response => {
        setUrl(response.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        toasterMessage('error', err.response.data.error);
      })
      .catch(() => {
        setLoading(false);
        toasterMessage('error', 'Something Bad Happened :{');
      });
  }, []);

  return !loading && url ? (
    <>
      <object data={url.invoiceUrl} width="100%" height="800px">
        <p>
          Your web browser doesn't have a PDF plugin. Instead you can{' '}
          <a href={url.invoiceUrl}>click here to download the PDF file.</a>
        </p>
      </object>
      {/* <PDFViewer
        document={{
          url: url.invoiceUrl,
        }}
      /> */}
    </>
  ) : (
    <Loader />
  );
};

export default connect(null, { postSurvey })(DownloadInvoice);
