/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toastDismiss } from '../../@utils/utils';
import {
  fetchSuperCategory,
  sendOnboardingDetails,
} from '../../store/AdminActions/onboardingAction';
import {
  getSuperCategoryList,
  updateCategory,
} from '../../store/RetailerWebStore/RetailerAction/manageCategory';
import { getNotificationCount } from '../../store/RetailerWebStore/RetailerAction/notifications';
import { getProfile } from '../../store/RetailerWebStore/RetailerAction/myProfile';
import analyticFunc from '../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  welcomeTitle: {
    fontSize: '36px',
    fontWeight: '600',
    margin: '0px auto',
    color: theme.palette.primary.main,
    lineHeight: '54px',
  },
  gridDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    textAlign: 'start',
    marginTop: '0.3rem',
  },
  container: {
    display: 'grid',
    gridTemplateRows: '0.1fr auto 0.1fr',
    fontFamily: theme.palette.typography.fontFamily,
  },
  title2: { fontSize: '18px', fontWeight: '600', margin: '18px 0px' },
  paper: {
    display: 'flex',
    padding: '18px',
    boxShadow: '0px 8px 18px rgba(17, 17, 17, 0.04)',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
  },
  paperSelected: {
    display: 'flex',
    padding: '18px',
    boxShadow: '0px 8px 18px rgba(17, 17, 17, 0.04)',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '2px solid #FE805C',
  },
  button: { width: '15%', margin: 'auto' },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridRowGap: '20px',
    gridColumnGap: '20px',
    minWidth: '150px',
  },
}));

const MANAGE_CATEGORY = 'manageCategory';

const CategorySelection = props => {
  const location = useLocation().pathname.substring(1);
  const fromSignup = props.fromSignup;
  const classes = useStyles();
  const [superCategories, setSuperCategories] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const {
    categories,
    getNotificationCount,
    getSuperCategoryList,
    updateCategory,
  } = props;

  const handleCategorySelection = categoryId => {
    const temp = [...selectedCategories];
    if (temp.includes(categoryId)) {
      const newList = temp.filter(id => categoryId !== id);
      setSelectedCategories(newList);
    } else {
      temp.push(categoryId);
      setSelectedCategories(temp);
    }
  };

  useEffect(() => {
    if (!props.userToken) {
      props.history.replace('/');
    }
    if (location === MANAGE_CATEGORY) {
      getNotificationCount();
      toastDismiss();
      // if (fromSignup) return getSuperCategorySignup();
      if (categories.length < 1) getSuperCategoryList();
    } else {
      props.fetchSuperCategory();
      if (props.isOnboarded) {
        props.history.replace('/dashboard');
      } else if (!(props.pincode && props.tradeName)) {
        props.history.replace('/onboarding');
      }
    }
  }, []);

  const onSuccess = useCallback(() => {
    props.history.push('/dashboard');
  }, []);

  useEffect(() => {
    if (categories) setCategoryList(categories);
    const arr = [];
    if (categories)
      categories.map(item => {
        if (item.isSelected) {
          arr.push(item.superCategoryId);
        }
        return 0;
      });
    setSelectedCategories(arr);
  }, [categories]);

  useEffect(() => {
    const arr = [];
    categoryList.map(item => {
      if (item.isSelected) {
        arr.push(item.superCategoryId);
      }
    });
    setSelectedCategories(arr);
  }, [categoryList]);

  useEffect(() => {
    if (props.superCategories) {
      // omitt the first category
      setSuperCategories(props.superCategories);
    } else {
      // console.log('Failed SuperCategories');
    }
  }, [props.superCategories]);

  const handleSuperChange = useCallback(
    superId => {
      const arr = [...categoryList];
      const arr1 = arr.map(item => {
        if (item.superCategoryId === superId) {
          // eslint-disable-next-line no-param-reassign
          item.isSelected = !item.isSelected;
          // eslint-disable-next-line no-unused-expressions
          item.isSelected
            ? item.categories.map(sub => {
                sub.isSelected = true;
              })
            : item.categories.map(sub => {
                sub.isSelected = false;
              });
        }
        return item;
      });
      setCategoryList(arr1);
    },
    [categoryList]
  );

  const afterSubmit = async () => {
    getProfile();
    props.history.push('/dashboard');
  };

  const handleSubmit = () => {
    if (location === MANAGE_CATEGORY) {
      /* manage category */
      analyticFunc(eventsPayload.ManageCategory[1]);
      const arr = [...categoryList];
      const arr1 = [];
      arr.map(cat => {
        if (cat.isSelected) {
          cat.categories.map(item => {
            const temp = { ...item };
            if (temp.isSelected) {
              delete temp.isSelected;
              arr1.push(temp);
            }
          });
        }
      });
      const payload = {
        selectedCategory: arr1,
        type: !fromSignup ? 'ManageCategory' : undefined,
      };
      updateCategory(payload, onSuccess, fromSignup);
    } else if (selectedCategories.length !== 0) {
      /* onboarding */
      analyticFunc(eventsPayload.Onboarding[6]);
      const payload = {
        userToken: props.userToken,
        userId: props.userId.toString(),
        tradeName: props.tradeName,
        pincode: props.pincode,
        selectedCategories: `[${selectedCategories.toString()}]`,
      };
      props.sendOnboardingDetails(payload, afterSubmit);
      // props.history.push('/dashboard');
    } else {
      // show Error Atleast One Category should be selected;
    }
  };

  return (
    <div className="flexedCenterDiv" style={{ height: 'auto' }}>
      <Container className={classes.container} maxWidth="lg">
        {location !== MANAGE_CATEGORY && (
          <p className={classes.welcomeTitle}> One Last Step. </p>
        )}
        <div className={classes.gridDiv}>
          <p className={classes.title2}> What kind of products do you sell? </p>
          <p> Choose Category </p>
          <Grid className={classes.gridContainer}>
            {(location === MANAGE_CATEGORY
              ? categoryList
              : superCategories.slice(1)
            ).map(superCategory => (
              <Grid key={superCategory.superCategoryId} item md>
                <Paper
                  onClick={() => {
                    if (location === MANAGE_CATEGORY)
                      handleSuperChange(superCategory.superCategoryId);
                    else handleCategorySelection(superCategory.superCategoryId);
                  }}
                  className={
                    selectedCategories.includes(superCategory.superCategoryId)
                      ? classes.paperSelected
                      : classes.paper
                  }
                >
                  {superCategory.superCategoryName}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
        <Button
          onClick={handleSubmit}
          className={classes.button}
          fullWidth
          color="primary"
          variant="contained"
          disabled={selectedCategories.length === 0}
        >
          {location === MANAGE_CATEGORY ? 'Submit' : "Let's Go"}
        </Button>
      </Container>
    </div>
  );
};

const mapStateToProps = ({
  getSuperCategories,
  OnboardingReducer,
  OtpLoginReducer,
}) => ({
  // ONBOARDING
  superCategories: OnboardingReducer.superCategories,
  tradeName: OnboardingReducer.userTradeName,
  pincode: OnboardingReducer.userPincode,
  userToken: OtpLoginReducer.userToken,
  userId: OtpLoginReducer.userId,
  isOnboarded: OtpLoginReducer.isOnboarded,

  // MANAGE-CATEGORY
  categories: getSuperCategories.categories,
});

// For Dispatching Action
const mapDispatchToProps = dispatch => ({
  // ONBOARDING
  fetchSuperCategory: () => dispatch(fetchSuperCategory()),
  sendOnboardingDetails: (payload, cb) =>
    dispatch(sendOnboardingDetails(payload, cb)),

  // MANAGE-CATEGORY
  updateCategory: (payload, onSuccess, fromSignup) =>
    dispatch(updateCategory(payload, onSuccess, fromSignup)),
  getSuperCategoryList: () => dispatch(getSuperCategoryList()),
  getNotificationCount: () => dispatch(getNotificationCount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);
