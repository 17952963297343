import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  lazy,
  Suspense,
} from 'react';
import { TextField, IconButton, Badge, Tooltip } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { AddSms } from './addSms';
import {
  getWallet,
  getOrder,
  confirmWallet,
} from '../../../store/RetailerWebStore/RetailerAction/sendSms';
import { connect } from 'react-redux';
import Payment from './payment';
import MyModal from '../../WebComponents/Modal';
import Loader from '../../Loader';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';
const CustomerContainer = lazy(() => import('./customerContainer'));

const mapStateToProps = ({
  SendSms: {
    wallet: { final_msgs, razorPayCredentials },
  },
  loginReducer: { user },
}) => ({
  final_msgs,
  razorPayCredentials,
  userData: user.userData && user.userData[0],
});

const SMS = memo(
  ({
    final_msgs,
    razorPayCredentials,
    getWallet,
    getOrder,
    confirmWallet,
    userData,
    getNotificationCount,
  }) => {
    const [next, setNext] = useState(false),
      [displayText, setDisplayText] = useState('flex'),
      [display, setDisplay] = useState('none'),
      [message, setMessage] = useState(''),
      [keyId, setKeyId] = useState(''),
      [order_id, setOrderId] = useState(null),
      [minAmount, setMinAmount] = useState(''),
      [loading, setLoading] = useState(false),
      payRef = useRef(),
      modalRef = useRef();

    useEffect(() => {
      getNotificationCount();
      getWallet(setLoading);
      setLoading(true);
    }, []);

    useEffect(() => {
      if (razorPayCredentials) {
        setKeyId(razorPayCredentials[2].metaData.user);
        // console.log(razorPayCredentials[2].metaData.user)
        setMinAmount(parseInt(razorPayCredentials[3].metaData.split('_')[0]));
      }
    }, [razorPayCredentials]);

    useEffect(() => {
      if (order_id) payRef.current.click();
    }, [order_id]);

    const getOrderDetails = useCallback(data => {
      setOrderId(data.id);
    }, []);

    const handleReset = useCallback(() => {
      setMessage('');
      getWallet();
      setNext(false);
      setDisplay('none');
      setDisplayText('flex');
    }, []);

    const defaultProps = {
      color: 'secondary',
      children: (
        <TextField
          className="w-100"
          id="outlined-multiline-static"
          label="Write message to be sent for marketing"
          multiline
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows="6"
          variant="outlined"
        />
      ),
    };

    return (
      <div>
        {next && (
          <span style={{ float: 'left' }}>
            <IconButton
              onClick={e => {
                setNext(false);
                setDisplayText('flex');
                setDisplay('none');
              }}
            >
              <ChevronLeft />
            </IconButton>
            Customers
          </span>
        )}
        <br />
        <br />
        <div
          className="row w-100 p-4 flex-column justify-content-center align-items-center"
          style={{ display: displayText }}
        >
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h5 className="text-left ml-2">Send SMS</h5>
            <Badge className="w-100" {...defaultProps} />
            <div className="ml-2 text-muted">
              {razorPayCredentials && (
                <p>
                  Character count : {message.length} | SMS count:{' '}
                  {Math.ceil(message.length / razorPayCredentials[1].metaData)}
                </p>
              )}
            </div>
            {!loading ? (
              <>
                <button
                  onClick={e => {
                    analyticFunc(eventsPayload.SendSMS[1]);
                    if (message) {
                      setNext(true);
                      setDisplayText('none');
                      setDisplay('block');
                    }
                  }}
                  className="rounded-pill text-white text-uppercase border-0 w-100 p-3 bg-dark"
                >
                  next
                </button>
                <br />
                <br />
                <div className="d-flex justify-content-around align-items-center">
                  <b>SMS Available : {final_msgs}</b>
                  <span>
                    Add SMS :{' '}
                    <Tooltip title="Add more SMS">
                      <IconButton
                        onClick={e => {
                          analyticFunc(eventsPayload.SendSMS[2]);
                          modalRef.current.click();
                        }}
                      >
                        +
                      </IconButton>
                    </Tooltip>
                  </span>
                </div>
                <div>
                  <MyModal ref={modalRef}>
                    <AddSms
                      getOrder={getOrder}
                      key={keyId}
                      getOrderDetails={getOrderDetails}
                      minAmount={minAmount}
                      perSmsCharge={
                        razorPayCredentials &&
                        razorPayCredentials[0].metaData.split('_')[0]
                      }
                    />
                  </MyModal>
                  {order_id && (
                    <Payment
                      ref={payRef}
                      order_id={order_id}
                      keyId={keyId}
                      handleSend={confirmWallet}
                      name={userData && userData.tradeName}
                      email={userData && userData.email}
                      contact={userData && userData.contactNumber}
                    />
                  )}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div
          className="w-100 p-4 flex-column align-items-center"
          style={{ display: display }}
        >
          <Suspense fallback={'loading...'}>
            <CustomerContainer
              message={message}
              handleReset={handleReset}
            ></CustomerContainer>
          </Suspense>
        </div>
      </div>
    );
  }
);

export default connect(mapStateToProps, {
  getWallet,
  getOrder,
  confirmWallet,
  getNotificationCount,
})(SMS);
