import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Button, List, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toasterMessage } from '../../../../@utils/utils';
import AddDecrement from '../../../../CommonComponents/addDecrement';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';
import {
  addGiftCard,
  removeGiftCard,
  incOrDec,
  incOrDecTotal,
} from '../rewardsAction';

const useStyles = makeStyles(theme => ({
  giftCardLayout: {
    padding: '1rem',
    borderRadius: '1rem',
    display: 'grid',
    gridTemplateColumns: '10rem 10rem',
    gridTemplateRows: 'auto 0.3fr',
    height: '10rem',
  },
  redeemBtn: {
    justifySelf: 'end',
    margin: '0.5rem 0',
    padding: '0.3rem 0.8rem',
    borderRadius: '0.5rem',
    height: '3.5em',
  },
  incDecBtn: {
    display: 'grid',
    justifyContent: 'end',
    margin: '0.5rem 0',
    padding: '0.3rem 0.8rem',
  },
  rs: {
    fontSize: '2.25rem',
    fontWeight: '600',
    marginBottom: '0px',
  },
  voucher: {
    fontSize: '1.15rem',
    fontWeight: '300',
    marginBottom: '0px',
  },
  pointsRequired: {
    color: theme.palette.primary.placeHolderActiveText,
    fontSize: '1rem',
    fontWeight: '500',
    marginBottom: '0px',
  },
  pnts: {
    color: theme.palette.primary.placeHolderActiveText,
    fontSize: '1rem',
    fontWeight: '400',
    marginBottom: '0px',
  },
}));

const GiftCardInfo = props => {
  const { giftCard } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inCart, setInCart] = useState(0);
  const [showToast, setShowToast] = useState([false]);

  useEffect(() => {
    if (props.total > 4) {
      setShowToast([true]);
    } else setShowToast([false]);
  }, [props.total]);

  const handleRedeem = () => {
    analyticFunc(eventsPayload.Reward[3]);
    if (props.total < 4) {
      dispatch(incOrDecTotal(1));
      setInCart(inCart + 1);
      dispatch(
        addGiftCard({
          ...giftCard,
          price: parseInt(giftCard.custom_denominations, 10),
        })
      );
    } else {
      setShowToast([true]);
    }
  };

  const handleIncrement = () => {
    if (props.total < 4) {
      dispatch(incOrDecTotal(1));
      setInCart(inCart + 1);
      dispatch(incOrDec({ giftCard, by: 1 }));
    } else {
      setShowToast([true]);
    }
  };

  const handleDecrement = () => {
    dispatch(incOrDecTotal(-1));
    setInCart(inCart - 1);
    const temp =
      inCart === 1
        ? dispatch(removeGiftCard(giftCard))
        : dispatch(incOrDec({ giftCard, by: -1 }));
  };

  const handleToastClose = () => {
    setShowToast([false]);
  };

  return (
    <Paper className={classes.giftCardLayout}>
      <div>
        <p className={classes.rs}>&#8377;{giftCard.custom_denominations}</p>
        <p className={classes.voucher}>VOUCHER</p>
      </div>
      <div style={{ justifySelf: 'end' }}>
        <p className={classes.pointsRequired}>Points Required</p>
        <p style={{ justifySelf: 'end' }} className={classes.pnts}>
          {giftCard.custom_denominations * props.conversionRate}
        </p>
      </div>
      <img
        src={giftCard.images.image}
        style={{ objectFit: 'fill' }}
        alt="Gift Card Logo"
        height="50%"
        width="50%"
      />
      {inCart === 0 && (
        <Button
          onClick={handleRedeem}
          className={classes.redeemBtn}
          variant="contained"
          color="primary"
        >
          REDEEM
        </Button>
      )}
      {inCart > 0 && (
        <AddDecrement
          toDisplay={inCart}
          style={{
            display: 'grid',
            justifyContent: 'end',
            margin: '0.58rem 0',
          }}
          inStyle={{
            display: 'grid',
            gridTemplateColumns: '0.2fr 0.1fr 0.2fr',
            height: '3.5em',
            alignContent: 'center',
          }}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showToast[0]}
        autoHideDuration={5000}
        onClose={handleToastClose}
      >
        <MuiAlert
          variant="filled"
          onClose={handleToastClose}
          severity="warning"
        >
          Max 4 Gift Cards allowed
        </MuiAlert>
      </Snackbar>
    </Paper>
  );
};

const mapStateToProps = ({ RewardsReducer }) => ({
  conversionRate: RewardsReducer.conversionRate,
  total: RewardsReducer.total,
});

export default connect(mapStateToProps, null)(GiftCardInfo);
