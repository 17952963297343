import React, { useRef, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MyModal from '../WebComponents/Modal';
import { StarOutlined, StarBorder, Send } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  getNotification,
  getNotificationCount,
  readNotification,
  feedBack,
  clearNotification,
} from '../../store/RetailerWebStore/RetailerAction/notifications';
import Loader from 'react-loader-spinner';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const Feedback = React.memo(
  ({ notificationId, handleModal, feedBack, updateNotification }) => {
    const [star, setStar] = useState(0);

    const handleClick = useCallback(value => {
      setStar(value);
      console.log('START vALUE--->', value);
    }, []);

    const handleSend = useCallback(value => {
      console.log('index start index====...>>>', star);
      feedBack({ notificationId, star: value }, updateNotification);
      handleModal();
    }, []);

    return (
      <div className="m-3">
        {[1, 2, 3, 4, 5].map(item => (
          <StarOutlined
            key={item}
            className="hover-pointer m-1"
            style={item <= star ? { color: 'yellow' } : { color: 'lightgrey' }}
            fontSize="large"
            onClick={e => handleClick(item)}
          />
        ))}
        <Send
          fontSize="large"
          className="text-themeorange hover-pointer"
          onClick={e => handleSend(star)}
        />
      </div>
    );
  }
);

function AlignItemsList({
  notifications,
  notificationList,
  getNotification,
  getNotificationCount,
  readNotification,
  feedBack,
  clearNotification,
  loading,
}) {
  const classes = useStyles();
  const [notificationId, setNotificationId] = useState('');
  const [load, setLoad] = useState(true);
  const [list, setList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const modalRef = useRef();
  const observer = useRef();
  const scrollref = useRef();

  useEffect(() => {
    clearNotification();
    getNotification({ page: 1, numberOfItems: 10 });
  }, []);

  const updateNotification = () => {
    clearNotification();
    getNotificationCount();
    getNotification({ page: 1, numberOfItems: 10 });
    setPageNumber(1);
  };

  useEffect(() => {
    setList(notifications);
  }, [notifications]);

  console.log('notificationList---------->', list, notificationList);

  // const loadMore = useCallback(
  //   (node) => {
  //     if (observer.current) observer.current.disconnect()
  //     observer.current = new IntersectionObserver(
  //       (enteries) => {
  //         if (enteries[0].isIntersecting && load ) {
  //           console.log('load')
  //           let pg = pageNumber + 1
  //           getNotification({
  //           page: pg, numberOfItems: 10,})
  //           setPageNumber(pg)
  //           // setList([...list, ...notifications])
  //           setLoad(false)
  //         }
  //       },
  //       {
  //         threshold: 0.01,
  //         rootMargin: '75%',
  //       },
  //     )
  //     if (node) observer.current.observe(node)
  //   },
  //   [observer, list],
  // )

  const loadMore = useCallback(() => {
    console.log('load outside');
    if (list.length < pageNumber * 10) return;
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight >=
      scrollref.current.scrollHeight
    ) {
      let pg = pageNumber + 1;
      getNotification({ page: pg, numberOfItems: 10 });
      setPageNumber(pg);
      console.log('load inside');
    }
  }, [list]);

  return (
    <div style={{ width: 300, height: 500 }}>
      {loading ? (
        <Loader />
      ) : (
        <List
          className={classes.root}
          style={{ maxHeight: '80vh', overflowY: 'scroll' }}
          ref={scrollref}
          onScroll={loadMore}
        >
          {list && list.length > 0 ? (
            list.map(
              (item, i) => (
                // i + 1 == list.length ? (
                <div key={i}>
                  <ListItem
                    alignItems="flex-start"
                    className="hover-pointer"
                    onClick={e => {
                      !item.is_read &&
                        readNotification(
                          { notificationId: item.notificationId },
                          updateNotification
                        );
                      setNotificationId(item.notificationId);
                      item.data && modalRef.current.click();
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Image" src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      // primary={item.text}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            style={item.is_read ? null : { fontWeight: 'bold' }}
                          >
                            {item.text}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              )
              // ) : (
              //   <div key={i}>
              //     <ListItem
              //       alignItems="flex-start"
              //       className="hover-pointer"
              //       onClick={(e) => {
              //         !item.is_read && readNotification({notificationId : item.notificationId}, updateNotification)
              //         setNotificationId(item.notificationId)
              //        item.data && modalRef.current.click()
              //       }}
              //     >
              //       <ListItemAvatar>
              //         <Avatar alt="Image" src="/static/images/avatar/1.jpg" />
              //       </ListItemAvatar>
              //       <ListItemText
              //         // primary={item.text}
              //         secondary={
              //           <React.Fragment>
              //             <Typography component="span" variant="body2" className={classes.inline} color="textPrimary" style={item.is_read ? null : { fontWeight: 'bold'}}>
              //             {item.text}
              //             </Typography>
              //           </React.Fragment>
              //         }
              //       />
              //     </ListItem>
              //     <Divider variant="inset" component="li" />
              //   </div>
              // ),
            )
          ) : (
            <div style={{ paddingTop: '7%' }}>
              <h4>" NO Notification "</h4>
            </div>
          )}
        </List>
      )}

      <MyModal ref={modalRef}>
        <Feedback
          notificationId={notificationId}
          feedBack={feedBack}
          updateNotification={updateNotification}
        />
      </MyModal>
    </div>
  );
}

const mapStateToProps = ({ notifications, Loader }) => {
  return {
    notifications: notifications.notifications,
    loading: Loader.loading,
  };
};

export default connect(mapStateToProps, {
  getNotification,
  getNotificationCount,
  readNotification,
  feedBack,
  clearNotification,
})(AlignItemsList);
