import { GET_NOTIFICATION, NOTIFICATION_COUNT } from '../RetailerAction/types';

const initialState = {
  notifications: [],
  notificationsCount: '',
};

const Notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_NOTIFICATION': {
      console.log('REDUCER NOTIFICATAIOSMSN----->', payload);
      return {
        ...state,
        notifications: payload ? [...state.notifications, ...payload] : [],
      };
    }
    case 'NOTIFICATION_COUNT': {
      return {
        ...state,
        notificationsCount: payload,
      };
    }
    default:
      return state;
  }
};
export default Notifications;
