/* eslint-disable */
import api, { baseURL } from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import { SET_LOADING } from './types';
import Axios from 'axios';

export const getSuperCategoryList = () => dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .get('/category/categoryList')
    .then(({ data }) => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: 'SUPER CATEGORIES', payload: data.data.categoryList });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const getSuperCategorySignup = () => dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  Axios.get(`${baseURL.baseURL}category/categoryList`)
    .then(({ data }) => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: 'SUPER CATEGORIES', payload: data.data.categoryList });
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const updateCategory = (payload, cb, fromRegister) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('category/selectCategory', payload)
    .then(response => {
      dispatch({ type: SET_LOADING, payload: false });
      fromRegister &&
        dispatch({ type: 'UPDATE_REGISTRATION', payload: { isRegister: 2 } });
      toasterMessage('success', response.data.message);
      cb && cb();
    })
    .catch(error => {
      dispatch({ type: SET_LOADING, payload: false });
      toasterMessage('error', error.response.data.message);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};
