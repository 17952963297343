import React, { useState, useEffect, useSelector } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddProductModal from '../../Billing/components/ProductComponents/addProductModal';
import { toasterMessage } from '../../../@utils/utils';
import { connect } from 'react-redux';
import EditProduct from '../../Billing/components/ProductComponents/editProduct';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  divInfo: {
    marginBottom: '1rem',
    display: 'grid',
    gridTemplateColumns: '0.8fr 0.2fr',
    gridTemplateRows: '0.8fr 0.1fr 0.1fr',
    borderBottom: '1px solid #D9DBE9',
  },
  btn: {
    whiteSpace: 'nowrap',
    gridRow: '1/-1',
    gridColumn: '2',
    margin: 'auto',
  },
}));

const InventoryInfo = props => {
  const classes = useStyles();

  const { userInventoryItem } = props;
  const { searchedInventoryItem } = props;
  let currentInventoryItem = userInventoryItem
    ? userInventoryItem
    : searchedInventoryItem;
  const {
    modelName,
    modelQuantity,
    MRP,
    units,
    sellingPrice,
    totalModelCount,
    days,
  } = currentInventoryItem;
  const [toggleAddProductModal, setToggleAddProductModal] = useState(false);
  const [toggleEditProductModal, setToggleEditProductModal] = useState(false);
  const [priceToShow, setPriceToShow] = useState(0);

  let stockTextColor = '';
  if (modelQuantity > 10) stockTextColor = 'text-success';
  else if (modelQuantity === 0) stockTextColor = 'text-danger';
  else stockTextColor = 'text-warning';

  const stockClassName = 'subtitle ' + stockTextColor;

  useEffect(() => {
    setPriceToShow(sellingPrice ? sellingPrice : 0);
  }, []);

  const handleAddProduct = () => {
    analyticFunc(eventsPayload.ManageInventory[12]);
    let filteredArray = props.usersInventory.filter(
      item => item.modelName === currentInventoryItem.modelName
    );
    if (filteredArray.length > 0) {
      toasterMessage('success', 'Already in your Inventory');
    } else {
      setToggleAddProductModal(true);
    }
  };

  const handleEditProduct = () => {
    analyticFunc(eventsPayload.ManageInventory[9]);
    setToggleEditProductModal(true);
  };

  return (
    <div className={classes.divInfo}>
      <h4 className="sm-headline">{modelName}</h4>
      {/* //! Handle Acc. to add and Edit  */}
      <Button
        onClick={searchedInventoryItem ? handleAddProduct : handleEditProduct}
        className={classes.btn}
        color="primary"
        variant="outlined"
      >
        {searchedInventoryItem ? 'Add +' : 'Edit'}
      </Button>

      <span style={{ gridRow: '2' }} className="subtitle mb-2">
        &#8377;{` ${priceToShow}/${units}`}
      </span>

      <h6 style={{ gridRow: '3' }} className={stockClassName}>
        {`STOCK: ${modelQuantity ? modelQuantity : 'NA'}`}
      </h6>
      <AddProductModal
        toggleModal={toggleAddProductModal}
        setToggleModal={setToggleAddProductModal}
        product={searchedInventoryItem}
        from={'inventory'}
      />
      <EditProduct
        toggleModal={toggleEditProductModal}
        setToggleModal={setToggleEditProductModal}
        product={currentInventoryItem}
        from={'inventory'}
      />
    </div>
  );
};
const mapStateToProps = ({ ManageInventoryReducer }) => {
  return {
    usersInventory: ManageInventoryReducer.usersInventory,
  };
};

export default connect(mapStateToProps, null)(InventoryInfo);
