// for production
// export const REACT_APP_FACEBOOK_ID = process.env.NODE_ENV === 'development' ? '537984453528145' : '1282621998600971'
// export const REACT_APP_GOOGLE_ID =
//   process.env.NODE_ENV === 'development'
//     ? '868903640552-u8ds48qglu3i7nb8dsdl5oqr96v0a92k.apps.googleusercontent.com'
//     : '300416987926-qtugf9pequ4jmnb6i96r9519ck50rqot.apps.googleusercontent.com'

// for n1 and localhost
export const REACT_APP_GOOGLE_ID =
  '868903640552-u8ds48qglu3i7nb8dsdl5oqr96v0a92k.apps.googleusercontent.com';
export const REACT_APP_FACEBOOK_ID = '254627645709473';
// export const REACT_APP_FACEBOOK_ID = "3594982813912848";
// export const REACT_APP_MAP_KEY = "AIzaSyDCJOIA3sGdG37ObmUBsBO3dK3aXfkUuLQ"
export const REACT_APP_MAP_KEY = 'AIzaSyDj_PMTtRB8dZ_PVMBuafYY0j9QladAYyE';
