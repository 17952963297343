/* eslint-disable */
const initialState = {
  models: [],
  model: [],
};

const getModelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIEW MODELS': {
      return {
        ...state,
        models: action.payload,
      };
    }
    case 'SHOW MODEL': {
      return {
        ...state,
        model: action.payload,
      };
    }
    case 'HANDLE_MODEL':
      return { ...state, model: { ...state.model, ...action.payload } };
    case 'RESET_MODEL':
      return { ...state, model: {} };
    case 'RESET_MODELS': {
      return {
        ...state,
        models: [],
      };
    }
    case 'SEARCH_MODEL':
      return {
        ...state,
        models: action.payload.models,
      };
    case 'RESET_SEARCH_MODEL': {
      return { ...state, models: [] };
    }
    default:
      return state;
  }
};
export default getModelsReducer;
