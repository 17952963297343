/* eslint-disable */
const initialState = {
  sampleData: [],
  sample: {},
};

const SampleDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIEW SAMPLE_DATA': {
      return {
        ...state,
        sampleData: action.payload,
      };
    }
    case 'HANDLE_SAMPLE_DATA': {
      return { ...state, sample: { ...state.sample, ...action.payload } };
    }
    case 'RESET_SAMPLE_DATA': {
      return { ...state, sample: {} };
    }
    case 'RESET_SEARCH_RETAILER': {
      return { ...state, sampleData: [] };
    }
    default:
      return state;
  }
};
export default SampleDataReducer;
