import React, { Suspense, lazy } from 'react';
const RewardDetails = lazy(() => import('./rewardDetails'));

const RewardPoints = React.memo(({}) => {
  return (
    <Suspense fallback={'loading...'}>
      <RewardDetails />
    </Suspense>
  );
});

export default RewardPoints;
