/* eslint-disable */
import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';

export const forgotPassword = (payload, cb) => async dispatch => {
  api
    .post('user/forgotPassword', payload)
    .then(response => {
      cb && cb(2);
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const verifyForgetPasswordOtp = (payload, cb) => async dispatch => {
  api
    .post('user/verifyForgetPasswordOtp', payload)
    .then(response => {
      cb && cb(3);
      localStorage.setItem('token', response.data.data);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const resetPassword = (payload, cb) => async dispatch => {
  api
    .post('user/resetPassword', payload)
    .then(response => {
      toasterMessage('success', response.data.message);
      cb && cb();
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};
