import React, { useEffect, useState, useCallback } from 'react';
import { Button, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toasterMessage } from '../../../@utils/utils';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import CustomInput from '../../../CommonComponents/customInput';
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {
  REACT_APP_FACEBOOK_ID,
  REACT_APP_GOOGLE_ID,
} from '../../../@utils/config';
import api from '../../../api/api';

const useStyles = makeStyles(theme => ({
  loginCont: {
    height: '50%',
    textAlign: 'center',
    // width: "50%",
    fontStyle: 'bold',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '22rem',
    // [theme.breakpoints.down("sm")]: { width: "auto",
    // },
    [theme.breakpoints.up('sm')]: {
      minWidth: '35rem',
    },
  },
  link: {
    color: '#FE805C',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    // minWidth: "20.4rem",
    color: 'white',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    margin: '0 auto 1rem auto',
  },
}));

const EmailLogin = ({
  classes,
  invalidEmailError,
  setIsEmailLogin,
  handleEmailInput,
  handleEmailLogin,
  setIsLoggedIn,
}) => (
  <div className={classes.loginCont}>
    <div style={{ minWidth: '20rem', marginBottom: '3rem' }}>
      <CustomInput
        onChange={handleEmailInput}
        error={invalidEmailError}
        autoFocus={true}
        inputLabel={'Email'}
      />
      {invalidEmailError && (
        <p className="errorLine">Oops. Please check your email address.</p>
      )}
      <Button
        variant="contained"
        fullWidth
        onClick={handleEmailLogin}
        color="primary"
      >
        Login
      </Button>
    </div>
    <span>
      Login with{' '}
      <a
        style={{ color: '#FE805C' }}
        onClick={() => setIsEmailLogin(false)}
        className={classes.link}
      >
        social account
      </a>{' '}
      instead
    </span>
  </div>
);

const SocialLogin = ({
  classes,
  googleLogin,
  isSimpleLogin,
  setIsEmailLogin,
  facebookLogin,
}) => (
  <>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
      }}
    >
      <FacebookLogin
        appId={REACT_APP_FACEBOOK_ID}
        fields="name,email,picture"
        scope="email,public_profile,user_gender,user_likes,user_location,user_posts"
        disableMobileRedirect
        callback={facebookLogin}
        onFailure={e => {}}
        render={renderProps => (
          <Button
            variant="contained"
            className={classes.btn}
            startIcon={<FacebookIcon />}
            onClick={renderProps.onClick}
            style={{ background: '#597CBB' }}
          >
            Login with Facebook
          </Button>
        )}
      />
      <p
        style={{
          marginRight: 'auto',
          marginLeft: 'auto',
          width: '55%',
          color: '#7A7A7A',
          fontSize: '0.8rem',
        }}
      >
        Use Facebook login to get personalized gift vouchers basis brands you
        like.
      </p>
      {/* 
      <Button
        variant="contained"
        className={classes.btn}
        startIcon={<TwitterIcon />}
        onClick={() => setIsLoggedIn(!isLoggedIn)}
        style={{ background: "#7ECAED" }}
      >
        Login with Twitter
      </Button> */}
    </div>
    {!isSimpleLogin && (
      <div>
        <h5 style={{ color: '#BDBDBD', marginBottom: '2rem' }}>OR</h5>
        <span>
          Login with{' '}
          {/* <GoogleLogin clientId={REACT_APP_GOOGLE_ID}
          render={renderProps => (
            <a
              style={{ color: "#FE805C", marginRight: "0.3rem" }}
              onClick={renderProps.onClick}
              className={classes.link}
            >
              Google
            </a>
          )}
          buttonText="Google"
          onSuccess={googleLogin}
          onFailure={() => {}}
          cookiePolicy={"single_host_origin"}
        />
        or{" "} */}
          <a
            style={{ color: '#FE805C' }}
            onClick={() => setIsEmailLogin(true)}
            className={classes.link}
          >
            Email
          </a>
        </span>
      </div>
    )}
  </>
);

const Login = props => {
  const classes = useStyles(props);
  const {
    setIsLoggedIn,
    token,
    isSimpleLogin,
    setWaitingForResponse,
    setResponse,
    setFbCred,
  } = props;
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  const googleLogin = useCallback(res => {
    const { email, googleId } = res.profileObj;
    let payload = {
      userId: googleId,
      email: email,
      loginType: 'Google',
      token: token,
    };
    getSurveyUrl(payload);
  }, []);

  const facebookLogin = res => {
    let payload = {
      userId: res.userID,
      userToken: res.accessToken,
      email: res.email,
      loginType: 'Facebook',
      token: token,
    };
    if (isSimpleLogin) payload = { ...payload, name: res.name };
    setFbCred(payload);
    if (!isSimpleLogin) getSurveyUrl(payload);
    else setIsLoggedIn(true);
  };

  const getSurveyUrl = payload => {
    setWaitingForResponse(true);
    api
      .post('survey/surveyLogin', payload)
      .then(response => {
        const { data } = response.data;
        setWaitingForResponse(false);
        setResponse(data);
        setIsLoggedIn(true);
      })
      .catch(error => {
        setWaitingForResponse(false);
        toasterMessage('error', 'Something From server happened');
        // toasterMessage("error", error.response.data.error);
      })
      .catch(() => {
        setWaitingForResponse(false);
        toasterMessage('error', 'Something bad happened');
      });
  };

  const isValidEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(userEmail);
  };

  const handleEmailInput = e => {
    setInvalidEmailError(false);
    setUserEmail(e.target.value);
  };
  const handleEmailLogin = () => {
    if (isValidEmail()) {
      let payload = {
        email: userEmail,
        loginType: 'Custom',
        token: token,
      };
      getSurveyUrl(payload);
    } else {
      setInvalidEmailError(true);
    }
  };

  return (
    <Paper className={classes.loginCont}>
      {!isEmailLogin && (
        <SocialLogin
          classes={classes}
          setIsEmailLogin={setIsEmailLogin}
          facebookLogin={facebookLogin}
          isSimpleLogin={isSimpleLogin}
          setIsLoggedIn={setIsLoggedIn}
          googleLogin={googleLogin}
        />
      )}

      {!isSimpleLogin && isEmailLogin && (
        <EmailLogin
          classes={classes}
          handleEmailInput={handleEmailInput}
          invalidEmailError={invalidEmailError}
          handleEmailLogin={handleEmailLogin}
          setIsEmailLogin={setIsEmailLogin}
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
    </Paper>
  );
};

export default Login;
