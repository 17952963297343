import 'date-fns';
/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormLabel,
  Switch,
  IconButton,
  InputAdornment,
  Input,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
/* eslint-disable */
import api from '../../api/api';
import { getStateCity } from '../../store/AdminActions/allStateCityAction';
import { toasterMessage, toastDismiss } from '../../@utils/utils';
import { connect } from 'react-redux';
import Loader from '../../RetailerWebPortal/Loader';

const ShopDetails = React.memo(
  ({ handleNext, stateCity, getStateCity, data }) => {
    const [tradeName, setTradeName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressline2, setAddressLine2] = useState('');
    const [country, setCountry] = useState('');
    const [countryId, setCountryId] = useState('1');
    const [state, setState] = useState('');
    const [stateId, setStateId] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [pincode, setPincode] = useState('');
    const [isGstNumber, setIsGstNumber] = useState('false');
    const [legalName, setLegalName] = useState('');
    const [gstNumber, setGSTNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [inputCity, setInputCity] = useState('');
    const [error, setError] = useState('');
    const [toggle, setToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cinNumber, setCinNumber] = useState('');

    useEffect(() => {
      toastDismiss();
      stateCity.length < 1 && getStateCity();
    }, []);

    useEffect(() => {
      if (data && Object.keys(data).length > 0) {
        setTradeName(data.tradeName);
        setAddressLine1(data.addressLine1);
        setAddressLine2(data.addressline2);
        setCountry(data.country);
        setCountryId(data.countryId);
        setCityId(data.cityId);
        setCity(data.city);
        setStateId(data.stateId);
        setState(data.state);
        setPincode(data.pincode);
        setIsGstNumber(data.isGstNumber);
        setLegalName(data.legalName);
        setGSTNumber(data.gstNumber);
        setWebsite(data.website);
        setCinNumber(data.cinNumber);
      }
    }, [data]);

    // const validateGst = useCallback(() => {
    // toastDismiss()
    //   setLoading(true)
    //   api
    //     .post('user/gstValidator', { type: 'REGISTRATION', gstNumber })
    //     .then((response) => {
    //       const { address, tradeNam } = response.data.data
    //       const { pncd, stcd, dst } = address
    //       setTradeName(tradeNam)
    //       setCountry('India')
    //       let arr = stateCity.filter((item) => item.stateName.toUpperCase() == stcd.toUpperCase())
    //       if (arr.length > 0) {
    //         setState(arr[0])
    //         setStateId(arr[0].stateId)
    //         let arr1 = arr[0].cityList.filter((c) => c.City.toUpperCase() == dst.toUpperCase())
    //         if (arr1.length > 0) {
    //           setPincode(pncd)
    //           setCity(arr1[0])
    //           setCityId(arr1[0].cityId)
    //         } else {
    //           toasterMessage('info', 'City is not in list')
    //         }
    //       } else {
    //         toasterMessage('info', 'State is not in list')
    //       }
    //       setLoading(false)
    //     })
    //     .catch((error) => {
    //       toasterMessage('error', error.response.data.error)
    //       setLoading(false)
    //     })
    //     .catch(() => {
    //       toasterMessage('error', 'something bad happened')
    //       setLoading(false)
    //     })
    // }, [gstNumber])

    const checkNext = useCallback(() => {
      let payload = {
        tradeName,
        addressLine1,
        addressline2: addressline2 ? addressline2 : undefined,
        countryId: '1',
        country,
        state,
        stateId,
        city,
        cityId,
        pincode,
        isGstNumber,
        legalName:
          isGstNumber == 'true' ? (toggle ? tradeName : legalName) : undefined,
        gstNumber: isGstNumber == 'true' ? gstNumber : undefined,
        website: website ? website : undefined,
        cinNumber: cinNumber ? cinNumber : undefined,
      };
      if (
        tradeName &&
        addressLine1 &&
        country &&
        stateId &&
        cityId &&
        pincode &&
        (isGstNumber == 'true' ? gstNumber : isGstNumber)
      ) {
        return handleNext(payload);
      }
      setError('Please fill all the fields');
    });

    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <TextField
            label="Shop/Trader Name *"
            fullWidth
            value={tradeName}
            onChange={e => setTradeName(e.target.value)}
          />
          <div className="field-container">
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="Address Line1 *"
                value={addressLine1}
                onChange={e => setAddressLine1(e.target.value)}
              />
            </div>
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="Address Line2"
                value={addressline2}
                onChange={e => setAddressLine2(e.target.value)}
                autoComplete="addressline2"
              />
            </div>
          </div>
          <div className="field-container">
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="Pin Code *"
                value={pincode}
                onChange={e =>
                  setPincode(
                    e.target.value
                      .replace(/[^0-9]/g, '')
                      .toString()
                      .slice(0, 6)
                  )
                }
              />
            </div>
            <div className="signup-fields">
              <FormControl variant="outlined" className="w-100">
                <InputLabel id="demo-simple-select-outlined-label">
                  Country *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                  label="Country"
                >
                  <MenuItem value={'India'}>India</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="field-container">
            <div className="signup-fields">
              {/* <Autocomplete
              id="combo-box-demo"
              freeSolo
              value={state}
              onChange={(e, newValue) => {
                if (newValue) {
                  setState(newValue)
                  setStateId(newValue.stateId)
                } else {
                  state && setState('')
                  stateId && setStateId('')
                }
                inputCity && setInputCity('')
                cityId && setCityId('')
                city && setCity('')
              }}
              inputValue={inputValue}
              onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue)
                inputCity && setInputCity('')
              }}
              options={stateCity}
              getOptionLabel={(option) => (option ? option.stateName : '')}
              renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
            /> */}
              <FormControl variant="outlined" className="w-100">
                <InputLabel id="demo-simple-select-outlined-label">
                  State *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state}
                  onChange={e => {
                    setState(e.target.value);
                    setStateId(e.target.value.stateId);
                    setCityId('');
                    setCity('');
                  }}
                  label="State"
                >
                  {stateCity.length > 0 &&
                    stateCity.map(item => (
                      <MenuItem key={item.stateId} value={item}>
                        {item.stateName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="signup-fields">
              {/* <Autocomplete
              id="combo-box-demo"
              freeSolo
              value={city}
              onChange={(e, newValue) => {
                if (newValue) {
                  setCity(newValue)
                  setCityId(newValue.cityId)
                } else {
                  setCity('')
                  setCityId('')
                }
              }}
              inputValue={inputCity}
              onInputChange={(e, newInputValue) => {
                setInputCity(newInputValue)
              }}
              options={
                stateCity && stateCity.filter((item) => item.stateName == state.stateName)[0]
                  ? stateCity.filter((item) => item.stateName == state.stateName)[0].cityList
                  : []
              }
              getOptionLabel={(option) => option?option.City:''}
              renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
            /> */}
              <FormControl variant="outlined" className="w-100">
                <InputLabel id="demo-simple-select-outlined-label">
                  City *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={city}
                  onChange={e => {
                    setCity(e.target.value);
                    setCityId(e.target.value.cityId);
                  }}
                  label="City"
                >
                  {stateCity &&
                    stateCity.map(item => {
                      if (item.stateName == state.stateName) {
                        return item.cityList.map(c => (
                          <MenuItem key={c.cityId} value={c}>
                            {c.City}
                          </MenuItem>
                        ));
                      }
                    })}
                </Select>
              </FormControl>
            </div>
          </div>
          <FormControl component="fieldset" className="w-100">
            <FormLabel component="legend" className="text-left">
              GST
            </FormLabel>
            <RadioGroup
              row
              aria-label="position"
              value={isGstNumber}
              onChange={e => setIsGstNumber(e.target.value)}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value={'false'}
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          {isGstNumber == 'true' && (
            <div className="field-container">
              <div className="signup-fields">
                <TextField
                  className="w-100"
                  label="GST Number *"
                  // onBlur={validateGst}
                  value={gstNumber}
                  onChange={e => setGSTNumber(e.target.value)}
                />
              </div>
              <div className="signup-fields">
                <div className="d-flex w-100 justify-content-between">
                  <FormControl className="w-100">
                    <InputLabel htmlFor="Legal-Name">
                      Same as Legal/Trade Name{' '}
                    </InputLabel>
                    <Input
                      id="Legal-Name"
                      value={toggle ? tradeName : legalName}
                      onChange={e => setLegalName(e.target.value)}
                      multiline
                      rowsMax={3}
                      endAdornment={
                        <InputAdornment position="end">
                          <Switch onClick={e => setToggle(!toggle)} />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <TextField
            label="CIN Number(optional)"
            fullWidth
            value={cinNumber}
            onChange={e =>
              setCinNumber(e.target.value.replace(/[^0-9]/g, '').toString())
            }
          />
          <TextField
            label="Website Address(optional)"
            fullWidth
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
          <p className="text-themeorange">{error}</p>
          {!loading ? (
            <button
              className="w-50 rounded-pill text-white bg-dark border-0 p-3 mt-2"
              onClick={checkNext}
            >
              NEXT
            </button>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({ getStateCityReducer }) => {
  return { stateCity: getStateCityReducer.stateCity };
};

export default connect(mapStateToProps, { getStateCity })(ShopDetails);
