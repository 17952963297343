import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper, Button, LinearProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ContactList from './ContactComponents/contactList';
import Contact from './ContactComponents/contact';
import CreateContactModal from './ContactComponents/createContactModal';
import { connect } from 'react-redux';
import { contactSelected, getContacts, setContactList } from '../billingAction';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  selectedPaper: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.1fr',
    borderRadius: '1rem',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const BillToContactContainer = props => {
  const classes = useStyles();

  const [toggleCreateContact, setToggleCreateContact] = useState(false);
  const [debouncingTime, setDebouncingTime] = useState(0);
  const [showContactList, setShowContactList] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [showContactSearch, setShowContactSearch] = useState(true);
  const [selectedContact, setSelectedContact] = useState({});
  const [contactQuery, setContactQuery] = useState('');

  useEffect(() => {
    if (props.contactList.length > 0) {
      setContactList(props.contactList);
      setShowContactList(true);
    }
  }, [props.contactList]);

  useEffect(() => {
    props.setContactList([]);
    setShowContactList(false);
  }, []);

  useEffect(() => {
    if (Object.keys(props.addedContact).length !== 0) {
      setShowContactSearch(false);
      setSelectedContact(props.addedContact);
      props.setContactList([]);
    }
  }, [props.addedContact]);

  const handleSearch = query => {
    if (query.length >= 3) {
      debouncingTime && clearTimeout(debouncingTime);
      let time = setTimeout(() => {
        let contactPayload = {
          searchData: query,
        };
        // dispatch contact request
        props.getContacts(contactPayload);
      }, 1000);
      setDebouncingTime(time);
    } else {
      setShowContactList(false);
      props.setContactList([]);
    }
  };

  const handleClose = () => {
    props.contactSelected({});
    setSelectedContact({});
    setShowContactSearch(true);
  };

  const handleSelection = contact => {
    analyticFunc(eventsPayload.Billing[4]);
    setShowContactList(false);
    props.contactSelected(contact);
    setSelectedContact(contact);
    setShowContactSearch(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <p className="formTitle"> BILL TO (optional)</p>

      {showContactSearch && (
        <div className="inputContainer">
          <SearchIcon className="inputContainerIcon" />
          <TextField
            label="Enter Customer Name/Phone"
            fullWidth
            value={contactQuery}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={e => {
              setContactQuery(e.target.value);
              handleSearch(e.target.value);
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              analyticFunc(eventsPayload.Billing[5]);
              setToggleCreateContact(!toggleCreateContact);
            }}
          >
            Create
          </Button>
        </div>
      )}

      {props.loadingContacts[0] && <LinearProgress />}

      {!showContactSearch && (
        <Paper className={classes.selectedPaper}>
          <Contact
            name={selectedContact.customerName}
            phoneNumber={selectedContact.mobileNumber}
          />
          <CloseIcon onClick={handleClose} />
        </Paper>
      )}
      {showContactList && (
        <ContactList
          contactList={contactList}
          handleSelection={handleSelection}
        />
      )}

      <CreateContactModal
        preFilledName={contactQuery}
        setToggleCreateContact={setToggleCreateContact}
        toggleCreateContact={toggleCreateContact}
      />
    </div>
  );
};

const mapStateToProps = ({ BillingReducer }) => {
  return {
    contactList: BillingReducer.contactList,
    addedContact: BillingReducer.selectedContact,
    loadingContacts: BillingReducer.loadingContacts,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    getContacts: payload => dispatch(getContacts(payload)),
    contactSelected: payload => dispatch(contactSelected(payload)),
    setContactList: payload => dispatch(setContactList(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillToContactContainer);
