import { GST_OPTIONS, BRAND_OFFER_OPTIONS } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  GSTOptions: [],
  brandOfferOptions: [],
};

const gstOptions = (state = initialState, { type, payload }) => {
  switch (type) {
    case GST_OPTIONS: {
      return {
        ...state,
        GSTOptions: payload,
      };
    }
    case BRAND_OFFER_OPTIONS: {
      return {
        ...state,
        brandOfferOptions: payload,
      };
    }
    default:
      return state;
  }
};
export default gstOptions;
