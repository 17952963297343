import {
  ADD_PRODUCT_TO_LIST,
  GET_CONTACTS,
  GET_PRODUCTS,
  GET_SERIAL_NUMBER,
  UPDATE_CART_QUANTITY,
  GET_DISTRIBUTOR,
  CONTACT_SELECTED,
  EDIT_BILL,
  EDIT_PRODUCT,
  REMOVE_PRODUCT_FROM_LIST,
  SET_LOADING_CONTACT,
  GET_MORE_PRODUCTS,
  SET_LOADING_PRODUCT,
  SET_PRODUCT_LIST,
  VIEW_INVOICE,
  SET_PRODUCT_ID,
  CLEAR_SELECTED_PRODUCTS,
} from './types';

/* eslint-disable */
const initialState = {
  contactList: [],
  productList: [],
  invoiceDetails: [],
  selectedProducts: [],
  distributorList: [],
  units: [],
  selectedContact: {},
  billNumberAvailable: [true],
  loadingProducts: [false],
  loadingContacts: [false],
  isEditing: [false],
  paymentDetails: {},
  invoice: '',
  getProductId: '',
};

const BillingReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_CONTACTS: {
      return {
        ...state,
        contactList: data,
      };
    }
    case SET_LOADING_CONTACT: {
      return {
        ...state,
        loadingContacts: [data],
      };
    }
    case SET_PRODUCT_LIST: {
      return {
        ...state,
        productList: data,
      };
    }
    case GET_MORE_PRODUCTS: {
      return {
        ...state,
        productList: [...state.productList, ...data],
      };
    }
    case SET_LOADING_PRODUCT: {
      return {
        ...state,
        loadingProducts: [data],
      };
    }
    case 'SET_CONTACT_LIST': {
      return {
        ...state,
        contactList: data,
      };
    }
    case GET_PRODUCTS: {
      return {
        ...state,
        productList: data,
      };
    }
    case GET_SERIAL_NUMBER: {
      return {
        ...state,
        invoiceDetails: data,
      };
    }
    case 'PREFILL_DETAILS': {
      console.log(data);
      return {
        ...state,
        isEditing: [true],
        selectedContact: data.selectedContact,
        selectedProducts: data.selectedProducts,
        invoiceDetails: data.invoiceDetails,
        paymentDetails: data.paymentDetails,
      };
    }
    case 'GET_UNITS': {
      return {
        ...state,
        units: data,
      };
    }
    case EDIT_BILL: {
      return {
        ...state,
        billNumberAvailable: data.data === '1' ? [true] : [false],
        invoiceDetails: data.data === '1' ? data.payload : state.invoiceDetails,
      };
    }
    case 'SET_DISTRIBUTOR_LIST':
    case GET_DISTRIBUTOR: {
      return {
        ...state,
        distributorList: data,
      };
    }
    case ADD_PRODUCT_TO_LIST: {
      if (state.selectedProducts.indexOf(data) !== -1) {
        // show toaster message
      }
      return {
        ...state,
        selectedProducts:
          state.selectedProducts.indexOf(data) === -1
            ? [...state.selectedProducts, data]
            : [...state.selectedProducts],
      };
    }

    case CLEAR_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedProducts: initialState.selectedProducts,
      };
    }

    case CONTACT_SELECTED: {
      return {
        ...state,
        selectedContact: data,
      };
    }
    case UPDATE_CART_QUANTITY: {
      const { product, by } = data;
      return {
        ...state,
        selectedProducts: state.selectedProducts.map(p => {
          if (p.modelId === product.modelId) {
            return { ...p, inCartQuantity: p.inCartQuantity + by };
          }
          return p;
        }),
      };
    }
    case REMOVE_PRODUCT_FROM_LIST: {
      return {
        ...state,
        selectedProducts: state.selectedProducts.filter(
          p => p.modelId !== data.modelId
        ),
      };
    }
    case EDIT_PRODUCT: {
      return {
        ...state,
        selectedProducts: state.selectedProducts.map(p => {
          if (p.modelId === data.modelId) {
            return { ...data };
          }
          return p;
        }),
      };
    }
    case 'RESET_STATE': {
      return initialState;
    }
    case VIEW_INVOICE: {
      return {
        ...state,
        invoice: data,
      };
    }
    case SET_PRODUCT_ID: {
      return {
        ...state,
        getProductId: data,
      };
    }
    default:
      return state;
  }
};
export default BillingReducer;
