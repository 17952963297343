const initialState = {
  retailers: [],
  retailer: [],
  contacts: [],
  count: 0,
};

const RetailerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_RETAILER': {
      return {
        ...state,
        retailers: action.payload,
      };
    }
    case 'FILTER_RETAILERS': {
      return {
        ...state,
        retailers: action.payload,
      };

      // if (action.payload.length === 0 || Object.keys(action.payload).length === 0) {
      //   return {
      //     ...state,
      //     retailers: state.filterRetailers
      //   };
      // } else {
      //   const filterKeys = Object.keys(action.payload)
      //   return {
      //     ...state,
      //     retailers: state.filterRetailers.filter(retailer => {
      //       return filterKeys.every(key => {
      //         if (Array.isArray(action.payload[key])) {
      //           return retailer[key].some(keyEle => action.payload[key].includes(keyEle));
      //         }
      //         if (key === 'startDate' || key === 'endDate') {
      //           return retailer.registrationDate >= action.payload['startDate'] && retailer.registrationDate <= action.payload['endDate'];
      //         }
      //         return action.payload[key] === retailer[key]
      //       });
      //     })
      //   };
      // }
    }
    case 'SHOW_RETAILER': {
      return {
        ...state,
        retailer: action.payload,
      };
    }
    case 'COUNT': {
      return {
        ...state,
        count: action.payload,
      };
    }
    case 'HANDLE_RETAILER':
      return { ...state, retailer: { ...state.retailer, ...action.payload } };
    case 'RESET_RETAILER':
      return { ...state, retailer: {} };
    case 'HANDLE_SELECTED_NUMBERS':
      return {
        ...state,
        contacts: action.payload,
      };
    case 'RESET_SELECTED_NUMBERS': {
      return { ...state, contacts: [] };
    }
    case 'SEARCH_RETAILER':
      return {
        ...state,
        retailers: action.payload,
      };
    case 'RESET_SEARCH_RETAILER': {
      return { ...state, retailers: [] };
    }
    default:
      return state;
  }
};
export default RetailerReducer;
