/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';

const Pay = React.memo(({ handleModal, btnTitle, data, paymentDone }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    // setText(data)
  }, []);

  const onSuccess = useCallback(() => {
    handleModal();
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    console.log('DATA IN MODEL--->', data);
    paymentDone(text);
    onSuccess();
  };
  return (
    <div className="m-3 d-flex flex-column align-items-center justify-content-center">
      <Grid style={{ marginTop: '5%' }}>
        <h5>Are you sure you want to pay ?</h5>
      </Grid>
      <Grid style={{ marginTop: '3%' }}>
        <TextField
          autoFocus
          variant="outlined"
          label={'Payment Amount'}
          value={text}
          onChange={e => setText(e.target.value.replace(/[^\d]/, ''))}
        />
        <br />
        <br />
      </Grid>
      <div className="w-100 d-flex justify-content-around">
        <button
          className="rounded-pill w-30 text-white text-uppercase p-2 border-0 bg-dark"
          type="submit"
          onClick={handleModal}
        >
          Cancel
        </button>
        <button
          className="rounded-pill w-30 text-white text-uppercase p-2 border-0 bg-themeorange"
          type="submit"
          onClick={onSubmit}
        >
          Confirm
        </button>
      </div>
    </div>
  );
});

const mapStateToProps = ({ loginReducer }) => {
  return {};
};

export default connect(mapStateToProps, {})(Pay);
