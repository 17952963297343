/* eslint-disable */
const initialState = {
  onlineStores: [],
};

const Signup = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_ONLINE_STORES': {
      return {
        ...state,
        onlineStores: payload,
      };
    }
    default:
      return state;
  }
};
export default Signup;
