import React, { useState } from 'react';
import { Button, TextField, Divider, List } from '@material-ui/core';
import CustomInput from '../../../../../CommonComponents/customInput';

const MrpForm = props => {
  const { priceFormData, setPriceFormData } = props;

  const { MRP: mrp, purchasePrice } = priceFormData;

  const [toggleMrpSection, setToggleMrpSection] = useState(false);

  const handleInputChange = (inputValue, from) => {
    if (!isNaN(inputValue)) {
      let tpd = { ...priceFormData };
      tpd[from] = Number(inputValue);
      setPriceFormData(tpd);
    }
  };
  return (
    <div>
      <Button
        color="primary"
        onClick={() => setToggleMrpSection(!toggleMrpSection)}
      >
        {toggleMrpSection ? '-' : '+'} MRP & Purchase price
      </Button>

      {toggleMrpSection && (
        <div style={{ display: 'flex' }}>
          <CustomInput
            inputLabel="MRP"
            value={mrp}
            onChange={e => handleInputChange(e.target.value, 'MRP')}
          />
          <CustomInput
            inputLabel="Purchase Price"
            value={purchasePrice}
            onChange={e => handleInputChange(e.target.value, 'purchasePrice')}
          />
        </div>
      )}
    </div>
  );
};

export default MrpForm;
