const eventsPayload = {
  Onboarding: [
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Clicks _Google Icon',
      LABEL: 'Onboarding Started_Using Google',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Clicks _Facebook Icon',
      LABEL: 'Onboarding Started_Using Facebook',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Enters_Mobile Number and submits',
      LABEL: 'Onboarding Started_Using Mobile Number',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Clicks_Resend OTP',
      LABEL: 'Onboarding_Resend OTP',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Enters OTP and Submits',
      LABEL: 'Onboarding_OTP Received',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Clicks_Choose Categories',
      LABEL: 'Onboarding_Business Details Added',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: "Clicks_Let's Go",
      LABEL: 'Onboarding_Category Selected',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Onboarding Completed',
      LABEL: 'Onboarding Completed_Using Facebook',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Onboarding Completed',
      LABEL: 'Onboarding Completed_Using Google',
    },
    {
      CATEGORY: 'Onboarding',
      ACTION: 'Onboarding Completed',
      LABEL: 'Onboarding Completed_Using Mobile Number',
    },
  ],
  ManageInventory: [
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Manage Inventory',
      LABEL: 'Manage Inventory_Enter',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Search By Scan',
      LABEL: 'Manage Inventory_Search By Scan',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Search by Name',
      LABEL: 'Manage Inventory_Search By Name',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Create Product',
      LABEL: 'Manage Inventory_Create Product',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Create Product_Scan Barcode',
      LABEL: 'Manage Inventory_Create Product_Scan Barcode',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clcks_Add Existing Distributor',
      LABEL: 'Manage Inventory_Add Existing Distributor',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clcks_Add New Distributor',
      LABEL: 'Manage Inventory_Add New Distributor',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Add Distributor_Save',
      LABEL: 'Manage Inventory_Add New Distributor_Save',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Create Product_Save',
      LABEL: 'Manage Inventory_Create Product_Save',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Edit Product',
      LABEL: 'Manage Inventory_Edit Product',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Edit Product_Scan Barcode',
      LABEL: 'Manage Inventory_Edit Product_Scan Barcode',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Edit Product_Save',
      LABEL: 'Manage Inventory_Edit Product_Save',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Add Product',
      LABEL: 'Manage Inventory_Add Product',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Cicks_Add Product_Scan Barcode',
      LABEL: 'Manage Inventory_Add Product_Scan Barcode',
    },
    {
      CATEGORY: 'Manage Inventory',
      ACTION: 'Clicks_Add Product_Save',
      LABEL: 'Manage Inventory_Add Product_Save',
    },
  ],
  Billing: [
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Billing',
      LABEL: 'Billing_Enter',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Edit ',
      LABEL: 'Billing_Edit Bill Number and Date',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Edit _Calendar',
      LABEL: 'Billing_Edit Bill Number and Date_Calendar',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Edit _Save',
      LABEL: 'Billing_Edit Bill Number and Date_Save',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Customer Name_Add Existing Customer',
      LABEL: 'Billing_Customer Name_Add Existing Customer',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Customer Name_Add New Customer',
      LABEL: 'Billing_Customer Name_Add New Customer',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Customer Name_Add New Customer_Save',
      LABEL: 'Billing_Customer Name_Add New Customer_Save',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Item Name_New Product',
      LABEL: 'Billing_Search Item Name_New Product',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Item Name_Existing Product',
      LABEL: 'Billing_Search Item Name_Existing Product',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Search By Scan',
      LABEL: 'Billing_Search By Scan',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Cicks_Scan Barcode',
      LABEL: 'Billing_Scan Barcode',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_SAVE',
      LABEL: 'Billing_Save (add first item)  Billing_Save_Multiple   ',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Add More Item',
      LABEL: 'Billing_Add more item',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Next',
      LABEL: 'Billing_Next (move to payment method)',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Clicks_Payment Method_Finance',
      LABEL: 'Billing_Payment method Finance',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Save Invoice',
      LABEL: 'Billing_Save Invoice',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_View Invoice',
      LABEL: 'Billing_view invoice',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_See Reward',
      LABEL: 'Billing_See Reward',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Short Format',
      LABEL: 'Billing_Short Format',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Long Format',
      LABEL: 'Billing_Long Format',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Share Invoice',
      LABEL: 'Billing_Share Invoice',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Whatsapp',
      LABEL: 'Billing_Share By Whatsapp',
    },
    {
      CATEGORY: 'Billing',
      ACTION: 'Click_Print',
      LABEL: 'Billing_Print',
    },
  ],
  Expenses: [
    {
      CATEGORY: 'Expense',
      ACTION: 'Clicks_Expense',
      LABEL: 'Expense_Enter',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Add Expense Type',
      LABEL: 'Expense_Add Expense Type',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Add Expense Type_Add Image',
      LABEL: 'Expense_Add Expense Type_Add Image',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Add Expense Type_Submit',
      LABEL: 'Expense_Add Expense Type_Submit (Save) ',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Cick_Add Expense',
      LABEL: 'Expense_Add Expense',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Cick_Add Expense_Submit',
      LABEL: 'Expense_Add Expense_Submit (Save) ',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Edit Expense',
      LABEL: 'Expense_Edit Expense',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Edit Expense_Delete',
      LABEL: 'Expense_Edit Expense_Delete',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Edit Expense_Submit',
      LABEL: 'Expense_Edit Expense_Submit (Save) ',
    },
    {
      CATEGORY: 'Expense',
      ACTION: 'Click_Send Report',
      LABEL: 'Expense_Send Report',
    },
  ],
  Reward: [
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_Reward',
      LABEL: 'Rewards_Enter',
    },
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_History',
      LABEL: 'Rewards_History',
    },
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_Voucher',
      LABEL: 'Rewards_Vouchers',
    },
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_Redeem',
      LABEL: 'Rewards_Redeem',
    },
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_Checkout',
      LABEL: 'Rewards_Checkout',
    },
    {
      CATEGORY: 'Reward',
      ACTION: 'Clicks_Error Message Ok ',
      LABEL: 'Rewards_Error Message',
    },
  ],
  SearchInvoice: [
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Search Invoice',
      LABEL: 'Search Invoice_Enter',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Create Bill',
      LABEL: 'Search Invoice_Create Bill',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Search Bill',
      LABEL: 'Search Invoice_Search',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Invoice',
      LABEL: 'Search Invoice_Open Bill',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Invoice_Edit Invoice',
      LABEL: 'Search Invoice_Open Bill_Edit Invoice',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Invoice_Short Format',
      LABEL: 'Search Invoice_Open Bill_Short Format',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Invoice_Long Format',
      LABEL: 'Search Invoice_Open Bill_Long Format',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Edit',
      LABEL: 'Search Invoice_Edit',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Delete',
      LABEL: 'Search Invoice_Delete',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Delete_Cancel',
      LABEL: 'Search Invoice_Delete_Cancel',
    },
    {
      CATEGORY: 'Search Invoice',
      ACTION: 'Clicks_Delete_Ok',
      LABEL: 'Search Invoice_Delete_ok',
    },
  ],
  Notifications: [
    {
      CATEGORY: 'Notifications',
      ACTION: 'Clicks_Notification Icon',
      LABEL: 'Notification_Enter',
    },
  ],
  ChangeGST: [
    {
      CATEGORY: 'Change GST',
      ACTION: 'Clicks_Change GST',
      LABEL: 'Change GST_Enter',
    },
    {
      CATEGORY: 'Change GST',
      ACTION: 'Clicks_Yes',
      LABEL: 'Change GST_Yes (GST User)',
    },
    {
      CATEGORY: 'Change GST',
      ACTION: 'Clicks_No',
      LABEL: 'Change GST_No (Non GST User)',
    },
    {
      CATEGORY: 'Change GST',
      ACTION: 'Clicks_Save',
      LABEL: 'Change GST_Save',
    },
  ],
  ManageCategory: [
    {
      CATEGORY: 'Manage Category',
      ACTION: 'Clicks_Manage Category',
      LABEL: 'Manage Category_Enter',
    },
    {
      CATEGORY: 'Manage Category',
      ACTION: 'Clicks_Submit',
      LABEL: 'Manage Category_Submit',
    },
  ],
  AddSubUser: [
    {
      CATEGORY: 'Add Sub User',
      ACTION: 'Clicks_Sub User',
      LABEL: 'Sub User_Enter',
    },
    {
      CATEGORY: 'Add Sub User',
      ACTION: 'Clicks_Add SUb User',
      LABEL: 'Sub User_Add Sub User',
    },
    {
      CATEGORY: 'Add Sub User',
      ACTION: 'Clicks GET OTP',
      LABEL: 'Sub User_Add Sub User_Get OTP',
    },
  ],
  CreditBook: [
    {
      CATEGORY: 'Credit Book',
      ACTION: 'Clicks_Credit Book',
      LABEL: 'Credit Book_Enter',
    },
    {
      CATEGORY: 'Credit Book',
      ACTION: 'Clicks_Search',
      LABEL: 'Credit Book_Search',
    },
    {
      CATEGORY: 'Credit Book',
      ACTION: 'Clicks_On Listed credit item',
      LABEL: 'Credit Book _Select Creditor',
    },
    {
      CATEGORY: 'Credit Book',
      ACTION: 'Clicks_Mark as paid',
      LABEL: 'Credit Book _Mark as Paid',
    },
    {
      CATEGORY: 'Credit Book',
      ACTION: 'Clicks_Reminder',
      LABEL: 'Credit Book _Reminder',
    },
  ],
  SendSMS: [
    {
      CATEGORY: 'Send SMS',
      ACTION: 'Clicks_Send SMS',
      LABEL: 'Send SMS_Enter',
    },
    {
      CATEGORY: 'Send SMS',
      ACTION: 'Clicks_Next',
      LABEL: 'Send SMS_Next (after content)',
    },
    {
      CATEGORY: 'Send SMS',
      ACTION: 'Clicks_Add SMS',
      LABEL: 'Send SMS_Add SMS',
    },
    {
      CATEGORY: 'Send SMS',
      ACTION: 'Clicks_Add SMS_Submit',
      LABEL: 'Send SMS_Add SMS_Submit',
    },
  ],
  Reports: [
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Reports',
      LABEL: 'Reports_Enter',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Sales Analysis',
      LABEL: 'Reports_Sales Analysis_Enter',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Sales Analysis_Submit',
      LABEL: 'Reports_Sales Analysis_Submit',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Profit and loss',
      LABEL: 'Reports_Profit and loss_Enter',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Profit and loss_Next',
      LABEL: 'Reports_Profit and loss_Next',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Profit and loss_Send Email',
      LABEL: 'Reports_Profit and loss_Send Email',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Sales Report',
      LABEL: 'Reports_Sales Report_Enter',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Sales Report_Submit',
      LABEL: 'Reports_Sales Report_Submit',
    },
    {
      CATEGORY: 'Reports',
      ACTION: 'Clicks_Inventory Overview',
      LABEL: 'Reports_Iventory Overview_Enter',
    },
  ],
  Profile: [
    {
      CATEGORY: 'Profile',
      ACTION: 'Clicks _Profile',
      LABEL: 'Profile_Enter',
    },
    {
      CATEGORY: 'Profile',
      ACTION: 'Clicks _Add Logo',
      LABEL: 'Profie_Add Logo',
    },
    {
      CATEGORY: 'Profile',
      ACTION: 'Clicks _Add Signature',
      LABEL: 'Profie_Add Signature',
    },
    {
      CATEGORY: 'Profile',
      ACTION: 'Clicks_Update',
      LABEL: 'Profile_Update',
    },
  ],
  Help: [
    {
      CATEGORY: 'Help',
      ACTION: 'Clicks _Help',
      LABEL: 'Help_Enter',
    },
  ],
  Logout: [
    {
      CATEGORY: 'Logout',
      ACTION: 'Clicks _Logout',
      LABEL: 'Logout',
    },
  ],
};

export default eventsPayload;
