/* eslint-disable */
import React, { useEffect } from 'react';
import BarcodeScanner from './barcodeScanner';

function BarcodeScannerComponent({ productScan }) {
  const [data, setData] = React.useState('Not Found');

  return (
    <>
      <BarcodeScanner
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            productScan(result.text);
          } else setData('Not Found');
        }}
      />
      <p>{data}</p>
      <button onClick={() => productScan()}>Cancel</button>
    </>
  );
}

export default BarcodeScannerComponent;
