/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Models from './models';
import Search from '../SearchBar';
import {
  searchProduct,
  clearSearch,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { toastDismiss } from '../../../@utils/utils';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const ManageInventory = React.memo(
  ({ searchProduct, getNotificationCount }) => {
    const [val, setVal] = useState('');

    useEffect(() => {
      getNotificationCount();
      toastDismiss();
    }, []);

    const getSearchKeyword = useCallback(
      value => {
        (value.length >= 2 || value.length == 0) &&
          searchProduct({
            type: 'model',
            name: value,
            page: 1,
            numberOfItems: 50,
          });
        setVal(value);
      },
      [setVal, searchProduct]
    );

    const cleanSearch = useCallback(() => {
      setVal('');
    });
    return (
      <div className="p-4">
        <>
          <h3
            className="text-themeorange border-bottom pb-1"
            style={{ float: 'left' }}
          >
            Products
          </h3>
          <div className="d-flex justify-content-center align-items-center mb-2">
            <Search
              getSearchKeyword={getSearchKeyword}
              val={val}
              placeholder={'Enter Product Name'}
            />
          </div>
          <Models searchKeyword={val} cleanSearch={cleanSearch} />
        </>
      </div>
    );
  }
);

export default connect(null, {
  searchProduct,
  clearSearch,
  getNotificationCount,
})(ManageInventory);
