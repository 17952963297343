import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal, Paper } from '@material-ui/core';
import AddDecrement from '../../../../CommonComponents/addDecrement';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditProduct from './editProduct';
import AddProductModal from './addProductModal';
import { connect } from 'react-redux';
import {
  addProductToSelectedList,
  increaseOrDecrease,
  removeProductFromList,
} from '../../billingAction';
import { toasterMessage } from '../../../../@utils/utils';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';

const OLD_PROD_WARNING =
  'This product is not available in our active catalog, if you delete it, you will have to create it manually';
const NORMAL_WARNING =
  'Are your sure this item will be removed from the list ?';

const ProductInfo = props => {
  const {
    product,
    handleAdd,
    handleIncreaseOrDecrease,
    isSelected,
    isGstUser,
    from,
  } = props;

  const {
    modelName,
    MRP,
    modelQuantity,
    units,
    inCartQuantity,
    sellingPrice,
    is_active,
  } = product;

  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [priceToShow, setPriceToShow] = useState(0);
  const [isInList, setIsInList] = useState(false);

  const [popUpMsg, setPopUpMsg] = useState(NORMAL_WARNING);

  let stockTextColor = '';
  if (modelQuantity > 10) stockTextColor = 'text-success';
  else if (modelQuantity === 0) stockTextColor = 'text-danger';
  else stockTextColor = 'text-warning';

  const stockClassName = 'subtitle ' + stockTextColor;

  const getPriceToShow = () => {
    setPriceToShow(sellingPrice ? sellingPrice : 0);
  };

  useEffect(() => {
    getPriceToShow();
  }, [product]);

  const handleAddProduct = () => {
    // * add: "true" -> Popup + API
    // * if(gstUser) add: false && gst field is not there -> Popup
    // * if(!gstUser) add directly
    analyticFunc(eventsPayload.Billing[11]);
    let isInList = props.selectedProducts.filter(
      p => p.modelId === product.modelId
    );
    if (isInList.length === 0) {
      if (product.add || (isGstUser && !product.gst)) {
        setShowAddProductModal(!showAddProductModal);
      } else if ((!product.add && product.gst) || !isGstUser) {
        handleAdd(product);
      }
    } else {
      toasterMessage('success', 'Product already in list');
    }
  };

  useEffect(() => {
    if (props.selectedProducts.indexOf(product) !== -1) {
      setIsInList(true);
    } else {
      setIsInList(false);
    }
  }, [props.selectedProducts]);

  const handleDecrement = () => {
    if (product.inCartQuantity === 1) {
      if (!is_active) setPopUpMsg(OLD_PROD_WARNING);
      else setPopUpMsg(NORMAL_WARNING);
      setShowConfirmation(true);
    } else {
      handleIncreaseOrDecrease({ product, by: -1 });
    }
  };

  return (
    <div
      style={{
        marginTop: '1rem',
        marginBottom: '1rem',
        display: 'grid',
        gridTemplateColumns: '0.9fr 0.1fr',
        gridTemplateRows: '0.8fr 0.1fr 0.1fr',
        borderBottom: '1px solid #D9DBE9',
      }}
    >
      <p className="sm-headline">{modelName}</p>
      {!isSelected && (
        <Button
          style={{
            whiteSpace: 'nowrap',
            gridRow: '1/-1',
            gridColumn: '2',
            margin: 'auto',
          }}
          color="primary"
          variant="outlined"
          onClick={handleAddProduct}
        >
          ADD +
        </Button>
      )}
      {isSelected && (
        <AddDecrement
          style={{ gridRow: '1/-1', gridColumn: '2', margin: 'auto' }}
          handleIncrement={() => {
            analyticFunc(eventsPayload.Billing[12]);
            handleIncreaseOrDecrease({ product, by: 1 });
          }}
          handleDecrement={handleDecrement}
          toDisplay={inCartQuantity}
        />
      )}
      <div>
        <span style={{ gridRow: '2' }} className="subtitle mb-2">
          &#8377;{` ${priceToShow}/${units}`}
        </span>
        {isSelected === 'true' && (
          <EditOutlinedIcon
            color="primary"
            style={{ padding: '0.1rem', marginLeft: '0.5rem' }}
            onClick={() => {
              if (is_active) setShowEditProductModal(!showEditProductModal);
              else toasterMessage('error', 'Old Product cannot be edited');
            }}
          />
        )}
      </div>
      <p style={{ gridRow: '3' }} className={stockClassName}>
        {' '}
        STOCK: {modelQuantity ? modelQuantity : 'NA'}
      </p>
      <EditProduct
        from={from}
        toggleModal={showEditProductModal}
        setToggleModal={setShowEditProductModal}
        product={product}
      />
      <AddProductModal
        from={from}
        toggleModal={showAddProductModal}
        setToggleModal={setShowAddProductModal}
        product={product}
      />
      <Modal
        style={{
          margin: 'auto',
          minWidth: '30rem',
          maxWidth: '30%',
          height: '30%',
        }}
        open={showConfirmation}
        onClose={() => {
          setShowConfirmation(!showConfirmation);
          setPopUpMsg(NORMAL_WARNING);
        }}
      >
        <Paper style={{ padding: '1rem' }}>
          <p> {popUpMsg} </p>
          <Button
            onClick={() => handleIncreaseOrDecrease({ product, by: -1 })}
            color="primary"
          >
            OK
          </Button>
          <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
        </Paper>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => {
  return {
    selectedProducts: BillingReducer.selectedProducts,
    askGstDetails: OtpLoginReducer.askGstDetails,
    isGstUser: OtpLoginReducer.isGstUser,
    gstNumber: OtpLoginReducer.gstNumber,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    addProductToSelectedList: product =>
      dispatch(addProductToSelectedList(product)),
    increaseOrDecrease: (product, by) =>
      dispatch(increaseOrDecrease(product, by)),
    removeProductFromList: product => dispatch(removeProductFromList(product)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
