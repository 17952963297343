/* eslint-disable */
import React from 'react';
import { userLogout } from '../../store/AdminActions/logoutAction';
import { connect } from 'react-redux';
import { toastDismiss } from '../../@utils/utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    toastDismiss();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h4>Something went wrong.</h4>
          <a href="/">
            <p onClick={e => this.props.userLogout()}>BACK TO HOME</p>
          </a>
        </>
      );
    }

    return this.props.children;
  }
}

export default connect(null, { userLogout })(ErrorBoundary);
