/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  addExpense,
  addExpenseCategory,
  editExpense,
  deleteExpense,
} from '../../../store/RetailerWebStore/RetailerAction/expenses';
import moment from 'moment';
import imageCompression from 'browser-image-compression';
import Loader from '../../Loader';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const AddExpense = ({
  addExpense,
  addExpenseCategory,
  editExpense,
  edit,
  data,
  handleModal,
  expenseCatId,
  deleteExpense,
}) => {
  const imgRef = useRef();
  const myRef = useRef();
  const [expenseCategoryName, setExpenseCategoryName] = useState('');
  const [amount, setAmount] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isRecurring, setIsRecurring] = useState(false);
  const [expenseRetailerName, setExpenseRetailerName] = useState('');
  const [expenseRetailerId, setExpenseRetailerId] = useState('');
  const [loading, setLoading] = useState(false);

  const onSuccess = bool => {
    setLoading(false);
    if (bool) {
      handleModal();
    }
  };

  useEffect(() => {
    if (data) {
      setAmount(data.amount);
      setFromDate(data.Date);
      data.isRecurring && setToDate(data.toDate);
      setExpenseRetailerId(data.expenseRetailerId);
      setExpenseRetailerName(data.expenseRetailerName);
      setIsRecurring(() => (data.isRecurring ? true : false));
    }
  }, [data]);

  const pickImage = useCallback(async file => {
    if (file && file.type.split('/')[0] == 'image') {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        };
        imageCompression(file, options).then(compressedFile => {
          imageCompression
            .getDataUrlFromFile(compressedFile)
            .then(base64Data => setCategoryImage(base64Data))
            .catch(err => {});
        });
      } catch (error) {}
    }
  }, []);

  const handleSubmit = useCallback(() => {
    let payload = {
      expenseRetailerId: data && expenseRetailerId,
      expenseCategoryId: expenseCatId,
      Date: edit && moment(fromDate).format('YYYY-MM-DD'),
      expenseCategoryName: !edit ? expenseCategoryName : undefined,
      expenseRetailerName: edit ? expenseRetailerName : undefined,
      amount: edit ? amount : undefined,
      categoryImage: !edit ? categoryImage.split(',')[1] : undefined,
      fromDate: edit && moment(fromDate).format('YYYY-MM-DD'),
      toDate: edit && moment(toDate).format('YYYY-MM-DD'),
      isRecurring: edit && isRecurring,
    };
    if (edit) {
      !data && addExpense(payload, onSuccess);
      data && editExpense(payload, onSuccess);
      setLoading(true);
    } else {
      addExpenseCategory(payload, onSuccess);
      setLoading(true);
    }
  });

  const handleDelete = useCallback(() => {
    let payload = {
      expenseRetailerId: data.expenseRetailerId,
    };
    deleteExpense(payload, onSuccess);
  }, []);

  return (
    <div className="d-flex align-items-center flex-column justify-items-center m-4">
      {!edit && (
        <div className="d-flex align-items-center justify-content-between w-100">
          <input
            className="d-none"
            onChange={e => pickImage(e.target.files[0])}
            ref={imgRef}
            accept="image/*"
            type="file"
          />
          <h6>Add Expense</h6>
          <div>
            <img
              className=""
              onClick={e => {
                analyticFunc(eventsPayload.Expenses[2]);
                imgRef.current.click();
              }}
              src={categoryImage ? categoryImage : 'assets/Profile.jpg'}
              alt="ProfilePic"
              height="100"
              width="100"
            />
            <br />
            <small>Add Expense Image</small>
          </div>
        </div>
      )}
      {edit && (
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: 160 }}
                variant="outlined"
                id="date-picker-dialog"
                className="headerDatePicker"
                label={isRecurring ? 'From Date' : 'Select Date'}
                format="yyyy/MM/dd"
                value={fromDate}
                onChange={val => setFromDate(val)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disableFuture
              />
              <KeyboardDatePicker
                style={
                  !isRecurring
                    ? { display: 'none', width: 160 }
                    : { display: 'flex', width: 160 }
                }
                variant="outlined"
                id="date-picker-dialog"
                className="headerDatePicker"
                label="To Date"
                format="yyyy/MM/dd"
                value={toDate}
                onChange={val => setToDate(val)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <FormControlLabel
            checked={isRecurring}
            control={
              <Switch
                color="primary"
                onChange={e => setIsRecurring(!isRecurring)}
              />
            }
            label="Recurring(Month)"
            labelPlacement="start"
          />
        </div>
      )}
      <br />
      {!edit && (
        <>
          <TextField
            style={{ width: 350 }}
            variant="outlined"
            id="Enter Expense Type"
            label="Enter Expense Type"
            value={expenseCategoryName}
            disabled={data}
            onChange={e => setExpenseCategoryName(e.target.value)}
          />
          <br />
        </>
      )}
      {edit && (
        <>
          <TextField
            style={{ width: 350 }}
            variant="outlined"
            id="Enter Detail"
            label="Enter Detail"
            value={expenseRetailerName}
            onChange={e => setExpenseRetailerName(e.target.value)}
          />
          <br />
          <TextField
            style={{ width: 350 }}
            type="number"
            id="Enter Amount"
            label="Enter Amount"
            variant="outlined"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
          <br />
        </>
      )}

      {!loading ? (
        <>
          <button
            className="w-100 p-3 m-2 border-0 rounded-pill text-white text-uppercase bg-themeorange"
            onClick={() => {
              if (data) analyticFunc(eventsPayload.Expenses[8]);
              else analyticFunc(eventsPayload.Expenses[5]);
              handleSubmit();
            }}
          >
            save
          </button>
          {data && (
            <button
              className="w-100 p-3 m-2 border-0 rounded-pill text-white text-uppercase bg-dark"
              onClick={() => {
                analyticFunc(eventsPayload.Expenses[7]);
                handleDelete();
              }}
            >
              Delete
            </button>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default connect(null, {
  addExpense,
  addExpenseCategory,
  editExpense,
  deleteExpense,
})(AddExpense);
