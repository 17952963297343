/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Grid } from '@material-ui/core';

const DeleteDialog = React.memo(({ handleModal, btnTitle, deleteItem }) => {
  const onSuccess = useCallback(() => {
    handleModal();
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    deleteItem();
    onSuccess();
  };

  return (
    <div className="m-4 d-flex flex-column align-items-center justify-content-center">
      <Grid>
        <h5>Are You Sure Want To Cancel ?</h5>
      </Grid>
      <div className="w-100 d-flex justify-content-around m-2">
        <button
          className="rounded-pill w-50 m-1 text-white text-uppercase p-2 border-0 bg-dark"
          type="submit"
          onClick={handleModal}
        >
          Cancel
        </button>
        <button
          className="rounded-pill w-50 m-1 text-white text-uppercase p-2 border-0 bg-themeorange"
          type="submit"
          onClick={onSubmit}
        >
          Confirm
        </button>
      </div>
    </div>
  );
});

export default DeleteDialog;
