/* eslint-disable */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import CustomStepper from '../../RetailerWebPortal/WebComponents/Stepper';
import { signup } from '../../store/RetailerWebStore/RetailerAction/signup';
import { history } from '../../routes';
import PersonalDetails from './personalDetails';
import ShopDetails from './shopDetails';
import PaymentDetails from './paymentDetails';
import PartialUser from './partialUser';
import {
  PersonOutline,
  Storefront,
  AccountBalanceWallet,
} from '@material-ui/icons';
import link from '../../images/logo.png';
import MyModal from '../../RetailerWebPortal/WebComponents/Modal';

const Signup = forwardRef(
  (
    { signup, isVerified, handleRegister, setRegister, socialData, resetForm },
    ref
  ) => {
    const [isRegistered, setIsRegistered] = useState(false);
    const [personalDetails, setPersonalDetails] = useState({});
    const [shopDetails, setShopDetails] = useState({});
    const [paymentDetails, setPaymentDetails] = useState();
    const [registerType, setRegisterType] = useState('Custom');
    const [socailLogin, setSocialLogin] = useState(false);
    const modalRef = useRef();
    // const myRef = useRef()

    useEffect(() => {
      if (socialData) setRegisterType('Social');
    }, [socialData]);

    useEffect(() => {
      handleRegister(modalRef.current.click());
    }, []);

    const onSuccess = useCallback(bool => {
      bool && setIsRegistered(true);
    }, []);

    useEffect(() => {
      if (isVerified) history.push('/dashboard');
    }, [isVerified]);

    useEffect(() => {
      if (socialData) {
        const { socialId, email, loginType } = socialData;
        setPersonalDetails({ email, socialId, loginType });
        setSocialLogin(true);
      }
    }, [socialData, setPersonalDetails]);

    const getData = useCallback((data, step) => {
      switch (step) {
        case 0:
          setPersonalDetails(data);
          break;
        case 1:
          setShopDetails(data);
          break;
        case 2:
          setPaymentDetails(data);
          break;
        default:
          break;
      }
    }, []);

    const handleSubmit = useCallback(
      data => {
        let payload = {
          personalDetails,
          shopDetails: {
            ...shopDetails,
            state: undefined,
            city: undefined,
            country: undefined,
          },
          paymentDetails: {
            ...data,
            onlineStore: data.onlineStore
              ? data.onlineStore
                  .filter(item => item.isSelected)
                  .map(item => item.id)
              : undefined,
            paymentMethod: data.paymentMethod
              ? data.paymentMethod
                  .filter(item => item.isSelected)
                  .map(item => item.name)
                  .join('/')
              : undefined,
            financeFacility: data.financeFacility
              ? data.financeFacility
                  .filter(item => item.isSelected)
                  .map(item => item.name)[0]
              : undefined,
          },
          registerType,
        };
        signup(payload, onSuccess);
      },
      [personalDetails, shopDetails, onSuccess, registerType, signup]
    );

    const handleClose = useCallback(() => {
      setRegister(false);
      setPersonalDetails({});
      setShopDetails({});
      setPaymentDetails();
      resetForm && resetForm();
    }, [resetForm, setRegister]);

    return (
      <>
        {!isRegistered ? (
          <MyModal ref={modalRef} close={handleClose}>
            <>
              <div style={{ width: 700 }}></div>
              <img
                src={link}
                width="150px"
                alt="alt-img"
                className="rounded mx-auto d-block"
              />
              <CustomStepper
                getData={getData}
                stepsTitle={[
                  'Personal Details',
                  'Shop Details',
                  'Payment Details',
                ]}
                Icons={[PersonOutline, Storefront, AccountBalanceWallet]}
                className="shadow"
              >
                <>
                  <PersonalDetails
                    data={personalDetails}
                    socialLogin={socailLogin}
                  />
                  <ShopDetails data={shopDetails} />
                  <PaymentDetails
                    data={paymentDetails}
                    handleSubmit={handleSubmit}
                  />
                </>
              </CustomStepper>
            </>
          </MyModal>
        ) : (
          <PartialUser />
        )}
      </>
    );
  }
);

const mapStateToProps = ({ Ledger }) => {
  return { isVerified: Ledger.isVerified };
};

export default connect(mapStateToProps, { signup })(Signup);
