/* eslint-disable */
const initialState = {
  rewards: [],
  reward: [],
  retailersrewards: [],
  rewardsRequests: [],
};

const AdminRewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_REWARDS': {
      return {
        ...state,
        rewards: action.payload,
      };
    }
    case 'SHOW_ALL_RETAILERS_REWARDS': {
      return {
        ...state,
        retailersrewards: action.payload,
      };
    }
    case 'RESET_REWARDS': {
      return {
        ...state,
        rewards: [],
        retailersrewards: [],
      };
    }
    case 'SET_REWARDS_REQUESTS': {
      return {
        ...state,
        rewardsRequests: action.payload,
      };
    }
    case 'APPEND_REWARDS_REQUESTS': {
      return {
        ...state,
        rewardsRequests: [...state.rewardsRequests, ...action.payload],
      };
    }
    case 'CLEAR_REWARDS_REQUESTS': {
      return {
        ...state,
        rewardsRequests: [],
      };
    }
    case 'HANDLE_REWARD':
      return { ...state, reward: { ...state.reward, ...action.payload } };
    case 'RESET_REWARD':
      return { ...state, reward: {} };
    default:
      return state;
  }
};
export default AdminRewardsReducer;
