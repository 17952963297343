import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import MyModal from '../Modal';
import { RenderList } from '../List';
import AddModel from './addModel';
import {
  getModel,
  clearModal,
  getProductDetails,
  addModel,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { useHistory } from 'react-router-dom';
import {
  productDetails,
  setActivePage,
  cancelInvoice,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import Loader from '../../Loader';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Prompt } from 'react-router';

const Models = React.memo(
  ({
    list,
    getModel,
    addModel,
    productDetails,
    loading,
    clearModal,
    getProductDetails,
    setActivePage,
    searchKeyword,
    productInfo,
    cleanSearch,
    searchedList,
    getProductId,
    cancelInvoice,
  }) => {
    const myRef = useRef();
    const [selectedList, setSelectedList] = useState({});
    const [List, setList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [fromMaster, setFromMaster] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [isBilling, setIsBilling] = useState('false');
    const [numberOfItems, setNumberOfItems] = useState(50);
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(
      false
    );
    const history = useHistory();

    const InvoiceCancel = () => {
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
      };
      cancelInvoice(payload);
    };

    useEffect(() => {
      var billingValue = history.location.search.split('?')[1];
      if (billingValue == 'isBilling=true') {
        setIsBilling('true');
        // setShouldBlockNavigation(true)
      }

      return () => {
        if (getProductId.billId && window.location.pathname != '/Billing') {
          // alert("Invoice Canceled")
          InvoiceCancel();
        }
      };
    }, []);

    useEffect(() => {
      if (searchKeyword) {
        if (searchedList && searchedList.length) {
          if (
            searchedList[0].modelName.toLowerCase() ===
            searchKeyword.toLowerCase()
          ) {
            setList(searchedList);
            return;
          }
        }
        setList([
          {
            id: 0,
            modelName: searchKeyword,
            totalModelCount: 0,
            days: 0,
            add: false,
            static: true,
          },
          ...searchedList,
        ]);
      } else {
        setList(list);
      }
    }, [list, searchedList]);

    useEffect(() => {
      if (searchKeyword.length == 0) getModel({ numberOfItems });
    }, [searchKeyword]);

    const onSuccess = useCallback(data => {
      getProductDetails({ modelId: data.modelId });
      // setShouldBlockNavigation(false)
      setActivePage(1);
      history.push('/Billing');
    }, []);

    const handleEdit = useCallback(
      value => {
        setSelectedList(value);
        !value.static
          ? value.add
            ? setFromMaster(true)
            : setEdit(true)
          : setFromMaster(false);
        myRef.current.click();
      },
      [selectedList]
    );

    const handleClose = useCallback(() => {
      clearModal();
      cleanSearch();
      setEdit(false);
      setFromMaster(false);
      getModel({ numberOfItems: 50 });
    }, []);

    const handleAddProduct = modelName => {
      let payload = {
        categoryId: 1,
        brandId: 1,
        modelName,
        modelDesc: 'No desc',
        modelQuantity: 0,
        purchasePrice: 0,
        sellingPrice: 0,
      };
      addModel(payload, onSuccess);
    };

    useEffect(() => {
      if (productInfo && addProduct) {
        const payload = {
          categoryId: productInfo.categoryId,
          brandId: productInfo.brandId,
          modelName: productInfo.modelName,
          modelDesc: 'No desc',
          modelQuantity: 0,
          purchasePrice: 0,
          sellingPrice: 0,
          HSN: productInfo.HSN && productInfo.HSN.toString(),
          MRP: productInfo.MRP,
        };
        addModel(payload, onSuccess);
      }
    }, [productInfo]);

    const handleBilling = useCallback(
      async value => {
        if (value.static) {
          return handleAddProduct(searchKeyword);
        }
        await getProductDetails({
          modelId: value.id,
          type: value.add ? 'ADD' : undefined,
        });
        // setShouldBlockNavigation(false)
        if (!value.add) {
          setActivePage(1);
          productDetails({ pg3Data: value });
          history.push('/Billing');
        } else {
          setAddProduct(true);
        }
      },
      [searchKeyword]
    );

    useEffect(() => {
      numberOfItems > 50 && getModel({ numberOfItems });
    }, [numberOfItems]);

    return (
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {/* <Prompt when={shouldBlockNavigation} message="Are you sure you want to cancel this invoice" /> */}
            <RenderList
              handleNext={isBilling == 'true' ? handleBilling : handleEdit}
              list={List.map(cat => {
                return {
                  id: cat.modelId,
                  name: cat.modelName,
                  count: cat.totalModelCount,
                  days: cat.days,
                  add: cat.add,
                  static: cat.static,
                };
              })}
            />
          </div>
        )}
        {list && list.length >= 50 && !searchKeyword && (
          <IconButton
            aria-label="delete"
            style={{ float: 'right' }}
            onClick={e => setNumberOfItems(prev => prev + 50)}
          >
            <ArrowDownwardIcon fontSize="inherit" />
          </IconButton>
        )}
        {searchKeyword && List && List.length < 1 && (
          <button
            name="addModel"
            onClick={e => {
              isBilling == 'true'
                ? handleAddProduct(searchKeyword)
                : myRef.current.click();
            }}
            className="rounded-pill text-white text-uppercase w-25 p-3 m-1 bg-themeorange border-0"
          >
            add Product
          </button>
        )}
        <MyModal ref={myRef} btnTitle={'add Product'} close={handleClose}>
          <AddModel
            data={
              edit || fromMaster
                ? { modelId: selectedList.id }
                : {
                    category: { id: 1, name: 'Unknown' },
                    brand: { id: 1, name: 'Unknown' },
                    productName: searchKeyword,
                  }
            }
            edit={edit}
          />
        </MyModal>
      </div>
    );
  }
);

const mapStateToProps = ({
  ManageInventory: { models, productDetails, searchedList },
  Loader,
  Billing,
}) => {
  return {
    list: models,
    searchedList,
    loading: Loader.loading,
    productInfo: productDetails,
    getProductId: Billing.getProductId,
  };
};

export default connect(mapStateToProps, {
  getModel,
  addModel,
  clearModal,
  productDetails,
  getProductDetails,
  setActivePage,
  cancelInvoice,
})(Models);
