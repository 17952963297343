import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal, List } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'grid',
    // gridTemplateColumns: "auto 0.1fr",
    gridTemplateRows: '0.1fr 0.2fr 0.1fr auto 0.2fr',
    padding: '4rem',
    borderRadius: '1.5rem',
  },
  title: {
    textAlign: 'center',
    fontWeight: '600',
  },
}));

const ConfirmationModal = props => {
  const classes = useStyles();

  const {
    toggleModal,
    setToggleModal,
    points,
    selectedGiftCards,
    handleConfirm,
  } = props;

  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '20rem',
        maxWidth: '30%',
        height: '60%',
      }}
      open={toggleModal}
      onClose={() => setToggleModal(!toggleModal)}
    >
      <Paper className={classes.paper}>
        <div style={{ display: 'grid', justifyContent: 'end' }}>
          <CloseRoundedIcon onClick={() => setToggleModal(!toggleModal)} />
        </div>
        <p className={classes.title}>Confirm Purchase</p>
        <p>
          Do you want to redeem <strong>{points}</strong> for :
        </p>
        <div>
          {selectedGiftCards.map(gc => (
            <p>{`${gc.qty} x ${gc.price} ${gc.name}`}</p>
          ))}
        </div>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </Paper>
    </Modal>
  );
};

export default ConfirmationModal;
