import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, Divider, List, MenuItem } from '@material-ui/core';
import CustomInput from '../../../../../CommonComponents/customInput';
import { getUnits } from '../../../billingAction';
// import { addProductToInventory } from "../../../WebComponents-v3/ManageInventory/manageInventoryAction";

const gstOptions = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 18,
    label: '18%',
  },
  {
    value: 28,
    label: '28%',
  },
];
const RequiredForm = props => {
  const {
    mode,
    requiredData,
    setRequiredData,
    showSellingPriceError,
    showItemNameError,
    getUnits,
  } = props;

  const {
    modelName: mn,
    units,
    gst,
    sellingPrice: sp,
    modelQuantity: qty,
  } = requiredData;

  const [formTitle, setFormTitle] = useState('CREATE PRODUCT');
  const [isInManageInventory, setIsInManageInventory] = useState(false);

  useEffect(() => {
    setFormTitle(
      mode == 2 ? 'EDIT PRODUCT' : mode == 1 ? 'ADD PRODUCT TO LIST' : formTitle
    );
    let currentLocation = window.location.pathname;
    if (currentLocation === '/inventoryManage') setIsInManageInventory(true);
    if (props.units.length === 0) getUnits();
  }, []);

  const handleInputChange = (inputValue, from) => {
    let tri = { ...requiredData };
    if (from === 'modelName') {
      // setShowItemNameError(false);
      tri[from] = inputValue;
    } else if (from === 'modelQuantity') {
      if (!isNaN(Number(inputValue))) {
        tri[from] = Number(inputValue);
      }
    } else if (from === 'sellingPrice') {
      // setShowSellingPriceError(false);
      if (!isNaN(Number(inputValue))) {
        tri[from] = Number(inputValue);
      }
    } else if (from === 'units') {
      tri[from] = inputValue;
    } else if (from === 'gst') {
      tri[from] = inputValue;
    }
    setRequiredData(tri);
  };

  return (
    <div>
      <p className="formTitle">{formTitle}</p>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '0.7fr 0.3fr',
          columnGap: '0.2rem',
        }}
      >
        <CustomInput
          error={showItemNameError}
          onErrorText={'Please enter item name.'}
          onChange={e => handleInputChange(e.target.value, 'modelName')}
          inputLabel={'Model Name'}
          disabled={mode > 0}
          value={mn}
        />
        <div className="inputContainer mb-3 ">
          <TextField
            select
            label="Unit"
            value={units}
            fullWidth
            name="units"
            InputProps={{
              disableUnderline: true,
            }}
            onChange={e => handleInputChange(e.target.value, 'units')}
          >
            {props.units.map(unit => (
              <MenuItem key={unit.unitId} value={unit.abbreviation}>
                {unit.abbreviation}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      {showItemNameError && (
        <p className="errorLine">Please enter model name.</p>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: mode == 0 || mode == 1 ? '1fr 1fr' : '1fr',
          columnGap: '0.8rem',
        }}
      >
        {(isInManageInventory || mode == 0 || mode == 1) && (
          <CustomInput
            inputLabel="Quantity"
            value={qty}
            onChange={e => handleInputChange(e.target.value, 'modelQuantity')}
          />
        )}
        <CustomInput
          error={showSellingPriceError}
          onChange={e => handleInputChange(e.target.value, 'sellingPrice')}
          inputLabel="Selling Price"
          value={sp}
        />
      </div>

      {showSellingPriceError && (
        <p className="errorLine">`Selling Price should be greater than 0`</p>
      )}
      {!!props.isGstUser && (
        <div className="inputContainer mb-3">
          <TextField
            label="GST %"
            select
            value={gst}
            onChange={e => handleInputChange(e.target.value, 'gst')}
            SelectProps={{
              native: true,
            }}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
          >
            {gstOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => ({
  units: BillingReducer.units,
  isGstUser: OtpLoginReducer.user.userData[0].isGstNumber,
});

// For Dispatching Action
const mapDispatchToProps = dispatch => ({
  getUnits: () => dispatch(getUnits()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequiredForm);
