/* eslint-disable */
const initialState = {
  userData: [],
};

const Profile = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PROFILE_DATA': {
      return {
        ...state,
        userData: payload,
      };
    }
    default:
      return state;
  }
};
export default Profile;
