/* eslint-disable */
const initialState = {
  brands: [],
  brand: [],
};

const getBrandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIEW BRANDS': {
      return {
        ...state,
        brands: action.payload,
      };
    }
    case 'SHOW BRAND': {
      return {
        ...state,
        brand: action.payload,
      };
    }
    case 'HANDLE_BRAND':
      return { ...state, brand: { ...state.brand, ...action.payload } };
    case 'RESET_BRAND':
      return { ...state, brand: {} };
    case 'RESET_BRANDS': {
      return {
        ...state,
        brands: [],
      };
    }
    case 'SEARCH_BRAND':
      return {
        ...state,
        brands: action.payload.brands,
      };
    case 'RESET_SEARCH_BRAND': {
      return { ...state, brands: [] };
    }
    default:
      return state;
  }
};
export default getBrandsReducer;
