/* eslint-disable */
import { combineReducers } from 'redux';
import loginReducer from './AdminReducers/loginReducer';
import getCategoriesReducer from './AdminReducers/allCategoriesReducer';
import getStateCityReducer from './AdminReducers/allStateCityReducer';
import getBrandsReducer from './AdminReducers/BrandsReducer';
import getSuperCategories from './AdminReducers/categoryReducer';
import getModelsReducer from './AdminReducers/ModelsReducers';
import RetailersReducer from './AdminReducers/vendorsReducer';
import BrandOfferReducer from './AdminReducers/brandManagementReducer';
import subCategoriesReducer from './AdminReducers/subCategoriesReducer';
import TemporaryUsersReducer from './AdminReducers/temporaryUsersReducer';
import CseReducer from './AdminReducers/cseReducer';
import AdCampaignReducer from './AdminReducers/adCampaignReducer';
import AdminReducer from './AdminReducers/adminReducer';
import ManageInventory from './RetailerWebStore/RetailerReducer/manageInventory';
import Billing from './RetailerWebStore/RetailerReducer/billing';
import Expenses from './RetailerWebStore/RetailerReducer/expenses';
import Loader from './RetailerWebStore/RetailerReducer/loading';
import Ledger from './RetailerWebStore/RetailerReducer/ledger';
import ManagerDesignationReducer from './AdminReducers/managerAndDesignationReducer';
import FilterReducer from './AdminReducers/filterReducer';
import GstOptions from './RetailerWebStore/RetailerReducer/gstOptions';
import SampleDataReducer from './AdminReducers/withinSampleReducer';
import SuccessReducer from './AdminReducers/successReducer';
import ProfitLoss from './RetailerWebStore/RetailerReducer/profit&Loss';
import InventoryOverview from './RetailerWebStore/RetailerReducer/inventoryOverview';
import SearchInvoice from './RetailerWebStore/RetailerReducer/searchInvoice';
import Signup from './RetailerWebStore/RetailerReducer/signup';
import FAQ from './RetailerWebStore/RetailerReducer/faq';
import FAQAdmin from './AdminReducers/faqReducer';
import RmReducer from './AdminReducers/rmReducer';
import SearchCredits from './RetailerWebStore/RetailerReducer/creditBooking';
import RewardPoints from './RetailerWebStore/RetailerReducer/rewardPoints';
import AddSubUser from './RetailerWebStore/RetailerReducer/addSubUser';
import SendSms from './RetailerWebStore/RetailerReducer/sendSms';
import Profile from './RetailerWebStore/RetailerReducer/profile';
import notifications from './RetailerWebStore/RetailerReducer/notifications';
import configReducer from './AdminReducers/configurationReducer';
import AdminRewardsReducer from './AdminReducers/rewardsReducer';
import SurveyReducer from '../AdminWebPortal/Survey/surveyReducer';
import OtpLoginReducer from './AdminReducers/otpLoginReducer';
import OnboardingReducer from './AdminReducers/onboardingReducer';
import BillingReducer from '../RetailerWebPortal/Billing/billingReducer';
import ManageInventoryReducer from '../RetailerWebPortal/WebComponents-v3/ManageInventory/manageInventoryReducer';
import RewardsReducer from '../RetailerWebPortal/WebComponents-v3/Rewards/rewardsReducer';

const appReducer = combineReducers({
  loginReducer,
  OtpLoginReducer,
  ManageInventoryReducer,
  OnboardingReducer,
  BillingReducer,
  RewardsReducer,
  getCategoriesReducer,
  getStateCityReducer,
  getBrandsReducer,
  getSuperCategories,
  getModelsReducer,
  SurveyReducer,
  RetailersReducer,
  BrandOfferReducer,
  subCategoriesReducer,
  TemporaryUsersReducer,
  RmReducer,
  CseReducer,
  AdCampaignReducer,
  AdminReducer,
  ManageInventory,
  Billing,
  GstOptions,
  Expenses,
  Loader,
  Ledger,
  ManagerDesignationReducer,
  FilterReducer,
  SampleDataReducer,
  SuccessReducer,
  ProfitLoss,
  InventoryOverview,
  RewardPoints,
  SearchInvoice,
  Signup,
  FAQ,
  FAQAdmin,
  AdminRewardsReducer,
  SearchCredits,
  RewardPoints,
  AddSubUser,
  SendSms,
  Profile,
  notifications,
  configReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = {};
  }

  return appReducer(state, action);
};
export default rootReducer;
