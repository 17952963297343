import _ from 'lodash';
import moment from 'moment';
const initialState = {
  rms: [],
  rm: [],
  tickets: [],
  manageRM: [],
  filterTickets: {
    endDate: moment.parseZone(new Date()).format('YYYY-MM-DD'),
    startDate: moment
      .parseZone(new Date().setDate(new Date().getDate() - 31))
      .format('YYYY-MM-DD'),
  },
  events: [],
};

const endDate = moment.parseZone(new Date()).format('YYYY-MM-DD');
const startDate = moment
  .parseZone(new Date().setDate(new Date().getDate() - 31))
  .format('YYYY-MM-DD');

const RmReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_TICKETS': {
      if (
        state.filterTickets.length === 0 ||
        Object.keys(state.filterTickets).length === 0
      ) {
        return {
          ...state,
          tickets: action.payload.filter(
            t =>
              moment.parseZone(t.dateOfAssignRm).format('YYYY-MM-DD') >=
                startDate &&
              moment.parseZone(t.dateOfAssignRm).format('YYYY-MM-DD') <=
                endDate &&
              t.currentStatusRm !== null &&
              t.currentStatusRm !== 6 &&
              t.currentStatusRm !== 8
          ),
        };
      } else {
        const filterKeys = Object.keys(state.filterTickets);
        return {
          ...state,
          tickets: action.payload.filter(ticket => {
            return filterKeys.every(key => {
              if (key === 'startDate' || key === 'endDate') {
                return (
                  moment
                    .parseZone(ticket.dateOfAssignRm)
                    .format('YYYY-MM-DD') >= state.filterTickets['startDate'] &&
                  moment
                    .parseZone(ticket.dateOfAssignRm)
                    .format('YYYY-MM-DD') <= state.filterTickets['endDate'] &&
                  ticket.currentStatusRm !== null &&
                  ticket.currentStatusRm !== 6 &&
                  ticket.currentStatusRm !== 8
                );
              }
              return (
                state.filterTickets[key] === ticket[key] &&
                ticket.currentStatusRm !== null &&
                ticket.currentStatusRm !== 6 &&
                ticket.currentStatusRm !== 8
              );
            });
          }),
        };
      }
    }
    case 'SHOW_PAST_TICKETS': {
      return {
        ...state,
        pastTickets: action.payload,
      };
    }
    case 'SHOW_DISPOSITION': {
      return {
        ...state,
        disposition: action.payload,
      };
    }
    case 'GET_MANAGE_RM': {
      return {
        ...state,
        manageRM: action.payload,
      };
    }
    case 'GET_EVENTS': {
      return {
        ...state,
        events: action.payload,
      };
    }
    case 'RESET_EVENTS': {
      return {
        ...state,
        events: [],
      };
    }
    case 'SHOW_ALL_RM': {
      return {
        ...state,
        rms: action.payload,
      };
    }
    case 'SHOW_RM': {
      return {
        ...state,
        rm: action.payload,
      };
    }
    case 'FILTER_RM_TICKETS':
      return {
        ...state,
        filterTickets: { ...state.filterTickets, ...action.payload },
      };
    case 'RESET_FILTER_RM_TICKETS':
      return {
        ...state,
        filterTickets: _.omit(state.filterTickets, action.payload),
      };
    case 'HANDLE_RM':
      return { ...state, rm: { ...state.rm, ...action.payload } };
    case 'RESET_RM':
      return { ...state, rm: [] };
    case 'RESET_MANAGE_RMS':
      return { ...state, managerm: [] };
    default:
      return state;
  }
};
export default RmReducer;
