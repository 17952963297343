/* eslint-disable */
import React, { memo } from 'react';
import { history } from '../../../routes';

const GateKeeper = memo(() => {
  history.goBack();
  return <></>;
});

export default GateKeeper;
