/* eslint-disable */
import React, { useEffect, useRef, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
// import AddCircleIcon from '@material-ui/icons/AddCircle'
// import CropFreeIcon from '@material-ui/icons/CropFree'
// import ViewWeekIcon from '@material-ui/icons/ViewWeek'
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import { Link } from 'react-router-dom'
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getProductDetails,
  addProductDetails,
  updateProduct,
  deleteProduct,
  setGetUserId,
  cancelInvoice,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import {
  clearModal,
  scanProduct,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
// import { setGSTOptions, setBrandOfferOptions ,getBrandOffersandGstSlabs} from '../../../store/RetailerWebStore/RetailerAction/gstOptions'
import { getBrandOffersandGstSlabs } from '../../../store/RetailerWebStore/RetailerAction/gstOptions';
import { RenderProductList } from './productList';
import Loader from '../../Loader';
import MyModal from '../Modal';
import DeleteDialog from '../DeleteDialog';
// import BarcodeScannerComponent from '../BarcodeScannerComponent'

import { Prompt } from 'react-router';

const ProductDetail = React.memo(
  ({
    handleNext,
    getProductId,
    getProductDetails,
    productDetails,
    addProductDetails,
    addedProductDetails,
    clearModal,
    deleteProduct,
    updateProduct,
    // pg1Data,
    loading,
    // setGSTOptions,
    // setBrandOfferOptions,
    GSTOptions,
    getBrandOffersandGstSlabs,
    brandOffersOptions,
    setGetUserId,
    cancelInvoice,
    scanProduct,
    userDetails,
    activePage,
    invoiceDate,
  }) => {
    const [billId, setBillId] = React.useState('');
    const [invoiceId, setInvoiceId] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [discount, setDiscount] = React.useState(0);
    const [brandOffer, setBrandOffer] = React.useState('');
    const [discountAmount, setDiscountAmount] = React.useState(0);
    const [gst, setGST] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [initialAmount, setInitialAmount] = React.useState('');
    const [amountBeforeTax, setAmountBeforeTax] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [HSN, setHSN] = React.useState('');
    const [productNote, setProductNote] = React.useState('');
    const [quantity, setQuantity] = React.useState(1);
    const [brand, setBrand] = React.useState('');
    const [product, setProduct] = React.useState('');
    const [srNo, setSrNo] = React.useState('');
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [edit, setEdit] = React.useState(false);
    const [showHSN, setShowHSN] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(
      true
    );
    const [barcodeScan, setBarcodeScan] = React.useState(false);
    const [isGST, setIsGST] = React.useState(false);
    const myRef = useRef();
    const history = useHistory();

    const InvoiceCancel = () => {
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
      };
      cancelInvoice(payload);
    };

    useEffect(() => {
      getBrandOffersandGstSlabs();

      return () => {
        if (
          window.location.pathname != '/Manage%20Inventory' &&
          window.location.pathname != '/Billing'
        ) {
          // alert("Invoice Canceled")
          InvoiceCancel();
        }
      };
    }, []);

    useEffect(() => {
      if (userDetails && userDetails.gstNumber) {
        setIsGST(true);
      }
    }, [userDetails]);

    useEffect(() => {
      if (addedProductDetails && addedProductDetails.billId) {
        setGetUserId({
          billId: addedProductDetails.billId,
          invoiceId: addedProductDetails.invoiceId,
        });
      }
    }, [addedProductDetails]);

    useEffect(() => {
      if (getProductId) {
        setBillId(getProductId.billId);
        setInvoiceId(getProductId.invoiceId);
      }
    }, [getProductId]);

    useEffect(() => {
      if (billId) {
        let payload = {
          billId: billId,
          invoiceId: invoiceId ? invoiceId : undefined,
        };
        getProductDetails(payload);
      }
    }, [billId]);

    useEffect(() => {
      if (productDetails) {
        console.log('product DETAILS==========', productDetails.sellingPrice);
        setSrNo(productDetails.modelId);
        setProduct(productDetails.modelName);
        setBrand(productDetails.brandName);
        // setQuantity(productDetails.modelQuantity)
        setPrice(productDetails.purchasePrice);
        setAmount(productDetails.sellingPrice);
        setInitialAmount(productDetails.sellingPrice);
        setAmountBeforeTax(productDetails.sellingPrice);
        setTotalAmount(productDetails.sellingPrice);
        // setGSTOptions(productDetails.gstSlabs)
        // setBrandOfferOptions(productDetails.brandOffers)
        setHSN(productDetails.HSN);
        setShowHSN(true);
        setChecked(false);
        setProductNote('');
        setBrandOffer('');
        setDiscountAmount(0);
        setGST('');
      }
    }, [productDetails]);

    // useEffect(() => {
    //   if (gst) {
    //     newPicker(gst)
    //   } else if (discountAmount) {
    //     newPicker(discountAmount)
    //   }
    // }, [gst, discountAmount])

    useEffect(() => {
      if (gst) {
        newPicker(gst);
      }
    }, [gst]);

    useEffect(() => {
      // if (gst) {
      //   newPicker(gst)
      // } else if (discountAmount) {
      //   newPicker(discountAmount)
      // } else {
      //   newPicker(0)
      // }
      // setDiscountAmount(0)
      // setGST('')
    }, [quantity]);

    const newPicker = value => {
      let newAmountEdit = parseInt(initialAmount) * parseInt(quantity);
      if (checked == true) {
        if (gst && gst !== 'No Gst') {
          let newAmount = newAmountEdit - discountAmount;
          // let GST = newAmount * (value / 100)
          // // let GST = newAmount * value

          // let updatedAmount = newAmount + GST
          // // let finalAmount = updatedAmount.toFixed(2)
          // let finalAmount = updatedAmount

          // setTotalAmount(finalAmount)
          // setGST(value)
          let AmountBeforeTax = (
            (parseInt(newAmount) / (100 + parseInt(value))) *
            100
          ).toFixed(2);
          let oneProductPrice = (
            ((parseInt(newAmount) / (100 + parseInt(value))) * 100 +
              parseInt(discountAmount)) /
            parseInt(quantity)
          ).toFixed(2);
          // let AmountBeforeTax = Math.round(((newAmount) / (100 + parseInt(value)) * 100) / parseInt(quantity))
          setAmount(oneProductPrice);
          setAmountBeforeTax(AmountBeforeTax);
          setTotalAmount(newAmount);
          setGST(value);
        } else if (gst && gst === 'No Gst') {
          let newD = newAmountEdit - discountAmount;
          setTotalAmount(newD);
        } else {
          let newD = newAmountEdit - value;
          setTotalAmount(newD);
        }
      } else {
        if (gst && gst !== 'No Gst') {
          // let GST = newAmountEdit * (value / 100)
          // let updatedAmount = newAmountEdit + GST

          // let finalAmount = updatedAmount.toFixed(2)
          // // let finalAmount = updatedAmount.toFixed(2)

          // setTotalAmount(finalAmount)
          let AmountBeforeTax = (
            (parseInt(newAmountEdit) / (100 + parseInt(value))) *
            100
          ).toFixed(2);
          let oneProductPrice = (AmountBeforeTax / parseInt(quantity)).toFixed(
            2
          );
          setAmount(oneProductPrice);
          setAmountBeforeTax(AmountBeforeTax);
          setTotalAmount(newAmountEdit);
          setGST(value);
        } else {
          setTotalAmount(newAmountEdit);
        }
      }
    };

    const addProduct = () => {
      let errMsg =
        amount < 1
          ? 'selling price can not be empty'
          : quantity < 1
          ? 'quantity can not be less than 1'
          : gst == ''
          ? 'GST is required'
          : discountAmount >= parseInt(totalAmount)
          ? 'Discount cannot be higher than total amount'
          : null;
      if (amount >= 1 && quantity >= 1 && gst) {
        let payload = {
          billId: billId,
          invoiceId: invoiceId ? invoiceId : undefined,
          modelId: srNo,
          quantity: quantity,
          gstPercent: gst === 'No Gst' ? '0.00' : gst.toString(),
          // "discountPercentage": discount,
          isDiscount: checked,
          brandOfferId: brandOffer ? parseInt(brandOffer) : undefined,
          price: amount,
          amountBeforeTax: amountBeforeTax,
          initialSellingPrice: initialAmount,
          Amount: totalAmount,
          discountAmount: discountAmount ? discountAmount : 0,
          HSN: HSN ? HSN : undefined,
          productNote: productNote ? productNote : undefined,
        };
        addProductDetails(payload);
        clearModal();
      } else {
        toasterMessage('error', errMsg);
      }
    };

    const editProduct = items => {
      let { item } = items;
      if (item.modelId) {
        setSrNo(item.modelId);
        setProduct(item.modelName);
        setBrand(item.brandName);
        setQuantity(item.quantity);
        setPrice(item.purchasePrice);
        setAmount(item.price);
        setInitialAmount(item.initialSellingPrice);
        setAmountBeforeTax(item.initialAmount);
        // setDiscount(item.discountPercentage)
        setBrandOffer(item.brandOfferId);
        setDiscountAmount(item.discountAmount);
        setChecked(item.discountAmount ? true : false);
        // setGST(item.gstPercent === '0.00' ? item.gstPercent : parseInt(item.gstPercent))
        setGST(item.gstPercent);
        setTotalAmount(item.Amount);
        setHSN(item.HSN);
        setShowHSN(false);
        setEdit(true);
        setProductNote(item.productNote);
      }
    };

    const DeleteProduct = item => {
      setDeleteId(item.item.modelId);
      myRef.current.click();
    };

    const productDelete = () => {
      let payload = {
        billId,
        invoiceId: invoiceId ? invoiceId : undefined,
        modelId: deleteId,
      };
      deleteProduct(payload);
      setDeleteId('');
    };

    const handleClose = useCallback(() => {
      setDeleteId('');
    });

    const UpdateProduct = () => {
      let errMsg =
        amount < 1
          ? 'selling price can not be empty'
          : quantity < 1
          ? 'quantity can not be less than 1'
          : gst == ''
          ? 'GST is required'
          : discountAmount >= parseInt(totalAmount)
          ? 'Discount cannot be higher than total amount'
          : null;
      if (amount >= 1 && quantity >= 1 && gst) {
        let payload = {
          billId: billId,
          invoiceId: invoiceId ? invoiceId : undefined,
          modelId: srNo,
          quantity: quantity,
          gstPercent: gst === 'No Gst' ? '0.00' : gst.toString(),
          // "discountPercentage": discount,
          isDiscount: checked,
          brandOfferId: brandOffer ? parseInt(brandOffer) : undefined,
          price: amount,
          amountBeforeTax: amountBeforeTax,
          initialSellingPrice: initialAmount,
          Amount: totalAmount,
          discountAmount: discountAmount ? discountAmount : 0,
          HSN: HSN ? HSN : undefined,
          productNote: productNote ? productNote : undefined,
        };
        updateProduct(payload);
        setEdit(false);
        clearModal();
      } else {
        toasterMessage('error', errMsg);
      }
    };

    // const selectProduct = () => {
    //   setShouldBlockNavigation(false)
    //   console.log("SET BLOCK NAVIGATION VALUE")
    //   if( !shouldBlockNavigation){
    //     history.push(`/Manage Inventory?isBilling=true`)
    //   }

    // }

    useEffect(() => {
      if (!shouldBlockNavigation) {
        history.push(`/Manage Inventory?isBilling=true`);
      }
    }, [shouldBlockNavigation]);

    const productScan = val => {
      setBarcodeScan(false);
      console.log('SCAN VAL---->', val);
      if (val) {
        scanProduct({ scanId: val });
      }
    };

    console.log('invoiceDate=====', invoiceDate);

    return (
      <React.Fragment>
        {
          // barcodeScan ? <BarcodeScannerComponent productScan={productScan} /> :
          <React.Fragment>
            {/* <Prompt when={shouldBlockNavigation} message="Are you sure you want to cancel this invoice" /> */}
            <div style={{ display: 'flex', padding: '2%' }}>
              <div
                style={{ marginLeft: '7%', cursor: 'pointer' }}
                onClick={() => {
                  setShouldBlockNavigation(false);
                  // selectProduct()
                  // console.log("SET BLOCK NAVIGATION VALUE")
                }}
              >
                {/* <Link to="/Manage Inventory?isBilling=true?search=true"> */}
                <SearchIcon color="action" fontSize="large" /> <br />
                <button
                  className="text-white border-0 p-2 rounded-pill bg-themeorange"
                  style={{ width: '100%' }}
                >
                  Search Product
                </button>
                {/* </Link> */}
              </div>

              {/* <div
                style={{ marginLeft: '67%', cursor: 'pointer' }}
                onClick={() => {
                  setBarcodeScan(true)
                }}
              >
                <CropFreeIcon color="action" fontSize="large" /> <br />
                Scan Product
              </div> */}
            </div>

            <Typography variant="h6" gutterBottom>
              Details Of Product
            </Typography>

            {loading ? (
              <Loader />
            ) : (
              <Grid container spacing={3}>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center">
                  <h5>Invoice Date:</h5>
                  <span>
                    {invoiceDate && moment(invoiceDate).format('DD/MMMM/YYYY')}
                  </span>
                </div>

                {!edit &&
                billId &&
                addedProductDetails &&
                addedProductDetails.productDetails &&
                addedProductDetails.productDetails.length > 0 ? (
                  <RenderProductList
                    list={addedProductDetails.productDetails}
                    edit={editProduct}
                    Delete={DeleteProduct}
                  />
                ) : (
                  <Grid item xs={12}>
                    <h5>{'No Data Found'}</h5>
                  </Grid>
                )}

                {(productDetails || edit) && (
                  <Grid
                    style={{
                      width: '100%',
                      border: '2px solid grey',
                      marginLeft: '5%',
                      marginRight: '5%',
                      marginBottom: '2%',
                      padding: '5%',
                    }}
                  >
                    <div className="d-flex w-100 flex-column justify-content-center align-items-between">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>SR No.</label> <label>{srNo}</label>
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>Product</label>{' '}
                        <label style={{ width: 150, overflow: 'auto' }}>
                          {product}
                        </label>
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>Brand</label> <label>{brand}</label>
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>HSN</label>
                        <TextField
                          style={{ width: 100 }}
                          id="HSN"
                          name="HSN"
                          fullWidth
                          autoComplete="HSN"
                          value={HSN}
                          onChange={e =>
                            setHSN(e.target.value.replace(/[^\d]/, ''))
                          }
                        />
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>IMEI</label>
                        <TextField
                          style={{ width: 100 }}
                          id="productNote"
                          name="productNote"
                          fullWidth
                          autoComplete="productNote"
                          value={productNote}
                          onChange={e =>
                            setProductNote(
                              e.target.value.replace(/[^0-9,]/g, '')
                            )
                          }
                        />
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>Quantity</label>
                        <TextField
                          style={{ width: 100 }}
                          id="quantity"
                          name="quantity"
                          fullWidth
                          // disabled={!edit}
                          autoComplete="quantity"
                          value={quantity}
                          onChange={e => {
                            setDiscountAmount(0);
                            setGST('');
                            setQuantity(e.target.value.replace(/[^\d]/, ''));
                          }}
                        />
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <label>Selling Price</label>
                        <TextField
                          style={{ width: 100 }}
                          id="amount"
                          name="amount"
                          fullWidth
                          // disabled={!edit}
                          autoComplete="amount"
                          value={amount}
                          onChange={e => {
                            setDiscountAmount(0);
                            setGST('');
                            setInitialAmount(
                              e.target.value.replace(/[^\d]/, '')
                            );
                            setAmount(e.target.value.replace(/[^\d]/, ''));
                          }}
                        />
                      </div>
                    </div>

                    <Grid item xs={12}>
                      <Grid>
                        {/* {showHSN && ( */}
                        {/* <Grid item xs={12} style={{ display: 'flex' }}>
                <label>Price</label>
                <TextField style={{ width: '17%', marginLeft: "75%" }}
                  id="price"
                  name="price"
                  fullWidth
                  disabled={!edit}
                  autoComplete="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Grid> */}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                      <h5>Discount</h5>
                      <div className="d-flex">
                        <Checkbox
                          checked={checked}
                          onChange={e => setChecked(e.target.checked)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <TextField
                          id="outlined-basic"
                          label="Discount amount"
                          variant="outlined"
                          disabled={!checked}
                          value={discountAmount}
                          // type="number"
                          onChange={e => {
                            setGST('');
                            // setDiscountAmount(e.target.value < parseInt(totalAmount) ? e.target.value.replace(/[^\d]/, '') : '')
                            setDiscountAmount(
                              e.target.value.replace(/[^\d]/, '')
                            );
                          }}
                          fullWidth
                        />
                      </div>
                    </Grid>

                    <Grid style={{ marginTop: '7%' }}>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <label>Select Brand Offer</label>
                        <Select
                          labelId="brand offer"
                          required
                          id="brand offer"
                          value={brandOffer}
                          onChange={e => setBrandOffer(e.target.value)}
                          style={{ width: 100 }}
                        >
                          {brandOffersOptions &&
                            brandOffersOptions.length > 0 &&
                            brandOffersOptions.map(item => (
                              <MenuItem
                                key={item.brandOfferId}
                                value={item.brandOfferId}
                              >
                                {item.brandOfferName}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <label>Amount Before Tax</label>
                        <TextField
                          id="amount"
                          name="amount"
                          disabled
                          autoComplete="amount"
                          value={amountBeforeTax}
                          style={{ width: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <label>Discount Amount</label>
                        <TextField
                          id="discountAmount"
                          name="discountAmount"
                          disabled
                          autoComplete="discountAmount"
                          value={discountAmount}
                          style={{ width: 100 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <label>GST</label>
                        <Select
                          labelId="brand offer"
                          required
                          id="brand offer"
                          value={gst}
                          onChange={e => {
                            setGST(e.target.value);
                          }}
                          style={{ width: 100 }}
                        >
                          {/* {GSTOptions &&
                            GSTOptions.length > 0 &&
                            GSTOptions.map((item) => (
                              <MenuItem key={item.gstId} value={item.value}>
                                {item.gstSlab}
                              </MenuItem>
                            ))} */}
                          {isGST
                            ? GSTOptions &&
                              GSTOptions.length > 0 &&
                              GSTOptions.map(item => (
                                <MenuItem key={item.gstId} value={item.value}>
                                  {item.gstSlab}
                                </MenuItem>
                              ))
                            : GSTOptions &&
                              GSTOptions.length > 0 &&
                              GSTOptions.map(item => {
                                if (item.gstSlab == 'No Gst') {
                                  return (
                                    <MenuItem
                                      key={item.gstId}
                                      value={item.value}
                                    >
                                      {item.gstSlab}
                                    </MenuItem>
                                  );
                                }
                              })}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '2%' }}>
                      <h5>Total Amount: {totalAmount}</h5>
                    </Grid>

                    {edit ? (
                      <button
                        className="bg-themeorange text-white rounded-pill border-0 p-2"
                        style={{
                          width: '38%',
                        }}
                        onClick={UpdateProduct}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        className="bg-themeorange text-white rounded-pill border-0 p-2"
                        style={{
                          width: '38%',
                        }}
                        onClick={addProduct}
                      >
                        Save
                      </button>
                    )}
                  </Grid>
                )}
                <button
                  className="text-white border-0 p-3 rounded-pill"
                  style={{
                    width: '50%',
                    marginLeft: '25%',
                    marginBottom: '2%',
                    background: 'grey',
                  }}
                  onClick={
                    !edit &&
                    addedProductDetails &&
                    addedProductDetails.productDetails &&
                    handleNext
                  }
                >
                  NEXT
                </button>
              </Grid>
            )}
            <MyModal
              ref={myRef}
              btnTitle={'Delete Product'}
              close={handleClose}
            >
              <DeleteDialog deleteItem={productDelete} />
            </MyModal>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
);

const mapStateToProps = ({
  Billing,
  ManageInventory,
  Loader,
  GstOptions,
  loginReducer,
}) => {
  const { productDetails } = ManageInventory;
  return {
    activePage: Billing.activePage,
    getProductId: Billing.getProductId,
    productDetails,
    addedProductDetails: Billing.productDetails,
    loading: Loader.loading,
    GSTOptions: GstOptions.GSTOptions,
    brandOffersOptions: GstOptions.brandOfferOptions,
    userDetails: loginReducer.user.userData[0],
    invoiceDate: Billing.invoiceDate && Billing.invoiceDate.date,
  };
};

export default connect(mapStateToProps, {
  getProductDetails,
  addProductDetails,
  clearModal,
  updateProduct,
  deleteProduct,
  // setGSTOptions,
  // setBrandOfferOptions,
  getBrandOffersandGstSlabs,
  setGetUserId,
  cancelInvoice,
  scanProduct,
})(ProductDetail);
