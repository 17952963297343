/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MyModal from '../Modal';
import Pay from './pay';
import Loader from '../../Loader';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import { history } from '../../../routes'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Grid,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from '@material-ui/core';
import {
  getCreditDetails,
  creditPayment,
  setCreditId,
  notifyCustomer,
} from '../../../store/RetailerWebStore/RetailerAction/creditBooking';
import { ChevronLeft } from '@material-ui/icons';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    border: '1px solid grey',
    background: 'grey',
    margin: '2%',
    textAlign: 'left',
  },
  root2: {
    flexGrow: 1,
    margin: '-24px 2%',
  },
  root3: {
    margin: '7% 2%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
}));

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CrediDetails = React.memo(
  ({
    getCreditDetails,
    creditDetails,
    creditPayment,
    loading,
    setCreditId,
    notifyCustomer,
    creditId,
  }) => {
    // const [creditId, setCreditId] = React.useState('')
    const [creditBookDetails, setCreditBookingDetails] = React.useState('');
    const [emiPayDetails, setEmiPayDetails] = React.useState('');
    const [paymentDetails, setPaymentDetails] = React.useState('');
    const history = useHistory();
    const classes = useStyles();
    const myRef = useRef();

    useEffect(() => {
      // const arr = history.location.search.split('?')
      // let Id = arr && arr.length > 1 ? arr[1].split('=')[1] : undefined
      // setCreditId(Id)
      // let payload = {
      //   creditId: Id,
      // }
      // getCreditDetails(payload)

      return () => {
        setCreditId('');
      };
    }, []);

    useEffect(() => {
      if (creditId) {
        let payload = {
          creditId,
        };
        getCreditDetails(payload);
      }
    }, [creditId]);

    useEffect(() => {
      if (creditDetails) {
        const { creditBookDetails, emiPayDetails } = creditDetails;
        setCreditBookingDetails(creditBookDetails);
        setEmiPayDetails(emiPayDetails);
      }
    }, [creditDetails]);

    const openModel = data => {
      const { item } = data;
      setPaymentDetails(item);
      myRef.current.click();
    };

    const handleClose = useCallback(() => {
      setPaymentDetails('');
    });

    const paymentDone = amount => {
      let payload = {
        creditId: paymentDetails.creditId,
        creditBookDetailsId: paymentDetails.creditBookDetailsId,
        amountRecieved: amount,
        Date: moment(new Date()).format('YYYY-MM-DD'),
      };
      creditPayment(payload);
    };

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <Grid>
            {/* <IconButton onClick={(e) => history.goBack()}>
            <ChevronLeft />
          </IconButton> */}
            <Card className={classes.root} variant="outlined">
              <div className="w-100 d-flex justify-content-between align-items-center p-2">
                <div className="d-flex flex-column">
                  <h5 className="text-white">
                    {creditBookDetails.customerName}
                  </h5>
                  <span className="text-white">
                    Mobile No. : {creditBookDetails.mobileNumber}
                  </span>
                  <span className="text-white">
                    Invoice No. : {creditBookDetails.billId}
                  </span>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h4 style={{ color: 'red' }}>
                    {creditBookDetails.dueBalance}
                  </h4>
                  <span className="text-white">Due Amount</span>
                </div>
              </div>
            </Card>

            <div className={classes.root2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>Start Date of EMI</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails &&
                    creditBookDetails.startDate.split('T')[0]
                      ? creditBookDetails.startDate.split('T')[0]
                      : '0'}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>End Date of EMI</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails &&
                    creditBookDetails.endDate.split('T')[0]
                      ? creditBookDetails.endDate.split('T')[0]
                      : 0}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>Number Of EMI</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails.totalEmi
                      ? creditBookDetails.totalEmi
                      : 0}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>EMI Amount</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails.emiAmount
                      ? creditBookDetails.emiAmount
                      : 0}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>Total Amount</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails.totalAmount
                      ? creditBookDetails.totalAmount
                      : 0}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>Balance</Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                    {creditBookDetails.dueBalance
                      ? creditBookDetails.dueBalance
                      : 0}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper} style={{ color: '#df5a28' }}>
                    Due Date
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.paper} style={{ color: '#df5a28' }}>
                    {creditBookDetails &&
                    creditBookDetails.endDate.split('T')[0]
                      ? creditBookDetails.endDate.split('T')[0]
                      : 0}
                  </Paper>
                </Grid>
              </Grid>
            </div>

            <div className={classes.root3}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}>EMI</TableCell>
                      <TableCell style={{ width: '13%' }}>Date</TableCell>
                      <TableCell style={{ width: '13%' }}>
                        Status&nbsp;
                      </TableCell>
                      <TableCell style={{ width: '1%' }}>
                        Reminder&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emiPayDetails &&
                      emiPayDetails.length > 0 &&
                      emiPayDetails.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {i + 1}
                          </TableCell>
                          <TableCell>{item.endDate}</TableCell>
                          <TableCell>
                            {item.status == 0 ? (
                              <FormControlLabel
                                control={
                                  <IOSSwitch checked={false} name="checkedB" />
                                }
                                label="MARK AS PAID"
                                onClick={() => {
                                  analyticFunc(eventsPayload.CreditBook[3]);
                                  openModel({ item });
                                }}
                              />
                            ) : (
                              <FormControlLabel
                                control={
                                  <IOSSwitch checked={true} name="checkedB" />
                                }
                                label="PAID"
                              />
                            )}
                          </TableCell>
                          <TableCell style={{ color: 'rgb(223, 90, 40)' }}>
                            {item.status == 1 ? (
                              <NotificationsIcon />
                            ) : (
                              <NotificationsNoneIcon
                                className="hover-pointer"
                                onClick={e => {
                                  analyticFunc(eventsPayload.CreditBook[4]);
                                  notifyCustomer({
                                    creditBookDetailsId:
                                      item.creditBookDetailsId,
                                    creditId: item.creditId,
                                  });
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <MyModal ref={myRef} close={handleClose}>
              <Pay
                data={paymentDetails.emiAmount}
                btnTitle={'PayMent'}
                paymentDone={paymentDone}
              />
            </MyModal>
          </Grid>
        )}
      </>
    );
  }
);

const mapStateToProps = ({ SearchCredits, Loader }) => {
  return {
    creditDetails: SearchCredits.creditDetails,
    loading: Loader.loading,
    creditId: SearchCredits.creditId,
  };
};

export default connect(mapStateToProps, {
  getCreditDetails,
  creditPayment,
  setCreditId,
  notifyCustomer,
})(CrediDetails);
