import {
  SET_REWARDS_HISTORY,
  SET_REWARDS_INFO,
  SET_GIFT_CARDS,
  REMOVE_GIFT_CARD,
  ADD_GIFT_CARD,
  INC_OR_DEC,
  SET_LOADING,
  INC_OR_DEC_TOTAL,
  CLEAR_REWARD_HISTORY,
} from './types';

const initialState = {
  rewardsInfo: {},
  rewardsHistory: [],
  giftCards: [],
  selectedGiftCards: [],
  conversionRate: 10,
  names: [],
  redeemed: [false],
  loading: [false],
  total: 0,
};

const RewardsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REWARDS_INFO: {
      let rate = parseInt(payload.conversionRate.split('_')[0]);
      return {
        ...state,
        rewardsInfo: payload.retailerData,
        conversionRate: rate,
      };
    }
    case INC_OR_DEC_TOTAL: {
      return {
        ...state,
        total: state.total + payload,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case 'REDEEMED': {
      return {
        ...state,
        redeemed: payload,
      };
    }
    case SET_REWARDS_HISTORY: {
      return {
        ...state,
        rewardsHistory: [...state.rewardsHistory, ...payload],
      };
    }
    case CLEAR_REWARD_HISTORY: {
      return {
        ...state,
        rewardsHistory: [],
      };
    }
    case SET_GIFT_CARDS: {
      return {
        ...state,
        giftCards: payload,
      };
    }
    case ADD_GIFT_CARD: {
      return {
        ...state,
        selectedGiftCards: [payload, ...state.selectedGiftCards],
        names: [payload.name, ...state.names],
      };
    }
    case REMOVE_GIFT_CARD: {
      return {
        ...state,
        selectedGiftCards: state.selectedGiftCards.filter(
          selectedCard => selectedCard.sku !== payload.sku
        ),
        names: state.names.filter(name => payload.name !== name),
      };
    }
    case INC_OR_DEC: {
      return {
        ...state,
        selectedGiftCards: state.selectedGiftCards.map(gc => {
          if (gc.sku === payload.giftCard.sku) {
            return { ...gc, qty: gc.qty + payload.by };
          }
          return gc;
        }),
      };
    }
    default:
      return state;
  }
};

export default RewardsReducer;
