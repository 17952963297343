import React, { forwardRef } from 'react';

const Payment = forwardRef(
  ({ order_id, keyId, handleSend, name, email, contact }, myRef) => {
    var options = {
      key: keyId,
      name,
      description: 'SMS Purchase',
      image:
        'https://storage.googleapis.com/mbillbasket/images/userImages/1.png?1591465017852',
      order_id,
      handler: function({
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
      }) {
        handleSend({
          razorpay_payment_id,
          razorpay_order_id,
          razorpay_signature,
        });
      },
      prefill: {
        email,
        contact,
      },
      notes: { address: 'MBILL', generic: Math.random() },
      theme: { color: '#F37254' },
    };
    var rzp1 = new window.Razorpay(options);

    const handlePay = e => {
      e.preventDefault();
      rzp1.open();
    };

    return (
      <button
        ref={myRef}
        className="border-0 p-2 rounded-pill bg-themeorange text-white d-none"
        onClick={handlePay}
      >
        Send SMS
      </button>
    );
  }
);

export default Payment;
