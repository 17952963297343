import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { ButtonGroup, Button } from '@material-ui/core';
import {
  viewInvoice,
  setGetUserId,
  setProductId,
  setInvoice,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import {
  editInvoiceDetails,
  prefillBillingDetails,
} from '../../../RetailerWebPortal/Billing/billingAction';
import { getShareUrl } from '../../../store/RetailerWebStore/RetailerAction/rewardPoints';
import Loader from '../../Loader';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';
import { scanIdToBarcode } from '../../../@utils/scanIdToBarcode';

const Invoice = React.memo(
  ({
    editInvoiceDetails,
    prefillBillingDetails,
    getProductId,
    setInvoice,
    viewInvoice,
    invoice,
    loading,
    setProductId,
    getShareUrl,
    getNotificationCount,
  }) => {
    // const [billId, setBillId] = React.useState('')
    // const [invoiceId, setInvoiceId] = React.useState('')
    const [invoiceData, setInvoiceData] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [type, setType] = React.useState(0);
    const [isShare, setIsShare] = React.useState(false);
    const history = useHistory();
    const shareRef = useRef();

    useEffect(() => {
      const currentUrl = new URL(window.location.href);
      const invoicePayload = {
        billId: currentUrl.searchParams.get('billId'),
        invoiceId: currentUrl.searchParams.get('invoiceId'),
        shareInvoice: true,
      };
      // setGetUserId(invoicePayload);
      setProductId(invoicePayload);

      getNotificationCount();
      toastDismiss();
      let viewInvoicePayload = {
        billId: currentUrl.searchParams.get('billId'),
        invoiceId: currentUrl.searchParams.get('invoiceId'),
        type: 0,
      };
      viewInvoice(viewInvoicePayload);
      return () => {
        setInvoice('');
      };
    }, []);

    useEffect(() => {
      setInvoiceData(invoice);
    }, [invoice]);

    const changeType = value => {
      console.log('TYPE DEKHO CHANGE---->', value);
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
        type: parseInt(value),
      };
      viewInvoice(payload);
      setType(value);
      // setInvoiceData('');
      setInvoice('');
      setIsShare(false);
    };

    const editInvoice = () => {
      if (getProductId.shareInvoice) {
        // setGetUserId({
        //   billId: getProductId.billId,
        //   invoiceId: getProductId.invoiceId
        //     ? getProductId.invoiceId
        //     : undefined,
        //   editInvoice: true,
        // });
        setProductId({
          billId: getProductId.billId,
          invoiceId: getProductId.invoiceId
            ? getProductId.invoiceId
            : undefined,
          editInvoice: true,
        });
        history.push(`/Billing`);
      }
    };

    const handleEditInvoice = useCallback(async event => {
      event.preventDefault();
      let payload = { invoiceId: getProductId.invoiceId };

      await editInvoiceDetails(payload)
        .then(response => {
          const { data } = response;

          let selectedContact = data.data.customerDetails[0];

          let selectedProducts = data.data.productDetails;
          // selectedProducts = selectedProducts.map(product => ({
          //   ...product,
          //   inCartQuantity: product.quantity,
          // }));

          // change scanId to barcode
          selectedProducts = scanIdToBarcode(selectedProducts);
          selectedProducts = selectedProducts.map(product => ({
            ...product,
            inCartQuantity: product.quantity,
          }));

          let billId = data.data.invoiceDetails[0].serialNo;
          let billDate = data.data.invoiceDetails[0].invoice_date;
          let formatedDate = billDate.split('T')[0];

          let invoiceDetails = {
            billNo: billId,
            date: billDate,
            dateFormated: formatedDate,
          };

          let paymentDetails = data.data.invoiceDetails[0];

          if (paymentDetails.paymentMode1 == 'finance') {
            let financePaymentDetails = data.data.creditBook[0];
            const {
              startDate: startDateEmi,
              downPayment,
              totalEmi: numberOfMonths,
              totalAmount: financeAmount,
            } = financePaymentDetails;

            let toAdd = {
              startDateEmi: startDateEmi.split('T')[0].replace('/', '-'),
              downPayment: downPayment,
              numberOfMonths: numberOfMonths,
              financeAmount: financeAmount,
            };

            paymentDetails = { ...paymentDetails, ...toAdd };
          }

          let payload = {
            selectedContact,
            selectedProducts,
            invoiceDetails,
            paymentDetails,
          };

          prefillBillingDetails(payload);
          history.push('/billing');
        })
        .catch(error => {
          toasterMessage('error', error.response.data.error);
        })
        .catch(e => {
          toasterMessage('error', 'Something bad happened');
        });
    });

    const onSuccess = useCallback(
      data => {
        setIsShare(preState => {
          setUrl(data.invoiceUrl);
          return true;
        });
        // setEmail(data.customerEmail);
      },
      [setUrl, setIsShare]
    );
    return (
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        {loading ? (
          <Loader />
        ) : (
          <div className="w-75 d-flex flex-column align-items-center">
            {getProductId.shareInvoice && (
              <div className="w-100 d-flex mt-2 mb-2 justify-content-between align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '6rem', borderRadius: '1rem' }}
                  onClick={handleEditInvoice}
                >
                  Edit
                </Button>

                <ButtonGroup
                  disableElevation
                  color="primary"
                  aria-label="outlined button group"
                >
                  <Button
                    variant={type === 1 ? 'contained' : 'outlined'}
                    onClick={() => {
                      analyticFunc(eventsPayload.Billing[19]);
                      changeType(1);
                    }}
                  >
                    Long Form
                  </Button>
                  <Button
                    variant={type === 0 ? 'contained' : 'outlined'}
                    onClick={() => {
                      analyticFunc(eventsPayload.Billing[18]);
                      changeType(0);
                    }}
                  >
                    Short Form
                  </Button>
                </ButtonGroup>

                {isShare ? (
                  <div>
                    <WhatsappShareButton
                      url={url}
                      title={'Pdf of invoice'}
                      separator=":: "
                      onClick={() => {
                        analyticFunc(eventsPayload.Billing[21]);
                      }}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '6rem', borderRadius: '1rem' }}
                    onClick={e => {
                      analyticFunc(eventsPayload.Billing[20]);
                      getShareUrl(
                        {
                          invoiceId: getProductId.invoiceId || undefined,
                          billId: parseInt(getProductId.billId, 10),
                          type,
                        },
                        onSuccess
                      );
                    }}
                  >
                    Share
                  </Button>
                )}
              </div>
            )}

            {invoice && invoiceData ? (
              <div>
                <object
                  data={`${invoice}?${Date.now()}`}
                  width="800px"
                  height="600vh"
                  className="bg-white"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{' '}
                    <a href={`${invoice}?${Date.now()}`}>
                      click here to download the PDF file.
                    </a>
                  </p>
                </object>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        )}
      </div>
    );
  }
);

const mapStateToProps = ({ BillingReducer, Loader }) => ({
  getProductId: BillingReducer.getProductId,
  invoice: BillingReducer.invoice,
  loading: Loader.loading,
});

export default connect(mapStateToProps, {
  prefillBillingDetails,
  editInvoiceDetails,
  viewInvoice,
  setInvoice,
  setProductId,
  getShareUrl,
  getNotificationCount,
})(Invoice);
