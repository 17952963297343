import React, { useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Badge,
  Paper,
  SwipeableDrawer,
  Avatar,
  Tooltip,
  Switch,
  Button,
  Box,
} from '@material-ui/core';
import {
  Menu,
  NotificationsNoneOutlined,
  WhatsApp,
  YouTube,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  getNotification,
  getNotificationCount,
} from '../../store/RetailerWebStore/RetailerAction/notifications';
import NetworkDetector from '../../Common/NetworkDetector';
import LogoutDialog from '../../Common/Logout/ConfirmationDialog';
import '../../styles/3dIcons.css';
import logo from '../../images/logo.png';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Notification from './notification';

import credit from '../../assets/svg/credits.svg';
import home from '../../assets/svg/home.svg';
import manageCategory from '../../assets/svg/manageCategory.svg';
import profile from '../../assets/svg/profile.svg';
import inventoryOverview from '../../assets/svg/inventoryOverview.svg';
import alertInfo from '../../assets/svg/alertInfo.svg';
import rewardLogo from '../../assets/svg/rewardLogo.svg';
import logoutLogo from '../../assets/svg/logoutLogo.svg';
import reports from '../../assets/svg/reports.svg';
import sms from '../../assets/svg/sms.svg';
import eventsPayload from '../../GoogleAnalytics/events_payload';
import analyticFunc from '../../GoogleAnalytics/analytic_func';
import FEATURE_LIST from '../../features';
import ENABLE_FEATURE from '../../features';
import GstUser from './GstUser';

import {
  updateUserPrivacy,
  getProfile,
} from '../../store/RetailerWebStore/RetailerAction/myProfile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  heading: {
    fontWeight: 800,
    marginTop: '1em',
    width: '100%',
  },
  menuButton: {
    marginRight: 15,
    color: 'grey',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // background: 'white',
    minheight: '100vh',
    height: '100%',
  },
  navLabel: {
    marginTop: '0.5em',
  },
  sideNavBar: {
    backgroundColor: '#fff',
    height: '100%',
    paddingTop: '0.5em',
    paddingRight: '1em',
    width: '20em',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    '&::-webkit-scrollbar': {
      width: '0.6em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
    },
    '&:hover': {
      overflowY: 'auto',
    },
  },
  listIcon: {
    width: '2em',
    height: '1.5em',
  },
  grayBack: {
    color: 'gray',
    backgroundColor: '#f1f1f1',
  },
  avatar: {
    color: theme.palette.primary.main,
    fontFamily: theme.palette.typography.fontFamily,
    fontWeight: '600',
    fontSize: '2.5em',
    width: '2em',
    height: '2em',
    backgroundColor: '#FFE8E0',
    marginRight: '0.8em',
  },
  headingText: {
    fontSize: '1.2em',
    fontWeight: '500',
  },
  subHeadingText: {
    fontSize: '1em',
    color: '#A0A3BD',
  },
  navText: {
    fontFamily: theme.palette.typography.fontFamily,
    fontSize: '1em',
    color: '#6E7191',
  },
  navOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1em',
    marginLeft: '5em',
  },
  whatsAPPIcon: {
    marginRight: '0.8em',
    color: '#4FCE5D',
    fontSize: '1.6rem',
  },
  youtubeIcon: {
    marginRight: '0.8em',
    color: '#FF0000',
    fontSize: '1.6rem',
  },
}));

const defaultProps = {
  color: 'secondary',
  children: <NotificationsNoneOutlined style={{ color: 'grey' }} />,
};

const MainLayout = React.memo(
  ({
    user,
    children,
    configuration,
    notificationsCount,
    notifications,
    updateUserPrivacy,
  }) => {
    const classes = useStyles();
    const [logoutOpen, setLogOutOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    const [haveSharedData, setHaveSharedData] = React.useState(1);
    const [disablePrivacy, setDisablePrivacy] = React.useState(false);

    const history = useHistory();

    const SOCIAL_MEIA_LINKS = [
      {
        link: 'https://wa.me/919319595452',
        title: 'Chat with us',
        icon: () => <WhatsApp fontSize="inherit" />,
        className: classes.whatsAPPIcon,
      },
      {
        link: 'https://www.youtube.com/channel/UCiRlmPn7OSDnxd8SbSZlg7g',
        title: 'Tutorial',
        icon: () => <YouTube fontSize="inherit" />,
        className: classes.youtubeIcon,
      },
    ];

    useEffect(() => {
      setHaveSharedData(user.haveSharedData);
    }, [user]);

    const handlePrivacyChange = async () => {
      try {
        setDisablePrivacy(true);
        const payload = {
          haveSharedData: haveSharedData === 1 ? 0 : 1,
        };
        await updateUserPrivacy(payload);
        setDisablePrivacy(false);
      } catch (error) {
        setDisablePrivacy(false);
      }
    };

    const navigationItemList = [
      {
        name: 'Home',
        icon: home,
        url: 'dashboard',
        id: 1,
        event: null,
        ENABLE: true,
      },
      {
        name: 'Manage Category',
        icon: manageCategory,
        url: 'manageCategory',
        id: 2,
        event: eventsPayload.ManageCategory[0],
        ENABLE: true,
      },
      {
        name: 'Add Sub User',
        icon: profile,
        url: 'addSubUser',
        id: 3,
        event: eventsPayload.AddSubUser[0],
        ENABLE: true,
      },
      // {
      //   name: 'Credit Book',
      //   icon: credit,
      //   url: 'creditBook',
      //   id: 4,
      // },
      // {
      //   name: 'Inventory Overview',
      //   icon: inventoryOverview,
      //   url: 'inventoryOverview',
      //   id: 5,
      //   event: null,
      //   ENABLE: true,
      // },
      {
        name: 'My Profile',
        icon: profile,
        url: 'myProfile',
        id: 6,
        event: eventsPayload.Profile[0],
        ENABLE: true,
      },
      // {
      //   name: 'Rewards',
      //   icon: rewardLogo,
      //   url: 'rewardPoints',
      //   id: 7,
      // },
      {
        name: 'Reports',
        icon: reports,
        url: 'reports',
        id: 8,
        event: eventsPayload.Reports[0],
        ENABLE: true,
      },
      {
        name: 'Send SMS',
        icon: sms,
        url: 'Send SMS',
        id: 9,
        event: eventsPayload.SendSMS[0],
        ENABLE: ENABLE_FEATURE.SMS,
      },
      {
        name: 'Help',
        icon: alertInfo,
        url: 'help',
        id: 10,
        event: eventsPayload.Help[0],
        ENABLE: true,
      },
      {
        name: 'Logout',
        icon: logoutLogo,
        url: 'logout',
        id: 11,
        event: eventsPayload.Logout[0],
        ENABLE: true,
      },
    ];

    const NotificationList = () => {
      analyticFunc(eventsPayload.Notifications[0]);
      setShowNotification(!showNotification);
    };

    const handleLogoutOpen = () => {
      setLogOutOpen(true);
    };

    const handleLogoutClose = () => {
      setLogOutOpen(false);
    };

    const handleNavClick = url => {
      if (url === 'logout') {
        handleLogoutOpen();
      } else {
        history.push(`/${url}`);
        setOpen(!open);
      }
    };

    const handleNavOpen = () => {
      setOpen(!open);
    };

    const list = () => (
      <div className={classes.sideNavBar} role="presentation">
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <IconButton onClick={() => setOpen(!open)}>
            <CloseIcon />
          </IconButton>
        </div>

        <ListItem>
          <ListItemIcon>
            <Avatar className={classes.avatar}>
              {!!user.tradeName ? user.tradeName[0] : 'S'}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.headingText}>
                {user.tradeName ? user.tradeName : 'Shop Name'}
              </Typography>
            }
            secondary={
              <>
                <Typography className={classes.subHeadingText}>
                  {user.email}
                </Typography>
                <Typography className={classes.subHeadingText}>
                  {user.cinNumber}
                </Typography>
              </>
            }
          />
        </ListItem>

        <List component="nav">
          {/* {navigationItemList.map(({ name, icon, url, id, event }, index) => (
            <ListItem
              className={classes.navLabel}
              onClick={() => {
                if (event) analyticFunc(event);
                handleNavClick(url);
              }}
              key={id}
              button
            >
              <ListItemIcon>
                <img
                  src={icon}
                  alt={`${url} icon`}
                  className={classes.listIcon} */}
          {navigationItemList.map(
            ({ name, icon, url, id, event, ENABLE }, index) => {
              if (!ENABLE) return <></>;
              return (
                <ListItem
                  className={classes.navLabel}
                  onClick={() => {
                    if (event) analyticFunc(event);
                    handleNavClick(url);
                  }}
                  key={id}
                  button
                >
                  <ListItemIcon>
                    <img
                      src={icon}
                      alt={`${url} icon`}
                      className={classes.listIcon}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography className={classes.navText}>
                        {name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            }
          )}
          <div className={classes.navOptionContainer}>
            <Typography className={classes.navText}>Privacy</Typography>
            <Switch
              color="primary"
              disabled={disablePrivacy}
              checked={haveSharedData === 0}
              onChange={handlePrivacyChange}
            />
          </div>
          <GstUser handleNavOpen={handleNavOpen} />
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar)}>
          <Toolbar style={{ background: 'white', width: '100%' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleNavOpen}
              edge="start"
              className={clsx(classes.menuButton)}
            >
              <Menu />
            </IconButton>
            <Link to="/dashboard" className="text-decoration-none">
              <img src={logo} alt="alt-img" width="120px" />
            </Link>
            <div className="d-flex justify-content-end align-item-center w-100">
              {SOCIAL_MEIA_LINKS.map(mediaLinks => (
                <a
                  href={mediaLinks.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={mediaLinks.className}
                  key={mediaLinks.link}
                >
                  <Tooltip title={mediaLinks.title}>
                    {mediaLinks.icon()}
                  </Tooltip>
                </a>
              ))}
              <Badge
                className="mt-2 hover-pointer"
                badgeContent={
                  notificationsCount && notificationsCount.count.toString()
                }
                max={9999}
                {...defaultProps}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClick={e => NotificationList()}
              />
              {showNotification && (
                <Paper
                  style={{
                    position: 'absolute',
                    right: 35,
                    top: 40,
                    zIndex: 999,
                    overflow: 'auto',
                  }}
                >
                  <Notification notificationList={notifications} />
                </Paper>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={() => setOpen(!open)}
        >
          {list()}
        </SwipeableDrawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </main>
        {logoutOpen && <LogoutDialog handleLogoutClose={handleLogoutClose} />}
      </div>
    );
  }
);

const mapStateToProps = ({ loginReducer, notifications }) => ({
  user: loginReducer.user.userData[0],
  configuration: loginReducer.user.configuration,
  notifications: notifications.notifications,
  notificationsCount: notifications.notificationsCount,
});
export default connect(mapStateToProps, {
  getNotification,
  getNotificationCount,
  updateUserPrivacy,
})(MainLayout);
