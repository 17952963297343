import { toasterMessage } from '../../../@utils/utils';
import api from '../../../api/api';
import {
  SET_REWARDS_HISTORY,
  SET_REWARDS_INFO,
  SET_GIFT_CARDS,
  REMOVE_GIFT_CARD,
  ADD_GIFT_CARD,
  INC_OR_DEC,
  SET_LOADING,
  INC_OR_DEC_TOTAL,
  CLEAR_REWARD_HISTORY,
} from './types';

export const getRewardInfo = () => async dispatch => {
  api
    .get('/rewardPoints/getRewardData')
    .then(response => {
      const { data } = response.data;
      dispatch({ type: SET_REWARDS_INFO, payload: data });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const getRewardHistory = page => async dispatch => {
  dispatch({ type: SET_LOADING, payload: [true] });
  api
    .get(`/rewardPoints/getRewardHistory?page=${page}`)
    .then(response => {
      const { data } = response.data;
      dispatch({ type: SET_REWARDS_HISTORY, payload: data });
      dispatch({ type: SET_LOADING, payload: [false] });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: [false] });
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
      dispatch({ type: SET_LOADING, payload: [false] });
    });
};

export const getGiftCards = () => async dispatch => {
  let payload = {
    type: 'productListapi',
    id: 330,
    limit: 1,
    offset: 10,
  };
  api
    .post('/rewardPoints/getCategories', payload)
    .then(response => {
      const { data } = response.data;
      dispatch({ type: SET_GIFT_CARDS, payload: data._embedded.product });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const postRewardPoints = payload => async dispatch => {
  api
    .post('/rewardPoints/postRewardPoints', payload)
    .then(response => {
      const { data } = response.data;
      toasterMessage('success', response.data.message);
      dispatch({ type: 'REDEEMED', payload: [true] });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const addGiftCard = toAdd => async dispatch => {
  let payload = {
    qty: 1,
    currency: 356,
    price: toAdd.price,
    sku: toAdd.sku,
    name: toAdd.name,
  };
  dispatch({ type: ADD_GIFT_CARD, payload: payload });
};

export const removeGiftCard = toRemove => async dispatch => {
  dispatch({ type: REMOVE_GIFT_CARD, payload: toRemove });
};

export const clearRewardHistory = () => async dispatch => {
  dispatch({ type: CLEAR_REWARD_HISTORY, payload: [] });
};

export const incOrDec = payload => async dispatch => {
  dispatch({ type: INC_OR_DEC, payload: payload });
};

export const incOrDecTotal = payload => async dispatch => {
  dispatch({ type: INC_OR_DEC_TOTAL, payload: payload });
};
