/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import * as LogOutAction from '../../store/AdminActions/logoutAction';

function DeleteDialog(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    props.handleLogoutClose();
  };

  const handleLogout = () => {
    props.userLogout();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="alertDeleteDialog"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ paddingTop: '0px', marginTop: '0px' }}
          >
            Are you sure you want to log out ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to={`/`} className="text-decoration-none">
            <Button
              onClick={handleLogout}
              variant="outlined"
              color="primary"
              autoFocus
              style={{ width: 100 }}
            >
              YES
            </Button>
          </Link>
          <Link to="#" className="text-decoration-none">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="bg-themeorange text-white"
              style={{ width: 100 }}
            >
              NO
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(null, { ...LogOutAction })(DeleteDialog);
