import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import win_rewards from '../../../assets/svg/win_rewards.svg';
import FillSurvey from './fillSurvey';
import Login from './login';
import { useParams } from 'react-router';
import SimplePage from './simplePage';
import FillSurveyStatic from './staticPage/fillSurveyStatic';

const useStyles = makeStyles(theme => ({
  cont: props => ({
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      alignItems: 'none',
    },
    height: '100vh',
  }),
  sigInCont: {
    marginRight: '2rem',
    flexShrink: '2',
    display: 'flex',
    textAlign: 'center',
    fontWeight: '800',
    fontSize: '3vw',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      marginRight: '0',
      marginBottom: '2rem',
    },
  },

  signInSpan: {
    color: '#3F3D56',
  },
  giftSpan: {
    color: theme.palette.primary.main,
  },
  loginCont: {
    height: '50%',
    textAlign: 'center',
    width: '50%',
    fontStyle: 'bold',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '22rem',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '35rem',
    },
  },
  link: {
    color: '#FE805C',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    // minWidth: "20.4rem",
    color: 'white',
    marginBottom: '1rem',
  },
}));

const SignInAndWin = ({ classes }) => (
  <div className={classes.sigInCont}>
    <div>
      <span className={classes.signInSpan}>Sign in and win </span>
      <br /> <span className={classes.giftSpan}>gift vouchers.</span>
    </div>
    <img src={win_rewards} style={{ width: '80%', alignSelf: 'center' }} />
  </div>
);

const EarnRewardContainer = React.memo(props => {
  const classes = useStyles(props);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fbCred, setFbCred] = useState();
  const [response, setResponse] = useState({});
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [isSimpleLogin, setIsSimpleLogin] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    setIsSimpleLogin(!token);
    // if(token)
    // const k = window.location.href.split("/");
    // if (k.length > 1 && k[k.length - 1] == "login") setIsSimpleLogin(true);
  }, []);

  return (
    <>
      <Backdrop style={{ zIndex: 999999 }} open={waitingForResponse}>
        <CircularProgress color="primary" />
      </Backdrop>
      {!isLoggedIn && (
        <div className={classes.cont}>
          <SignInAndWin classes={classes} />
          <Login
            token={token}
            isSimpleLogin={isSimpleLogin}
            setFbCred={setFbCred}
            setResponse={setResponse}
            setWaitingForResponse={setWaitingForResponse}
            setIsLoggedIn={setIsLoggedIn}
          />
        </div>
      )}
      {!isSimpleLogin && isLoggedIn && (
        <FillSurvey response={response} fbCred={fbCred} />
      )}

      {isSimpleLogin && isLoggedIn && <FillSurveyStatic fbCred={fbCred} />}
    </>
  );
}, []);

export default EarnRewardContainer;
