import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Login from '../../Common/Login/Login';
import Dashboard from '../../AdminWebPortal/Dashboard/Dashboard';
import LayoutRetailer from '../../RetailerWebPortal/MainLayout/layout';
import PartialUser from '../../Common/Signup/partialUser';
import GateKeeper from '../../RetailerWebPortal/WebComponents/GateKeeper';
import MyProfile from '../../RetailerWebPortal/WebComponents/ProfilePage';

const ProtectedRoute = props => {
  const {
    isLoggedIn,
    user,
    Comp,
    name,
    isOnboarded,
    userData,
    userToken,
  } = props;
  const isRefugee = false;

  useEffect(() => {
    if (!(userToken && userData.length && userData[0].isRegister)) {
      props.history.replace('/');
    } else {
      let userType = user.userType;
      if (userType) userType = userType.toLowerCase();

      if (
        userType &&
        (userType === 'retailer' || userType === 'subretailer') &&
        !isOnboarded
      ) {
        props.history.replace('/onboarding');
      }
    }
  }, []);

  const switchUser = userType => {
    if (userType) userType = userType.toLowerCase();
    switch (userType) {
      case 'admin':
      case 'subadmin':
      case 'cse':
      case 'floormanager':
      case 'zonalmanager':
      case 'statemanager':
      case 'avp': {
        return <Dashboard />;
      }

      case 'retailer':
      case 'subretailer': {
        return user.configuration[name] || name === 'dashboard' ? (
          /* name === 'reports' */
          <LayoutRetailer>
            <Comp {...props} />
          </LayoutRetailer>
        ) : (
          <GateKeeper {...props} />
        );
      }
      // case "subretailer": {
      //   return user.userData[0].isRegister < 3 ? (
      //     <PartialUser />
      //   ) : user.isRefuge ? (
      //     <MyProfile />
      //   ) : user.configuration[name] || name === "dashboard" ? (
      //     <LayoutRetailer>
      //       <Comp {...props} />
      //     </LayoutRetailer>
      //   ) : (
      //     <GateKeeper {...props} />
      //   );
      // }
      default: {
        console.log('DEFAULT ROUTE');
        return <Login {...props} />;
      }
    }
  };
  return isLoggedIn
    ? switchUser(user.userType || user.userData[0].userType)
    : switchUser(null);
};

const mapStateToProps = ({ loginReducer, OtpLoginReducer }) => {
  return {
    isLoggedIn: loginReducer.isUserLoggedIn,
    user: loginReducer.user,
    isOnboarded: OtpLoginReducer.isOnboarded,
    userData: OtpLoginReducer.user.userData,
    userToken: OtpLoginReducer.userToken,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
