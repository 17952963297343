import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CustomInput from '../../../CommonComponents/customInput';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const Search = React.memo(({ getSearchKeyword, val, placeholder }) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const width = window.innerWidth;
  const breakPoint = 500;

  useEffect(() => {
    setText(val);
  }, [val]);

  useEffect(() => {
    const clock = setTimeout(() => {
      getSearchKeyword(text);
    }, 500);
    return () => clearTimeout(clock);
  }, [text]);

  return (
    <CustomInput
      beforeTextField={<SearchIcon className="inputContainerIcon" />}
      onChange={e => setText(e.target.value)}
      inputLabel={placeholder || 'Search'}
      value={text}
    />
  );
});

export default Search;
