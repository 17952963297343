import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
// import gc1 from "../../../../assets/svg/bmshow.svg";
// import gc2 from "../../../../assets/svg/lifestyleGC.svg";
import gc3 from '../../../../assets/svg/amazonGC.svg';
// import gc4 from "../../../../assets/svg/mmtGC.svg";
// import gc5 from "../../../../assets/svg/myntraGC.svg";
// import gc6 from "../../../../assets/svg/shopperStopGC.svg";

const useStyles = makeStyles(theme => ({
  surveyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  surveyMainText: {
    fontSize: '3.3125rem',
    fontWeight: '800',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      textAlign: 'left',
    },
  },
  rewardCard: {
    height: '26.5rem',
    width: '20rem',
    borderRadius: '1rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  rewardLogo: {
    width: '14rem',
    height: '14rem',
  },
}));

const RewardsEarnedStatic = React.memo(props => {
  const classes = useStyles(props);
  const [voucherValue, setVoucherValue] = useState(25);
  const [voucherName, setVoucherName] = useState('BookMyShow Gift Card');
  const [voucherImage, setVoucherImage] = useState(gc3);
  const [rewardsReceived, setRewardsReceived] = useState(true);
  const { token } = useParams();

  useEffect(() => {
    setVoucherValue(Math.floor(Math.random() * 60 + 1));
    // let num = Math.floor(Math.random() * 5) + 1;
    // console.log(num);
    // setVoucherImage(`gc${num}`);
  }, []);

  return (
    <>
      {rewardsReceived && (
        <div
          className={classes.surveyContainer}
          style={{ justifyContent: 'space-evenly' }}
        >
          <div className={classes.surveyMainText}>
            <span style={{ color: '#3F3D56' }}>
              Congrats!
              <br />
            </span>
            <span style={{ color: '#FE805C' }}>
              {' '}
              You have won &#8377; {voucherValue}
            </span>{' '}
          </div>
          <Paper className={classes.rewardCard}>
            <img src={voucherImage} className={classes.rewardLogo} />
            <div>
              <h5 style={{ fontSize: '2rem', fontWeight: '800' }}>
                &#8377; {voucherValue}
              </h5>
              <span style={{ color: '#AFAFBD', fontSize: '0.75rem' }}>
                Voucher details and instructions on how to redeem has been sent
                to your email id.
              </span>
            </div>
          </Paper>
        </div>
      )}
      {!rewardsReceived && <div>Please wait while we confirm....</div>}
    </>
  );
}, []);

export default RewardsEarnedStatic;
