import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import EditBill from './editBill';
import { connect } from 'react-redux';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  editButton: {
    margin: '0 0',
    paddingTop: '0px',
  },
}));

const BillNumberContainer = props => {
  const classes = useStyles();
  const { invoiceDetails } = props;
  const { billNo, dateFormated } = invoiceDetails;
  const [toggleEditModal, setToggleEditModal] = useState(false);
  return (
    <div>
      <div className={classes.div}>
        <p style={{ fontWeight: '500' }}>Bill Number : {billNo}</p>
        <Button
          color="primary"
          className={classes.editButton}
          onClick={() => {
            analyticFunc(eventsPayload.Billing[1]);
            setToggleEditModal(!toggleEditModal);
          }}
        >
          Edit
        </Button>
      </div>
      <p className="subtitle">{dateFormated}</p>
      <EditBill
        number={billNo}
        toggleEditModal={toggleEditModal}
        setToggleEditModal={setToggleEditModal}
      />
    </div>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => {
  return {
    invoiceDetails: BillingReducer.invoiceDetails,
  };
};

export default connect(mapStateToProps, null)(BillNumberContainer);
