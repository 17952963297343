import React from 'react';
React.lazy(() => import('../../styles/error.css'));

const NotFound = React.memo(() => {
  return (
    <div className="error-container">
      <span>4</span>
      <span>0</span>
      <span>4</span>
    </div>
  );
});

export default NotFound;
