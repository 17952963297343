import { createMuiTheme } from '@material-ui/core/styles';
import { StyleRoot } from 'radium';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#EEAF9F',
      main: '#FE805C',
      dark: '#E95930',
      placeHolderBackground: '#EFF0F6',
      placeHolderActiveText: '#a0a3bd',
      contrastText: '#000',
    },
    secondary: {
      main: '#FFE8E0',
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '14px',
    },
    others: {
      dividerColor: '#D9DBE9',
    },
  },

  overrides: {
    // MuiButton Style
    MuiButton: {
      root: {
        padding: '0.75rem',
        borderRadius: '0.5rem',
        alignItems: 'center',
        justifyContent: 'center',
      },
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#FE805C',
        '&:hover': {
          backgroundColor: '#EEAF9F',
        },
      },
      label: {
        fontSize: '1rem',
        textTransform: 'none',
      },
    },

    // TextFields Placeholder Text
    MuiInputLabel: {
      root: {
        '&.Mui-error': {
          color: '#C30052',
        },
      },
      formControl: {
        color: '#a0a3bd',
        fontSize: '1.1rem',
        transform: 'translate(0, 14px) scale(1)',
      },
    },
    // Might create problem w/ styling TBT
    MuiFormControl: {
      marginNormal: {
        marginTop: '0',
        marginBottom: '0',
      },
    },
  },
});
