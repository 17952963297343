/* eslint-disable */
import React, { useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getProductDetails,
  modeOfPayment,
  viewModeOfPayment,
  setActivePage,
  cancelInvoice,
  setBillingStatus,
  setGetUserId,
  setInvoiceDate,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import { useHistory } from 'react-router-dom';
import Loader from '../../Loader';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';

import { Prompt } from 'react-router';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ModeOfPayment = React.memo(
  ({
    getProductId,
    setActivePage,
    getProductDetails,
    addedProductDetails,
    paymentMode,
    loading,
    viewModeOfPayment,
    paymentDetails,
    cancelInvoice,
    setBillingStatus,
    setGetUserId,
    invoiceDate,
    setInvoiceDate,
  }) => {
    const classes = useStyles();
    const [billId, setBillId] = React.useState('');
    const [invoiceId, setInvoiceId] = React.useState('');
    const [paymentMode1, setPaymentMode1] = React.useState('');
    const [paymentModeAmount1, setPaymentModeAmount1] = React.useState('');
    const [paymentMode2, setPaymentMode2] = React.useState('');
    const [paymentModeAmount2, setPaymentModeAmount2] = React.useState('');
    const [numberOfEMI, setNumberOfEMI] = React.useState('');
    const [EMIAmount, setEMIAmount] = React.useState('');
    const [startDateOfEMI, setStartDateOfEMI] = React.useState('');
    const [endDateOfEMI, setEndDateOfEMI] = React.useState('');
    const [totalAmount, setTotalAmount] = React.useState('');
    const [prevAmount, setPrevAmount] = React.useState('');
    const [showPaymentMode2, setShowPaymentMode2] = React.useState(false);
    const [specialNote, setSpecialNote] = React.useState('');
    const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(
      true
    );
    const [dueBalance, setDueBalance] = React.useState('');
    const [emiCompleted, setEmiCompleted] = React.useState('');
    const [prevCreditAmount, setPrevCreditAmount] = React.useState('');
    const [prevEmiAmount, setPrevEmiAmount] = React.useState('');

    const history = useHistory();

    let paymentOptions = [
      { id: '1', value: 'Cash', name: 'Cash' },
      { id: '2', value: 'debit', name: 'Debit Card' },
      { id: '3', value: 'credit', name: 'Credit Card' },
      { id: '4', value: 'digitalWallet', name: 'Digital Wallet' },
      { id: '5', value: 'finance', name: 'Finance' },
    ];

    const InvoiceCancel = () => {
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
      };
      cancelInvoice(payload);
    };

    useEffect(() => {
      toastDismiss();

      return () => {
        if (
          window.location.pathname != '/Invoice' &&
          window.location.pathname != '/Billing'
        ) {
          // alert("Invoice Canceled")
          InvoiceCancel();
        }
      };
    }, []);

    console.log('invoiceDate=====', invoiceDate);

    useEffect(() => {
      setBillId(getProductId.billId);
      setInvoiceId(getProductId.invoiceId);
    }, [getProductId]);

    useEffect(() => {
      if (dueBalance) {
        let dPayment = totalAmount - (prevAmount - dueBalance);
        setDueBalance(dPayment);
      }
    }, [prevAmount, totalAmount]);

    useEffect(() => {
      if (billId) {
        let payload = {
          billId: billId,
          invoiceId: invoiceId ? invoiceId : undefined,
        };
        // viewModeOfPayment(payload)
        getProductDetails(payload);
        setTotalAmount(addedProductDetails.roundedAmount);
      }
    }, [billId]);

    useEffect(() => {
      if (paymentDetails && totalAmount) {
        setPaymentMode1(paymentDetails.paymentMode1);
        setPaymentModeAmount1(paymentDetails.paymentMode1Amount);
        setPaymentMode2(paymentDetails.paymentMode2);
        setPaymentModeAmount2(paymentDetails.paymentMode2Amount);
        setNumberOfEMI(paymentDetails.numberOfEmi);
        setEMIAmount(paymentDetails.emiAmount);
        setStartDateOfEMI(paymentDetails.startDateEmi);
        setEndDateOfEMI(paymentDetails.endDateEmi);
        setPrevAmount(paymentDetails.roundedAmount);
        setSpecialNote(paymentDetails.specialNote);
        setDueBalance(paymentDetails.dueBalance);
        setEmiCompleted(paymentDetails.emiCompleted);
        setPrevCreditAmount(paymentDetails.totalCreditAmount);
        setPrevEmiAmount(paymentDetails.emiAmount);
      }
    }, [paymentDetails, totalAmount]);

    useEffect(() => {
      if (
        billId &&
        totalAmount &&
        paymentMode1 &&
        paymentModeAmount1 &&
        paymentModeAmount1 < totalAmount
      ) {
        setShowPaymentMode2(true);
      } else if (
        billId &&
        totalAmount &&
        paymentMode1 &&
        paymentModeAmount1 &&
        paymentModeAmount1 >= totalAmount
      ) {
        setShowPaymentMode2(false);
      }
    }, [paymentModeAmount1]);

    useEffect(() => {
      if (paymentModeAmount1 && paymentMode2 && totalAmount) {
        let leftAmount = parseInt(totalAmount) - parseInt(paymentModeAmount1);
        setPaymentModeAmount2(leftAmount);
      }
    }, [paymentMode2, paymentModeAmount1]);

    useEffect(() => {
      if (paymentMode1 == 'finance') {
        setPaymentModeAmount1(totalAmount);
        setShowPaymentMode2(false);
      }
    }, [paymentMode1]);

    const changePaymentMode1 = e => {
      e.preventDefault();
      setPaymentMode1(e.target.value);
      setPaymentModeAmount1('');
      setPaymentMode2('');
      setPaymentModeAmount2('');
      setNumberOfEMI('');
      setEMIAmount('');
      setStartDateOfEMI('');
      setEndDateOfEMI('');
      setShowPaymentMode2(false);
    };

    useEffect(() => {
      if (numberOfEMI && invoiceDate) {
        setStartDateOfEMI(
          moment(invoiceDate)
            .add(1, 'M')
            .format('YYYY-MM-DD')
        );
        setEndDateOfEMI(
          moment(invoiceDate)
            .add(numberOfEMI, 'M')
            .format('YYYY-MM-DD')
        );
      }
    }, [numberOfEMI, invoiceDate]);

    const paymentSubmit = useCallback(async event => {
      event.preventDefault();
      let payload = {
        billId: billId,
        invoiceId: invoiceId ? invoiceId : undefined,
        paymentMode1: paymentMode1,
        paymentMode1Amount: paymentModeAmount1
          ? parseInt(paymentModeAmount1)
          : undefined,
        paymentMode2: paymentMode2 ? paymentMode2 : '',
        paymentMode2Amount: paymentModeAmount2
          ? parseInt(paymentModeAmount2)
          : 0,
        numberOfEmi: numberOfEMI ? parseInt(numberOfEMI) : undefined,
        emiAmount: EMIAmount ? parseInt(EMIAmount) : undefined,
        startDateEmi: startDateOfEMI ? startDateOfEMI : undefined,
        endDateEmi: endDateOfEMI ? endDateOfEMI : undefined,
        totalCreditAmount:
          EMIAmount && numberOfEMI
            ? parseInt(EMIAmount * numberOfEMI)
            : undefined,
        specialNote: specialNote ? specialNote : undefined,
      };
      await modeOfPayment(payload)
        .then(res => {
          // setShouldBlockNavigation(false)
          setActivePage(0);
          setBillingStatus({ status: 1 });
          setGetUserId({ billId, invoiceId, shareInvoice: true });
          setInvoiceDate('');
          history.push(`/invoice?billId=${billId}&invoiceId=${invoiceId}`);
          // if (invoiceId) {
          //   history.push(`/Invoice?billId=${billId}?invoiceId=${invoiceId}`)
          // } else {
          //   history.push(`/Invoice?billId=${billId}`)
          // }
        })
        .catch(err => {
          toasterMessage('error', err.response.data.error);
        });
    });

    return (
      <React.Fragment>
        {/* <Prompt when={shouldBlockNavigation} message="Are you sure you want to cancel this invoice" /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ margin: '10px', textAlign: 'left' }}>
            {prevCreditAmount ? (
              <h6>Prev Credit Amount: {prevCreditAmount}</h6>
            ) : null}
            {prevEmiAmount ? <h6>Prev EMI Amount: {prevEmiAmount}</h6> : null}
            {prevEmiAmount ? <h6>Prev EMI Completed: {emiCompleted}</h6> : null}
          </div>

          <div style={{ margin: '10px', textAlign: 'right' }}>
            <h6>Total Price: {totalAmount}</h6>
            {prevAmount ? <h6>Previous Price: {prevAmount}</h6> : null}
            {dueBalance ? <h6>Due Balance: {dueBalance}</h6> : null}
          </div>
        </div>

        <Typography variant="h6" gutterBottom>
          Payment Method
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <h5>Invoice Date:</h5>
                <span style={{ paddingLeft: '71%', paddingTop: '1%' }}>
                  {invoiceDate && moment(invoiceDate).format('DD/MMMM/YYYY')}
                </span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                xs={12}
                style={{ textAlign: 'left' }}
              >
                <label id="paymentMode1">Payment Mode 1</label>
                <Select
                  displayEmpty
                  placeholder="Payment Mode 1"
                  labelId="paymentMode1"
                  id="paymentMode1"
                  value={paymentMode1}
                  onChange={e => changePaymentMode1(e)}
                  // onChange={(e) => setPaymentMode1(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    <span style={{ color: 'grey' }}>Payment Mode 1</span>
                  </MenuItem>
                  {paymentOptions &&
                    paymentOptions.length > 0 &&
                    paymentOptions.map(item => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {paymentMode1 && paymentMode1 == 'finance' ? (
              <React.Fragment>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <label style={{ width: '100%' }}>Number of EMIs</label>
                  <TextField
                    id="numberOfEMI"
                    name="numberOfEMI"
                    fullWidth
                    autoComplete="numberOfEMI"
                    value={numberOfEMI}
                    onChange={e => {
                      setEMIAmount((totalAmount / e.target.value).toFixed(2));
                      setNumberOfEMI(
                        Math.max(0, e.target.value.replace(/[^\d]/, ''))
                          .toString()
                          .slice(0, 2)
                      );
                      // setPaymentModeAmount1(e.target.value * EMIAmount)
                    }}
                  />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex' }}>
                  <label style={{ width: '100%' }}>EMI Amount</label>
                  <TextField
                    disabled
                    id="emiAmount"
                    name="emiAmount"
                    fullWidth
                    autoComplete="emiAmount"
                    value={EMIAmount}
                    onChange={e => {
                      setEMIAmount(e.target.value.replace(/[^\d]/, ''));
                      setPaymentModeAmount1(e.target.value * numberOfEMI);
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <label style={{ width: '100%' }}>Start Date of EMI</label>
                  <TextField
                    id="startDateOfEMI"
                    name="startDateOfEMI"
                    fullWidth
                    disabled
                    autoComplete="startDateOfEMI"
                    value={startDateOfEMI}
                    onChange={e => setStartDateOfEMI(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <label style={{ width: '100%' }}>End Date of EMI</label>
                  <TextField
                    id="enddateOfEMI"
                    name="enddateOfEMI"
                    fullWidth
                    disabled
                    autoComplete="enddateOfEMI"
                    value={endDateOfEMI}
                    onChange={e => setEndDateOfEMI(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', marginLeft: '30%' }}
                >
                  <h6>Total Credit Amount: </h6>{' '}
                  <span style={{ marginLeft: '1%' }}>{paymentModeAmount1}</span>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <label style={{ width: '100%', marginLeft: '1%' }}>
                  <p>Payment Mode Amount1</p>
                </label>
                <TextField
                  id="paymentModeAmount1"
                  name="paymentModeAmount1"
                  placeholder="Payment Mode Amount1"
                  fullWidth
                  autoComplete="paymentModeAmount1"
                  value={paymentModeAmount1}
                  onChange={e =>
                    setPaymentModeAmount1(e.target.value.replace(/[^\d]/, ''))
                  }
                />
              </Grid>
            )}

            {showPaymentMode2 && (
              <React.Fragment>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    xs={12}
                    style={{ textAlign: 'left' }}
                  >
                    <label id="city">Payment Mode 2</label>
                    <Select
                      displayEmpty
                      labelId="Payment Mode 2"
                      id="paymentMode2"
                      value={paymentMode2}
                      onChange={e => setPaymentMode2(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        <span style={{ color: 'grey' }}>Payment Mode 2</span>
                      </MenuItem>
                      {paymentOptions &&
                        paymentOptions.length > 0 &&
                        paymentOptions.map(item => {
                          if (item.value != paymentMode1) {
                            return (
                              <MenuItem key={item.id} value={item.value}>
                                {item.name}
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                {paymentMode2 && paymentMode2 == 'finance' ? (
                  <React.Fragment>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <label style={{ width: '100%' }}>Number of EMIs</label>
                      <TextField
                        id="numberOfEMI"
                        name="numberOfEMI"
                        fullWidth
                        autoComplete="numberOfEMI"
                        value={numberOfEMI}
                        paymentModeAmount2
                        onChange={e => {
                          setNumberOfEMI(
                            Math.max(0, e.target.value.replace(/[^\d]/, ''))
                              .toString()
                              .slice(0, 2)
                          );
                          // setPaymentModeAmount2(e.target.value * EMIAmount)
                          setEMIAmount(
                            (paymentModeAmount2 / e.target.value).toFixed(2)
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <label style={{ width: '100%' }}>EMI Amount</label>
                      <TextField
                        disabled
                        id="emiAmount"
                        name="emiAmount"
                        fullWidth
                        autoComplete="emiAmount"
                        value={EMIAmount}
                        onChange={e => {
                          setEMIAmount(e.target.value.replace(/[^\d]/, ''));
                          setPaymentModeAmount2(e.target.value * numberOfEMI);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <label style={{ width: '100%' }}>Start Date of EMI</label>
                      <TextField
                        id="startDateOfEMI"
                        name="startDateOfEMI"
                        disabled
                        fullWidth
                        autoComplete="startDateOfEMI"
                        value={startDateOfEMI}
                        onChange={e => setStartDateOfEMI(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex' }}>
                      <label style={{ width: '100%' }}>End Date of EMI</label>
                      <TextField
                        id="enddateOfEMI"
                        name="enddateOfEMI"
                        disabled
                        fullWidth
                        autoComplete="enddateOfEMI"
                        value={endDateOfEMI}
                        onChange={e => setEndDateOfEMI(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', marginLeft: '30%' }}
                    >
                      <h6>Total Credit Amount: </h6>{' '}
                      <span style={{ marginLeft: '1%' }}>
                        {paymentModeAmount2}
                      </span>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <label style={{ width: '100%', marginLeft: '1%' }}>
                      <p>Payment Mode Amount2</p>
                    </label>
                    <TextField
                      id="paymentModeAmount2"
                      name="paymentModeAmount2"
                      placeholder="Payment Mode Amount2"
                      fullWidth
                      autoComplete="paymentModeAmount2"
                      value={paymentModeAmount2}
                      onChange={e =>
                        setPaymentModeAmount2(
                          e.target.value.replace(/[^\d]/, '')
                        )
                      }
                    />
                  </Grid>
                )}
              </React.Fragment>
            )}

            <Grid item xs={12} style={{ textAlign: 'left' }}>
              <label style={{ width: '100%', marginLeft: '1%' }}>
                <p>Remarks</p>
              </label>
              <TextField
                id="specialNote"
                placeholder="Remarks"
                name="specialNote"
                fullWidth
                autoComplete="specialNote"
                value={specialNote}
                onChange={e => setSpecialNote(e.target.value)}
              />
            </Grid>

            {!loading ? (
              <button
                className="border-0 p-3 text-white w-50 rounded-pill"
                style={{
                  background: 'rgb(237, 79, 42)',
                  marginLeft: '25%',
                  marginBottom: '2%',
                }}
                onClick={paymentSubmit}
              >
                Submit
              </button>
            ) : (
              <Loading />
            )}
          </Grid>
        )}
      </React.Fragment>
    );
  }
);

const mapStateToProps = ({ Billing, Loader }) => {
  return {
    getProductId: Billing.getProductId,
    addedProductDetails: Billing.productDetails,
    paymentMode: Billing.paymentMode,
    paymentDetails: Billing.paymentDetails,
    loading: Loader.loading,
    invoiceDate: Billing.invoiceDate && Billing.invoiceDate.date,
  };
};

export default connect(mapStateToProps, {
  getProductDetails,
  setActivePage,
  modeOfPayment,
  viewModeOfPayment,
  cancelInvoice,
  setBillingStatus,
  setGetUserId,
  setInvoiceDate,
})(ModeOfPayment);
