import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  gridDiv: {
    padding: '1rem',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '0.2fr 1fr',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '1rem',
    },
  },
  avatar: {
    margin: 'auto',
    gridCol: '1',
    gridRow: '1/3',
  },
}));

const Contact = props => {
  const classes = useStyles();
  const { name, phoneNumber } = props;
  const [initials, setInitials] = useState('');

  useEffect(() => {
    getInitials();
  }, []);

  const getInitials = () => {
    var set = name.split(' ');
    if (set.length > 1) {
      setInitials(
        set[0].charAt(0).toUpperCase() + set[1].charAt(0).toUpperCase()
      );
    } else {
      setInitials(set[0].charAt(0).toUpperCase());
    }
  };

  return (
    <div onClick={props.handleSelection} className={classes.gridDiv}>
      <Avatar className={classes.avatar}>{initials}</Avatar>
      <p className="my-auto">{name}</p>
      <p className="subtitle my-auto">{phoneNumber} </p>
    </div>
  );
};

export default Contact;
