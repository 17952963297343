/* eslint-disable */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Clear } from '@material-ui/icons';
import { toastDismiss } from '../../../@utils/utils';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    maxHeight: '90vh',
    overflowY: 'auto',
  },
}));

const MyModal = React.forwardRef(({ children, close }, myRef) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    toastDismiss();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    close && close(value);
  };

  return (
    <div>
      <button
        ref={myRef}
        className="d-none"
        type="button"
        onClick={handleOpen}
      ></button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ position: 'relative' }} className={classes.paper}>
            <Clear
              className="hover-pointer text-secondary"
              style={{ position: 'absolute', right: 10, zIndex: 999 }}
              onClick={e => handleClose(false)}
            />
            {React.cloneElement(children, { handleModal: handleClose })}
          </div>
        </Fade>
      </Modal>
    </div>
  );
});

export default MyModal;
