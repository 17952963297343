import { SET_LOADING } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  loading: false,
};

const Expenses = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    default:
      return state;
  }
};
export default Expenses;
