import { GET_FAQ } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  faqList: [],
};

const faq = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FAQ: {
      return {
        ...state,
        faqList: payload,
      };
    }
    default:
      return state;
  }
};
export default faq;
