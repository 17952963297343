import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CreateProduct from './createProduct';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '4rem',
    borderRadius: '1.5rem',
    position: 'absolute',
    overflow: 'auto',
    height: '60%',
    width: '100%',
    display: 'block',
    marginTop: '100px',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '1px solid slategrey',
    },
  },
  modal: {
    margin: 'auto',
    maxWidth: '60%',
    maxheight: '90%',
  },
}));

const EditProduct = props => {
  const classes = useStyles();
  const { toggleModal, setToggleModal, product } = props;
  const { from } = props;
  return (
    <Modal
      className={classes.modal}
      open={toggleModal}
      onClose={() => setToggleModal(!toggleModal)}
    >
      <Paper className={classes.paper}>
        <CreateProduct
          editProduct={product}
          setToggleModal={setToggleModal}
          toggleModal
          from={from}
        />
      </Paper>
    </Modal>
  );
};

export default EditProduct;
