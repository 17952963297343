import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updateGstStatus } from '../RetailerWebPortal/Billing/billingAction';
import { updateProfile } from '../store/RetailerWebStore/RetailerAction/myProfile';
import CustomInput from '../CommonComponents/customInput';
import analyticFunc from '../GoogleAnalytics/analytic_func';
import eventsPayload from '../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem',
    borderRadius: '1.5rem',
  },
}));

const AskGstDetailsModal = props => {
  const classes = useStyles();
  const [isGstUser, setIsGstUser] = useState(true);
  const [gstNumber, setGstNumber] = useState('');
  const [showGstError, setShowGstError] = useState(false);
  const { toggleModal, setToggleModal } = props;

  const allFieldsValid = () => {
    let isValidGst = true;
    if (gstNumber !== '') {
      let gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      if (gstRegex.test(gstNumber)) {
        isValidGst = true;
        setShowGstError(false);
      } else {
        isValidGst = false;
        setShowGstError(true);
      }
    }
    let allFieldsValid = isValidGst;
    return allFieldsValid;
  };

  const handleSubmit = () => {
    // let payload = {
    //   ...props.user,
    //   isGstNumber: gstNumber && gstNumber.length > 0 ? "yes" : "No",
    //   gstNumber: gstNumber && gstNumber.length > 0 ? gstNumber : undefined,
    // };
    // props.updateProfile(payload);
    analyticFunc(eventsPayload.ChangeGST[3]);
    if (allFieldsValid()) {
      let payload = {
        gst: isGstUser ? 1 : 0,
      };
      if (gstNumber !== '') payload = { ...payload, gstNumber: gstNumber };
      props.updateGstStatus(payload);
      setToggleModal(false);
    }
  };

  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '20rem',
        maxWidth: '30%',
        height: '60%',
      }}
      open={toggleModal}
    >
      <Paper className={classes.paper}>
        <p className="formTitle">Are you GST registered ?</p>
        <div>
          <Button
            onClick={() => {
              analyticFunc(eventsPayload.ChangeGST[1]);
              setIsGstUser(true);
            }}
            style={{
              border: isGstUser ? '0.13rem solid #FE805C' : '',
              marginRight: '0.4rem',
              marginBottom: '1rem',
            }}
            variant="outlined"
          >
            YES
          </Button>
          <Button
            onClick={() => {
              analyticFunc(eventsPayload.ChangeGST[2]);
              setIsGstUser(false);
            }}
            style={{
              border: isGstUser ? '' : '0.13rem solid #FE805C',
              marginRight: '0.4rem',
              marginBottom: '1rem',
            }}
            variant="outlined"
          >
            NO
          </Button>
        </div>
        {isGstUser && (
          <CustomInput
            error={showGstError}
            value={gstNumber}
            onChange={e => {
              setShowGstError(false);
              setGstNumber(e.target.value);
            }}
            inputLabel="GST Number (optional)"
          />
        )}
        {showGstError && (
          <p className="errorLine">Please enter valid GST Number</p>
        )}
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </Paper>
    </Modal>
  );
};

const mapStateToProps = ({ loginReducer }) => {
  return {
    user: loginReducer.user,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    // addContact: (payload) => dispatch(addContact(payload)),
    updateGstStatus: payload => dispatch(updateGstStatus(payload)),
    updateProfile: payload => dispatch(updateProfile(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AskGstDetailsModal);
