/* eslint-disable */
import React, { useState, useEffect } from 'react';
import api from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import { connect, useDispatch } from 'react-redux';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from '@material-ui/core';
import { getDistributor } from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { getStateCity } from '../../../store/AdminActions/allStateCityAction';
import Loader from '../../Loader';

const AddDistributor = React.memo(
  ({ getDistributor, handleModal, stateCity, getStateCity }) => {
    const [name, setName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [country] = useState('India');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pinCode, setPincode] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      stateCity.length < 1 && getStateCity();
    }, []);

    const handleSubmit = e => {
      e.preventDefault();
      let payload = {
        country,
        state,
        city,
        pinCode,
        gstNumber,
        addressLine1,
        addressLine2,
        name,
        mobileNo,
      };
      setLoading(true);

      api
        .post('distributor/addDistributor', payload)
        .then(response => {
          setLoading(false);
          getDistributor();
          toasterMessage('success', response.data.message);
          handleModal(true);
        })
        .catch(error => {
          setLoading(false);
          toasterMessage('error', error.response.data.error);
        })
        .catch(() => {
          toasterMessage('error', 'something bad happened');
        });
    };

    // const validateGst = () => {
    // toastDismiss()
    //   api
    //     .post('user/gstValidator', { gstNumber })
    //     .then((response) => {
    //       const { address, tradeNam } = response.data.data
    //       const { pncd, stcd, dst } = address
    //       setName(tradeNam)
    //       let arr = stateCity.filter((item) => item.stateName.toUpperCase() == stcd.toUpperCase())
    //       if (arr.length > 0) {
    //         setState(arr[0].stateName)
    //         let arr1 = arr[0].cityList.filter((c) => c.City.toUpperCase() == dst.toUpperCase())
    //         if (arr1.length > 0) {
    //           setCity(arr1[0].City)
    //           setPincode(pncd)
    //         } else {
    //           toasterMessage('info', 'City is not in list')
    //         }
    //       } else {
    //         toasterMessage('info', 'State is not in list')
    //       }
    //     })
    //     .catch((error) => {
    //       toasterMessage('error', error.response.data.error)
    //     })
    //     .catch(() => {
    //       toasterMessage('error', 'something bad happened')
    //     })
    // }

    return (
      <div className="m-2">
        <h5 className="text-center">Add Distributor</h5>
        <FormControl>
          <TextField
            style={{ width: 350 }}
            label="Distributor Name*"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <br />
          <TextField
            style={{ width: 350 }}
            label="Address Line1*"
            value={addressLine1}
            onChange={e => setAddressLine1(e.target.value)}
          />
          <br />
          <TextField
            style={{ width: 350 }}
            label="Address Line2"
            value={addressLine2}
            onChange={e => setAddressLine2(e.target.value)}
          />
          <br />
          <TextField
            style={{ width: 350 }}
            label="GST No.*"
            value={gstNumber}
            // onBlur={validateGst}
            onChange={e => setGstNumber(e.target.value.toString().slice(0, 15))}
          />
          <br />
          <TextField
            style={{ width: 350 }}
            label="Mobile Number*"
            value={mobileNo}
            onChange={e =>
              setMobileNo(
                e.target.value
                  .replace(/[^0-9]/g, '')
                  .toString()
                  .slice(0, 10)
              )
            }
          />
          <br />
          <TextField
            style={{ width: 350 }}
            label="Pin Code*"
            type="number"
            value={pinCode}
            onChange={e =>
              setPincode(
                e.target.value
                  .replace(/[^0-9]/g, '')
                  .toString()
                  .slice(0, 6)
              )
            }
          />
          <br />
          <div>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Country*
              </InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={'India'}
                label="Country"
              >
                <MenuItem value={'India'}>{'India'}</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                State*
              </InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.toUpperCase()}
                onChange={e => setState(e.target.value)}
                label="State"
              >
                {stateCity ? (
                  stateCity.map(item => (
                    <MenuItem
                      key={item.stateId}
                      value={item.stateName.toUpperCase()}
                    >
                      {item.stateName.toUpperCase()}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={state}>{state}</MenuItem>
                )}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                City*
              </InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={city.toUpperCase()}
                onChange={e => setCity(e.target.value)}
                label="City"
              >
                {stateCity ? (
                  stateCity.map(item => {
                    if (item.stateName.toUpperCase() == state.toUpperCase()) {
                      return item.cityList.map(c => (
                        <MenuItem key={c.cityId} value={c.City.toUpperCase()}>
                          {c.City.toUpperCase()}
                        </MenuItem>
                      ));
                    }
                  })
                ) : (
                  <MenuItem value={city}>{city}</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <br />
          {!loading ? (
            <button
              className="rounded-pill w-100 text-white p-3 border-0 text-capitalize bg-themeorange"
              type="submit"
              onClick={handleSubmit}
            >
              submit
            </button>
          ) : (
            <Loader />
          )}
        </FormControl>
      </div>
    );
  }
);

const mapStateToProps = ({ getStateCityReducer }) => {
  return { stateCity: getStateCityReducer.stateCity };
};

export default connect(mapStateToProps, { getDistributor, getStateCity })(
  AddDistributor
);
