/* eslint-disable */
const initialState = {
  stateCity: [],
};

const getStateCityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STATECITY': {
      return {
        ...state,
        stateCity: action.payload,
      };
    }
    case 'RESET_STATECITIES': {
      return {
        ...state,
        stateCity: [],
      };
    }
    default:
      return state;
  }
};
export default getStateCityReducer;
