const initialState = {
  brandOffers: [],
  gstSlabs: [],
};

const BrandOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_BRAND_OFFERS': {
      return {
        ...state,
        brandOffers: action.payload,
      };
    }
    case 'ADD_BRAND_OFFER': {
      return {
        ...state,
        brandOffers: action.payload,
      };
    }
    case 'ADD_GST_SLAB': {
      return {
        ...state,
        gst: action.payload,
      };
    }
    case 'SHOW_ALL_GST_SLABS': {
      return {
        ...state,
        gstSlabs: action.payload,
      };
    }

    default:
      return state;
  }
};
export default BrandOfferReducer;
