/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
// import firebase from '../../@utils/firebase'
import {
  Button,
  CssBaseline,
  IconButton,
  Container,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { toasterMessage, toastDismiss } from '../../@utils/utils';
import {
  REACT_APP_FACEBOOK_ID,
  REACT_APP_GOOGLE_ID,
} from '../../@utils/config';
import { connect } from 'react-redux';
import PartialUser from '../Signup/partialUser';
import * as LoginAction from '../../store/AdminActions/loginAction';
import * as ForgotPassword from '../../store/AdminActions/forgotPasswordAction';
import { userLogout } from '../../store/AdminActions/logoutAction';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import FontAwesome from 'react-fontawesome';
import link from '../../images/logo.png';
import Signup from '../Signup';
import MyModal from '../../RetailerWebPortal/WebComponents/Modal';
import { history } from '../../routes';

const useStyles = makeStyles(theme => ({
  loginroot: {
    width: '100%',
  },
  paper: {
    padding: '3em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#df5a28',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  heading: {
    color: ' #df5a28',
    fontFamily: 'Roboto',
  },
  loginContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#df5a28',
      main: '#df5a28',
      dark: '#df5a28',
      contrastText: '#000',
    },
  },
});

const Alert = React.memo(({ handleModal, handleAlert }) => {
  return (
    <div className="m-4">
      <h3 className="text-left">mBill</h3>
      <h6>User not registered Please register first.</h6>
      <button
        className="btn float-right mb-2 bg-themeorange text-white"
        onClick={e => {
          handleAlert(e);
          handleModal();
        }}
      >
        Ok
      </button>
    </div>
  );
});

const Login = React.memo(props => {
  const classes = useStyles();
  const alertRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [partialUser, setPartialUser] = useState(false);
  const [register, setRegister] = useState(false);
  const [accept, setAccept] = useState(false);
  const [socialData, setSocialData] = useState('');

  useEffect(() => {
    toastDismiss();
    // history.push('/')
  }, []);

  // const getFCMToken = useCallback(() => {
  //   const messaging = firebase.messaging()
  //   return new Promise((resolve, reject) => {
  //     messaging
  //       .requestPermission()
  //       .then(() => {
  //         return messaging.getToken()
  //       })
  //       .then((token) => {
  //         resolve(token)
  //       })
  //       .catch((err) => {
  //         reject(err)
  //         console.log('catch', err)
  //       })
  //   })
  // }, [firebase])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // submitting the login form
  const submitLoginForm = useCallback(
    event => {
      event.preventDefault();
      const loginType = 'Custom';
      props.loginUser({ loginType, email, password }, onSuccess);
    },
    [email, password]
  );

  const checkEmail = () => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!email) {
      return setEmailError(e => {
        return 'Email or Username is required';
      });
    }
    if (email.includes('@' && '.') && !pattern.test(email)) {
      return setEmailError('Email is not valid');
    }
    setEmailError('');
  };

  const handleRegister = () => {}; // it's important don't remove this

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (props.isUserLoggedIn) {
      if (props.userType.toLowerCase() == 'retailer') {
        setPartialUser(true);
      } else {
        props.history.push('/dashboard');
      }
    }
  }, [props.isUserLoggedIn, props.history]);

  const onSuccess = useCallback(
    value => {
      switch (value) {
        case 'Not Register':
          alertRef.current.click();
          break;
        // case 'Register':
        //   let fcmToken = await getFCMToken()
        //   props.sendFcmToken({ fcmToken })
        //   break;
        default:
          break;
      }
    },
    [alertRef]
  );

  const googleLogin = useCallback(res => {
    const { email, googleId } = res.profileObj;
    let payload = {
      email: email,
      socialId: googleId,
      loginType: 'Google',
    };
    setSocialData(payload);
    props.loginUser(payload, onSuccess);
  }, []);

  const facebookLogin = useCallback(res => {
    let payload = {
      socialId: res.userID,
      loginType: 'Facebook',
    };
    setSocialData(payload);
    props.loginUser(payload, onSuccess);
  }, []);

  const handleBack = useCallback(() => {
    switch (props.userStage) {
      case 1:
        if (!accept) {
          props.userLogout();
          setPartialUser(false);
          setEmail('');
          setPassword('');
          setSocialData('');
          setRegister(false);
        } else {
          setAccept(false);
        }
        return null;
      case 2:
        props.userLogout();
        setPartialUser(false);
        setEmail('');
        setPassword('');
        setSocialData('');
        setRegister(false);
        return null;
      default:
        props.userLogout();
        setPartialUser(false);
        setRegister(false);
        return null;
    }
  }, [props.userStage]);

  const handleAlert = useCallback(e => {
    e.stopPropagation();
    setRegister(true);
  }, []);

  const resetForm = () => {
    setSocialData('');
  };

  return (
    <div>
      {!partialUser ? (
        <div className={classes.loginroot}>
          <ThemeProvider theme={theme}>
            <Container
              component="main"
              maxWidth="xs"
              className={classes.loginContainer}
            >
              <CssBaseline />
              <div>
                <Paper className="d-flex flex-column align-items-center p-3 shadow-lg">
                  <img src={link} alt="logo" width="200px" />
                  <div className="w-100">
                    <h4 className="text-left" style={{ color: '#61605E' }}>
                      Login
                    </h4>
                    <p className="text-secondary text-left">
                      Welcome back, Kindly enter your login details
                    </p>
                  </div>
                  <ValidatorForm
                    className={classes.form}
                    onSubmit={submitLoginForm}
                  >
                    <TextValidator
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email ID/Username"
                      name="email"
                      value={email}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      onChange={e => setEmail(e.target.value)}
                      onBlur={e => {
                        checkEmail();
                      }}
                      onFocus={e => setEmailError('')}
                    />
                    <small className="text-themeorange">{emailError}</small>
                    <TextValidator
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      validators={['required']}
                      errorMessages={['This field is required']}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="border-0 rounded-pill p-2 text-white bg-themeorange mt-2 mb-2 btn-lg"
                    >
                      Login
                    </Button>
                  </ValidatorForm>
                  {/* <p className="text-secondary font-weight-bold">
                    or Login with Social media
                  </p>
                  <div className="w-100 d-flex justify-content-between">
                    <FacebookLogin
                      appId={REACT_APP_FACEBOOK_ID}
                      fields="name,email,picture"
                      callback={facebookLogin}
                      onFailure={e => {
                        toasterMessage("error", "Error in Facebook Login");
                      }}
                      cssClass="rounded-pill text-white border-0 facebook-btn pr-md-4 pl-md-4 mb-1 btn-lg"
                      icon="fa-facebook"
                      textButton=" facebook"
                    />
                    <GoogleLogin
                      clientId={REACT_APP_GOOGLE_ID}
                      buttonText="GOOGLE"
                      onSuccess={googleLogin}
                      onFailure={e => {
                        toasterMessage("error", "Error in Google Login");
                      }}
                      render={renderProps => (
                        <button
                          className="rounded-pill text-white border-0 google-btn mb-1 pr-md-4 pl-md-4 btn-lg"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <FontAwesome name="google" />
                          &nbsp;Google
                        </button>
                      )}
                    />
                  </div> */}
                  <Link to="/forgotPassword" style={{ textDecoration: 'none' }}>
                    <h6 className="text-themeorange hover-pointer mt-2">
                      Forgot password?
                    </h6>
                  </Link>
                  {/* <div className="d-inline-flex">
                    Don't have an account?&nbsp;
                    <Link
                      to="#"
                      className="text-themeorange"
                      onClick={e => setRegister(true)}
                    >
                      Register
                    </Link>
                    {register && (
                      <Signup
                        handleRegister={handleRegister}
                        setRegister={setRegister}
                        socialData={socialData}
                        resetForm={resetForm}
                      />
                    )}
                  </div> */}
                  {/* <MyModal ref={alertRef}>
                    <Alert handleAlert={handleAlert} />
                  </MyModal> */}
                </Paper>
              </div>
            </Container>
          </ThemeProvider>
        </div>
      ) : (
        <PartialUser handleBack={handleBack} />
      )}
    </div>
  );
});

const mapStateToProps = ({ loginReducer }) => {
  return {
    isUserLoggedIn: loginReducer.isUserLoggedIn,
    userType:
      loginReducer.user &&
      loginReducer.user.userData[0] &&
      loginReducer.user.userData[0].userType,
    userStage:
      loginReducer.user &&
      loginReducer.user.userData[0] &&
      loginReducer.user.userData[0].isRegister,
  };
};
export default connect(mapStateToProps, {
  ...LoginAction,
  ...ForgotPassword,
  userLogout,
})(Login);
