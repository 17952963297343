import { toasterMessage } from '../../../@utils/utils';
import api from '../../../api/api';

export const getCatalogCategories = () => async dispatch => {
  return api.post('/rewardpoints/getCategories', { type: 'categoryListapi' });
};

export const getCatalogProducts = (paylaod, cb) => async dispatch => {
  api
    .post('/rewardpoints/getCategories', paylaod)
    .then(response => {
      dispatch({ type: 'CATALOGS', payload: response.data.data });
      cb && cb();
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
      cb && cb();
    })
    .catch(err => {
      toasterMessage('error', 'Something Bad Happened :(');
      cb && cb();
    });
};

export const getRewardData = () => async dispatch => {
  api
    .get('/rewardpoints/getRewardData')
    .then(response => {
      dispatch({ type: 'REWARD_DATA', payload: response.data.data });
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
    })
    .catch(err => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const redeemPoints = (payload, cb) => async dispatch => {
  api
    .post('/rewardpoints/postRewardPoints', payload)
    .then(response => {
      cb && cb(true);
      toasterMessage('success', response.data.message);
    })
    .catch(err => {
      cb && cb(false);
      toasterMessage('error', err.response.data.error);
    })
    .catch(err => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const getShareUrl = (payload, cb) => async dispatch => {
  api
    .post('/survey/getInvoiceUrl', payload)
    .then(response => {
      cb && cb(response.data.data);
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
    })
    .catch(err => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
