import React, { useState, useEffect } from 'react';
import { Button, List, Paper, LinearProgress } from '@material-ui/core';
import { getDistributor, setDistributorList } from '../../../billingAction';
import { connect } from 'react-redux';
import Contact from '../../ContactComponents/contact';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../../../../CommonComponents/customInput';
import AddDistributorModal from './addDistributorModal';
import analyticFunc from '../../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '1rem',
    position: 'absolute',
    zIndex: '100',
    width: '27%',
    maxHeight: '200%',
    overflow: 'auto',
    scrollbarWidth: 'thin',
  },
  selectedPaper: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.1fr',
    borderRadius: '1rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const VendorForm = props => {
  const classes = useStyles();
  const { setDistributorDetails, distributorDetails } = props;
  const [toggleCreateDistributorModal, setToggleDistributorModal] = useState(
    false
  );
  const [debouncingTime, setDebouncingTime] = useState(0);

  const [showDistributorList, setShowDistributorList] = useState(false);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.length >= 3 && props.distributorList.length)
      setShowDistributorList(props.distributorList.length);
    else {
      setShowDistributorList(false);
    }
  }, [props.distributorList]);

  const setLoading = bool => {
    setVendorLoading(bool);
  };

  useEffect(() => {
    if (searchText.length >= 3) {
      debouncingTime && clearTimeout(debouncingTime);
      let time = setTimeout(() => {
        let vendorPayload = {
          searchData: searchText,
        };
        props.getDistributor(vendorPayload, setLoading);
      }, 250);
      setDebouncingTime(time);
    } else {
      props.setDistributorList([]);
    }
  }, [searchText]);

  useEffect(() => {
    props.setDistributorList([]);
  }, []);

  return (
    <>
      {!distributorDetails.name && (
        <CustomInput
          inputLabel={'Select Distributor'}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          afterTextField={
            <Button
              style={{ whiteSpace: 'nowrap' }}
              variant="outlined"
              color="primary"
              className="mt-0"
              onClick={() => {
                if (props.from === 'inventory')
                  analyticFunc(eventsPayload.ManageInventory[6]);
                setToggleDistributorModal(!toggleCreateDistributorModal);
              }}
            >
              + Distributor
            </Button>
          }
        />
      )}
      {vendorLoading && <LinearProgress />}
      {!distributorDetails.name && showDistributorList && (
        <Paper className={classes.paper}>
          <List>
            {props.distributorList.map(distributor => (
              <Contact
                handleSelection={() => setDistributorDetails(distributor)}
                key={distributor.id}
                name={distributor.name}
              />
            ))}
          </List>
        </Paper>
      )}
      {distributorDetails.name && (
        <Paper className={classes.selectedPaper}>
          <Contact name={distributorDetails.name} />
          <CloseIcon
            onClick={() => {
              setDistributorDetails({});
              setSearchText('');
            }}
          />
        </Paper>
      )}
      <AddDistributorModal
        setDistributorDetails={setDistributorDetails}
        toggleModal={toggleCreateDistributorModal}
        setToggleModal={setToggleDistributorModal}
        from={props.from}
      />
    </>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => {
  return {
    distributorList: BillingReducer.distributorList,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    getDistributor: (payload, cb) => dispatch(getDistributor(payload, cb)),
    setDistributorList: payload => dispatch(setDistributorList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm);
