import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../../../../CommonComponents/customInput';
import { addDistributor } from '../../../billingAction';
import analyticFunc from '../../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem',
    borderRadius: '1.5rem',
  },
}));

const AddDistributorModal = props => {
  const classes = useStyles();
  const { toggleModal, setToggleModal, setDistributorDetails } = props;
  const [name, setName] = useState('');
  const [showNameError, setShowNameError] = useState(false);
  const [address, setAddress] = useState('');
  const [gst, setGst] = useState('');
  const [showGstError, setShowGstError] = useState(false);
  const [number, setNumber] = useState('');
  const [showNumberError, setShowNumberError] = useState(false);

  const allFieldsValid = () => {
    let validName = name && name !== '' ? true : false;
    setShowNameError(name !== '' && name ? false : true);

    let isValidGst = true;
    if (gst !== '') {
      let gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      if (gstRegex.test(gst)) {
        isValidGst = true;
        setShowGstError(false);
      } else {
        isValidGst = false;
        setShowGstError(true);
      }
    }

    let isValidNumber = true;
    if (number !== '') {
      isValidNumber = number.length === 10;
      setShowNumberError(!(number.length === 10));
    }

    let allFieldsValid = validName && isValidGst && isValidNumber;
    return allFieldsValid;
  };

  const handleSubmit = () => {
    if (props.from === 'inventory') {
      analyticFunc(eventsPayload.ManageInventory[7]);
    }
    if (allFieldsValid()) {
      let payload = {
        name: name,
      };
      var addKV = { addressLine1: address };
      var gstKV = { gstNumber: gst };
      var numberKV = { mobileNo: number };

      if (gst !== '') payload = { ...payload, ...gstKV };
      if (number !== '') payload = { ...payload, ...numberKV };
      if (address) payload = { ...payload, ...addKV };
      props.addDistributor(payload, getResponse);
    }
  };
  const getResponse = (res, bool) => {
    console.log(res);
    if (bool) setDistributorDetails(res);
    setToggleModal(false);
  };
  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '20rem',
        maxWidth: '30%',
        height: '60%',
      }}
      open={toggleModal}
    >
      <Paper className={classes.paper}>
        <CustomInput
          error={showNameError}
          value={name}
          inputLabel={'Distributor Name*'}
          onChange={e => setName(e.target.value)}
        />
        {showNameError && <p className="errorLine">Name must not be empty.</p>}
        <CustomInput
          inputLabel={'Address Line 1'}
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <CustomInput
          error={showGstError}
          inputLabel={'GST No'}
          value={gst}
          onChange={e => setGst(e.target.value)}
        />
        {showGstError && <p className="errorLine">Enter a valid GST Number</p>}
        <CustomInput
          error={showNumberError}
          onChange={e => {
            setShowNumberError(false);
            if (!isNaN(e.target.value)) setNumber(e.target.value);
          }}
          inputLabel={'Mobile No.'}
          value={number}
        />
        {showNumberError && (
          <p className="errorLine">Please enter valid number</p>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '1rem',
          }}
        >
          <Button
            onClick={() => setToggleModal(!toggleModal)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

// const mapStateToProps = ({ BillingReducer }) => {
//   return {};
// };

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    addDistributor: (payload, cb) => dispatch(addDistributor(payload, cb)),
  };
};

export default connect(null, mapDispatchToProps)(AddDistributorModal);
// export default AddDistributorModal;
