import api from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import { GET_NOTIFICATION, NOTIFICATION_COUNT, SET_LOADING } from './types';

export const getNotification = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('user/getNotification', payload)
    .then(response => {
      dispatch({ type: GET_NOTIFICATION, payload: response.data.data });
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(error => {
      dispatch({ type: GET_NOTIFICATION, payload: [] });
      // toasterMessage('error', error.response.data.error)
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const clearNotification = () => async dispatch => {
  console.log('CLEAR--------------------->>>======>>>??');
  dispatch({ type: GET_NOTIFICATION, payload: '' });
};

export const getNotificationCount = () => dispatch => {
  api
    .get('user/notificationCount')
    .then(response => {
      dispatch({ type: NOTIFICATION_COUNT, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      // toasterMessage('error', 'Something Bad Happend :(')
    });
};

export const readNotification = (payload, cb) => dispatch => {
  api
    .post('user/readNotification', payload)
    .then(response => {
      cb && cb();
      // dispatch({ type: NOTIFICATION_COUNT, payload: response.data.data })
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const feedBack = (payload, cb) => dispatch => {
  console.log('qwertyu feedback----->.', payload);
  api
    .post('user/feedBack', payload)
    .then(response => {
      // dispatch({ type: NOTIFICATION_COUNT, payload: response.data.data })
      toasterMessage('success', response.data.message);
      cb && cb();
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};
