/* eslint-disable */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Paper, Container, Button, List } from '@material-ui/core';
import SearchBar from '../../WebComponents/SearchBar';
import { connect } from 'react-redux';
import {
  searchInvoice,
  deleteInvoice,
  clearSearchInvoice,
} from '../../../store/RetailerWebStore/RetailerAction/searchInvoice';
import { Create, Delete } from '@material-ui/icons';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import Loader from '../../Loader';
import { useHistory } from 'react-router-dom';
import ViewInvoice from '../Invoice';
import MyModal from '../Modal';
import {
  viewInvoice,
  setBillingStatus,
  setGetUserId,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import DeleteDialog from './deleteInvoice';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import { makeStyles } from '@material-ui/core/styles';
import searchSvg from '../../../assets/svg/search.svg';
import InvoiceInfo from './invoiceInfo';
import LinearProgress from '@material-ui/core/LinearProgress';
import { editInvoiceDetails } from '../../Billing/billingAction';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'grid',
    gridTemplateRows: '0.1fr auto',
    padding: '1rem',
    height: '91vh',
  },
  btn: {
    marginRight: '0.5rem',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },
  list: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));

const EmptyContainer = ({ handleCreateBill }) => (
  <div style={{ justifySelf: 'center', alignSelf: 'center' }}>
    <img src={searchSvg} alt="Search Icon Image" />
    <h6>Haven’t created a bill yet?</h6>
    <p className="subtitle text-center">Create your first bill here</p>
    <Button
      onClick={handleCreateBill}
      color="primary"
      fullWidth
      variant="contained"
    >
      Create Bill
    </Button>
  </div>
);

const SearchInvoice = React.memo(
  ({
    searchInvoice,
    invoices,
    viewInvoice,
    clearSearchInvoice,
    setBillingStatus,
    setGetUserId,
    getNotificationCount,
    editInvoiceDetails,
  }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const history = useHistory();
    const myRef = useRef();
    const [page, setPage] = useState(1);
    const scrollref = useRef();

    const loadMore = useCallback(() => {
      if (invoices.length < page * 20) return;
      if (
        scrollref.current.scrollTop + scrollref.current.clientHeight >=
        scrollref.current.scrollHeight
      ) {
        let pg = page + 1;
        searchInvoice({ page: pg, keyword: searchText, numberOfItems: 20 });
        setPage(pg);
      }
    }, [loading, invoices]);

    const onSuccess = useCallback(bool => {
      setLoading(false);
    }, []);

    useEffect(() => {
      let empty = '';
      setSearchText(empty);
      let p = { keyword: empty, page: page, numberOfItems: 20 };
      searchInvoice(p, onSuccess);
      setLoading(true);
      getNotificationCount();
      toastDismiss();
      clearSearchInvoice();
    }, []);

    const getSearchKeyword = useCallback(value => {
      setPage(1);
      if (searchText != '') clearSearchInvoice();
      !start && setStart(true);
      setSearchText(value);
      let payload = {
        keyword: value,
        page: 1,
        numberOfItems: 20,
      };
      if (start) {
        searchInvoice(payload, onSuccess);
        setLoading(true);
      }
    });

    const editInvoice = (e, data) => {
      e.stopPropagation();
      const { item } = data;
      let billId = item.billId;
      let invoiceId = item.invoiceId ? item.invoiceId : undefined;
      setBillingStatus({ status: item.status });
      setGetUserId({ billId, invoiceId, editInvoice: true });
      history.push(`/Billing`);
      // if (invoiceId) {
      //   history.push(`/Billing?billId=${billId}?invoiceId=${invoiceId}`)
      // } else {
      //   history.push(`/Billing?billId=${billId}`)
      // }
    };

    const InvoiceDelete = (e, data) => {
      e.stopPropagation();
      e.preventDefault();
      const { item } = data;
      setDeleteId(item);
      myRef.current.click();
    };

    const handleCreateBill = () => {
      history.push('/billing');
    };

    const handleClose = useCallback(() => {
      setDeleteId('');
    });

    const DeleteInvoice = useCallback(async () => {
      let payload = {
        billId:
          deleteId.billId && deleteId.billId.includes('NG')
            ? deleteId.billId.split('_')[1]
            : deleteId.billId,
        invoiceId: deleteId.invoiceId ? deleteId.invoiceId : undefined,
      };
      await deleteInvoice(payload)
        .then(res => {
          toasterMessage('success', res.data.message);
          getSearchKeyword(searchText);
          setDeleteId('');
        })
        .catch(err => {
          toasterMessage('error', err.response.data.error);
        });
    });

    const handleViewInvoice = useCallback(data => {
      const { item } = data;
      let billId = item.billId;
      let invoiceId = item.invoiceId ? item.invoiceId : undefined;
      setBillingStatus({ status: item.status });
      setGetUserId({
        billId,
        invoiceId,
        shareInvoice: item.status == '12' || item.status == '2' ? false : true,
      });
      // history.push(`/invoice`);
      history.push(`/invoice?billId=${billId}&invoiceId=${invoiceId}`);
    }, []);

    const renderList = useCallback(() => {
      if (invoices) {
        return invoices.length > 0 ? (
          <List className={classes.list} ref={scrollref} onScroll={loadMore}>
            {invoices.map((item, index) => (
              <InvoiceInfo
                key={index}
                onClick={e => {
                  analyticFunc(eventsPayload.SearchInvoice[3]);
                  item.status == 1 || item.status == 12 || item.status == 2
                    ? handleViewInvoice({ item })
                    : null;
                }}
                invoice={item}
                history={history}
              />
            ))}
          </List>
        ) : (
          <EmptyContainer handleCreateBill={handleCreateBill} />
        );
      }
    }, [invoices]);

    return (
      <>
        <Container className={classes.cont} maxWidth="sm">
          <SearchBar getSearchKeyword={getSearchKeyword} />
          {!loading ? renderList() : <LinearProgress />}
        </Container>
        <MyModal ref={myRef} btnTitle={'Delete Product'} close={handleClose}>
          <DeleteDialog deleteItem={DeleteInvoice} />
        </MyModal>
      </>
    );
  }
);

const mapStateToProps = ({ SearchInvoice }) => {
  return { invoices: SearchInvoice.invoices };
};
export default connect(mapStateToProps, {
  searchInvoice,
  viewInvoice,
  clearSearchInvoice,
  setBillingStatus,
  setGetUserId,
  getNotificationCount,
  editInvoiceDetails,
})(SearchInvoice);
