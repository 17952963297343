import React, { useEffect, useState } from 'react';
import SelectedProductList from './components/ProductComponents/selectedProductList';
import BillToContactContainer from './components/billToContactContainer';
import {
  Container,
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductSearch from './components/ProductComponents/productSearch';
import BillingSummary from './BillingSummaryComponents/billingSummary';
import AskGstDetailsModal from '../../Common/askGstDetailsModal';
import {
  getSerialNumber,
  addProductToSelectedList,
  increaseOrDecrease,
  removeProductFromList,
  resetBillingState,
  clearSelectedProducts,
  getUnits,
} from './billingAction';
import { connect } from 'react-redux';
import BillNumberContainer from './components/billNumberContainer';
import { toasterMessage } from '../../@utils/utils';
import CancelModal from './cancelModal';
import { useHistory } from 'react-router-dom';
import analyticFunc from '../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'flex !important',
    flexDirection: 'column',
    marginTop: '3rem',
    height: '70vh',
  },
  dialogBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogButton: {
    padding: '0.5em 2em',
  },
  dialogText: {
    color: '#6E7191',
    ...theme.palette.typography,
  },
  btnText: {
    fontFamily: theme.palette.typography.fontFamily,
  },
}));

const BillingContainer = props => {
  const classes = useStyles();
  const history = useHistory();
  const [showProdSearchComp, setShowProdSearchComp] = useState(true);
  const [showBillingSummaryComp, setShowBillingSummaryComp] = useState(false);
  const [showGstModal, setShowGstModal] = useState(false); // This is for snackbar
  const [showModal, setShowModal] = useState(false); // this is for gst modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // let gst = props.user.userData[0].isGstNumber;
    let gst = props.isGstUser ? 1 : 0;
    if (!(gst === 1)) {
      setShowGstModal(true);
    } else {
      setShowGstModal(false);
    }
    if (props.isEditing) {
      if (!props.isEditing[0]) {
        props.getSerialNumber();
      }

      return () => {
        if (props.isEditing[0]) {
          props.resetBillingState();
        }
      };
    }
  }, [props.isEditing]);
  useEffect(() => {
    if (props.units.length === 0) getUnits();
  }, []);
  const handleClickEvent = () => {
    analyticFunc(eventsPayload.Billing[13]);
    // props.selectedProducts.length !== 0 &&
    // props.selectedContact &&
    // Object.keys(props.selectedContact).length !== 0

    if (
      (props.selectedProducts.length !== 0 && props.selectedContact) ||
      !showProdSearchComp
      // Object.keys(props.selectedContact).length !== 0
    ) {
      setShowProdSearchComp(!showProdSearchComp);
      setShowBillingSummaryComp(!showBillingSummaryComp);
    } else {
      toasterMessage('error', 'Select Contact or add products to list');
    }
  };

  const handleClose = () => {
    setShowBillingSummaryComp(false);
  };

  const confirmationDialogue = () => (
    <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
      <DialogContent>
        <DialogContentText>
          <Typography className={classes.dialogText}>
            Are you sure, all items will be removed from the list ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <div className={classes.dialogBtnContainer}>
        <Button
          className={classes.dialogButton}
          onClick={() => setShowConfirmation(false)}
        >
          <Typography className={classes.btnText}> Cancel </Typography>
        </Button>
        <Button
          className={classes.dialogButton}
          color="primary"
          onClick={() => {
            props.removeSelectedProducts();
            setShowConfirmation(false);
          }}
          autoFocus
        >
          <Typography className={classes.btnText}> Agree </Typography>
        </Button>
      </div>
    </Dialog>
  );

  return (
    <div className="flexedCenterDiv" style={{ height: '90vh' }}>
      <Container className={classes.cont} maxWidth="xs">
        <BillNumberContainer />
        <BillToContactContainer />
        <SelectedProductList
          from={'billing'}
          handleClickEvent={handleClickEvent}
          showProdSearchComp={showProdSearchComp}
          setShowConfirmation={setShowConfirmation}
        />
      </Container>
      {showProdSearchComp && <ProductSearch from={'billing'} />}
      {showBillingSummaryComp && (
        <BillingSummary
          isGstUser={props.user.userData[0].isGstNumber === 1 ? true : false}
          selectedProducts={props.selectedProducts}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showGstModal}
        autoHideDuration={6000}
        onClose={() => {
          setShowGstModal(false);
        }}
        message="Update GST"
        action={
          <Button
            color="primary"
            onClick={() => {
              analyticFunc(eventsPayload.ChangeGST[0]);
              setShowModal(true);
            }}
          >
            Update
          </Button>
        }
      />
      {confirmationDialogue()}
      <AskGstDetailsModal
        toggleModal={showModal}
        setToggleModal={setShowModal}
      />
      {/* <CancelModal toggleModal={showCancelModal} setToggleModal={setShowCancelModal} /> */}
    </div>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer, loginReducer }) => {
  return {
    askGstDetails: OtpLoginReducer.askGstDetails,
    isGstUser: OtpLoginReducer.isGstUser,
    gstNumber: OtpLoginReducer.gstNumber,
    selectedProducts: BillingReducer.selectedProducts,
    selectedContact: BillingReducer.selectedContact,
    isEditing: BillingReducer.isEditing,
    user: OtpLoginReducer.user,
    units: BillingReducer.units,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    getUnits: () => dispatch(getUnits()),
    getSerialNumber: () => dispatch(getSerialNumber()),
    addProductToSelectedList: product =>
      dispatch(addProductToSelectedList(product)),
    increaseOrDecrease: (product, by) =>
      dispatch(increaseOrDecrease(product, by)),
    removeProductFromList: product => dispatch(removeProductFromList(product)),
    resetBillingState: () => dispatch(resetBillingState()),
    removeSelectedProducts: () => dispatch(clearSelectedProducts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingContainer);
