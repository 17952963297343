import _ from 'lodash';
import moment from 'moment';
const initialState = {
  cses: [],
  cse: [],
  tickets: [],
  disposition: [],
  ticketsDetails: [],
  pastTickets: [],
  ticketHistory: [],
  manageCSE: [],
  cseConfig: [],
  filterTickets: {
    endDate: moment.parseZone(new Date()).format('YYYY-MM-DD'),
    startDate: moment
      .parseZone(new Date().setDate(new Date().getDate() - 31))
      .format('YYYY-MM-DD'),
  },
};

const endDate = moment.parseZone(new Date()).format('YYYY-MM-DD');
const startDate = moment
  .parseZone(new Date().setDate(new Date().getDate() - 31))
  .format('YYYY-MM-DD');

const CseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_TICKETS': {
      if (
        state.filterTickets.length === 0 ||
        Object.keys(state.filterTickets).length === 0
      ) {
        return {
          ...state,
          tickets: action.payload.filter(
            t =>
              t.filterDate >= startDate &&
              t.filterDate <= endDate &&
              t.currentStatus !== null &&
              t.currentStatus !== 5 &&
              t.currentStatus !== 6
          ),
        };
      } else {
        const filterKeys = Object.keys(state.filterTickets);
        return {
          ...state,
          tickets: action.payload.filter(ticket => {
            return filterKeys.every(key => {
              if (key === 'startDate' || key === 'endDate') {
                return (
                  ticket.filterDate >= state.filterTickets['startDate'] &&
                  ticket.filterDate <= state.filterTickets['endDate'] &&
                  ticket.currentStatus !== null &&
                  ticket.currentStatus !== 5 &&
                  ticket.currentStatus !== 6
                );
              }
              return (
                state.filterTickets[key] === ticket[key] &&
                ticket.currentStatus !== null &&
                ticket.currentStatus !== 5 &&
                ticket.currentStatus !== 6
              );
            });
          }),
        };
      }
    }
    case 'SHOW_PAST_TICKETS': {
      return {
        ...state,
        pastTickets: action.payload,
      };
    }
    case 'SHOW_DISPOSITION': {
      return {
        ...state,
        disposition: action.payload,
      };
    }
    case 'GET_MANAGE_CSE': {
      return {
        ...state,
        manageCSE: action.payload,
      };
    }
    case 'GET_CSE_CONFIGURATION': {
      return {
        ...state,
        cseConfig: action.payload,
      };
    }
    case 'SHOW_TICKET_HISTORY': {
      return {
        ...state,
        ticketHistory: action.payload,
      };
    }
    case 'SHOW_TICKETS_DETAILS': {
      return {
        ...state,
        ticketsDetails: action.payload,
      };
    }
    case 'SHOW_ALL_CSE': {
      return {
        ...state,
        cses: action.payload,
      };
    }
    case 'SHOW_CSE': {
      return {
        ...state,
        cse: action.payload,
      };
    }
    case 'FILTER_TICKETS':
      return {
        ...state,
        filterTickets: { ...state.filterTickets, ...action.payload },
      };
    case 'RESET_FILTER_TICKETS':
      return {
        ...state,
        filterTickets: _.omit(state.filterTickets, action.payload),
      };
    case 'HANDLE_CSE':
      return { ...state, cse: { ...state.cse, ...action.payload } };
    case 'RESET_CSE':
      return { ...state, cse: [] };
    case 'RESET_MANAGE_CSES':
      return { ...state, manageCSE: [] };
    case 'RESET_TICKET_DETAIL':
      return { ...state, ticketsDetails: [] };
    case 'RESET_PAST_TICKET':
      return { ...state, pastTickets: [] };
    case 'RESET_TICKET_HISTORY':
      return { ...state, ticketHistory: [] };
    default:
      return state;
  }
};
export default CseReducer;
