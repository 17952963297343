import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { ListItem, Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { updateGstStatus } from '../Billing/billingAction';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 800,
    marginTop: '1em',
    width: '100%',
  },
  grayBack: {
    color: 'gray',
    backgroundColor: '#f5f5f5',
    borderRadius: '12px',
    fontSize: '6px',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CustomButton = React.memo(
  ({ title, handleChange, styles, isGstUser }) => {
    const classes = useStyles();
    return (
      <Button
        onClick={handleChange}
        className={classes.grayBack}
        style={{ ...styles }}
      >
        {title}
      </Button>
    );
  }
);

const GstUser = ({ GstUser, updateGstStatus, handleNavOpen }) => {
  const classes = useStyles();
  const [isGstUser, setIsGstUser] = useState(0);
  const Buttons = [
    {
      title: 'No',
      value: 0,
      styles: {
        marginRight: '1em',
        border: !isGstUser ? 'solid orange 2px' : 'none',
      },
    },
    {
      title: 'Yes',
      value: 1,
      styles: {
        border: isGstUser ? 'solid orange 2px' : 'none',
      },
    },
  ];

  useEffect(() => {
    if (GstUser) setIsGstUser(GstUser);
  }, []);

  const handleChange = useCallback(value => {
    updateGstStatus({
      gst: value,
    });
    setIsGstUser(value);
    handleNavOpen();
  }, []);

  return (
    <ListItem className={classes.col}>
      <Typography className={classes.heading}>Make GST Bills?</Typography>
      <Box width={'100%'} style={{ marginTop: '1em' }}>
        {Buttons.map((obj, index) => (
          <CustomButton
            key={obj.title + '-' + index}
            isGstUser={isGstUser}
            handleChange={() => handleChange(obj.value)}
            title={obj.title}
            styles={obj.styles}
          />
        ))}
      </Box>
    </ListItem>
  );
};

const mapStateToProps = ({ OtpLoginReducer }) => {
  return {
    GstUser: OtpLoginReducer.isGstUser,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    updateGstStatus: payload => dispatch(updateGstStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GstUser);
