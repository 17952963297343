/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Stepper from '../Stepper';
import CustomerDetails from './customerDetail';
import ProductDetail from './productDetail';
import ModeOfPayment from './modeOfPayment';
import PersonIcon from '@material-ui/icons/Person';
import PaymentIcon from '@material-ui/icons/Payment';
import { Apple, PhonelinkSetup, PhoneIphone } from '@material-ui/icons';
import {
  setActivePage,
  setCustomerDetails,
  setProductDetails,
  setModeOfPayment,
  setGetUserId,
  cancelInvoice,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import { getProductDetails } from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
// import Search from '../SearchBar'
import { Paper } from '@material-ui/core';
import { toastDismiss } from '../../../@utils/utils';
import { useLocation } from 'react-router-dom';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const Billing = React.memo(
  ({
    activePage,
    cancelInvoice,
    getProductId,
    setGetUserId,
    setActivePage,
    setCustomerDetails,
    setProductDetails,
    setModeOfPayment,
    getProductDetails,
    billingStatus,
    getNotificationCount,
  }) => {
    const [pg1data, setPg1data] = useState('');
    const [billStatus, setBillStatus] = useState('');
    // const [activePage, setActivePage] = useState("")

    // function useQuery(){
    //   return new URLSearchParams(useLocation().search);
    // }

    // let query = useQuery()
    // var pageNumber = query.get("activePage")

    useEffect(() => {
      getNotificationCount();
    }, []);

    const InvoiceCancel = () => {
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
      };
      if (billingStatus.status === 0) {
        cancelInvoice(payload);
        setGetUserId('');
      }
    };

    const ActivePage = () => {
      setActivePage(0);
    };

    const getData = useCallback((data, step) => {
      setPg1data(data);
    });

    return (
      <div>
        <Stepper
          getData={getData}
          stepsTitle={[
            'Customer Details',
            'Product Details',
            'Mode of Payment',
          ]}
          Icons={[PersonIcon, PhonelinkSetup, PaymentIcon]}
          activePage={activePage && parseInt(activePage)}
          ActivePage={ActivePage}
        >
          <>
            <CustomerDetails isQuickCheckOut={pg1data} />
            <ProductDetail />
            <ModeOfPayment isbilling={true} pg1Data={pg1data} />
          </>
        </Stepper>
      </div>
    );
  }
);

const mapStateToProps = ({ Billing }) => {
  return {
    activePage: Billing.activePage,
    getProductId: Billing.getProductId,
    billingStatus: Billing.billingStatus,
  };
};

export default connect(mapStateToProps, {
  setActivePage,
  setCustomerDetails,
  setProductDetails,
  setModeOfPayment,
  getProductDetails,
  setGetUserId,
  cancelInvoice,
  getNotificationCount,
})(Billing);
