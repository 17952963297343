import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Link, makeStyles, Box, Typography } from '@material-ui/core';
import happyBirthday from '../../../../assets/svg/happy_birthday.svg';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '3rem',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    margin: 'auto',
    display: 'block',
  },
  greeting: {
    textAlign: 'center',
    fontWeight: '800',
    fontSize: '2.5em',
    fontFamily: theme.palette.typography.fontFamily,
    color: '#3F3D56',
    paddingBottom: '0em',
    marginBottom: '0em',
    marginTop: '0.5em',
  },
  text: {
    textAlign: 'center',
    marginTop: '0.3em',
    paddingBottom: '0em',
    marginBottom: '0em',
    fontSize: '1em',
    fontFamily: theme.palette.typography.fontFamily,
  },
  redeemButtonText: {
    fontFamily: theme.palette.typography.fontFamily,
    fontSize: '0.8em',
  },
  button: {
    fontFamily: theme.palette.typography.fontFamily,
    textAlign: 'center',
    paddingTop: '1em',
    marginTop: '1em',
    width: '100%',
  },
  invoiceButtonText: {
    fontFamily: theme.palette.typography.fontFamily,
    fontSize: '1em',
    fontWeight: '600',
    margin: '0',
  },
  buttonFlexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const RewardScreen = props => {
  const { changeInPoints, showInvoice } = props;
  const history = useHistory();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={happyBirthday} alt="Reward" className={classes.image} />

      <p className={classes.greeting}>Congrats!</p>

      <p className={classes.text}>
        You have earned {changeInPoints} points for creating this invoices.
        <Button
          color="primary"
          variant="text"
          size="small"
          onClick={() => {
            analyticFunc(eventsPayload.Billing[17]);
            history.push('/rewardPoints');
          }}
        >
          <span className={classes.redeemButtonText}>Redeem Now</span>
        </Button>
      </p>
      <div className={classes.buttonFlexBox}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          style={{ marginRight: '1em' }}
          onClick={() => {
            analyticFunc(eventsPayload.Billing[16]);
            showInvoice();
          }}
        >
          <span className={classes.invoiceButtonText}>Show Invoice</span>
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ marginLeft: '1em' }}
          onClick={() => {
            history.go();
          }}
        >
          <span className={classes.invoiceButtonText}>Create New Invoice</span>
        </Button>
      </div>
    </div>
  );
};
export default RewardScreen;
