/* eslint-disable */
import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';
import { history } from '../../routes';

export const loginUser = (payload, cb) => async dispatch => {
  api
    .post('user/login', payload)
    .then(response => {
      const { data } = response.data;
      localStorage.setItem('token', data.userToken);
      dispatch({
        type: 'USER_LOGGED_IN',
        payload: data.userData.length
          ? data
          : { ...data, userData: [data.userData] },
      });
      // cb && cb('Register')
      // if (response.status === 200 && data.userData.length) {
      //   if (data.userData[0].isRegister === 3) history.push('/dashboard')
      // }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      if (error.response.status === 300) {
        cb && cb('Not Register');
      }
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

// export const sendFcmToken=(payload)=>()=> {
//   api.post('user/sendFcmToken', payload)
//   .then((response) => {
//   })
//   .catch((error) => {
//     toasterMessage('error', error.response.data.error)
//   })
//   .catch(() => {
//     toasterMessage('error', 'Something bad happened')
//   })
// }

export function resetUser() {
  return {
    type: 'RESET_USER',
  };
}
