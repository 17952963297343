/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Paper, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OutlinedCard from './cards';
import { toastDismiss } from '../../../@utils/utils';
import CrediDetails from './creditDetails';
import Pay from './pay';
import Loader from '../../Loader';
import SearchBar from '../../WebComponents/SearchBar';
import {
  searchCredits,
  clearSearchCredits,
  setCreditId,
} from '../../../store/RetailerWebStore/RetailerAction/creditBooking';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import eventsPayload from '../../../GoogleAnalytics/events_payload';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';

const CreditBook = React.memo(
  ({
    handleModal,
    setCreditId,
    searchCredits,
    credits,
    clearSearchCredits,
    getNotificationCount,
  }) => {
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const history = useHistory();
    const myRef = useRef();
    const [pageNumber, setPageNumber] = useState(1);
    const scrollref = useRef();

    const loadMore = useCallback(() => {
      // if (credits.length < pageNumber*20) return
      if (
        scrollref.current.scrollTop + scrollref.current.clientHeight >=
        scrollref.current.scrollHeight
      ) {
        let pg = pageNumber + 1;
        searchCredits({
          page: pg,
          keyword: searchText ? searchText : undefined,
          numberOfItems: 20,
        });
        setPageNumber(pg);
      }
    }, [loading, credits]);

    useEffect(() => {
      getNotificationCount();
      clearSearchCredits();
      toastDismiss();
      searchCredits({
        page: 1,
        keyword: searchText ? searchText : undefined,
        numberOfItems: 20,
      });
    }, []);

    console.log('credits======', credits);

    const onSuccess = useCallback(bool => {
      setLoading(false);
    }, []);

    const getSearchKeyword = useCallback(value => {
      console.log('insede=====');
      setPageNumber(1);

      !start && setStart(true);
      setSearchText(value);
      let payload = {
        keyword: value ? value : undefined,
        page: 1,
        numberOfItems: 20,
      };
      if (start) {
        clearSearchCredits();
        searchCredits(payload, onSuccess);
        setLoading(true);
      }
    });

    const detailsPage = data => {
      const { item } = data;
      setCreditId(item.creditId);
      history.push(`/creditBookingDetails?id=${item.creditId}`, true);
      // history.push(`/creditBookingDetails`)
    };

    return (
      <div>
        <Paper className="p-2 m-2 ">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="border-bottom pb-2 ">Credit Book</h5>
            <div
              onClick={() => {
                analyticFunc(eventsPayload.CreditBook[1]);
              }}
            >
              <SearchBar getSearchKeyword={getSearchKeyword} />
            </div>
          </div>
          <br />
          <div
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
            ref={scrollref}
            onScroll={loadMore}
          >
            <OutlinedCard detailsPage={detailsPage} data={credits} />
          </div>
        </Paper>
      </div>
    );
  }
);

const mapStateToProps = ({ SearchCredits }) => {
  return {
    credits: SearchCredits.credits,
  };
};

export default connect(mapStateToProps, {
  searchCredits,
  clearSearchCredits,
  setCreditId,
  getNotificationCount,
})(CreditBook);
