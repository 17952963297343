/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { Paper, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 12,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomStepper = React.memo(
  ({
    stepsTitle,
    clearSearch,
    children,
    activePage,
    Icons,
    getData,
    ActivePage,
  }) => {
    const classes = useStyles();
    const myRef = useRef();
    const [activeStep, setActiveStep] = useState(0);
    const [back, setBack] = useState(false);
    const child = children.props.children;
    const Page1 = child[0].type;
    const Page2 = child[1].type;
    const Page3 = child[2].type;
    const Page4 = undefined;
    const SearchBar = child[3] && child[3].type;
    function ColorlibStepIcon(props) {
      const classes = useColorlibStepIconStyles();
      const { active, completed } = props;

      const Icon1 = Icons[0];
      const Icon2 = Icons[1];
      const Icon3 = Icons[2];

      const icons = {
        1: <Icon1 />,
        2: <Icon2 />,
        3: <Icon3 />,
      };

      return (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
          })}
        >
          {icons[String(props.icon)]}
        </div>
      );
    }

    ColorlibStepIcon.propTypes = {
      active: PropTypes.bool,
      completed: PropTypes.bool,
      icon: PropTypes.node,
    };

    useEffect(() => {
      activePage && setActiveStep(activePage);
    }, [activePage]);

    const getSteps = useCallback(() => {
      return stepsTitle ? stepsTitle : ['Page1', 'Page2', 'Page3'];
    }, [stepsTitle]);

    const steps = getSteps();

    const handleNext = useCallback(
      value => {
        getData && getData(value, activeStep);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      },
      [activeStep]
    );

    const handleBack = useCallback(
      (value, cb) => {
        if (activeStep !== 0) {
          if (activeStep == 1) {
            ActivePage ? ActivePage() : setBack(!back);
          }
          if (activeStep == 2) {
            clearSearch && clearSearch();
            setBack(!back);
          }
          setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
        cb && cb();
      },
      [activeStep]
    );

    const getStepContent = useCallback(
      activeStep => {
        switch (activeStep) {
          case 0:
            return (
              Page1 && <Page1 handleNext={handleNext} {...child[0].props} />
            );
          case 1:
            return (
              Page2 && <Page2 handleNext={handleNext} {...child[1].props} />
            );
          case 2:
            return (
              Page3 && <Page3 handleNext={handleNext} {...child[2].props} />
            );
          default:
            return 'Unknown step';
        }
      },
      [activeStep, { ...child }]
    );

    return (
      <div className={classes.root}>
        <div style={{ minHeight: '80vh' }} className="col-sm-12">
          {activeStep === 3 ? (
            <Page4 {...child[2].props} />
          ) : (
            <Paper>
              <div className="d-flex p-2 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleBack}
                    edge="start"
                    style={{ display: activeStep === 0 ? 'none' : 'flex' }}
                  >
                    <ChevronLeft fontSize="large" />
                  </IconButton>
                  {getSteps().filter((heading, i) => i === activeStep)}
                </div>
                {SearchBar && <SearchBar {...child[3].props} />}
              </div>
              <Stepper
                className="p-1"
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="p-2">{getStepContent(activeStep)}</div>
            </Paper>
          )}
        </div>
      </div>
    );
  }
);

export default CustomStepper;
