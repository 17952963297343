/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
} from '@material-ui/icons';

export const NetworkDetector = React.memo(({ user }) => {
  const [isOnline, setIsOnline] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setIsOnline(window.navigator.onLine);
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let res = window.navigator.onLine;
      setCount(c => (c + 1) % 10);
      res !== isOnline && setIsOnline(res);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [count, isOnline]);

  return (
    <>
      {isOnline ? (
        <SignalCellular4Bar style={{ color: 'green' }} />
      ) : user.userType === 'Retailer' ? (
        <SignalCellularConnectedNoInternet0Bar style={{ color: 'red' }} />
      ) : (
        <SignalCellularConnectedNoInternet0Bar />
      )}
    </>
  );
});

const mapStateToProps = state => {
  return {
    user: state.loginReducer.user.userData[0],
  };
};

export default connect(mapStateToProps, {})(NetworkDetector);
