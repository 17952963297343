const initialState = {
  modelData: [],
  surveyData: [],
  surveyDataFetched: false,
  searchData: [[], [], [], []],
  loading: [false, false, false, false],
  open: [false, false, false, false],
  loadingVouchers: false,
  voucherData: [],
};

const SurveyReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'DATA_FETCHED': {
      return {
        ...state,
        modelData: payload,
      };
    }
    case 'SURVEY_DATA_FETCHED': {
      return {
        ...state,
        surveyData: payload,
        surveyDataFetched: true,
      };
    }
    case 'SEARCH_DATA': {
      let tdata = [...state.searchData];
      if (payload.page > 1) {
        tdata[payload.from] = [...tdata[payload.from], ...payload.data];
      } else {
        tdata[payload.from] = payload.data;
      }

      let topen = [...state.open];
      topen[payload.from] = true;

      return {
        ...state,
        searchData: tdata,
        open: topen,
      };
    }
    case 'RESET_SEARCH_DATA': {
      return {
        ...state,
        searchData: initialState.searchData,
        loading: initialState.loading,
        open: initialState.open,
      };
    }
    case 'RESET_SURVEY_DATA': {
      return {
        ...state,
        surveyData: initialState.surveyData,
        surveyDataFetched: initialState.surveyDataFetched,
      };
    }
    case 'LOADING': {
      let tload = [...state.loading];
      tload[payload.from] = payload.status;
      return {
        ...state,
        loading: tload,
      };
    }
    case 'LOADING_V': {
      return {
        ...state,
        loadingVouchers: payload,
      };
    }
    case 'VOUCHERS': {
      return {
        ...state,
        voucherData: payload,
      };
    }
    default:
      return state;
  }
};

export default SurveyReducer;
