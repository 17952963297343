/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import {
  GET_EXPENSES,
  GET_EXPENSE_CATEGORY,
  CLEAR_EXPENSE_CATEGORY,
} from './types';

export const getExpenseCategory = (payload, cb) => async dispatch => {
  api
    .post('expenses/getCategoryExpenseList', payload)
    .then(response => {
      cb && cb(true);
      const { expenseCategory, totalExpenses } = response.data.data;
      dispatch({
        type: GET_EXPENSE_CATEGORY,
        payload: { expenseCategory, totalExpenses },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_EXPENSE_CATEGORY,
        payload: { expenseCategory: [], totalExpenses: '' },
      });
      cb && cb(false);
      // toasterMessage('error', error.response.data.error)
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const clearExpenseCategory = () => async dispatch => {
  dispatch({ type: CLEAR_EXPENSE_CATEGORY, payload: [] });
};

export const getExpensesList = (payload, cb) => async dispatch => {
  api
    .post('expenses/getExpenseList', payload)
    .then(response => {
      cb && cb(true);
      dispatch({ type: GET_EXPENSES, payload: response.data.data });
    })
    .catch(error => {
      cb && cb();

      dispatch({ type: GET_EXPENSES, payload: [] });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const addExpenseCategory = (payload, cb) => async dispatch => {
  api
    .post('expenses/addExpenseCategory', payload)
    .then(response => {
      cb && cb(true);

      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      cb && cb();

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const editExpense = (payload, cb) => async dispatch => {
  api
    .post('expenses/editExpense', payload)
    .then(response => {
      cb && cb(true);
    })
    .catch(error => {
      cb && cb();

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const addExpense = (payload, cb) => async dispatch => {
  api
    .post('expenses/addExpense', payload)
    .then(response => {
      cb && cb(true);
    })
    .catch(error => {
      cb && cb();

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const deleteExpense = (payload, cb) => async dispatch => {
  api
    .post('expenses/deleteExpense', payload)
    .then(response => {
      cb && cb(true);
    })
    .catch(error => {
      cb && cb(false);

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);

      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const sendExpenseReport = (payload, cb) => async dispatch => {
  api
    .post('expenses/sendExpenseReport', payload)
    .then(response => {
      cb && cb(true);
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      cb && cb();

      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
