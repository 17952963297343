/* eslint-disable */
import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';

export const getStateCity = payload => async dispatch => {
  await api
    .get('static/getState')
    .then(response => {
      const { data } = response.data;
      if (response.status === 200) {
        dispatch({ type: 'STATECITY', payload: data });
      }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happenned');
    });
};

export function resetStateCities() {
  return {
    type: 'RESET_STATECITIES',
  };
}
