import { INVENTORY_OVERVIEW } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  inventoryDetails: [],
};

const InventoryOverview = (state = initialState, { type, payload }) => {
  switch (type) {
    case INVENTORY_OVERVIEW: {
      return {
        ...state,
        inventoryDetails: payload,
      };
    }
    default:
      return state;
  }
};
export default InventoryOverview;
