import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled from '@emotion/styled';
import NotFound from '../Common/Error/NotFound';
import ProtectedRoute from './RetailerWebRoutes/protectedRoute';
import Reports from '../RetailerWebPortal/WebComponents-v3/Reports/reports';
import Home from '../RetailerWebPortal/WebComponents/HomePage';
import ProfilePage from '../RetailerWebPortal/WebComponents/ProfilePage';
import ManageCategory from '../RetailerWebPortal/WebComponents-v3/ManageCategory';
import ChangePassword from '../Common/ChangePassword/ChangePassword';
import ManageInventory from '../RetailerWebPortal/WebComponents/ManageInventory';
import Billing from '../RetailerWebPortal/WebComponents/Billing';
import SalesAnalysis from '../RetailerWebPortal/WebComponents/Ledger/salesDetails';
import Expense from '../RetailerWebPortal/WebComponents/Expense';
import ProfitLoss from '../RetailerWebPortal/WebComponents/Profit&Loss';
import Invoice from '../RetailerWebPortal/WebComponents/Invoice';
import SMS from '../RetailerWebPortal/WebComponents/SMS';
import CreditBook from '../RetailerWebPortal/WebComponents/CreditBook';
import BookingDetails from '../RetailerWebPortal/WebComponents/CreditBook/creditDetails';
import Help from '../RetailerWebPortal/WebComponents/Help';
import FAQ from '../RetailerWebPortal/WebComponents/FAQ';
import RewardPoints from '../RetailerWebPortal/WebComponents/RewardPoints';
import InventoryOverview from '../RetailerWebPortal/WebComponents/InventoryOverview';
import SearchInvoice from '../RetailerWebPortal/WebComponents/SearchInvoice';
import SalesReport from '../RetailerWebPortal/WebComponents/SalesReport';
// import Gallery from '../RetailerWebPortal/WebComponents/gallery'
import ErrorBoundary from '../Common/ErrorBoundary';
import AddSubUser from '../RetailerWebPortal/WebComponents/AddSubUser';
// import SubUserList from '../RetailerWebPortal/WebComponents/AddSubUser/subUserList';
import VideoTutorial from '../RetailerWebPortal/WebComponents/VideoTutorial';
import EarnReward from '../RetailerWebPortal/WebComponents/EarnReward';
import DownloadInvoice from '../RetailerWebPortal/WebComponents/EarnReward/downloadInvoice';
import ForgotPassword from '../Common/ForgotPassword';
import OtpLogin from '../Common/Login/OtpLogin';
import OtpVerify from '../Common/Login/OtpVerify';
import Onboarding from '../Common/Onboarding/Onboarding';
import CategorySelection from '../Common/Onboarding/CategorySelection';
import BillingContainer from '../RetailerWebPortal/Billing/BillingContainer';
import ManageInventoryContainer from '../RetailerWebPortal/WebComponents-v3/ManageInventory/manageInventoryContainer';
import AdminLogin from '../Common/Login/Login';
import RewardsContainer from '../RetailerWebPortal/WebComponents-v3/Rewards/rewardsContainer';
import EarnRewardContainer from '../RetailerWebPortal/WebComponents-v3/EarnReward/earnRewardContainer';
import rewardsEarned from '../RetailerWebPortal/WebComponents-v3/EarnReward/rewardsEarned';
import DataDeletion from '../RetailerWebPortal/WebComponents-v3/DataDeletion/dataDeletion';
import RewardsEarnedStatic from '../RetailerWebPortal/WebComponents-v3/EarnReward/staticPage/rewardsEarnedStatic';
import ENABLE_FEATURE from '../features';

const Container = styled.div`
  /* text-align: center; */
`;

export const history = createBrowserHistory();

const Routes = React.memo(() => {
  return (
    <>
      <Router history={history}>
        <ErrorBoundary>
          <Container>
            <Switch>
              <Route path="/" exact component={OtpLogin} />
              <Route path="/admin-login" exact component={AdminLogin} />
              <Route path="/verifyOtp" exact component={OtpVerify} />
              <Route
                path="/downloadPdf/:invoiceToken"
                exact
                component={DownloadInvoice}
              />
              <Route path="/onboarding" exact component={Onboarding} />
              <Route
                path="/categorySelection"
                exact
                component={CategorySelection}
              />
              <Route
                path="/billing"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={BillingContainer}
                    name="billing"
                    {...props}
                  />
                )}
              />
              <Route
                path="/inventoryManage"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ManageInventoryContainer}
                    name="manageInventory"
                    {...props}
                  />
                )}
              />
              {/* <Route path="/billing" exact component={BillingContainer} /> */}
              <Route path="/forgotPassword" exact component={ForgotPassword} />
              <Route
                path="/dashboard"
                render={props => (
                  <ProtectedRoute Comp={Home} name="dashboard" {...props} />
                )}
              />
              <Route
                path="/myProfile"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ProfilePage}
                    name="myProfile"
                    {...props}
                  />
                )}
              />
              <Route
                path="/changePassword"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ChangePassword}
                    name="changePassword"
                    {...props}
                  />
                )}
              />
              <Route
                path="/manageCategory"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ManageCategory}
                    name="manageCategory"
                    {...props}
                  />
                )}
              />
              <Route
                path="/addSubUser"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={AddSubUser}
                    name="addSubUser"
                    {...props}
                  />
                )}
              />
              {/* <Route
                path="/subUserList"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={SubUserList}
                    name="addSubUser"
                    {...props}
                  />
                )}
              /> */}
              <Route
                path="/creditBook"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={CreditBook}
                    name="creditBook"
                    {...props}
                  />
                )}
              />
              <Route
                path="/creditBookingDetails"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={BookingDetails}
                    name="creditBook"
                    {...props}
                  />
                )}
              />
              <Route
                path="/rewardPoints"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={RewardsContainer}
                    name="rewardPoints"
                    {...props}
                  />
                )}
              />
              <Route
                path="/inventoryOverview"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={InventoryOverview}
                    name="inventoryOverview"
                    {...props}
                  />
                )}
              />
              <Route
                path="/help"
                exact
                render={props => (
                  <ProtectedRoute Comp={Help} name="help" {...props} />
                )}
              />
              <Route
                path="/faq"
                exact
                render={props => (
                  <ProtectedRoute Comp={FAQ} name="faq" {...props} />
                )}
              />
              <Route
                path="/manage Inventory"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ManageInventory}
                    name="manageInventory"
                    {...props}
                  />
                )}
              />
              <Route
                path="/Billing"
                exact
                render={props => (
                  <ProtectedRoute Comp={Billing} name="billing" {...props} />
                )}
              />
              <Route
                path="/Sales Analysis"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={SalesAnalysis}
                    name="ledger"
                    {...props}
                  />
                )}
              />
              <Route
                path="/Expenses"
                exact
                render={props => (
                  <ProtectedRoute Comp={Expense} name="expenses" {...props} />
                )}
              />
              <Route
                path="/Profit & Loss Details"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={ProfitLoss}
                    name="profitNloss"
                    {...props}
                  />
                )}
              />
              <Route
                path="/Sales Report"
                exact
                render={props => (
                  <ProtectedRoute Comp={SalesReport} name="sales" {...props} />
                )}
              />
              <Route
                path="/Search Invoice"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={SearchInvoice}
                    name="searchInvoice"
                    {...props}
                  />
                )}
              />
              <Route
                path="/Invoice"
                exact
                render={props => (
                  <ProtectedRoute
                    Comp={Invoice}
                    name="searchInvoice"
                    {...props}
                  />
                )}
              />
              {ENABLE_FEATURE.SMS && (
                <Route
                  path="/Send SMS"
                  exact
                  render={props => (
                    <ProtectedRoute Comp={SMS} name="sendSms" {...props} />
                  )}
                />
              )}
              <Route
                path="/reports"
                exact
                render={props => (
                  <ProtectedRoute Comp={Reports} name="reports" {...props} />
                )}
              />
              <Route
                path="/earnRewards"
                exact
                component={EarnRewardContainer}
              />
              <Route
                path="/earnRewards/:token"
                exact
                component={EarnRewardContainer}
              />
              <Route path="/getRewards" exact component={RewardsEarnedStatic} />
              <Route
                path="/getRewards/:token"
                exact
                component={rewardsEarned}
              />
              <Route path="/data-deletion" exact component={DataDeletion} />
              <Route path="/*" component={NotFound} />
            </Switch>
          </Container>
        </ErrorBoundary>
      </Router>
    </>
  );
});

export default Routes;
