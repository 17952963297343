/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import { SET_LOADING } from './types';

export const signup = (payload, cb) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('user/register', payload)
    .then(response => {
      const { data } = response.data;
      localStorage.setItem('token', data.userToken);
      dispatch({ type: 'USER_LOGGED_IN', payload: data });
      toasterMessage('success', response.data.message);
      cb && cb(true);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      cb && cb(false);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
      cb && cb(false);
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const validateUser = (payload, cb) => async dispatch => {
  await api
    .post('user/validateUserName', payload)
    .then(response => {
      cb && cb(true);
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const getOnlineStore = cb => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .get('static/onlineStore')
    .then(response => {
      cb && cb(true);
      dispatch({ type: 'GET_ONLINE_STORES', payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happend :(');
    });
  dispatch({ type: SET_LOADING, payload: false });
};
