export const INVENTORY_ROUTES = {
  dashboard: 'dashboard',
  categories: 'categories',
  brands: 'brands',
  models: 'models',
};

export const INVENTORY_TYPE = {
  categories: 'categories',
  subcategories: 'subcategories',
  brands: 'brands',
  models: 'models',
};

export const DASHBOARD_ROUTES = {
  dashboard: 'dashboard',
  changePassword: 'changePassword',
  categories: 'categories',
  vendors: 'vendors',
  messageUsers: 'messageUsers',
  customerSupport: 'customerSupport',
  retailerManager: 'retailerManager',
  brandManagement: 'brandManagement',
  addAdmin: 'addAdmin',
  leads: 'leads',
  faq: 'faq',
  googleCampaign: 'googleCampaign',
  rewards: 'rewards',
  rewardsApproval: 'rewardsApproval',
  reports: 'reports',
  configurations: 'configurations',
  survey: 'survey',
  upload: 'upload',
  download: 'download',
  retailerMapping: 'retailerMapping',
};

export const ALLOW_ANALYTICS = true;
