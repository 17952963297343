import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import CustomInput from '../../../../../CommonComponents/customInput';
import analyticFunc from '../../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../../GoogleAnalytics/events_payload';

const BarcodeForm = props => {
  const { isGstUser, barFormData, setBarFormData, showIMEI } = props;
  const { barcode, HSN: hsn, imei } = barFormData;

  const [toggleBarcodeSection, setToggleBarcodeSection] = useState(false);

  const handleInputChange = (inputValue, from) => {
    const tbfd = { ...barFormData };
    tbfd[from] = inputValue;
    setBarFormData(tbfd);
  };
  return (
    <div>
      <Button
        className="mt-0"
        color="primary"
        onClick={() => {
          if (props.from === 'inventory')
            analyticFunc(eventsPayload.ManageInventory[4]);
          setToggleBarcodeSection(!toggleBarcodeSection);
        }}
      >
        {toggleBarcodeSection ? '-' : '+'} Barcode/Serial No./IMEI No.
      </Button>
      {toggleBarcodeSection && (
        <>
          <CustomInput
            inputLabel="Barcode"
            value={barcode}
            onChange={e => handleInputChange(e.target.value, 'barcode')}
          />

          <CustomInput
            value={imei}
            disabled={!showIMEI}
            inputLabel="IMEI No./Serial No."
            multiline={true}
            onChange={e => handleInputChange(e.target.value, 'imei')}
          />
          {showIMEI && (
            <p style={{ marginTop: '-10px', marginLeft: '10px' }}>
              *Add muliple IMEIs/Serial Numbers separated by commas
            </p>
          )}
          {!showIMEI && (
            <p style={{ marginTop: '-10px', marginLeft: '10px' }}>
              *IMEI can be added only in cart
            </p>
          )}

          {!!isGstUser && (
            <CustomInput
              inputLabel="HSN Code"
              value={hsn}
              onChange={e => handleInputChange(e.target.value, 'HSN')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BarcodeForm;
