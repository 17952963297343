import { toast } from '../../node_modules/react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';

toast.configure({
  autoClose: 2000,
  hideProgressBar: true,
  draggable: false,
  zIndex: 999,
  position: 'bottom-left',
});

export function toastDismiss() {
  toast.dismiss();
}

export function toasterMessage(type, message) {
  toast.dismiss();
  toast[type](message, { delay: 500 });
}

export const removeWhiteSpaces = str => {
  if (typeof str !== 'string') {
    throw new Error(`${str} is not a string`);
  }
  return str.replace(/\s/g, '');
};
