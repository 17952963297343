/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import { SET_LOADING, GET_FAQ } from './types';

export const getFAQ = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .get('static/getFAQ')
    .then(response => {
      dispatch({ type: GET_FAQ, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    });
  dispatch({ type: SET_LOADING, payload: false });
};
