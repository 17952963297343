/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import {
  ADD_SUBUSER,
  UPDATE_SUBUSER,
  DELETE_SUBUSER,
  GET_SUBUSER,
  SET_LOADING,
  UPDATE_RETAILER_DETAIL,
} from './types';

export const addSubUsers = (payload, cb) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  // return api.post('user/addSubUsers', payload);
  await api
    .post('user/addSubRetailer', payload)
    .then(response => {
      //       dispatch({ type: ADD_SUBUSER, payload: response.data.data })
      if (cb) cb();
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const editSubUsers = (payload, cb) => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  // return api.post('user/editSubUsers', payload);
  api
    .post('user/editSubRetailer', payload)
    .then(response => {
      // dispatch({ type: UPDATE_SUBUSER, payload: response.data.data })
      toasterMessage('success', response.data.message);
      if (cb) cb();
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const deleteSubRetailer = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('user/deleteSubRetailer', payload)
    .then(response => {
      // dispatch({ type: DELETE_SUBUSER, payload: response.data.data });
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const getSubRetailer = () => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .get('user/getSubRetailer')
    .then(response => {
      dispatch({ type: GET_SUBUSER, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const updateRetailerDetails = payload => async dispatch => {
  dispatch({ type: UPDATE_RETAILER_DETAIL, payload });
};
