/* eslint-disable */
import 'date-fns';
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Loader from '../../RetailerWebPortal/Loader';
import { getOnlineStore } from '../../store/RetailerWebStore/RetailerAction/signup';
import { toastDismiss } from '../../@utils/utils';

const PaymentDetails = React.memo(
  ({ handleSubmit, getOnlineStore, onlineStores, loading, back }) => {
    const [shopStatus, setShopStatus] = useState('');
    const [shopType, setshopType] = useState('');
    const [isSellOnline, setIsSellOnline] = useState('false');
    const [sellingOptions, setSellingOptions] = useState([]);
    const [checkedOneOption, setCheckedOneOption] = useState(false);
    const [finance, setfinance] = useState([
      {
        name: 'Provided by us',
        isSelected: false,
      },
      { name: 'Tie up with finance Company/Bank', isSelected: false },
      { name: 'No finance facility', isSelected: false },
    ]);
    const [paymentMethod, setPaymentMethod] = useState([
      { name: 'Cash', isSelected: false },
      { name: 'Credit/Debit', isSelected: false },
      { name: 'Digital Wallet', isSelected: false },
    ]);
    const [error, setError] = useState('');

    useEffect(() => {
      toastDismiss();
    }, []);

    useEffect(() => {
      if (onlineStores && !sellingOptions.length)
        setSellingOptions(onlineStores);
    }, [onlineStores]);

    const handleFinanceChange = value => {
      let arr = finance.map(item => {
        if (value.name.toLowerCase() == 'no finance facility') {
          if (item.name == value.name) {
            item.isSelected = !item.isSelected;
          } else {
            item.isSelected = false;
          }
        } else {
          if (item.name == value.name) {
            item.isSelected = !item.isSelected;
          } else if (item.name.toLowerCase() == 'no finance facility') {
            item.isSelected = false;
          }
        }
        return item;
      });
      setfinance(arr);
    };

    const handlePaymentChange = value => {
      let arr = paymentMethod.map(item => {
        if (item.name == value.name) {
          item.isSelected = !value.isSelected;
        }
        return item;
      });
      setPaymentMethod(arr);
    };

    const handleSellingOptionsChange = (id, select) => {
      let arr = sellingOptions.map(item => {
        if (item.id == id) {
          item.isSelected = !select;
        }
        return item;
      });
      setSellingOptions(arr);
    };

    const checkNext = useCallback(() => {
      let payload = {
        shopStatus,
        shopType,
        onlineStore: isSellOnline == 'true' ? sellingOptions : undefined,
        paymentMethod,
        isSellOnline,
        financeFacility: finance,
      };
      if (
        shopStatus &&
        shopType &&
        finance.filter(item => item.isSelected == true).length > 0 &&
        paymentMethod.filter(item => item.isSelected == true).length > 0 &&
        (isSellOnline == 'true'
          ? checkedOneOption && isSellOnline
          : isSellOnline)
      ) {
        setError('');
        return handleSubmit(payload);
      }
      setError('Please fill all the fields');
    });

    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="field-container">
            <div className="signup-fields">
              <FormControl className="w-100">
                <InputLabel id="country">Select Shop Status *</InputLabel>
                <Select
                  labelId="shopStatus"
                  id="shopStatus"
                  value={shopStatus}
                  onChange={e => setShopStatus(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="partnership">Partnership</MenuItem>
                  <MenuItem value="private limited">Private Limited</MenuItem>
                  <MenuItem value="proprietorship">Proprietorship</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="signup-fields">
              <FormControl className="w-100">
                <InputLabel id="country">Select Shop Type *</InputLabel>
                <Select
                  labelId="shopType"
                  id="shopType"
                  value={shopType}
                  onChange={e => setshopType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="stand alone store">
                    Stand Alone Store
                  </MenuItem>
                  <MenuItem value="local chain store">
                    Local Chain store
                  </MenuItem>
                  <MenuItem value="national chain store">
                    National Chain store
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="field-container">
            <div className="signup-fields">
              <FormControl className="w-100">
                <FormLabel className="text-left">
                  <h6>Finance/EMI Facility ?</h6>
                </FormLabel>
                {finance.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={item.isSelected}
                        onChange={e => handleFinanceChange(item)}
                      />
                    }
                    label={item.name}
                  />
                ))}
              </FormControl>
            </div>
            <div className="signup-fields">
              <FormControl className="w-100">
                <FormLabel className="text-left">
                  <h6>Select payment method</h6>
                </FormLabel>
                {paymentMethod.map((item, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={item.isSelected}
                        onChange={e => handlePaymentChange(item)}
                      />
                    }
                    label={item.name}
                  />
                ))}
              </FormControl>
            </div>
          </div>
          <FormControl className="w-100">
            <FormLabel className="text-left">
              <h6>Do You Sell Online?</h6>
            </FormLabel>
            <RadioGroup
              row
              aria-label="position"
              value={isSellOnline}
              onChange={e => {
                if (e.target.value && onlineStores.length < 1) getOnlineStore();
                setIsSellOnline(e.target.value);
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          {isSellOnline == 'true' && (
            <div>
              {sellingOptions.map((item, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={item.isSelected}
                      onChange={e => {
                        !checkedOneOption && setCheckedOneOption(true);
                        handleSellingOptionsChange(item.id, item.isSelected);
                      }}
                    />
                  }
                  label={item.name}
                />
              ))}
            </div>
          )}
          <br />
          <p className="text-themeorange">{error}</p>
          {!loading ? (
            <button
              className="w-50 rounded-pill text-white bg-themeorange border-0 p-3 mt-2"
              onClick={checkNext}
            >
              Register
            </button>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({ Signup, Loader }) => {
  return {
    onlineStores: Signup.onlineStores.map(item => {
      return { ...item, isSelected: false };
    }),
    loading: Loader.loading,
  };
};

export default connect(mapStateToProps, { getOnlineStore })(PaymentDetails);
