/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import {
  GET_CUSTOMER_DETAILS,
  // ADD_CUSTOMER_DETAILS,
  GET_PRODUCTDETAILS,
  ADD_PRODUCT_DETAILS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT_DETAILS,
  SET_ACTIVE_PAGE,
  // MODE_OF_PAYMENT,
  VIEW_INVOICE,
  SET_USER_ID,
  SET_LOADING,
  SET_CUSTOMER_DETAILS,
  SET_PRODUCT_DETAILS,
  VIEW_MODE_OF_PAYMENT,
  SET_MODE_OF_PAYMENT,
  SET_BILLING_STATUS,
} from './types';
import { SET_PRODUCT_ID } from '../../../RetailerWebPortal/Billing/types';

export const getCustomerDetails = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/getCustomerDetails', payload)
    .then(response => {
      dispatch({ type: GET_CUSTOMER_DETAILS, payload: response.data.data });
    })
    .catch(error => {
      // toasterMessage('error', error.response.data.message)
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const setCustomerDetails = payload => async dispatch => {
  dispatch({ type: SET_CUSTOMER_DETAILS, payload });
};

export const addCustomerDetails = payload => {
  // dispatch({ type: SET_LOADING, payload: true })
  return api.post('billing/addCustomer', payload);
  // .then((response) => {
  //   dispatch({ type: ADD_CUSTOMER_DETAILS, payload: response.data.data })
  //   toasterMessage('success', response.data.message)
  // })
  // .catch((error) => {
  //   toasterMessage('error', error.response.data.error)
  // })
  // dispatch({ type: SET_LOADING, payload: false })
};

export const addSerialNo = payload => async dispatch => {
  // dispatch({ type: SET_LOADING, payload: true })
  return api.post('billing/addSerialNo', payload);
  // .then((response) => {
  //   dispatch({ type: ADD_CUSTOMER_DETAILS, payload: response.data.data })
  //   toasterMessage('success', response.data.message)
  // })
  // .catch((error) => {
  //   toasterMessage('error', error.response.data.error)
  // })
  // dispatch({ type: SET_LOADING, payload: false })
};

export const setGetUserId = payload => async dispatch => {
  dispatch({ type: SET_USER_ID, payload });
};

export const setProductId = payload => async dispatch => {
  dispatch({ type: SET_PRODUCT_ID, data: payload });
};

export const addProductDetails = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/addProduct', payload)
    .then(response => {
      dispatch({ type: ADD_PRODUCT_DETAILS, payload: response.data.data });
      dispatch({ type: SET_BILLING_STATUS, payload: { status: 0 } });
      if (response.data.data.inventoryLowMessage) {
        toasterMessage('info', response.data.data.inventoryLowMessage);
      } else {
        // toasterMessage('success', response.data.message)
      }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const updateProduct = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/updatePriceQuantity', payload)
    .then(response => {
      dispatch({ type: UPDATE_PRODUCT, payload: response.data.data });
      dispatch({ type: SET_BILLING_STATUS, payload: { status: 0 } });
      if (response.data.data.inventoryLowMessage) {
        toasterMessage('info', response.data.data.inventoryLowMessage);
      } else {
        // toasterMessage('success', response.data.message)
      }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const deleteProduct = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/deleteProduct', payload)
    .then(response => {
      dispatch({ type: DELETE_PRODUCT, payload: response.data.data });
      dispatch({ type: SET_BILLING_STATUS, payload: { status: 0 } });
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const getProductDetails = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/getProduct', payload)
    .then(response => {
      dispatch({ type: GET_PRODUCT_DETAILS, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      // toasterMessage('error', error.response.data.error)
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const setProductDetails = payload => async dispatch => {
  dispatch({ type: SET_PRODUCT_DETAILS, payload });
};

export const modeOfPayment = payload => {
  // dispatch({ type: SET_LOADING, payload: true })
  return api.post('billing/modeOfPayment', payload);
  // .then((response) => {
  //   dispatch({ type: MODE_OF_PAYMENT, payload: response.data.data })
  //   toasterMessage('success', response.data.message)
  // })
  // .catch((error) => {
  //   toasterMessage('error', error.response.data.error)
  // })
  // dispatch({ type: SET_LOADING, payload: false })
};

export const viewModeOfPayment = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('billing/viewModeOfPayment', payload)
    .then(response => {
      let { billId, invoiceId } = response.data.data;
      dispatch({ type: VIEW_MODE_OF_PAYMENT, payload: response.data.data });
      dispatch({ type: SET_USER_ID, payload: { billId, invoiceId } });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      // toasterMessage('error', error.response.data.error)
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const setModeOfPayment = payload => async dispatch => {
  dispatch({ type: SET_MODE_OF_PAYMENT, payload });
};

export const setActivePage = payload => async dispatch => {
  dispatch({ type: SET_ACTIVE_PAGE, payload });
};

export const productDetails = payload => async dispatch => {
  dispatch({ type: GET_PRODUCTDETAILS, payload });
};

export const viewInvoice = payload => async dispatch => {
  delete payload['billId'];
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('billing/viewInvoice', payload)
    .then(response => {
      if (response.data.status === 200) {
        dispatch({ type: VIEW_INVOICE, data: response.data.data });
        toasterMessage('success', response.data.message);
      } else {
        toasterMessage('error', response.data.error);
      }
    })
    .catch(error => {
      dispatch({ type: VIEW_INVOICE, data: '' });
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING, payload: false });
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const setInvoice = payload => async dispatch => {
  dispatch({ type: VIEW_INVOICE, data: payload });
};

export const cancelInvoice = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .post('billing/cancelInvoice', payload)
    .then(response => {
      // console.log('INVOICE CANCEL RESPONSE--->', response)
      // dispatch({ type: CANCEL_INVOICE, payload: response.data.data })
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      // toasterMessage('error', error.response.data.error)
      dispatch({ type: SET_LOADING, payload: false });
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const setBillingStatus = payload => async dispatch => {
  dispatch({ type: SET_BILLING_STATUS, payload });
};

export const setInvoiceDate = payload => async dispatch => {
  dispatch({ type: 'SET_INVOICE_DATE', payload });
};
