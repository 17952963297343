/* eslint-disable */
import React, { memo, useCallback, useState } from 'react';
import { TextField, IconButton, Paper } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import link from '../../images/splash-screen.png';
import VerifyOtp from '../../RetailerWebPortal/WebComponents/Ledger';
import ChangePassword from '../ChangePassword/ChangePassword';
import { Link } from 'react-router-dom';
import {
  forgotPassword,
  verifyForgetPasswordOtp,
  resetPassword,
} from '../../store/AdminActions/forgotPasswordAction';
import { connect } from 'react-redux';
import { history } from '../../routes';

export default connect(null, {
  forgotPassword,
  verifyForgetPasswordOtp,
  resetPassword,
})(
  memo(({ forgotPassword, verifyForgetPasswordOtp, resetPassword }) => {
    const [email, setEmail] = useState(''),
      [step, setStep] = useState(1);

    const onSuccess = useCallback(() => {
      history.push('/');
    }, []);

    const handleVerifyOtp = useCallback(
      payload => {
        verifyForgetPasswordOtp({ ...payload, keyword: email }, setStep);
      },
      [email, verifyForgetPasswordOtp]
    );

    const handleChangePassword = useCallback(
      payload => {
        resetPassword({ ...payload, keyword: email }, onSuccess);
      },
      [email, resetPassword]
    );

    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: '90vh' }}
      >
        {step !== 1 && (
          <IconButton
            onClick={e => setStep(c => c - 1)}
            style={{ position: 'absolute', left: 10, top: 10 }}
          >
            <ChevronLeft />
          </IconButton>
        )}
        {step === 1 ? (
          <Paper
            className="d-flex flex-column align-items-center shadow-lg"
            style={{ width: 400 }}
          >
            <div
              className="w-100 d-flex justify-content-center"
              style={{ position: 'relative' }}
            >
              <img src={link} alt="alt-img" height="150px" width="100%" />
              <h4
                className="m-1 text-white"
                style={{ position: 'absolute', top: 20 }}
              >
                Forgot Password
              </h4>
            </div>
            <div className="p-3">
              <p className="text-left text-secondary">
                To recover your password, you need to enter your registerd Email
                Id or Mobile Number
              </p>
              <hr style={{ color: 'black' }} />
              <TextField
                label="Email ID or Phone Number"
                variant="outlined"
                style={{ width: 350 }}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <br />
              <br />
              <button
                className="border-0 rounded-pill p-2 text-white bg-themeorange btn-lg"
                style={{ width: 350 }}
                onClick={e => {
                  e.preventDefault();
                  forgotPassword({ keyword: email }, setStep);
                }}
              >
                Submit
              </button>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <h6 className="text-themeorange hover-pointer mt-2">Back</h6>
              </Link>
            </div>
          </Paper>
        ) : step === 2 ? (
          <VerifyOtp fromForgotPassword={handleVerifyOtp} />
        ) : (
          <ChangePassword fromForgotPassword={handleChangePassword} />
        )}
      </div>
    );
  })
);
