import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';

export const getProfile = () => dispatch => {
  api
    .get('user/getProfile')
    .then(({ data: { data } }) => {
      data = data[0];
      data.userType.toLowerCase() === 'retailer'
        ? dispatch({ type: 'USER_UPDATED', payload: data })
        : dispatch({ type: 'PROFILE_DATA', payload: data });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const updateProfile = (payload, cb) => dispatch => {
  api
    .post('user/updateProfile', payload)
    .then(response => {
      cb && cb(true);
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      cb && cb();
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const updateUserPrivacy = payload => async dispatch => {
  return api
    .post('user/updateUserPrivacy', payload)
    .then(res => {
      const { status, message } = res.data;
      if (status === 200) {
        dispatch({ type: 'PRIVACY_UPDATED' });
      } else {
        throw new Error(message);
      }
    })
    .catch(err => {
      let message = 'Something bad happened';
      if (err.response) {
        message = err.response.data.message;
      } else if (err.request) {
        message = 'Server did not respond';
      } else {
        message = err.message;
      }

      toasterMessage('error', message);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happend :(');
    });
};
