/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Paper,
  Grid,
} from '@material-ui/core';
import Chart from '../Charts';
import ComboChart from './comboChart';
import {
  getSuperCategory,
  getCategory,
  getBrand,
  getSpecificModel,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { inventoryOverview } from '../../../store/RetailerWebStore/RetailerAction/inventoryOverview';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { toastDismiss } from '../../../@utils/utils';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

Exporting(Highcharts);

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    width: '100%',
    textAlign: 'left',
  },
});

const InventoryOverview = React.memo(
  ({
    categoryList,
    superCategoryList,
    brands,
    specificModels,
    getSuperCategory,
    getCategory,
    getBrand,
    getSpecificModel,
    inventoryOverview,
    inventoryDetails,
    inventoryData,
    getNotificationCount,
  }) => {
    const classes = useStyles();

    const [superCategoryId, setSuperCatogeryId] = useState('');
    const [superCategoryName, setSuperCatogeryName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [brandId, setBrandId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [modelId, setModelId] = useState('');
    const [modelName, setModelName] = useState('');
    const [disableSelect, setDissbleSelect] = useState(false);

    useEffect(() => {
      getNotificationCount();
      toastDismiss();
      inventoryOverview({ superCategoryId: 0 }); // Need to be initiialised with an emplty value for no graph on initial render
      getSuperCategory();
      // getCategory()
    }, []);

    useEffect(() => {
      if (superCategoryList && superCategoryList.length > 0) {
        setSuperCatogeryName(superCategoryList);
      }
    }, [superCategoryList]);

    useEffect(() => {
      if (superCategoryId <= 0) {
        setDissbleSelect(true);
      } else {
        setDissbleSelect(false);
      }
      superCategoryId &&
        superCategoryId > 0 &&
        getCategory({ superCategoryId });
      setCategoryId('');
      setBrandId('');
      setModelId('');
    }, [superCategoryId]);

    useEffect(() => {
      categoryId && categoryId > 0 && getBrand({ categoryId });
      setBrandId('');
      setModelId('');
    }, [categoryId]);

    useEffect(() => {
      brandId && getSpecificModel({ categoryId, brandId });
      setModelId('');
    }, [brandId]);

    // const ComboData = useCallback(() => {
    //   let inventoryProduct = [
    //     { value: 227, quantity: 7 },
    //     { value: 876, quantity: 35 },
    //     { value: 846, quantity: 25 },
    //     { value: -976, quantity: -95 },
    //   ]
    //   let QData = []
    //   let VData = []

    //   if (inventoryDetails) {
    //     inventoryDetails &&
    //       inventoryDetails.map((item, id) => {
    //         let Qat = item.quantity >= 0 ? item.quantity : 0
    //         let val = item.value >= 0 ? item.value : 0
    //         QData.push(Qat)
    //         VData.push(val)
    //       })
    //   }
    //   for (let i = 7 - inventoryDetails.length; i >= QData.length; i--) {
    //     QData.push(0)
    //     VData.push(0)
    //   }

    //   let Data = { QData, VData }

    //   return Data
    // }, [inventoryDetails])

    const ComboData = useCallback(() => {
      // let inventoryProduct = [
      //   { value: 227, quantity: 7 },
      //   { value: 876, quantity: 35 },
      //   { value: 846, quantity: 25 },
      //   { value: -976, quantity: -95 },
      // ]
      let QData = [];
      let VData = [];

      if (inventoryDetails) {
        inventoryDetails &&
          inventoryDetails.map((item, id) => {
            let Qat = item.quantity >= 0 ? item.quantity : 0;
            let val = item.value >= 0 ? item.value : 0;
            QData.push(Qat);
            VData.push(val);
          });
      }
      for (let i = 7 - inventoryDetails.length; i >= QData.length; i--) {
        QData.push(0);
        VData.push(0);
      }

      let Data = { QData, VData };

      console.log('COMBO DATA------>', Data);
      return Data;
    }, [inventoryDetails]);

    const handleSubmit = useCallback(() => {
      let payload = {
        superCategoryId: parseInt(superCategoryId),
        categoryId: categoryId ? parseInt(categoryId) : undefined,
        brandId: brandId ? parseInt(brandId) : undefined,
        modelId: modelId ? parseInt(modelId) : undefined,
      };
      inventoryOverview(payload);
    });

    return (
      <div className="w-100 d-flex justify-content-center align-items-center p-4">
        <Grid className="">
          <div style={{ textAlign: 'left', marginLeft: '2%', marginTop: '1%' }}>
            <h3 color="primary">Inventory Overview</h3>
          </div>
          <Grid className={classes.root}>
            <FormControl variant="outlined" className="w-100">
              <InputLabel id="category">Category</InputLabel>
              <Select
                style={{ width: '1127px' }}
                labelId="SuperCategory"
                id="SuperCategory"
                value={
                  superCategoryId && superCategoryId + ',,,' + superCategoryName
                }
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setSuperCatogeryName(arr[1]);
                  setSuperCatogeryId(arr[0]);
                }}
                label="Category"
              >
                {superCategoryList &&
                  superCategoryList.map(item => (
                    <MenuItem
                      key={item.superCategoryId}
                      value={
                        item.superCategoryId + ',,,' + item.superCategoryName
                      }
                    >
                      {item.superCategoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined" className="w-100">
              <InputLabel id="category">Sub Category</InputLabel>
              <Select
                disabled={disableSelect}
                style={{ width: '1127px' }}
                labelId="category"
                id="category"
                value={categoryId && categoryId + ',,,' + categoryName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setCategoryName(arr[1]);
                  setCategoryId(arr[0]);
                }}
                label="Sub Category"
              >
                {categoryList &&
                  categoryList.map(item => (
                    <MenuItem
                      key={item.categoryId}
                      value={item.categoryId + ',,,' + item.categoryName}
                    >
                      {item.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined" className="w-100">
              <InputLabel id="brand">Brand</InputLabel>
              <Select
                disabled={disableSelect}
                style={{ width: '1127px' }}
                labelId="brand"
                id="brand"
                value={brandId && brandId + ',,,' + brandName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setBrandName(arr[1]);
                  setBrandId(arr[0]);
                }}
                label="Brand"
              >
                {brands &&
                  brands.map(item => (
                    <MenuItem
                      key={item.brandId}
                      value={item.brandId + ',,,' + item.brandName}
                    >
                      {item.brandName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined" className="w-100">
              <InputLabel id="Model">Model</InputLabel>
              <Select
                disabled={disableSelect}
                style={{ width: '1127px' }}
                labelId="Model"
                id="Model"
                value={modelId && modelId + ',,,' + modelName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setModelName(arr[1]);
                  setModelId(arr[0]);
                }}
                label="Model"
              >
                {specificModels &&
                  specificModels.map(item => (
                    <MenuItem
                      key={item.modelId}
                      value={item.modelId + ',,,' + item.modelName}
                    >
                      {item.modelName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <button
              style={{ marginLeft: '25%' }}
              className="rounded-pill p-3 text-white text-uppercase w-50 border-0 bg-themeorange"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <br />
            <br />
            <h6>
              {inventoryData.superCategoryName}{' '}
              {inventoryData.superCategoryName && inventoryData.categoryName
                ? '>'
                : null}{' '}
              {inventoryData.categoryName}{' '}
              {inventoryData.brandName ? '>' : null} {inventoryData.brandName}{' '}
              {inventoryData.modelName ? '>' : null} {inventoryData.modelName}
            </h6>
            <br />
            <br />

            <div
              className="flex-column justify-content-center"
              style={{ overflow: 'auto', width: '60%', marginLeft: '10%' }}
            >
              {inventoryDetails && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <h6>Quantity</h6>
                  <h6>Value</h6>
                </div>
              )}
              {inventoryDetails && (
                <ComboChart
                  ComboData={ComboData()}
                  vAxis={'Quantity'}
                  title="Age of Inventory"
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
);

const mapStateToProps = ({ ManageInventory, InventoryOverview }) => {
  const {
    categoryList,
    brands,
    specificModels,
    superCategoryList,
  } = ManageInventory;
  return {
    categoryList,
    superCategoryList,
    brands,
    specificModels,
    inventoryDetails: InventoryOverview.inventoryDetails.report,
    inventoryData: InventoryOverview.inventoryDetails,
  };
};

export default connect(mapStateToProps, {
  getSuperCategory,
  getCategory,
  getBrand,
  getSpecificModel,
  inventoryOverview,
  getNotificationCount,
})(InventoryOverview);
