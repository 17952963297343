import {
  Typography,
  Container,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from 'react-router';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '3em',
  },
  card: {
    height: '5em',
    marginTop: '2em',
    borderRadius: '1.2em',
  },
  cardActionArea: {
    height: '100%',
    width: '100%',
  },
  title: {
    paddingLeft: '1em',
    // fontSize: '1.2em',
    fontFamily: theme.palette.typography.fontFamily,
  },
}));

const reportsList = [
  {
    title: 'Sales Report',
    url: 'Sales Report',
    clickEvent: eventsPayload.Reports[6],
    id: 1,
  },
  {
    title: 'Profit and Loss Report',
    url: 'Profit & Loss Details',
    clickEvent: eventsPayload.Reports[3],
    id: 2,
  },
  {
    title: 'Expense Report',
    url: 'Expenses',
    clickEvent: eventsPayload.Expenses[0],
    id: 3,
  },
  {
    title: 'Inventory Overview',
    url: 'inventoryOverview',
    clickEvent: eventsPayload.Reports[8],
    id: 4,
  },
];

const Reports = props => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Container maxWidth="sm" className={classes.container}>
      {reportsList.map(({ title, url, clickEvent, id }) => (
        <Card
          key={id}
          className={classes.card}
          onClick={() => {
            if (clickEvent) analyticFunc(clickEvent);
            history.push(`${url}`);
          }}
        >
          <CardActionArea className={classes.cardActionArea}>
            <CardContent>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Container>
  );
};

export default Reports;
