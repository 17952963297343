import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { toasterMessage } from '../../../../@utils/utils';
import { LinearProgress, List, Paper } from '@material-ui/core';
import HistoryInfo from './historyInfo';
import { useSelector, useDispatch } from 'react-redux';
import { getRewardHistory, clearRewardHistory } from '../rewardsAction';

const useStyles = makeStyles(theme => ({
  cont: {
    marginTop: '2rem',
  },
  historyList: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    height: '50vh',
  },
}));

const dummyHistoryData = [
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'credit',
    rewardService: 'INVOICE',
    rewardServiceId: 10,
    points: 126,
    orderMessage: null,
    rewardsRedeemed: null,
    createdAt: '2021-02-16T10:44:58.000Z',
  },
  {
    transactionType: 'debit',
    rewardService: null,
    rewardServiceId: null,
    points: 500,
    orderMessage: 'The request data is invalid.',
    rewardsRedeemed:
      '[{"sku":"EGCGBAMZ001","price":10,"qty":1,"name":"Myntra"},{"sku":"EGCGBAMZ001","price":10,"qty":1,"name":"Amazon"}]',
    createdAt: '2021-02-16T10:43:22.000Z',
  },
];

const RewardsHistory = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollref = useRef();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getRewardHistory(pageNumber));
    return () => {
      dispatch(clearRewardHistory());
    };
  }, []);

  useEffect(() => {
    if (props.redeemed[0]) dispatch(getRewardHistory(pageNumber));
  }, [props.redeemed]);

  const loadMore = useCallback(() => {
    if (props.rewardsHistory.length < pageNumber * 10) return;
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight >=
      scrollref.current.scrollHeight
    ) {
      let pg = pageNumber + 1;
      dispatch(getRewardHistory(pg));
      setPageNumber(pg);
    }
  }, [props.loading, props.searchList]);

  return (
    <div className={classes.cont}>
      <p style={{ fontWeight: '500' }}>HISTORY</p>
      {props.loading[0] && <LinearProgress />}
      <List className={classes.historyList} ref={scrollref} onScroll={loadMore}>
        {props.rewardsHistory.map((historyData, idx) => (
          <HistoryInfo historyData={historyData} key={idx} />
        ))}
      </List>
    </div>
  );
};

const mapStateToProps = ({ RewardsReducer }) => {
  return {
    rewardsHistory: RewardsReducer.rewardsHistory,
    loading: RewardsReducer.loading,
    redeemed: RewardsReducer.redeemed,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, null)(RewardsHistory);
