import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerHTML from 'dangerously-set-html-content';
import { CircularProgress } from '@material-ui/core';
import api, { baseURL } from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';

const useStyles = makeStyles(theme => ({
  surveyContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: "space-between",
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  surveyMainText: {
    fontSize: '2.3125rem',
    fontWeight: '800',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      textAlign: 'center',
    },
  },
}));

const SimplePage = props => {
  const classes = useStyles(props);
  const { fbCred } = props;
  const [htmlLoading, setHtmlLoading] = useState(false);
  const [html, setHtml] = useState();

  useEffect(() => {
    getHtml();
  }, []);
  const getHtml = () => {
    if (!html) {
      setHtmlLoading(true);
      const url = new URL(`${baseURL.baseURL}survey/getUserRecommendations`);
      url.searchParams.append('userId', fbCred.userId);
      url.searchParams.append('userToken', fbCred.userToken);
      api
        .get(url)
        .then(response => {
          const { data } = response.data;
          setHtmlLoading(false);
          setHtml(data.html);
        })
        .catch(error => {
          setHtmlLoading(false);
          toasterMessage('error', 'Something From server happened');
        })
        .catch(() => {
          setHtmlLoading(false);
          toasterMessage('error', 'Something bad happened');
        });
    }
  };

  return (
    <div className={classes.surveyContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={classes.surveyMainText}>
          <span style={{ color: '#3F3D56' }}>
            Hello {fbCred.name ? fbCred.name : 'mBill User'}! <br />
          </span>
          <span style={{ color: '#FE805C' }}>
            {' '}
            have a look at our recommendations
          </span>{' '}
        </div>
      </div>
      {htmlLoading && <CircularProgress />}
      {!htmlLoading && html && (
        <InnerHTML
          style={{
            borderRadius: '0.5rem',
            width: '55%',
            backgroundColor: '#F7F7FC',
            boxShadow: '-0.1rem -0.1rem 1rem  1px #E5E5E5 ',
          }}
          html={html}
        />
      )}
    </div>
  );
};

export default SimplePage;
