/* eslint-disable */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { connect } from 'react-redux';
import * as changePassword from '../../store/AdminActions/changePasswordAction';
import link from '../../images/splash-screen.png';
import { getNotificationCount } from '../../store/RetailerWebStore/RetailerAction/notifications';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    width: '50%',
  },
  errorMessage: {
    color: 'red',
  },
  form: {
    width: '100%',
    borderRadius: 10,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
    [theme.breakpoints.up('md')]: {
      width: '50 %',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    marginTop: theme.spacing(4),
    alignContent: 'flex-end',
  },
  heading: {
    color: 'white',
    fontFamily: 'Roboto',
  },
}));

function ChangePassword(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    newPassword: '',
    currentPassword: '',
    confirmPassword: '',
    errorNewPassword: '',
    errorCurrentPassword: '',
    errorConfirmPassword: '',
    showPassword: false,
  });

  // useEffect(() => {
  //   if (props.userType.toLowerCase() === "retailer" || props.userType.toLowerCase() === "subretailer"){
  //   props.getNotificationCount()
  // }}, [])

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowOldPassword = () => {
    setValues({ ...values, showcurrentPassword: !values.showcurrentPassword });
  };

  const handleClickShowNewPassword = () => {
    setValues({ ...values, shownewPassword: !values.shownewPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showconfirmPassword: !values.showconfirmPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  ///////submitting form////////
  const submitChangePasswordForm = event => {
    event.preventDefault();
    let { currentPassword, newPassword, confirmPassword } = values;
    if (props.fromForgotPassword) {
      props.fromForgotPassword({ newPassword, confirmPassword });
    } else {
      if (validateForm()) {
        let payload = {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        props.changePassword(payload);
      }
    }
  };

  // validating the form
  const validateForm = () => {
    let { currentPassword, newPassword, confirmPassword } = values;
    let errorCurrentPassword = '';
    let errorNewPassword = '';
    let errorConfirmPassword = '';
    let formIsValid = true;

    if (!currentPassword) {
      formIsValid = false;
      errorCurrentPassword = 'Please enter your current password';
    }
    if (!newPassword) {
      formIsValid = false;
      errorNewPassword = 'Please enter your new password';
    }
    if (!confirmPassword) {
      formIsValid = false;
      errorConfirmPassword = 'Please enter your confirm password';
    }

    if (currentPassword) {
      let plength = new RegExp(/^.*(?=.{8,})/);
      if (!plength.test(currentPassword)) {
        formIsValid = false;
        errorCurrentPassword =
          'Password should consist of minimum 8 characters';
      }
    }
    if (newPassword) {
      let pattern = new RegExp(/^(?=.*[0-9].*)(?=.*[a-z].*).*$/);
      let plength = new RegExp(/^.*(?=.{8,})/);
      if (!pattern.test(newPassword)) {
        formIsValid = false;
        errorNewPassword =
          'New Password should consist of one lower case letter and a number';
      }
      if (!plength.test(newPassword)) {
        formIsValid = false;
        errorNewPassword =
          'New Password should consist of minimum 8 characters';
      }
      if (confirmPassword) {
        if (newPassword !== confirmPassword) {
          formIsValid = false;
          errorConfirmPassword =
            "Confirm password doesn't match with new password";
        }
      }
    }

    setValues({
      ...values,
      errorCurrentPassword,
      errorNewPassword,
      errorConfirmPassword,
    });
    return formIsValid;
  };

  return (
    <div className={classes.root}>
      <Paper className="shadow-lg">
        <div className={classes.container} style={{ width: 400 }}>
          <div
            className="w-100 d-flex justify-content-center"
            style={{ position: 'relative' }}
          >
            <img src={link} alt="alt-img" height="150px" width="100%" />
            <h4
              className="m-1 text-white"
              style={{ position: 'absolute', top: 20 }}
            >
              CHANGE PASSWORD
            </h4>
          </div>
          <form>
            {!props.fromForgotPassword && (
              <>
                <FormControl className="w-100" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-currentpassword">
                    Current Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined"
                    type={values.showcurrentPassword ? 'text' : 'password'}
                    value={values.currentPassword}
                    name="currentPassword"
                    label="Current Password"
                    onChange={event => handleChange(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showcurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={150}
                  />
                </FormControl>
                <br />
              </>
            )}
            <span className={classes.errorMessage}>
              {values.errorCurrentPassword}
            </span>
            <br />
            <FormControl className="w-100" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-newpassword">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-newpassword"
                type={values.shownewPassword ? 'text' : 'password'}
                value={values.newpassword}
                name="newPassword"
                onChange={event => handleChange(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.shownewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={110}
              />
            </FormControl>
            <br />
            <span className={classes.errorMessage}>
              {values.errorNewPassword}
            </span>
            <br />
            <FormControl className="w-100" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-confirmPassword">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirmPassword"
                type={values.showconfirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                name="confirmPassword"
                onChange={event => handleChange(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showconfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={140}
              />
            </FormControl>
            <br />
            <span className={classes.errorMessage}>
              {values.errorConfirmPassword}
            </span>
            <br />
            <div className="m-2">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="rounded-pill p-2 w-100 text-white bg-themeorange"
                onClick={submitChangePasswordForm}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {userType: state.loginReducer.user.userData[0].userType }
// }

export default connect(null, {
  ...changePassword,
  getNotificationCount,
})(ChangePassword);
