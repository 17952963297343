import { Container } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Button, TextField, Divider, List } from '@material-ui/core';
import MrpForm from './CreateProductComponents/mrpForm';
import RequiredForm from './CreateProductComponents/requiredForm';
import BarcodeForm from './CreateProductComponents/barcodeForm';
import VendorForm from './CreateProductComponents/vendorForm';
import {
  setDistributor,
  addProduct,
  getDistributor,
  editProduct,
  addProductToSelectedList,
} from '../../billingAction';
import { connect } from 'react-redux';
import { addProductToInventory } from '../../../WebComponents-v3/ManageInventory/manageInventoryAction';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';
import { removeWhiteSpaces, toasterMessage } from '../../../../@utils/utils';

const BUTTON_TITLE = {
  addProduct: 'Add Product',
  editProduct: 'Save',
  addToList: 'Add To List',
};

const CreateProduct = props => {
  const {
    selectedProducts,
    productToList,
    editProduct,
    setToggleModal,
    toggleModal,
    setRenderCreateProduct,
    from,
  } = props;

  // 0 : Create || 1 : Add to List || 2 : Edit Product
  const [mode, setMode] = useState(0);
  const [requiredData, setRequiredData] = useState({
    modelName: '',
    units: 'Pcs',
    modelQuantity: 0,
    sellingPrice: 0,
    gst: 0,
  });

  const [barFormData, setBarFormData] = useState({
    barcode: null,
    imei: null,
    HSN: null,
  });

  const [priceFormData, setPriceFormData] = useState({
    MRP: 0,
    purchasePrice: 0,
  });
  const [distributorDetails, setDistributorDetails] = useState({});
  const [showDistributorSection, setShowDistributorSection] = useState(false);

  const [buttonTitle, setButtonTitle] = useState(BUTTON_TITLE.addProduct);
  const [disabled, setDisabled] = useState(false);

  const [showSellingPriceError, setShowSellingPriceError] = useState(false);
  const [showItemNameError, setShowItemNameError] = useState(false);

  const [currProd, setCurrProd] = useState({});
  const [showIMEI, setShowIMEI] = useState(false);

  const [barFormValid, setBarFormValid] = useState(false);

  useEffect(() => {
    if (barFormValid) {
      // console.log('imei is ', barFormData.imei);
      /** start kro yr */
      if (mode == 0 || from === 'inventory') addProductApiAndListSubmit();
      else if (mode == 2) editProductSubmit();
      else if (mode == 1)
        productToList.add
          ? addProductApiAndListSubmit()
          : productToListSubmit();
    }
  }, [barFormValid]);

  useEffect(() => {
    fun();
  }, []);

  const fun = () => {
    setShowIMEI(from === 'billing');
    setMode(editProduct ? 2 : productToList ? 1 : 0);
    setButtonTitle(
      editProduct
        ? BUTTON_TITLE.editProduct
        : productToList
        ? BUTTON_TITLE.addToList
        : buttonTitle
    );
    let prod = editProduct ? editProduct : productToList;
    setCurrProd(prod || currProd);
    if (prod) {
      const {
        modelName: mn,
        units: units,
        sellingPrice: sp,
        gst: pgst,
        modelQuantity: mq,
        barcode,
        HSN,
        MRP,
        purchasePrice,
        distributorDetails: dd,
        imei,
      } = prod;
      setDistributorDetails(dd || distributorDetails);
      setRequiredData({
        modelName: mn,
        units: units || 'Pcs',
        sellingPrice: sp,
        gst: pgst || 0,
        modelQuantity: mq,
      });
      setBarFormData({
        barcode: barcode || null,
        HSN: HSN || null,
        imei: imei || null,
      });
      setPriceFormData({
        MRP: MRP || 0,
        purchasePrice: purchasePrice || 0,
      });
    }
  };

  const editProductSubmit = () => {
    let trd = { ...requiredData };
    trd['gst'] = trd.gst.toString();

    let payload = currProd;
    payload = { ...payload, ...trd };

    const { barcode: bc, HSN: hsn, imei } = barFormData;
    if (bc) payload['barcode'] = bc;
    else delete payload['barcode'];

    if (hsn) payload['HSN'] = hsn;
    else delete payload['HSN'];

    if (imei) payload['imei'] = imei;
    else delete payload['imei'];

    const { MRP: mrp, purchasePrice: pp } = priceFormData;
    if (mrp) payload['MRP'] = mrp;
    if (pp) payload['purchasePrice'] = pp;
    if (distributorDetails.id) payload['distributorId'] = distributorDetails.id;
    // console.log("Editing Payload", payload);

    // * this directly adds it to the list
    props.editProductInList(payload);
    setToggleModal(!toggleModal);
  };

  const productToListSubmit = () => {
    let trd = { ...requiredData };
    trd['gst'] = trd.gst.toString();
    let payload = currProd;
    payload = { ...payload, ...trd };

    //! should include priceFormData || barFormData
    // console.log("P2List", payload);
    props.addProductToSelectedList(payload);
    setToggleModal(!toggleModal);
  };

  const addProductApiAndListSubmit = () => {
    let trd = { ...requiredData };
    trd['gst'] = trd.gst.toString();

    let payload = currProd;
    payload = { ...payload, ...trd };

    const { barcode: bc, HSN: hsn, imei } = barFormData;
    if (bc) payload['barcode'] = bc;
    if (hsn) payload['HSN'] = hsn;
    if (imei) payload['imei'] = imei;

    const { MRP: mrp, purchasePrice: pp } = priceFormData;
    if (mrp) payload['MRP'] = mrp;
    if (pp) payload['purchasePrice'] = pp;

    if (distributorDetails.id) payload['distributorId'] = distributorDetails.id;

    if (from === 'inventory') {
      props.addProductToInventory(payload);
    } else {
      props.addProduct(payload, mode);
    }

    if (setRenderCreateProduct) setRenderCreateProduct(false);
    else if (setToggleModal) setToggleModal(!toggleModal);
  };

  const validateEachIMEI = () => {
    try {
      if (
        !(
          from === 'billing' &&
          barFormData.imei &&
          typeof barFormData.imei === 'string'
        )
      ) {
        return true;
      }

      let currIMEI = removeWhiteSpaces(barFormData.imei);

      if (!currIMEI) return true;

      // ======== logic to check, extra comma ========

      if (currIMEI.endsWith(',')) currIMEI = currIMEI.slice(0, -1);

      const currIMEIArr = currIMEI.split(',');

      const IMEI_REGEX = /^([a-zA-Z0-9]+)$/;
      let areIMEIsValid = true;

      currIMEIArr.forEach(_imei => {
        areIMEIsValid = areIMEIsValid && _imei.match(IMEI_REGEX);
      });

      if (!areIMEIsValid) {
        toasterMessage(
          'error',
          'IMEIs should contain digits and english alphabets only'
        );
        return false;
      }

      // ============= ******* ==================

      let allIMEIsString = `${currIMEI},`;

      selectedProducts.forEach(product => {
        const isSameProduct = currProd.modelId === product.modelId;

        if (
          !isSameProduct &&
          typeof product['imei'] === 'string' &&
          !!product['imei']
        ) {
          allIMEIsString += `${product.imei},`;
        }
      });

      const imeiArr = removeWhiteSpaces(allIMEIsString)
        .slice(0, -1)
        .split(',');

      const imeiSet = new Set();

      const duplicateIMEIExist = imeiArr.some(_imei => {
        if (imeiSet.has(_imei)) {
          toasterMessage('error', `IMEI ${_imei} is duplicate`);
          return true;
        }

        imeiSet.add(_imei);
        return false;
      });

      if (!duplicateIMEIExist) {
        console.log('dup imie ', currIMEI);
        setBarFormData(prev => ({
          ...prev,
          imei: currIMEI,
        }));
      }

      return !duplicateIMEIExist;
    } catch (err) {
      return false;
    }
  };

  const areAllFieldsValid = () => {
    try {
      const {
        modelName: name,
        sellingPrice,
        modelQuantity: qty,
      } = requiredData;

      const isValidItemName = name;
      setShowItemNameError(!name);

      const isValidSellingPrice = sellingPrice;
      setShowSellingPriceError(sellingPrice == 0);

      const allFieldsValid =
        isValidSellingPrice && isValidItemName && validateEachIMEI();

      setBarFormValid(allFieldsValid);
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = () => {
    /* for google analytics */
    if (from === 'inventory' && buttonTitle === BUTTON_TITLE.editProduct) {
      analyticFunc(eventsPayload.ManageInventory[11]);
    } else if (
      from === 'inventory' &&
      buttonTitle === BUTTON_TITLE.addProduct
    ) {
      analyticFunc(eventsPayload.ManageInventory[8]);
    } else if (from === 'inventory' && buttonTitle === BUTTON_TITLE.addToList) {
      analyticFunc(eventsPayload.ManageInventory[14]);
    }

    /* main logic */
    areAllFieldsValid();
  };

  const handleCancel = () => {
    if (setRenderCreateProduct) {
      setRenderCreateProduct(false);
    } else {
      setToggleModal(false);
    }
  };

  return (
    <Container
      style={{ display: 'flex', height: '80%', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', padding: '0.5rem' }}>
          <RequiredForm
            requiredData={requiredData}
            setRequiredData={setRequiredData}
            mode={mode}
            showItemNameError={showItemNameError}
            showSellingPriceError={showSellingPriceError}
          />
        </div>
        <div style={{ width: '50%', padding: '0.5rem', marginTop: '0.35rem' }}>
          <BarcodeForm
            showIMEI={showIMEI}
            isGstUser={props.isGstUser}
            barFormData={barFormData}
            setBarFormData={setBarFormData}
            from={from}
          />

          <MrpForm
            priceFormData={priceFormData}
            setPriceFormData={setPriceFormData}
          />

          <Button
            color="primary"
            onClick={() => setShowDistributorSection(!showDistributorSection)}
          >
            {showDistributorSection ? '-' : '+'} Vendor/ Distributor Details
          </Button>
          {showDistributorSection && (
            <VendorForm
              distributorDetails={distributorDetails}
              setDistributorDetails={setDistributorDetails}
              from={from}
            />
          )}
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          marginTop: 'auto',
        }}
      >
        <Button
          onClick={handleCancel}
          className="mx-auto mb-2 pr-3 pl-3"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          disabled={disabled}
          className="mx-auto mb-2 pr-3 pl-3"
          variant="contained"
          color="primary"
          style={{ minWidth: '7em' }}
        >
          {buttonTitle}
        </Button>
      </div>

      {(from === 'inventory' || buttonTitle !== BUTTON_TITLE.editProduct) && (
        <p style={{ alignSelf: 'flex-end', marginRight: '5em' }}>
          * Changes will impact inventory
        </p>
      )}
    </Container>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => ({
  isGstUser: OtpLoginReducer.user.userData[0].isGstNumber,
  selectedProducts: BillingReducer.selectedProducts,
});

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    addProduct: (payload, mode) => dispatch(addProduct(payload, mode)),
    editProductInList: payload => dispatch(editProduct(payload)),
    addProductToSelectedList: product =>
      dispatch(addProductToSelectedList(product)),
    addProductToInventory: product => dispatch(addProductToInventory(product)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
