/* eslint-disable */
import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';
import { history } from '../../routes';

export const changePassword = payload => async dispatch => {
  await api
    .post('user/changePassword', payload)
    .then(response => {
      if (response.status === 200) {
        toasterMessage('success', 'Password Changed Successfully');
        history.push('/');
        dispatch({ type: 'USER_LOGOUT' });
      }
    })
    .catch(error => {
      if (
        error.response.data &&
        error.response.data.status &&
        error.response.data.error
      ) {
        toasterMessage('error', error.response.data.error);
      } else {
        toasterMessage('error', 'Error Changing Password');
      }
    });
};
