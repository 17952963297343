import React, { memo, useState } from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default memo(
  ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    focusedInput,
    setFocusedInput,
  }) => {
    return (
      <DateRangePicker
        startDate={moment(startDate) || 'State Date'}
        startDateId="start_date_id"
        endDate={moment(endDate) || 'End Date'}
        endDateId="end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        isOutsideRange={() => false}
        // isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
      />
    );
  }
);
