import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toasterMessage } from '../../../@utils/utils';
import { useParams } from 'react-router';
import api from '../../../api/api';

const useStyles = makeStyles(theme => ({
  surveyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  surveyMainText: {
    fontSize: '3.3125rem',
    fontWeight: '800',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      textAlign: 'left',
    },
  },
  rewardCard: {
    height: '26.5rem',
    width: '20rem',
    borderRadius: '1rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  rewardLogo: {
    width: '14rem',
    height: '14rem',
  },
}));

const RewardsEarned = React.memo(props => {
  const classes = useStyles(props);
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherName, setVoucherName] = useState('');
  const [voucherImage, setVoucherImage] = useState('');
  const [rewardsReceived, setRewardsReceived] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    let payload = {
      token,
    };
    api
      .post('survey/surveyReward', payload)
      .then(response => {
        const { data } = response.data;
        setVoucherValue(data.voucherValue);
        setVoucherName(data.voucherName);
        setVoucherImage(data.voucherImages.mobile);
        setRewardsReceived(true);
      })
      .catch(error => {
        toasterMessage('error', 'Something From server happened');
        // toasterMessage("error", error.response.data.error);
      })
      .catch(() => {
        toasterMessage('error', 'Something bad happened');
      });
  }, []);
  return (
    <>
      {rewardsReceived && (
        <div
          className={classes.surveyContainer}
          style={{ justifyContent: 'space-evenly' }}
        >
          <div className={classes.surveyMainText}>
            <span style={{ color: '#3F3D56' }}>
              Congrats!
              <br />
            </span>
            <span style={{ color: '#FE805C' }}>
              {' '}
              You have won &#8377; {voucherValue}
            </span>{' '}
          </div>
          <Paper className={classes.rewardCard}>
            <img src={voucherImage} className={classes.rewardLogo} />
            <div>
              <h5 style={{ fontSize: '2rem', fontWeight: '800' }}>
                &#8377; {voucherValue}
              </h5>
              <span style={{ color: '#AFAFBD', fontSize: '0.75rem' }}>
                Voucher details and instructions on how to redeem has been sent
                to your email id.
              </span>
            </div>
          </Paper>
        </div>
      )}
      {!rewardsReceived && <div>Please wait while we confirm....</div>}
    </>
  );
}, []);

export default RewardsEarned;
