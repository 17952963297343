import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { toasterMessage } from '../../../../@utils/utils';
import { Paper } from '@material-ui/core';
import rewards_background from '../../../../assets/svg/rewards_background.svg';
import { getRewardInfo } from '../rewardsAction';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '1rem',
    backgroundImage: `url(${rewards_background})`,
    display: 'grid',
    gridTemplateRows: '0.1fr 0.3fr 0.2fr',
    alignContent: 'center',
  },
  currentPoints: {
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: '1rem',
    padding: '0.3rem 1.5rem',
    margin: '0.5rem auto',
    fontSize: '2rem',
  },
  totalPoints: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: '0.3rem 1.5rem',
    margin: '0 auto',
    borderRadius: '1rem',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '0.3rem',
    fontWeight: '500',
  },
}));

const RewardPointsInfo = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rewardsInfo = useSelector(state => state.RewardsReducer.rewardsInfo);
  const redeemed = useSelector(state => state.RewardsReducer.redeemed);
  const [totalPoints, setTotalPoints] = useState(0);
  const [availablePoints, setAvailablePoints] = useState(0);

  useEffect(() => {
    dispatch(getRewardInfo());
  }, []);

  useEffect(() => {
    if (redeemed[0]) dispatch(getRewardInfo());
  }, [redeemed]);

  useEffect(() => {
    if (rewardsInfo && Object.keys(rewardsInfo).length > 0) {
      setAvailablePoints(rewardsInfo.totalpointsAvailable);
      setTotalPoints(rewardsInfo.totalPointsEarned);
    }
  }, [rewardsInfo]);

  return (
    <Paper className={classes.paper}>
      <p className={classes.heading}>CURRENT POINTS</p>
      <div className={classes.currentPoints}>{availablePoints}</div>
      <div className={classes.totalPoints}>Total Points: {totalPoints}</div>
    </Paper>
  );
};

const mapStateToProps = ({ BillingReducer }) => {
  return {};
};

export default connect(null, null)(RewardPointsInfo);
