import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

import SummaryLine from './summaryLine';
import RewardModal from '../components/Modal/rewardModal';
import PaymentMethod from './paymentMethod';
import {
  sendBillingDetails,
  resetBillingState,
  sendEditBillingDetails,
  isValidIMEI,
} from '../billingAction';
import { removeWhiteSpaces, toasterMessage } from '../../../@utils/utils';
import { setGetUserId } from '../../../store/RetailerWebStore/RetailerAction/billing';

const BillingSummary = props => {
  const { selectedProducts, isGstUser } = props;
  const [rewardPoints, setRewardPoints] = useState(0);
  const [igstSelected, setIgstSelected] = useState(true);
  const [isGstBill, setIsGstBill] = useState(false);
  const [multiPaymentOption, setMultiPaymentOption] = useState(false);
  const [remarks, setRemarks] = useState(props.paymentDetails.remark);
  const [summaryAmounts, setSummaryAmounts] = useState({
    totalAmount: 0,
    subTotal: 0,
    totalTax: 0,
  });
  const [payments, setPayments] = useState([
    {
      paymentMode1Amount: 0,
      paymentMode1: 'cash',
    },
    {
      paymentMode2Amount: 0,
      paymentMode2: 'cash',
    },
    {
      financeAmount: 0,
      emiAmount: 0,
      downPayment: 0,
      numberOfMonths: 1,
      startDateEmi: new Date().toISOString().split('T')[0],
    },
  ]);
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceId: null,
    billId: null,
    shareInvoice: null,
  });
  const [balance, setBalance] = useState(0);
  const [billingPayload, setBillingPayload] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const financeSections = ['downPayment', 'startDateEmi', 'numberOfMonths'];
  const [financeError, setFinanceError] = useState(false);
  const [monthsError, setMonthsError] = useState(false);

  const [showRewardModal, setShowRewardModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (props.paymentDetails && Object.keys(props.paymentDetails).length > 0) {
      const {
        paymentMode1Amount,
        paymentMode2Amount,
        paymentMode1,
        paymentMode2,
        financeAmount,
        emiAmount,
        downPayment,
        numberOfMonths,
        startDateEmi,
        remark,
      } = props.paymentDetails;
      let tp = payments;
      let p1 = { paymentMode1, paymentMode1Amount, remark };
      let p2 = { paymentMode2, paymentMode2Amount, remark };
      let fp = {
        financeAmount,
        emiAmount,
        downPayment,
        numberOfMonths,
        startDateEmi,
      };
      if ([undefined, null, ''].includes(remark) === false) {
        p1 = { ...p1, remark };
        p2 = { ...p2, remark };
      }
      tp[0] = p1;
      tp[1] = p2;
      tp[2] = fp;
      setPayments(tp);
      if (paymentMode1 == 'finance') setFinanceSelected(true);
      setMultiPaymentOption(paymentMode2Amount > 0);
    }
    setIsGstBill(isGstUser);
  }, []);

  useEffect(() => {
    if (props.isEditing[0]) {
      setIsEditing(props.isEditing[0]);
      setIsGstBill(
        props.isEditing[0]
          ? props.paymentDetails.billtype === 'GST'
            ? true
            : false
          : isGstUser
      );
      setIgstSelected(props.paymentDetails.gstType !== 'IGST');
    }
  }, [props.isEditing]);

  useEffect(() => {
    getTotalAmount();
  }, [isGstBill]);

  useEffect(() => {
    getBalance();
  }, [payments]);

  useEffect(() => {
    getTotalAmount();
  }, [selectedProducts, igstSelected]);

  const getBalance = updatedTotal => {
    // updatedTotal : getting old value in "t"
    let { paymentMode1Amount: p1a } = payments[0];
    let { paymentMode2Amount: p2a } = payments[1];
    let t = summaryAmounts['totalAmount'];
    let pbu = p1a + (multiPaymentOption ? p2a : 0);
    setBalance(pbu - (updatedTotal ? updatedTotal : t));
  };

  const [financeSelected, setFinanceSelected] = useState(false);
  const handleSelection = (value, from) => {
    analyticFunc(eventsPayload.Billing[14]);
    setFinanceSelected(value == 'finance');
    let tp = [...payments];
    let i = from === 'first' ? 0 : 1;
    let k = tp[i];
    k[`paymentMode${i + 1}`] = value;
    if (value == 'finance') {
      k = tp[2];
      k['financeAmount'] = summaryAmounts.totalAmount - k['downPayment'];
      k['emiAmount'] =
        (summaryAmounts.totalAmount - k['downPayment']) / k['numberOfMonths'];
    }
    setPayments(tp);
  };

  const handleInputChange = (value, from, section) => {
    let tp = [...payments];
    let i = financeSections.includes(section) ? 2 : from === 'first' ? 0 : 1;
    let k = tp[i];
    if (section == 'downPayment') {
      setFinanceError(false);
      let g = Number(summaryAmounts.totalAmount) - Number(value);
      if (!isNaN(g))
        if (g > 0) {
          k['financeAmount'] = g;
          k['emiAmount'] = g / k['numberOfMonths'];
          k[section] = Number(value);
        } else setFinanceError(true);
    } else if (section == 'numberOfMonths') {
      setMonthsError(false);
      let d = Number(value);
      if (!isNaN(Number(value))) {
        if (d == 0) {
          setMonthsError(true);
        }
        k[section] = d;
        k.emiAmount = k.financeAmount / d;
      }
    } else {
      k[section] = ['remark', 'startDateEmi'].includes(section)
        ? value
        : Number(value);
      if (section === 'remark') setRemarks(value);
    }
    setPayments(tp);
  };

  const addFieldsToProduct = product => {
    product['billtype'] = isGstBill ? 'GST' : 'NOGST';
    var priceToUse =
      (product.sellingPrice ? product.sellingPrice : 0) *
      product.inCartQuantity;
    var tax = (priceToUse * (isGstBill ? product.gst * 0.01 : 1)).toFixed(2);
    var amountBeforeTaxCal = priceToUse - tax;

    let amountKV = { amount: priceToUse };
    let cgstAmountKV = { cgstAmount: igstSelected ? 0 : tax * 0.5 };
    let sgstAmountKV = { sgstAmount: igstSelected ? 0 : tax * 0.5 };
    let igstAmountKV = { igstAmount: igstSelected ? tax : 0 };
    let amountBeforeTaxKV = { amountBeforeTax: amountBeforeTaxCal };
    let invoiceDateKV = { invoice_date: props.invoiceDetails.date };

    // product = {...product,...amountKV};
    // product = {...product, ...cgstAmountKV};
    // product = {...product, ...sgstAmountKV};
    // product = {...product, ...igstAmountKV};
    // product = {...product, ...amountBeforeTaxKV};
    // product = {...product, ...invoiceDateKV};

    product.amount = priceToUse;
    product.cgstAmount = isGstBill
      ? !igstSelected
        ? 0
        : Number(tax * 0.5)
      : 0;
    product['sgstAmount'] = isGstBill
      ? !igstSelected
        ? 0
        : Number(tax * 0.5)
      : 0;
    product.igstAmount = isGstBill ? (!igstSelected ? Number(tax) : 0) : 0;
    product.amountBeforeTax = isGstBill ? amountBeforeTaxCal : priceToUse;
    let fodr = props.invoiceDetails?.date?.split('T');
    product['invoice_date'] = fodr[0];
    product['quantity'] = product.modelQuantity ? product.modelQuantity : 1;
    product.gstPercent = isGstBill
      ? product.gst
        ? product.gst.toString()
        : '9'
      : '0';
    product['quantity'] = product.inCartQuantity;
    product['categoryId'] = product.categoryId ? product.categoryId : 999;
    product['brandId'] = product.brandId ? product.brandId : 999;
    product['categoryName'] = product.categoryName
      ? product.categoryName
      : 'NOT KNOWN';
    product['brandName'] = product.brandName ? product.brandName : 'NOT KNOWN';
  };

  const getTotalAmount = () => {
    //! Pass & Change the inCartQuantity from 1 to whatever is there from the product details
    let productAmount = 0;
    let tax = 0;

    selectedProducts.map(product => {
      const priceToUse = product.sellingPrice ? product.sellingPrice : 0;
      const productQty = product.inCartQuantity
        ? product.inCartQuantity
        : product.quantity;

      const priceBeforeTax = priceToUse / (1 + product.gst * 0.01);
      productAmount += priceToUse * productQty;
      tax += (priceToUse - priceBeforeTax) * productQty;

      addFieldsToProduct(product);
    });
    let tp = {
      totalTax: isGstBill ? tax.toFixed(2) : 0,
      subTotal: isGstBill
        ? (productAmount - tax).toFixed(2)
        : productAmount.toFixed(2),
      totalAmount: productAmount.toFixed(2),
    };
    if (balance) getBalance(productAmount.toFixed(2));
    const x = payments;
    const d = x[0];
    const t = x[2];
    d.paymentMode1Amount = productAmount.toFixed(2);
    if (financeSelected) {
      let v = productAmount.toFixed(2) - t.downPayment;
      t.financeAmount = v;
      t.emiAmount = v / t.numberOfMonths;
    }
    setPayments(x);

    setSummaryAmounts(tp);
  };

  const generatePayload = () => {
    try {
      let fodr = '0';
      try {
        fodr = props.invoiceDetails.date.split('T');
      } catch (e) {}
      let payload = {
        billtype: isGstBill ? 'GST' : 'NOGST',
        billNo: props.invoiceDetails.billNo,
        invoice_date: fodr[0],
        customerMobileNumber: props.selectedContact.mobileNumber,
        totalAmount: summaryAmounts.totalAmount,
      };
      if (financeSelected)
        payload = { ...payload, paymentMode1: 'finance', ...payments[2] };
      else payload = { ...payload, ...payments[0] };

      if (payments[1].paymentMode2Amount)
        payload = { ...payload, ...payments[1] };

      let customerIdKV = { customerId: props.selectedContact.customerId };
      if (props.selectedContact.customerId)
        payload = { ...payload, ...customerIdKV };
      else
        payload = {
          ...payload,
          customerId: 7, // CUSTOMER_ID of GUEST user
          customerMobileNumber: '9999999999',
        };

      payload = { ...payload, productDetails: selectedProducts };

      payload.productDetails.forEach(product => {
        if (!!product['imei'] && typeof product['imei'] === 'string') {
          product['imei'] = removeWhiteSpaces(product['imei']);
        }

        if (!product['imei'] || typeof product['imei'] !== 'string') {
          delete product['imei'];
        }
      });

      setBillingPayload(payload);
    } catch (err) {
      console.log('err is ', err);
    }
  };

  const validateEachIMEI = async () => {
    try {
      let imei = '';
      props.selectedProducts.forEach(product => {
        if (typeof product['imei'] === 'string' && !!product['imei']) {
          imei += `${product['imei']},`;
        }
      });

      if (imei.endsWith(',')) imei = imei.slice(0, -1);

      imei = removeWhiteSpaces(imei);

      if (!imei) {
        return true;
      }

      let payload = { imei, retailerId: props.retailerId };

      if (isEditing) {
        const invoiceID = props.paymentDetails.invoiceId;
        payload = { ...payload, invoiceID: String(invoiceID) };
      }

      let isIMEIUnique = true;

      await props.isValidIMEI(payload, _isIMAIUnique => {
        isIMEIUnique = _isIMAIUnique;
      });

      return isIMEIUnique;
    } catch (err) {
      return false;
    }
  };

  const hhandle = async () => {
    const isUniqueIMEIs = await validateEachIMEI();
    if (isUniqueIMEIs) generatePayload();
  };

  const showInvoice = () => {
    history.push(
      `/invoice?billId=${invoiceDetails.billId}&invoiceId=${invoiceDetails.invoiceId}`
    );
  };

  useEffect(() => {
    if (billingPayload.billtype)
      if (isEditing) handleEditInvoice();
      else handleSubmitInvoice();
  }, [billingPayload]);

  const handleSubmitInvoice = useCallback(async () => {
    // event.preventDefault();
    analyticFunc(eventsPayload.Billing[15]);
    /* original condition inside if
    ** 
      props.selectedProducts.length !== 0 &&
      props.selectedContact //&&
      Object.keys(props.selectedContact).length !== 0
    */
    if (
      props.selectedProducts.length !== 0 &&
      props.selectedContact //&&
      // Object.keys(props.selectedContact).length !== 0
    ) {
      await props
        .sendBillingDetails(billingPayload)
        .then(res => {
          const { data } = res.data;
          setRewardPoints(data.rewards.points);
          const invoiceRes = data.invoiceDetails[0];
          const invoicePayload = {
            billId: invoiceRes.serialNo,
            invoiceId: invoiceRes.invoiceId,
            shareInvoice: true,
          };
          props.resetBillingState();
          props.setGetUserId(invoicePayload);
          setInvoiceDetails({
            invoiceId: invoicePayload.invoiceId,
            billId: invoicePayload.billId,
            shareInvoice: true,
          });
          if (!isEditing) setShowRewardModal(!showRewardModal);
          // history.push(`/Invoice`);
          // history.push(
          //   `/invoice?billId=${invoicePayload.billId}&invoiceId=${invoicePayload.invoiceId}`
          // );
        })
        .catch(err => {
          toasterMessage('error', err.response.data.error);
        })
        .catch(() => {
          toasterMessage('error', 'Something bad happened');
        });
    } else {
      toasterMessage('error', 'Select Contact or add products to list');
    }
  });

  const handleEditInvoice = useCallback(async () => {
    // event.preventDefault();
    if (
      props.selectedProducts.length !== 0 &&
      props.selectedContact &&
      Object.keys(props.selectedContact).length !== 0
    ) {
      let editPayload = {
        ...billingPayload,
        invoiceId: props.paymentDetails.invoiceId,
      };

      await props
        .sendEditBillingDetails(editPayload)
        .then(res => {
          const { data } = res;
          const invoiceRes = data.data.invoiceDetails[0];
          let invoicePayload = {
            billId: invoiceRes.serialNo,
            invoiceId: invoiceRes.invoiceId,
            shareInvoice: true,
          };
          props.resetBillingState();
          props.setGetUserId(invoicePayload);
          history.push(
            `/invoice?billId=${invoicePayload.billId}&invoiceId=${invoicePayload.invoiceId}`
          );
        })
        .catch(err => {
          toasterMessage('error', err.response.data.error);
        })
        .catch(e => {
          toasterMessage('error', 'Something bad happened');
        });
    } else {
      toasterMessage('error', 'Select Contact or add products to list');
    }
  });

  return (
    <>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderLeft: '1px solid #D9DBE9',
        }}
        maxWidth="md"
      >
        {isGstBill && (
          <div>
            <p className="formTitle">GST Type</p>
            <Button
              onClick={() => setIgstSelected(!igstSelected)}
              style={{
                border: igstSelected ? '0.13rem solid #FE805C' : '',
                marginRight: '0.4rem',
              }}
              variant="outlined"
            >
              CGST & SGST
            </Button>
            <Button
              onClick={() => setIgstSelected(!igstSelected)}
              style={{ border: !igstSelected ? '0.13rem solid #FE805C' : '' }}
              variant="outlined"
            >
              IGST
            </Button>
          </div>
        )}
        <div>
          <p className="formTitle">SUMMARY</p>
          <SummaryLine title={'SubTotal'} amount={summaryAmounts.subTotal} />
          {isGstBill && (
            <div>
              <SummaryLine title={'Taxes'} amount={summaryAmounts.totalTax} />
              {igstSelected && (
                <>
                  <SummaryLine
                    title={'CGST'}
                    amount={summaryAmounts.totalTax * 0.5}
                    subtitle="true"
                  />
                  <SummaryLine
                    title={'SGST'}
                    amount={summaryAmounts.totalTax * 0.5}
                    subtitle="true"
                  />
                </>
              )}
              {!igstSelected && (
                <SummaryLine
                  title={'IGST'}
                  amount={summaryAmounts.totalTax}
                  subtitle="true"
                />
              )}
            </div>
          )}
          <SummaryLine title={'Total'} amount={summaryAmounts.totalAmount} />
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '0.1fr 0.6fr 0.1fr 0.2fr',
          }}
        >
          <p style={{ gridRow: '1', gridColumn: '1/-1' }} className="formTitle">
            Choose Payment Method
          </p>
          <PaymentMethod
            from={'first'}
            payments={payments}
            financeError={financeError}
            monthsError={monthsError}
            remarks={remarks}
            handleSelection={handleSelection}
            handleInputChange={handleInputChange}
          />
          {multiPaymentOption && (
            <PaymentMethod
              from={'second'}
              payments={payments}
              financeError={financeError}
              monthsError={monthsError}
              remarks={remarks}
              handleSelection={handleSelection}
              handleInputChange={handleInputChange}
            />
          )}
          {/* <span
            style={{
              color: "#FE805C",
              fontWeight: "bold",
              fontSize: "1rem",
              gridColumn: "1",
              gridRow: "3",
            }}
            onClick={() => setMultiPaymentOption(!multiPaymentOption)}
          >
            {multiPaymentOption ? "-" : "+"} Multiple Payment Methods
          </span> */}
          <div
            style={{
              gridRow: '4',
              gridColumn: '1/-1',
              display: 'grid',
              gridTemplateColumns: '0.2fr auto',
              alignItems: 'center',
              padding: '1rem',
            }}
          >
            <p>
              {financeSelected ? 'EMI Amount' : 'Balance'}{' '}
              <span
                className={
                  financeSelected
                    ? 'text-success'
                    : balance > 0
                    ? 'text-success'
                    : 'text-danger'
                }
              >
                {financeSelected
                  ? isNaN(payments[2].emiAmount)
                    ? 'NA'
                    : payments[2].emiAmount
                  : balance}
              </span>
            </p>
            <Button
              onClick={() => hhandle()}
              variant="contained"
              color="primary"
            >
              {isEditing ? 'Edit Invoice' : 'Save Invoice'}
            </Button>
          </div>
        </div>
      </Container>
      {showRewardModal && (
        <RewardModal
          toggleModal={showRewardModal}
          changeInPoints={rewardPoints}
          showInvoice={showInvoice}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => {
  return {
    invoiceDetails: BillingReducer.invoiceDetails,
    selectedContact: BillingReducer.selectedContact,
    paymentDetails: BillingReducer.paymentDetails,
    isEditing: BillingReducer.isEditing,
    selectedProducts: BillingReducer.selectedProducts,
    retailerId: OtpLoginReducer.userId,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    sendBillingDetails: payload => dispatch(sendBillingDetails(payload)),
    setGetUserId: payload => dispatch(setGetUserId(payload)),
    resetBillingState: () => dispatch(resetBillingState()),
    sendEditBillingDetails: payload =>
      dispatch(sendEditBillingDetails(payload)),
    isValidIMEI: (payload, cb) => dispatch(isValidIMEI(payload, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingSummary);
