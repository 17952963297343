/* eslint-disable */
import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';

export const getSuperCategories = payload => async dispatch => {
  dispatch({ type: 'SET_LOADING', payload: true });
  await api
    .get('admin/inventory/superCategory/getSuperCategory')
    .then(response => {
      const { data } = response.data;
      if (response.status === 200) {
        dispatch({ type: 'SUPER CATEGORIES', payload: data });
      }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: 'RESET_CATEGORIES' });
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happenned');
      dispatch({ type: 'RESET_CATEGORIES' });
    });
  dispatch({ type: 'SET_LOADING', payload: false });
};

export function resetcategories() {
  return {
    type: 'RESET_CATEGORIES',
  };
}
