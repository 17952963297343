/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import { SALES_DETAILS, SET_LOADING } from './types';

export const verifyOtp = (payload, fromRegister) => async dispatch => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    await api.post('user/otpVerification', payload);
    dispatch({ type: 'VERIFY_OTP', payload: true });
    fromRegister &&
      dispatch({ type: 'UPDATE_REGISTRATION', payload: { isRegister: 3 } });
    dispatch({ type: SET_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: false });
    toasterMessage('error', error.response.data.error);
  }
};

export const getSalesAnalysis = payload => async dispatch => {
  try {
    const response = await api.post('ledger/saleAnalysisDetails', payload);
    if (response.data.data.report.length < 1)
      toasterMessage('error', 'No Sales Data present yet');
    dispatch({ type: SALES_DETAILS, payload: response.data.data });
  } catch (error) {
    toasterMessage('error', error.response.data.error);
  }
};

export const addExpenseCategory = payload => async () => {
  try {
    await api.post('expenses/addExpenseCategory', payload);
  } catch (error) {
    toasterMessage('error', error.response.data.error);
  }
};

export const editExpense = payload => async () => {
  try {
    await api.post('expenses/editExpense', payload);
  } catch (error) {
    toasterMessage('error', error.response.data.error);
  }
};

export const addExpense = payload => async () => {
  try {
    await api.post('expenses/addExpense', payload);
  } catch (error) {
    toasterMessage('error', error.response.data.error);
  }
};

export const sendSalesReport = (payload, cb) => () => {
  api
    .post('ledger/sendSalesReport', payload)
    .then(response => {
      cb && cb(true);
      toasterMessage('success', response.data.message);
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
