/* eslint-disable dot-notation */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  Card,
  IconButton,
  Typography,
  Link,
  Avatar,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';
import { sendOtpForAddSubuser } from '../../../store/AdminActions/otpLoginAction';
import {
  addSubUsers,
  editSubUsers,
  updateRetailerDetails,
  getSubRetailer,
  deleteSubRetailer,
} from '../../../store/RetailerWebStore/RetailerAction/addSubUser';
import CustomInput from '../../../CommonComponents/customInput';
import addSubUserBG from '../../../assets/svg/addSubUserBG.svg';
import Loader from '../../Loader';
import { toasterMessage } from '../../../@utils/utils';
import DeleteUserModal from './deleteUserModal';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const EDITSUBRETAILER = 'EDITSUBRETAILER';
const ADDSUBRETAILER = 'ADDSUBRETAILER';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

const useStyles = makeStyles(theme => ({
  noBorder: {
    border: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '90vh',
    minWidth: '60vw',
  },
  textArea: {
    padding: '0.5em',
    marginBottom: '1em',
    background: '#EFF0F6',
    [`& fieldset`]: {
      borderRadius: '1em',
    },
  },
  text: {
    textAlign: 'center',
    fontFamily: theme.palette.typography.fontFamily,
  },
  cardHeadingText: {
    fontFamily: theme.palette.typography.fontFamily,
    color: '#6E7191',
    fontWeight: '500',
  },
  cardSubHeadingText: {
    fontFamily: theme.palette.typography.fontFamily,
    color: '#AFAFBD',
  },
  resendOTPLink: {
    textAlign: 'start',
    textDecoration: 'none',
  },
  resendOTPText: {
    fontFamily: theme.palette.typography.fontFamily,
  },
  btn: {
    marginTop: '1em',
  },

  cardStyle: {
    borderRadius: '1em',
    marginBottom: '1em',
  },
  cardFlexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '4rem',
  },
  avatar: {
    margin: '0em 0.5em',
    fontWeight: '600',
    fontFamily: 'poppins',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  editButton: {
    background: '#FFF4DF',
    color: '#F4B740',
    borderRadius: '0',
    height: '100%',
    width: '2em',
  },
  deleteButton: {
    background: '#FFF3F8',
    color: '#C30052',
    borderRadius: '0',
    height: '100%',
    width: '2em',
  },
  imgStyle: {
    marginTop: '4em',
    Width: '10em',
    height: '10em',
    marginBottom: '2em',
  },
}));

const AddSubUser = props => {
  const {
    sendOtp,
    addSubUsers,
    editSubUsers,
    getSubUsers,
    subUsersList,
    deleteSubUsers,
    loading,
  } = props;
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [showSubusers, setShowSubUsers] = useState(true);
  const [isAddSubUser, setIsAddSubUser] = useState(true);
  const [subUser, setSubUser] = useState({
    name: '',
    contactNumber: '',
    userId: '',
  });
  const [toggleModal, setToggleModal] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  // callback after submiting name and phone
  const openEnterOtp = () => {
    setIsOtpSent(true);
    setCounter(30);
  };

  // after submiting OTP callback function
  const afterSubmitCb = () => {
    setShowSubUsers(true);
    setIsAddSubUser(true);
    setIsOtpSent(false);
    setSubUser('');
  };

  const handleSendOtp = async () => {
    const regexForPhone = /^\d{10}$/;
    const regexForName = /^([a-zA-Z ]){2,30}$/;
    if (
      regexForName.test(subUser.name) &&
      regexForPhone.test(subUser.contactNumber)
    ) {
      setIsNameValid(true);
      setIsPhoneValid(true);
      const payload = {
        contactNumber: subUser.contactNumber,
        type: isAddSubUser ? ADDSUBRETAILER : EDITSUBRETAILER,
      };
      if (!isAddSubUser) payload['userId'] = subUser.userId;
      try {
        await sendOtp(payload, openEnterOtp);
      } catch (error) {
        // toasterMessage('error', 'Phone number already exists');
      }
    } else {
      setIsOtpSent(false);
      setIsPhoneValid(regexForPhone.test(subUser.contactNumber));
      setIsNameValid(regexForName.test(subUser.name));
    }
  };

  const handleSubmit = async () => {
    const payload = {
      name: subUser.name,
      contactNumber: subUser.contactNumber,
      otp,
    };
    if (!isAddSubUser) payload['userId'] = subUser.userId;
    if (isAddSubUser) await addSubUsers(payload, afterSubmitCb);
    else await editSubUsers(payload, afterSubmitCb);
  };

  const openDeleteModal = currSubUser => {
    setSubUser({
      name: `${currSubUser.firstName} ${currSubUser.lastName}`,
      contactNumber: currSubUser.contactNumber,
      userId: currSubUser.userId,
    });
    setToggleModal(true);
  };

  const handleEditSubUser = user => {
    setSubUser({
      name: `${user.firstName} ${user.lastName}`,
      contactNumber: user.contactNumber,
      userId: user.userId,
    });
    setIsAddSubUser(false);
    setShowSubUsers(false);
  };

  const handleDeleteUser = async userId => {
    const payload = { userId };
    await deleteSubUsers(payload);
    await getSubUsers();
  };

  const resendOTP = () => {
    if (counter === 0) {
      handleSendOtp();
    }
  };

  useEffect(() => {
    getSubUsers();
  }, []);

  useEffect(() => {
    getSubUsers();
  }, [showSubusers]);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => setCounter(preValue => preValue - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <div className="fixedCenterDiv" style={{ marginTop: '5em' }}>
        <Container maxWidth="xs" className="flexedCenterContainer">
          {showSubusers === false ? (
            <>
              <CustomInput
                error={!isNameValid}
                divStyle={{ padding: '0.5rem' }}
                autoFocus
                disabled={isOtpSent}
                inputLabel={'Name'}
                value={subUser.name}
                onChange={e => {
                  setSubUser({ ...subUser, name: e.target.value });
                }}
              />

              <CustomInput
                error={!isPhoneValid}
                divStyle={{ padding: '0.5rem' }}
                inputLabel={'Mobile Number'}
                disabled={isOtpSent}
                value={subUser.contactNumber}
                onChange={e => {
                  setSubUser({ ...subUser, contactNumber: e.target.value });
                }}
              />

              {isOtpSent && (
                <CustomInput
                  divStyle={{ padding: '0.5rem' }}
                  autoFocus
                  inputLabel={'OTP'}
                  onChange={e => {
                    e.preventDefault();
                    setOtp(e.target.value);
                  }}
                />
              )}
              <Button
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  if (!isOtpSent) {
                    analyticFunc(eventsPayload.AddSubUser[2]);
                    handleSendOtp();
                  } else {
                    handleSubmit();
                  }
                }}
                color="primary"
              >
                {!isOtpSent ? 'Get OTP' : 'Add'}
              </Button>

              {isOtpSent && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '1em',
                      marginBottom: '1em',
                    }}
                  >
                    <Typography>OTP sent to {subUser.contactNumber}</Typography>
                    <Link
                      component="button"
                      onClick={() => {
                        setIsOtpSent(false);
                        setCounter(0);
                      }}
                      className={classes.resendOTPText}
                    >
                      Edit Number
                    </Link>
                  </div>
                  <Link
                    component="button"
                    color="primary"
                    disabled={counter > 0}
                    onClick={() => resendOTP()}
                    className={classes.resendOTPLink}
                  >
                    {counter !== 0 ? (
                      <Typography className={classes.resendOTPText}>
                        resend OTP (00:
                        {counter <= 9 ? `0${counter}` : `${counter}`})
                      </Typography>
                    ) : (
                      <Typography className={classes.resendOTPText}>
                        resend OTP
                      </Typography>
                    )}
                  </Link>
                </>
              )}
            </>
          ) : (
            <>
              {subUsersList.length !== 0 ? (
                subUsersList.map((item, id) => (
                  <Card key={item.userId} className={classes.cardStyle}>
                    <div className={classes.cardFlexBox}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar
                          className={classes.avatar}
                          style={{
                            background: stringToColor(`${item.firstName}`),
                          }}
                        >
                          {`${item.firstName[0]}`}
                        </Avatar>
                        <div>
                          <Typography
                            variant="subtitle1"
                            className={classes.cardHeadingText}
                          >
                            {`${item.firstName} ${item.lastName}`}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.cardSubHeadingText}
                          >
                            {item.contactNumber}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.buttonGroup}>
                        <IconButton
                          aria-label="settings"
                          onClick={() => handleEditSubUser(item)}
                          className={classes.editButton}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => openDeleteModal(item)}
                          aria-label="settings"
                          className={classes.deleteButton}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <>
                  <img
                    src={addSubUserBG}
                    alt="No subuser"
                    className={classes.imgStyle}
                  />
                  <Typography
                    variant="h6"
                    className={classes.text}
                    style={{ fontWeight: '500' }}
                  >
                    No sub users added yet
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.text}
                    style={{ color: '#AFAFBD' }}
                  >
                    Add first users here
                  </Typography>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={() => {
                  analyticFunc(eventsPayload.AddSubUser[1]);
                  setShowSubUsers(!showSubusers);
                }}
              >
                Add Sub-user
              </Button>
            </>
          )}
        </Container>
      </div>

      {!!toggleModal && (
        <DeleteUserModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          handleDeleteUser={handleDeleteUser}
          subUser={subUser}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ AddSubUser, Loader }) => ({
  subUsersList: AddSubUser.subUserDetails,
  loading: Loader.loading,
});

const mapDispatchToProps = dispatch => ({
  sendOtp: (payload, cb) => dispatch(sendOtpForAddSubuser(payload, cb)),
  addSubUsers: (payload, cb) => dispatch(addSubUsers(payload, cb)),
  editSubUsers: (payload, cb) => dispatch(editSubUsers(payload, cb)),
  getSubUsers: () => dispatch(getSubRetailer()),
  deleteSubUsers: payload => dispatch(deleteSubRetailer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubUser);
