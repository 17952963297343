import api, { baseURL } from '../../../api/api';
import Axios from 'axios';

export const getPdf = (payload, cb) => async dispatch => {
  return Axios.post(`${baseURL.baseURL}rewardPoints/getSurveyData`, payload);
};

export const postSurvey = (payload, cb) => async dispatch => {
  return Axios.post(`${baseURL.baseURL}survey/getInvoiceUrl`, payload);
};
