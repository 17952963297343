import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  sendOtp,
  customLogin,
  otpSentInform,
} from './../../store/AdminActions/otpLoginAction';
import GoogleLogin from 'react-google-login';
import {
  REACT_APP_FACEBOOK_ID,
  REACT_APP_GOOGLE_ID,
} from './../../@utils/config';
import logo_small from './../../assets/svg/logoSmall.svg';
import logo_google from './../../assets/svg/logoGoogle.svg';
import logo_fb from './../../assets/svg/logoFb.svg';
import underUpdate from './../../assets/svg/underUpdate.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import CustomInput from '../../CommonComponents/customInput';
import { toasterMessage } from '../../@utils/utils';
import analyticFunc from '../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../GoogleAnalytics/events_payload';
import ENABLE_FEATURE from '../../features';

const useStyles = makeStyles(theme => ({
  logoImage: {
    height: '15%',
    width: '30%',
    margin: '0 auto',
  },
  customLogoContainer: {
    width: '20%',
    padding: '12px',
    margin: '0px 24px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  customContainer: {
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center',
  },
}));

const CustomLoginLogo = ({ contStyle, logoStyle, props, logo, onClick }) => (
  <div
    className={contStyle}
    onClick={() => {
      onClick();
      props.onClick();
    }}
    disabled={props.disabled}
  >
    <img className={logoStyle} alt="logo" src={logo} />
  </div>
);

const OtpLogin = React.memo(props => {
  const classes = useStyles();

  const [mobileNumber, setMobileNumber] = useState('');
  const [referrer_link, setReferrerLink] = useState(null);
  // const [error, setError] = useState(false);

  const responseFacebook = useCallback(res => {
    let loginPlatform = 'Facebook';

    let payload = {
      email: res.email,
      socialId: res.userID,
      loginType: loginPlatform,
    };
    if (referrer_link) payload = { ...payload, referrer_link };

    props.customLogin(payload, loginPlatform);
  }, []);

  const googleLoginSuccess = useCallback(res => {
    let loginPlatform = 'Google';
    const { email, googleId } = res.profileObj;
    let payload = {
      email: email,
      socialId: googleId,
      loginType: loginPlatform,
    };

    if (referrer_link) payload = { ...payload, referrer_link };

    props.customLogin(payload, loginPlatform);
  }, []);

  const googleLoginFailure = response => {
    // console.log("Failure");
    // console.log(response);
    // Do nothing
  };

  const handleAdminLogin = () => {
    props.history.push('/admin-login');
  };

  useEffect(() => {
    const url = window.location.href;
    const index = url.indexOf('?');
    if (index !== -1) {
      setReferrerLink(url.substring(index + 1));
    }

    let userType = '';
    if (props.user && props.user.userData && props.user.userData.length) {
      userType = props.user.userData[0].userType ?? '';
    }
    if (props.userToken) {
      if (userType.toLowerCase() === 'admin' || props.isOnboarded)
        props.history.push('/dashboard');
      else if (userType.toLowerCase() === 'retailer')
        props.history.push('/onboarding');
    }
  }, []);

  const sendOtp = useCallback(async event => {
    analyticFunc(eventsPayload.Onboarding[2]);
    event.preventDefault();

    var phonenoRegex = /^\d{10}$/;
    if (phonenoRegex.test(mobileNumber)) {
      let payload = {
        contactNumber: mobileNumber,
        type: 'LOGIN',
      };

      await props
        .sendOtp(payload)
        .then(response => {
          // console.log(response);
          const { data } = response.data;
          let pay = { ...data, userContactNumber: mobileNumber };
          props.otpSentInform(pay);
          let queryParam = '';
          if (referrer_link) queryParam = `?${referrer_link}`;
          props.history.push(`/verifyOtp${queryParam}`);
        })
        .catch(error => {
          toasterMessage('error', error.response.data.error);
        })
        .catch(() => {
          toasterMessage('error', 'Something bad happened');
        });
    } else {
      // Show error in textfield
      // setError(true);
    }
  });

  return (
    <div className="flexedCenterDiv">
      <Container className="flexedCenterContainer" maxWidth="xs">
        {ENABLE_FEATURE.RETAILER_LOGIN && (
          <>
            <img
              className={classes.logoImage}
              src={logo_small}
              alt="Logo of Company"
            />
            <div>
              <p className="formTitle">Login to your account</p>
              <CustomInput
                divStyle={{ padding: '0.5rem' }}
                autoFocus={true}
                inputLabel={'Mobile Number'}
                onChange={e => {
                  setMobileNumber(e.target.value);
                }}
              />
              {/* <p style={{visibility: {error}}}>Please enter a valid number.</p> */}
              <Button
                fullWidth
                className="mt-3"
                color="primary"
                variant="contained"
                onClick={sendOtp}
                disabled={mobileNumber.length !== 10 ? true : false}
              >
                Get OTP
              </Button>
            </div>

            <div>
              <p style={{ textAlign: 'center' }}>- or login with -</p>
              <div className={classes.customContainer}>
                <GoogleLogin
                  clientId={REACT_APP_GOOGLE_ID}
                  onSuccess={googleLoginSuccess}
                  onFailure={googleLoginFailure}
                  render={renderProps => (
                    <CustomLoginLogo
                      contStyle={classes.customLogoContainer}
                      logoStyle={classes.customLoginLogo}
                      props={renderProps}
                      logo={logo_google}
                      onClick={() => {
                        analyticFunc(eventsPayload.Onboarding[0]);
                      }}
                    />
                  )}
                />
                <FacebookLogin
                  appId={REACT_APP_FACEBOOK_ID}
                  fields="email"
                  callback={responseFacebook}
                  render={renderProps => (
                    <CustomLoginLogo
                      contStyle={classes.customLogoContainer}
                      logoStyle={classes.customLoginLogo}
                      props={renderProps}
                      logo={logo_fb}
                      onClick={() => {
                        analyticFunc(eventsPayload.Onboarding[1]);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
        {!ENABLE_FEATURE.RETAILER_LOGIN && (
          <div
            className="my-auto"
            style={{
              textAlign: 'center',
            }}
          >
            <p className="formTitle" style={{ textAlign: 'center' }}>
              Web Version is getting updated and will be active soon, click{' '}
              <a
                style={{ color: '#FE805C', fontSize: '1.2rem' }}
                href="https://play.google.com/store/apps/details?id=mbillapp.com&hl=en_US&gl=US"
              >
                HERE
              </a>{' '}
              to use our app to create bills!
            </p>
            <img src={underUpdate} />
            {/* <p style={{ position: "relative", bottom: "0", marginTop: "4rem" }}>
            Sign in as{" "}
            <a style={{ color: "#FE805C" }} href="" onClick={handleAdminLogin}>
              admin
            </a>
          </p> */}
          </div>
        )}
      </Container>
    </div>
  );
});

OtpLogin.propTypes = {
  otpSent: PropTypes.bool,
  isOnboarded: PropTypes.bool.isRequired,
  userContactNumber: PropTypes.string,
  userEmailId: PropTypes.string,
};

// For getting global state of variables
const mapStateToProps = ({ OtpLoginReducer }) => {
  return {
    userContactNumber: OtpLoginReducer.userContactNumber,
    userEmailId: OtpLoginReducer.userEmailId,
    otpSent: OtpLoginReducer.otpSent,
    isOnboarded: OtpLoginReducer.isOnboarded,
    user: OtpLoginReducer.user,
    userToken: OtpLoginReducer.userToken,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    sendOtp: payload => dispatch(sendOtp(payload)),
    customLogin: (payload, platform) =>
      dispatch(customLogin(payload, platform)),
    otpSentInform: payload => dispatch(otpSentInform(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpLogin);
