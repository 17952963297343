import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MyModal from '../Modal';
import AddExpense from './addExpense';
import {
  getExpenseCategory,
  clearExpenseCategory,
  sendExpenseReport,
} from '../../../store/RetailerWebStore/RetailerAction/expenses';
import Loader from '../../Loader';
import imgLink from '../../../images/alt.jpg';
import ExpenseDetails from './expenseDetails';
import DateRange from './customDateRange';
import moment from 'moment';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Expense = ({
  getExpenseCategory,
  clearExpenseCategory,
  totalExpenses,
  expenseCategory,
  sendExpenseReport,
  getNotificationCount,
}) => {
  const classes = useStyles();
  const myRef = useRef();
  const listRef = useRef();
  const [expenseCategoryId, setExpenseCategoryId] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1),
    [startDate, setStartDate] = useState(`01-01-${new Date().getFullYear()}`),
    [endDate, setEndDate] = useState(new Date()),
    [focusedInput, setFocusedInput] = useState();
  const scrollref = useRef();

  useEffect(() => {
    getNotificationCount();
  }, []);

  const loadMore = useCallback(() => {
    if (expenseCategory.length < pageNumber * 20) return;
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight >=
      scrollref.current.scrollHeight
    ) {
      let pg = pageNumber + 1;
      getExpenseCategory({
        page: pg,
        numberOfItems: 20,
        fromDate: moment(startDate).format('YYYY-MM-DD'),
        toDate: moment(endDate).format('YYYY-MM-DD'),
      });
      setPageNumber(pg);
    }
  }, [loading, expenseCategory]);

  const onSuccess = useCallback(() => {
    setLoading(false);
  }, []);

  const handleModal = useCallback(
    (sDate, eDate) => {
      clearExpenseCategory();
      console.log(
        'startDate---->',
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );
      let payload = {
        page: 1,
        numberOfItems: 20,
        fromDate: sDate
          ? moment(sDate).format('YYYY-MM-DD')
          : moment(startDate).format('YYYY-MM-DD'),
        toDate: eDate
          ? moment(eDate).format('YYYY-MM-DD')
          : moment(endDate).format('YYYY-MM-DD'),
      };
      getExpenseCategory(payload, onSuccess);
      setLoading(true);
    },
    [onSuccess, clearExpenseCategory, getExpenseCategory]
  );

  // useEffect(() => {
  //   handleModal()
  // }, [])

  useEffect(() => {
    handleModal(startDate, endDate);
  }, [endDate]);

  const shareExpense = () => {
    console.log(
      'EXPENSE SHARE==== dates',
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD')
    );
    let payload = {
      fromDate: moment(startDate).format('YYYY-MM-DD'),
      toDate: moment(endDate).format('YYYY-MM-DD'),
    };
    sendExpenseReport(payload, onSuccess);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center p-md-4 p-sm-2"
      style={{ minHeight: '90vh' }}
    >
      <Paper className="p-4 expense">
        <Card className={classes.root}>
          <CardContent className="card-bg">
            <h3>Total</h3>
            <h2>
              Rs{' '}
              {totalExpenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </h2>
          </CardContent>
        </Card>

        <DateRange
          startDate={startDate}
          endDate={endDate}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <div
          style={{ maxHeight: '80vh', overflowY: 'scroll' }}
          ref={scrollref}
          onScroll={loadMore}
        >
          {expenseCategory &&
            expenseCategory.map(item => (
              <Paper
                key={item.expenseCategoryId}
                className="text-uppercase d-flex align-items-center m-2 p-2 btn"
                onClick={e => {
                  setExpenseCategoryId(item.expenseCategoryId);
                  listRef.current.click();
                }}
              >
                <img
                  src={item.categoryImage ? item.categoryImage : imgLink}
                  alt="alt-img"
                  width="50px"
                />
                &nbsp;&nbsp;
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <h4>{item.expenseCategoryName}</h4>
                  <h5>{item.categoryAmount}</h5>
                </div>
              </Paper>
            ))}
        </div>
        <br />
        {!loading ? (
          <div style={{ display: 'flex' }}>
            <button
              className="m-2 p-3 border-0 rounded-pill w-50 bg-dark text-white text-capitalize"
              onClick={e => {
                analyticFunc(eventsPayload.Expenses[1]);
                myRef.current.click();
              }}
            >
              add expense type
            </button>
            <button
              className="m-2 p-3 border-0 rounded-pill w-50 bg-dark text-white text-capitalize"
              onClick={() => {
                analyticFunc(eventsPayload.Expenses[9]);
                shareExpense();
              }}
            >
              share expense
            </button>
          </div>
        ) : (
          <Loader />
        )}
        <MyModal ref={listRef} close={() => handleModal(startDate, endDate)}>
          <ExpenseDetails
            expenseCategoryId={expenseCategoryId}
            fromDate={startDate}
            toDate={endDate}
          />
        </MyModal>

        <MyModal ref={myRef} close={() => handleModal(startDate, endDate)}>
          <AddExpense />
        </MyModal>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ Expenses }) => {
  const { expenseCategory, totalExpenses } = Expenses;
  return { expenseCategory, totalExpenses };
};
export default connect(mapStateToProps, {
  getExpenseCategory,
  clearExpenseCategory,
  sendExpenseReport,
  getNotificationCount,
})(Expense);
