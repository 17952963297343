import {
  GET_CUSTOMER_DETAILS,
  // ADD_CUSTOMER_DETAILS,
  ADD_PRODUCT_DETAILS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT_DETAILS,
  SET_ACTIVE_PAGE,
  MODE_OF_PAYMENT,
  SET_USER_ID,
  VIEW_INVOICE,
  SET_CUSTOMER_DETAILS,
  SET_PRODUCT_DETAILS,
  VIEW_MODE_OF_PAYMENT,
  SET_MODE_OF_PAYMENT,
} from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  customerDetails: [],
  getProductId: '',
  productDetails: [],
  activePage: 0,
  paymentMode: false,
  paymentDetails: '',
  invoice: '',
  billingStatus: '',
  invoiceDate: '',
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload,
      };
    }
    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload,
      };
    }
    // case ADD_CUSTOMER_DETAILS: {
    //   return {
    //     ...state,
    //     getProductId: action.payload.billId,
    //   }
    // }
    case SET_USER_ID: {
      console.log('getProductId======', action.payload);
      return {
        ...state,
        getProductId: action.payload,
      };
    }
    case ADD_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case UPDATE_PRODUCT: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case DELETE_PRODUCT: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case GET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case SET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.payload,
      };
    }
    case MODE_OF_PAYMENT: {
      return {
        ...state,
        paymentMode: true,
      };
    }
    case VIEW_MODE_OF_PAYMENT: {
      return {
        ...state,
        paymentDetails: action.payload,
      };
    }
    case SET_MODE_OF_PAYMENT: {
      return {
        ...state,
        paymentDetails: action.payload,
      };
    }
    // case VIEW_INVOICE: {
    //   return {
    //     ...state,
    //     invoice: action.payload,
    //   };
    // }
    case 'SET_BILLING_STATUS': {
      return {
        ...state,
        billingStatus: action.payload,
      };
    }
    case 'SET_INVOICE_DATE': {
      return {
        ...state,
        invoiceDate: action.payload,
      };
    }
    default:
      return state;
  }
};
export default billing;
