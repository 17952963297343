/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import { GST_OPTIONS, BRAND_OFFER_OPTIONS, SET_LOADING } from './types';

// export const setGSTOptions = (payload) => async (dispatch) => {
//     dispatch({ type: GST_OPTIONS, payload: payload })
// }

// export const setBrandOfferOptions = (payload) => async (dispatch) => {
//     dispatch({ type: BRAND_OFFER_OPTIONS, payload: payload })
// }

export const getBrandOffersandGstSlabs = () => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  await api
    .get('billing/getBrandOffersandGstSlabs')
    .then(response => {
      dispatch({ type: GST_OPTIONS, payload: response.data.data.gstSlabs });
      dispatch({
        type: BRAND_OFFER_OPTIONS,
        payload: response.data.data.brandOffers,
      });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    });
  dispatch({ type: SET_LOADING, payload: false });
};
