import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { toasterMessage } from '../../../../@utils/utils';
import { Button, List, Paper, Container } from '@material-ui/core';
import { getGiftCards, postRewardPoints } from '../rewardsAction';
import { useSelector, useDispatch } from 'react-redux';
import GiftCardInfo from './giftCardInfo';
import ConfirmationModal from '../confirmationModal';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'grid',
    gridTemplateRows: 'auto 0.1fr',
    padding: '1rem 3rem 0 3rem',
    borderLeft: `1px solid ${theme.palette.others.dividerColor}`,
  },
  giftCardCont: {
    display: 'grid',
    gridTemplateRows: '0.05fr auto',
    rowGap: '0.5rem',
  },
  checkoutCont: {
    display: 'grid',
    gridTemplateColumns: '0.2fr auto',
    alignItems: 'center',
    columnGap: '3rem',
  },
  giftCardList: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 23rem)',
    height: '75vh',
    // gridTemplateColumns: "minmax(5rem, 0.5fr) minmax(5rem, 0.5fr)",
    rowGap: '2rem',
    columnGap: '2rem',
  },
  gcSection: {
    padding: '0 2rem',
  },
}));

const GiftCardSection = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [requiredPoints, setRequiredPoints] = useState(0);
  const [giftCards, setGiftCards] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const rewardsInfo = useSelector(state => state.RewardsReducer.rewardsInfo);

  useEffect(() => {
    calculateRequiredPoints();
  }, [props.selectedGiftCards]);

  useEffect(() => {
    dispatch(getGiftCards());
  }, []);

  useEffect(() => {
    setGiftCards(props.giftCards);
  }, [props.giftCards]);

  const calculateRequiredPoints = () => {
    let points = 0;
    props.selectedGiftCards.map(gc => {
      points += gc.price * gc.qty * props.conversionRate;
    });
    setRequiredPoints(points);
  };

  const handleConfirm = () => {
    if (rewardsInfo && props.userData && props.names) {
      let productsToSend = props.selectedGiftCards.map(gc => {
        delete gc.name;
        return gc;
      });
      //! Get User data email or mobile number  and Generate payload
      let payload = {
        address: { billToThis: false, country: 'IN' },
        deliveryMode: props.userData.email ? 'EMAIL' : 'SMS',
        syncOnly: true,
        payments: [
          { code: 'svc', amount: requiredPoints / props.conversionRate },
        ],

        points: requiredPoints.toString(),
        products: productsToSend,
        names: props.names,
      };
      dispatch(postRewardPoints(payload));
      setShowConfirmation(false);
    }
  };

  const handleCheckout = () => {
    analyticFunc(eventsPayload.Reward[4]);
    if (rewardsInfo) {
      if (props.selectedGiftCards.length > 0) {
        if (rewardsInfo.totalpointsAvailable >= requiredPoints) {
          setShowConfirmation(true);
        } else {
          toasterMessage('error', 'Insufficient Points');
        }
      } else {
        toasterMessage('error', 'Select atleast 1 Gift Card !');
      }
    }
  };

  return (
    <Container maxWidth="lg" disableGutters className={classes.cont}>
      {/* //* Gift Card Section */}
      <div className={classes.gcSection}>
        <span className="font-weight-bold">REDEEM</span>
        {/* // TODO: List of Gift Cards */}
        <List className={classes.giftCardList}>
          {giftCards.map((giftCard, idx) => (
            <GiftCardInfo giftCard={giftCard} key={giftCard.id} />
          ))}
        </List>
      </div>
      {/* //* Checkout Section */}
      <div className={classes.checkoutCont}>
        <span>
          Points Required{' '}
          <span className="font-weight-bold">{requiredPoints}</span>
        </span>
        <Button onClick={handleCheckout} variant="contained" color="primary">
          Checkout
        </Button>
      </div>
      <ConfirmationModal
        toggleModal={showConfirmation}
        setToggleModal={setShowConfirmation}
        points={requiredPoints}
        selectedGiftCards={props.selectedGiftCards}
        handleConfirm={handleConfirm}
      />
    </Container>
  );
};

const mapStateToProps = ({ RewardsReducer, OtpLoginReducer }) => {
  return {
    selectedGiftCards: RewardsReducer.selectedGiftCards,
    giftCards: RewardsReducer.giftCards,
    names: RewardsReducer.names,
    userData: OtpLoginReducer.user,
    conversionRate: RewardsReducer.conversionRate,
  };
};

export default connect(mapStateToProps, null)(GiftCardSection);
