/* eslint-disable */
import React, { useCallback } from 'react';
import { SendOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { sendEmail } from '../../../store/RetailerWebStore/RetailerAction/profit&Loss';
import moment from 'moment';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const ProfitLossDetails = ({
  profitLossDetails,
  sendEmail,
  toDate,
  fromDate,
}) => {
  const renderData = useCallback(() => {
    if (profitLossDetails) {
      return Object.keys(profitLossDetails).map((key, index) => {
        var design = '';
        if (['costOfGoodsSold', 'grossProfit', 'netProfit'].includes(key)) {
          design =
            'd-flex w-100 align-items-center justify-content-between font-weight-bold m-1';
        } else {
          design =
            'd-flex w-100 align-items-center justify-content-between bg-light text-muted m-1';
        }
        return (
          <div className={design} key={index}>
            <p className="text-capitalize">
              {key.split(/(?=[A-Z])/).join(' ')}
            </p>
            <p className="">
              {parseInt(profitLossDetails[key])
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </p>
          </div>
        );
      });
    }
  }, [profitLossDetails]);

  const handleSent = useCallback(() => {
    analyticFunc(eventsPayload.Reports[5]);
    let payload = {
      ...profitLossDetails,
      toDate: moment(toDate).format('YYYY-MM-DD'),
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
    };
    sendEmail(payload);
  }, [profitLossDetails]);

  return (
    <div
      className="p-2 d-flex align-items-center flex-column m-2"
      style={{ width: 350 }}
    >
      <div className="w-100">
        <h4 className="border-bottom pb-1">Profit & Loss</h4>
        <div>{renderData()}</div>
      </div>
      <button
        className="d-flex align-items-center justify-content-between rounded-pill p-3 border-0 text-white text-capitalize bg-success w-100"
        onClick={handleSent}
      >
        send email
        <SendOutlined />
      </button>
    </div>
  );
};

const mapStateToProps = ({ ProfitLoss }) => {
  return { profitLossDetails: ProfitLoss.profitLossReport.reportDetails };
};

export default connect(mapStateToProps, { sendEmail })(ProfitLossDetails);
