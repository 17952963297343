import api, { baseURL } from '../../../api/api';
import Axios from 'axios';
import { toasterMessage } from '../../../@utils/utils';
import { SEARCH_INVOICE } from './types';

var CancelToken = Axios.CancelToken;
var cancel;

export const searchInvoice = (payload, cb) => async dispatch => {
  cancel && cancel();
  Axios({
    method: 'POST',
    url: `${baseURL.baseURL}billing/searchInvoice`,
    data: payload,
    headers: {
      'Content-type': 'application/json',
      'x-auth-token': localStorage.getItem('token'),
    },
    cancelToken: new CancelToken(c => {
      cancel = c;
    }),
  })
    .then(response => {
      cb && cb(true);
      dispatch({ type: SEARCH_INVOICE, payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      dispatch({ type: SEARCH_INVOICE, payload: [] });
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const clearSearchInvoice = () => async dispatch => {
  dispatch({ type: 'CLEAR_INVOICE' });
};

export const deleteInvoice = payload => {
  return api.post('billing/deleteInvoice', payload);
};
