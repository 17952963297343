/* eslint-disable */
import 'date-fns';
/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
/* eslint-disable */
import api from '../../../api/api';
import { connect } from 'react-redux';
import { getStateCity } from '../../../store/AdminActions/allStateCityAction';
import {
  getCustomerDetails,
  addCustomerDetails,
  addSerialNo,
  setGetUserId,
  viewModeOfPayment,
  setCustomerDetails,
  setProductDetails,
  cancelInvoice,
  setBillingStatus,
  setModeOfPayment,
  setInvoiceDate,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MyModal from '../Modal';
import Loader from '../../Loader';
import ChangeBillId from './changeBillId';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    color: 'red',
  },
}));

const CustomerDetails = React.memo(
  ({
    handleNext,
    stateCity,
    getStateCity,
    getProductId,
    customerDetails,
    isQuickCheckOut,
    addSerialNo,
    getCustomerDetails,
    setGetUserId,
    loading,
    userDetails,

    viewModeOfPayment,
    handleModal,
    setCustomerDetails,
    setProductDetails,
    cancelInvoice,
    billingStatus,
    setBillingStatus,
    activePage,
    setModeOfPayment,
    setInvoiceDate,
    invoiceDate,
  }) => {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [billId, setBillId] = React.useState('');
    const [invoiceId, setInvoiceId] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [quickCheckout, setQuickCheckout] = React.useState('no');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [state, setStates] = React.useState('');
    const [city, setCity] = React.useState('');
    const [invoiceRequire, setInvoiceRequire] = React.useState('false');
    const [isGST, setIsGST] = React.useState(true);
    const [companyName, setCompanyName] = React.useState('');
    const [gstNumber, setGstNumber] = React.useState('');
    const [id, setId] = React.useState('');
    const [disable, setDisable] = React.useState(false);
    const [text, setText] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState({
      errorCity: '',
      errorState: '',
      errorMobileNumber: '',
      // errorEmail: '',
      errorName: '',
      errorAddress: '',
      errorQuickCheckout: '',
      errorCompanyName: '',
      errorGSTNumber: '',
    });
    const [isShipingSameAsBilling, setIsShipingSameAsBilling] = React.useState(
      'true'
    );
    const [shipingMobileNumber, setShipingMobileNumber] = React.useState('');
    const [shipingName, setShipingName] = React.useState('');
    const [shipingAddress, setShipingAddress] = React.useState('');
    const [shipingEmail, setShipingEmail] = React.useState('');
    const [shipingPincode, setShipingPincode] = React.useState('');
    const [selectedShipingState, setSelectedShipingState] = useState('');
    const [shipingState, setShipingStates] = React.useState('');
    const [shipingCity, setShipingCity] = React.useState('');
    const [shipingInvoiceRequire, setShipingInvoiceRequire] = React.useState(
      'false'
    );
    const [shipingCompanyName, setShipingCompanyName] = React.useState('');
    const [shipingGstNumber, setShipingGstNumber] = React.useState('');

    const history = useHistory();
    const myRef = useRef();

    const InvoiceCancel = () => {
      let payload = {
        billId: getProductId.billId,
        invoiceId: getProductId.invoiceId ? getProductId.invoiceId : undefined,
      };
      cancelInvoice(payload);
      setGetUserId('');
      setModeOfPayment('');
    };

    useEffect(() => {
      toastDismiss();
      setProductDetails('');
      getStateCity();
      console.log('active page==--=>>', activePage);
      if (billingStatus.status === 0 && activePage == 0) {
        console.log('billingStatus--->', billingStatus);
        InvoiceCancel();
      }
      // const arr = history.location.search.split('?')
      // if (billingStatus.status != 0 && arr && arr.length > 1) {
      //   let bId = arr && arr.length > 1 ? arr[1].split('=')[1] : undefined
      //   let iId = arr && arr.length > 2 ? arr[2].split('=')[1] : undefined

      //   let payload = {
      //     billId: bId,
      //     invoiceId: iId,
      //   }
      //   setGetUserId(payload)

      //   setBillId(bId)
      //   setInvoiceId(iId)
      // }
    }, []);

    useEffect(() => {
      if (invoiceDate) {
        setSelectedDate(invoiceDate);
      }
    }, [invoiceDate]);

    useEffect(() => {
      if (userDetails && userDetails.gstNumber) {
        setIsGST(false);
      }
    }, [userDetails]);

    useEffect(() => {
      console.log(
        'billingStatus.status----------------->',
        billingStatus.status
      );
      if (billingStatus.status && billingStatus.status !== 0) {
        let payload = {
          billId: getProductId.billId,
          invoiceId: getProductId.invoiceId
            ? getProductId.invoiceId
            : undefined,
        };
        viewModeOfPayment(payload);
        // getCustomerDetails(payload)
        setDisable(true);
        // setGetUserId(payload)
      }
    }, [billingStatus]);

    useEffect(() => {
      console.log(
        'billingStatus.status----------------->',
        billingStatus.status
      );
      if (billingStatus.status && billingStatus.status !== 0) {
        let payload = {
          billId: getProductId.billId,
          invoiceId: getProductId.invoiceId
            ? getProductId.invoiceId
            : undefined,
        };
        getCustomerDetails(payload);
      }
    }, [getProductId]);

    useEffect(() => {
      if (isQuickCheckOut) {
        setQuickCheckout(isQuickCheckOut.quickCheckout);
      }
    }, [isQuickCheckOut]);

    useEffect(() => {
      if (quickCheckout == 'no' && !getProductId.billId && mobileNumber) {
        let pattern = new RegExp(/^\d{10}$/);
        if (pattern.test(mobileNumber)) {
          getCustomerDetails({ mobileNumber: mobileNumber });
        }
      }
    }, [mobileNumber]);

    console.log(quickCheckout);
    useEffect(() => {
      if (quickCheckout == 'yes') {
        setMobileNumber(userDetails.contactNumber);
        setQuickCheckout(
          userDetails.quickCheckout ? userDetails.quickCheckout : 'yes'
        );
        setName(userDetails.firstName);
        setEmail(userDetails.email);
        setAddress(userDetails.addressLine1);
        setStates(userDetails.STATE + ',' + userDetails.stateId);
        setCity(userDetails.City + ',' + userDetails.cityId);
        setSelectedState(userDetails.STATE);
        // setInvoiceRequire(customerDetails.isInvoiceRequiredCompany)
        setCompanyName(userDetails.tradeName);
        setGstNumber(userDetails.gstNumber);
        setInvoiceRequire(
          userDetails.isInvoiceRequiredCompany ? 'true' : 'false'
        );
      }
    }, [userDetails, quickCheckout]);

    useEffect(() => {
      if (quickCheckout == 'no') {
        setMobileNumber('');
        // setQuickCheckout('no')
        setName('');
        setEmail('');
        setAddress('');
        setStates('');
        setCity('');
        setSelectedState('');
        setInvoiceRequire('false');
        // setInvoiceRequire(customerDetails.isInvoiceRequiredCompany)
        setCompanyName('');
        setGstNumber('');
      }
    }, [quickCheckout]);

    useEffect(() => {
      if (customerDetails) {
        console.log('customerDetails=====', customerDetails);
        setMobileNumber(
          customerDetails.mobileNumber
            ? customerDetails.mobileNumber
            : mobileNumber
        );
        setQuickCheckout(customerDetails.quickCheckout ? 'yes' : 'no');
        setName(customerDetails.customerName);
        setEmail(customerDetails.customerEmail);
        setAddress(customerDetails.customerAddress);
        setStates(customerDetails.STATE + ',' + customerDetails.stateId);
        setCity(customerDetails.City + ',' + customerDetails.cityId);
        setSelectedState(customerDetails.STATE);
        setCompanyName(customerDetails.companyName);
        setGstNumber(customerDetails.gstNumber);
        setInvoiceRequire(
          customerDetails.isInvoiceRequiredCompany ? 'true' : 'false'
        );
        // setInvoiceRequire(customerDetails.isInvoiceRequiredCompany)

        setIsShipingSameAsBilling(
          customerDetails.isShipingSameAsBilling ? 'true' : 'false'
        );
        setShipingMobileNumber(customerDetails.shippingContactNumber);
        setShipingName(customerDetails.shippingCustomerName);
        setShipingAddress(customerDetails.shippingaddressLine1);
        setShipingEmail(customerDetails.shippingEmail);
        setShipingPincode(customerDetails.shippingPincode);
        setShipingStates(
          customerDetails.shipingSTATE + ',' + customerDetails.shippingStateId
        );
        setShipingCity(
          customerDetails.shipingCity + ',' + customerDetails.shippingCityId
        );
        setSelectedShipingState(customerDetails.shipingSTATE);
        setShipingCompanyName(customerDetails.shippingCompanyName);
        setShipingGstNumber(customerDetails.shippingGstNumber);
        setShipingInvoiceRequire(
          customerDetails.isShipingInvoiceRequiredCompany ? 'true' : 'false'
        );
      }
    }, [customerDetails]);

    const handleDateChange = date => {
      setSelectedDate(date);
    };
    const submitForm = useCallback(async event => {
      event.preventDefault();
      if (validateForm()) {
        let stateId = state.split(',');
        let cityId = city.split(',');
        let shipingStateId = shipingState.split(',');
        let shipingCityId = shipingCity.split(',');
        let payload = {
          date: moment(selectedDate).format('YYYY-MM-DD'),
          mobileNumber: mobileNumber,
          quickCheckout: quickCheckout === 'no' ? false : true,
          customerName: name,
          customerEmail: email ? email : undefined,
          customerAddress: address,
          // STATE: stateId[0],
          // city: cityId[0],
          stateId: stateId[1],
          cityId: cityId[1],
          isInvoiceRequiredCompany: invoiceRequire == 'true' ? true : false,
          companyName:
            invoiceRequire == 'true'
              ? companyName
                ? companyName
                : undefined
              : undefined,
          gstNumber:
            invoiceRequire == 'true'
              ? gstNumber
                ? gstNumber
                : undefined
              : undefined,

          isShipingSameAsBilling:
            isShipingSameAsBilling == 'true' ? true : false,
          shippingContactNumber:
            isShipingSameAsBilling == 'true'
              ? mobileNumber
              : shipingMobileNumber,
          shippingCustomerName:
            isShipingSameAsBilling == 'true' ? name : shipingName,
          shippingaddressLine1:
            isShipingSameAsBilling == 'true' ? address : shipingAddress,
          shippingEmail:
            isShipingSameAsBilling == 'true'
              ? email
                ? email
                : undefined
              : shipingEmail
              ? shipingEmail
              : undefined,
          shippingPincode: shipingPincode ? shipingPincode : undefined,
          // shipingSTATE: isShipingSameAsBilling == 'true' ? stateId[0] :  shipingStateId[0],
          // shipingCity: isShipingSameAsBilling == 'true' ? cityId[0] :  shipingCityId[0],
          shippingStateId:
            isShipingSameAsBilling == 'true' ? stateId[1] : shipingStateId[1],
          shippingCityId:
            isShipingSameAsBilling == 'true' ? cityId[1] : shipingCityId[1],
          isShipingInvoiceRequiredCompany:
            isShipingSameAsBilling == 'true'
              ? invoiceRequire == 'true'
                ? true
                : false
              : shipingInvoiceRequire == 'true'
              ? true
              : false,
          shippingCompanyName:
            isShipingSameAsBilling == 'true'
              ? invoiceRequire == 'true'
                ? companyName
                  ? companyName
                  : undefined
                : undefined
              : shipingInvoiceRequire == 'true'
              ? shipingCompanyName
                ? shipingCompanyName
                : undefined
              : undefined,
          shippingGstNumber:
            isShipingSameAsBilling == 'true'
              ? invoiceRequire == 'true'
                ? gstNumber
                  ? gstNumber
                  : undefined
                : undefined
              : shipingInvoiceRequire == 'true'
              ? shipingGstNumber
                ? shipingGstNumber
                : undefined
              : undefined,
        };
        if (getProductId.billId) {
          handleNext({
            quickCheckout,
            date: moment(selectedDate).format('YYYY-MM-DD'),
          });
          setInvoiceDate({ date: moment(selectedDate).format('YYYY-MM-DD') });
        } else {
          setLoading(true);
          await addCustomerDetails(payload)
            .then(res => {
              if (res.status == 200) {
                let ID = res.data.data;
                setGetUserId(ID);
                setBillingStatus({ status: 0 });
                if (ID.billId == '1' && ID.canbereset) {
                  setText(ID.billId);
                  myRef.current.click();
                } else {
                  handleNext({ quickCheckout });
                  setInvoiceDate({
                    date: moment(selectedDate).format('YYYY-MM-DD'),
                  });
                }
                setLoading(false);
                setCustomerDetails(payload);
              }
            })
            .catch(err => {
              toasterMessage('error', err.response.data.error);
              setLoading(false);
            })
            .catch(err => {
              toasterMessage('error', 'Something Bad Happened');
              setLoading(false);
            });
        }
      }
    });

    const validateForm = () => {
      let errorCity = '';
      let errorState = '';
      let errorMobileNumber = '';
      // let errorEmail = ''
      let errorName = '';
      let errorAddress = '';
      let errorQuickCheckout = '';
      let errorCompanyName = '';
      let errorGSTNumber = '';
      let formIsValid = true;

      if (!quickCheckout) {
        formIsValid = false;
        errorQuickCheckout = 'Please select Quick Checkout';
      }
      if (!name) {
        formIsValid = false;
        errorName = 'Please enter name';
      }
      if (!address) {
        formIsValid = false;
        errorAddress = 'Please enter address';
      }
      if (!state) {
        formIsValid = false;
        errorState = 'Please select state';
      }
      if (!city) {
        formIsValid = false;
        errorCity = 'Please select city';
      }
      if (!mobileNumber) {
        formIsValid = false;
        errorMobileNumber = 'Please Enter MobileNumber';
      }
      if (mobileNumber) {
        let pattern = new RegExp(/^\d{10}$/);
        if (!pattern.test(mobileNumber)) {
          formIsValid = false;
          errorMobileNumber = 'Invalid Contact Number';
        }
      }
      if (invoiceRequire == 'yes') {
        if (!companyName) {
          formIsValid = false;
          errorCompanyName = 'Please select companyName';
        }
        if (!gstNumber) {
          formIsValid = false;
          errorGSTNumber = 'Please select gstNumber';
        }
      }
      setError({
        ...error,
        // errorEmail,
        errorState,
        errorCity,
        errorGSTNumber,
        errorMobileNumber,
        errorName,
        errorAddress,
        errorQuickCheckout,
        errorCompanyName,
      });
      return formIsValid;
    };

    const handleClose = useCallback(() => {
      console.log('CHANGE BILL ID IF ITS 1');
    });

    const changeBillID = newText => {
      let payload = {
        serialNo: newText,
        invoiceId: getProductId.invoiceId,
      };
      addSerialNo(payload)
        .then(res => {
          let newId = res.data.data;
          setGetUserId(newId);
          // onSuccess()
          handleNext({ quickCheckout });
          setInvoiceDate({ date: moment(selectedDate).format('YYYY-MM-DD') });
        })
        .catch(err => {
          toasterMessage('error', err.response.data.error);
        })
        .catch(() => {
          toasterMessage('error', 'something bad happened');
        });
    };

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Details Of Customer
        </Typography>

        {loading || isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Grid container spacing={3}>
              <div className="w-100 d-flex justify-content-between align-items-center p-2">
                <h6 className="m-2">Invoice Date:</h6>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: 200 }}
                    variant="outlined"
                    id="date-picker-dialog"
                    className="headerDatePicker"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disableFuture
                    disabled={disable}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Grid item xs={12}>
                <FormControl
                  className={classes.formControl}
                  xs={12}
                  style={{ textAlign: 'left' }}
                >
                  <InputLabel id="quickCheckout">
                    Quick Checkout/Bill to Self
                  </InputLabel>
                  <Select
                    labelId="quickCheckout"
                    id="quickCheckout"
                    value={quickCheckout}
                    onChange={e => {
                      setQuickCheckout(e.target.value);
                    }}
                    fullWidth
                    disabled={disable}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
                <span className={classes.errorMessage}>
                  {error.errorQuickCheckout}
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  // type="number"
                  value={mobileNumber}
                  onChange={e => {
                    setMobileNumber(
                      Math.max(0, e.target.value.replace(/[^\d]/, ''))
                        .toString()
                        .slice(0, 10)
                    );
                  }}
                  fullWidth
                  autoComplete="MobileNumber"
                  disabled={disable}
                />
                <span className={classes.errorMessage}>
                  {error.errorMobileNumber}
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  disabled={disable}
                />
                <span className={classes.errorMessage}>{error.errorName}</span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // required
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  disabled={disable}
                />
                <span className={classes.errorMessage}>{error.errorEmail}</span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="address"
                  name="address"
                  label="Address"
                  fullWidth
                  autoComplete="address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  disabled={disable}
                />
                <span className={classes.errorMessage}>
                  {error.errorAddress}
                </span>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className={classes.formControl}
                  xs={12}
                  style={{ textAlign: 'left' }}
                >
                  <InputLabel id="state">State</InputLabel>
                  <Select
                    labelId="state"
                    id="state"
                    value={state}
                    onChange={e => {
                      let arr = e.target.value.split(',');
                      setSelectedState(arr[0]);
                      setStates(e.target.value);
                    }}
                    fullWidth
                    disabled={disable}
                  >
                    {/* <MenuItem value={'delhi'}>delhi</MenuItem>
                            <MenuItem value={'punjab'}>punjab</MenuItem> */}

                    {stateCity &&
                      stateCity.length > 0 &&
                      stateCity.map(item => (
                        <MenuItem
                          key={item.stateId}
                          value={item.stateName + ',' + item.stateId}
                        >
                          {item.stateName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <span className={classes.errorMessage}>{error.errorState}</span>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className={classes.formControl}
                  xs={12}
                  style={{ textAlign: 'left' }}
                >
                  <InputLabel id="city">City</InputLabel>
                  <Select
                    labelId="city"
                    id="city"
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    fullWidth
                    disabled={disable}
                  >
                    {stateCity &&
                      stateCity.length > 0 &&
                      stateCity.map(item => {
                        if (item.stateName == selectedState) {
                          return item.cityList.map(c => (
                            <MenuItem
                              key={c.cityId}
                              value={c.City + ',' + c.cityId}
                            >
                              {c.City}
                            </MenuItem>
                          ));
                        }
                      })}
                    {/* <MenuItem value={'delhi'}>Delhi</MenuItem>
                            <MenuItem value={'new delhi'}>New Delhi</MenuItem> */}
                  </Select>
                </FormControl>
                <span className={classes.errorMessage}>{error.errorCity}</span>
              </Grid>

              <Grid item xs={12}>
                {/* <h4>Is invoice required for company?</h4> */}
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Is invoice required for company?
                  </FormLabel>
                  <RadioGroup
                    style={{ marginLeft: '21%' }}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={invoiceRequire}
                    onChange={e => setInvoiceRequire(e.target.value)}
                  >
                    <FormControlLabel
                      disabled={disable}
                      value="true"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disable}
                      value="false"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {invoiceRequire && invoiceRequire == 'true' && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="companyName"
                      name="companyName"
                      label="Company Name"
                      fullWidth
                      autoComplete="companyName"
                      value={companyName}
                      onChange={e => setCompanyName(e.target.value)}
                      disabled={disable}
                    />
                    <span className={classes.errorMessage}>
                      {error.errorCompanyName}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="gstNumber"
                      name="gstNumber"
                      label="GST Number"
                      fullWidth
                      autoComplete="gstNumber"
                      value={gstNumber}
                      // onBlur={validateGst}
                      onChange={e =>
                        setGstNumber(e.target.value.toString().slice(0, 15))
                      }
                      disabled={disable}
                    />
                    <span className={classes.errorMessage}>
                      {error.errorGSTNumber}
                    </span>
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Shiping Address Same As Billing Address
                  </FormLabel>
                  <RadioGroup
                    style={{ marginLeft: '28%' }}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={isShipingSameAsBilling}
                    onChange={e => setIsShipingSameAsBilling(e.target.value)}
                  >
                    <FormControlLabel
                      disabled={disable}
                      value="true"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disable}
                      value="false"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {isShipingSameAsBilling && isShipingSameAsBilling == 'false' && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="shipingMobileNumber"
                      name="shipingMobileNumber"
                      label="Mobile Number"
                      value={shipingMobileNumber}
                      onChange={e => {
                        setShipingMobileNumber(
                          Math.max(0, e.target.value.replace(/[^\d]/, ''))
                            .toString()
                            .slice(0, 10)
                        );
                      }}
                      fullWidth
                      autoComplete="Shiping MobileNumber"
                      disabled={disable}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="shipingName"
                      name="shipingName"
                      label="Name"
                      fullWidth
                      autoComplete="shipingName"
                      value={shipingName}
                      onChange={e => setShipingName(e.target.value)}
                      disabled={disable}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // required
                      id="shipingEmail"
                      name="shipingEmail"
                      label="Email"
                      fullWidth
                      autoComplete="shipingEmail"
                      value={shipingEmail}
                      onChange={e => setShipingEmail(e.target.value)}
                      disabled={disable}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="shipingAddress"
                      name="shipingAddress"
                      label="Address"
                      fullWidth
                      autoComplete="shipingAddress"
                      value={shipingAddress}
                      onChange={e => setShipingAddress(e.target.value)}
                      disabled={disable}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      xs={12}
                      style={{ textAlign: 'left' }}
                    >
                      <InputLabel id="state">State</InputLabel>
                      <Select
                        labelId="shipingState"
                        id="shipingState"
                        value={shipingState}
                        onChange={e => {
                          let arr = e.target.value.split(',');
                          setSelectedShipingState(arr[0]);
                          setShipingStates(e.target.value);
                        }}
                        fullWidth
                        disabled={disable}
                      >
                        {stateCity &&
                          stateCity.length > 0 &&
                          stateCity.map(item => (
                            <MenuItem
                              key={item.stateId}
                              value={item.stateName + ',' + item.stateId}
                            >
                              {item.stateName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      xs={12}
                      style={{ textAlign: 'left' }}
                    >
                      <InputLabel id="city">City</InputLabel>
                      <Select
                        labelId="shipingCity"
                        id="shipingCity"
                        value={shipingCity}
                        onChange={e => setShipingCity(e.target.value)}
                        fullWidth
                        disabled={disable}
                      >
                        {stateCity &&
                          stateCity.length > 0 &&
                          stateCity.map(item => {
                            if (item.stateName == selectedShipingState) {
                              return item.cityList.map(c => (
                                <MenuItem
                                  key={c.cityId}
                                  value={c.City + ',' + c.cityId}
                                >
                                  {c.City}
                                </MenuItem>
                              ));
                            }
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="shipingPincode"
                      name="shipingPincode"
                      label="Pincode"
                      value={shipingPincode}
                      onChange={e => {
                        setShipingPincode(e.target.value.replace(/[^\d]/, ''));
                      }}
                      fullWidth
                      autoComplete="shipingPincode"
                      disabled={disable}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Is invoice required for company?
                      </FormLabel>
                      <RadioGroup
                        style={{ marginLeft: '21%' }}
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="top"
                        value={shipingInvoiceRequire}
                        onChange={e => setShipingInvoiceRequire(e.target.value)}
                      >
                        <FormControlLabel
                          disabled={disable}
                          value="true"
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={disable}
                          value="false"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {shipingInvoiceRequire && shipingInvoiceRequire == 'true' && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="setShipingCompanyName"
                          name="setShipingCompanyName"
                          label="Company Name"
                          fullWidth
                          autoComplete="setShipingCompanyName"
                          value={shipingCompanyName}
                          onChange={e => setShipingCompanyName(e.target.value)}
                          disabled={disable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="shipingGstNumber"
                          name="shipingGstNumber"
                          label="GST Number"
                          fullWidth
                          autoComplete="shipingGstNumber"
                          value={shipingGstNumber}
                          onChange={e =>
                            setShipingGstNumber(
                              e.target.value.toString().slice(0, 15)
                            )
                          }
                          disabled={disable}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              <button
                style={{
                  borderRadius: 25,
                  width: '50%',
                  background: 'rgb(237, 79, 42)',
                  color: 'white',
                  padding: 15,
                  marginLeft: '25%',
                  marginBottom: '2%',
                  border: 'none',
                }}
                onClick={e => {
                  submitForm(e);
                }}
              >
                NEXT
              </button>
            </Grid>
          </React.Fragment>
        )}
        <MyModal ref={myRef} btnTitle={'Change BillId'} close={handleClose}>
          <ChangeBillId data={text} onChnageID={changeBillID} />
        </MyModal>
      </React.Fragment>
    );
  }
);

const mapStateToProps = ({
  Billing,
  getStateCityReducer,
  Loader,
  loginReducer,
}) => {
  const { stateCity } = getStateCityReducer;
  return {
    getProductId: Billing.getProductId,
    customerDetails: Billing.customerDetails,
    stateCity: stateCity,
    loading: Loader.loading,
    userDetails: loginReducer.user.userData[0],
    billingStatus: Billing.billingStatus,
    activePage: Billing.activePage,
    invoiceDate: Billing.invoiceDate && Billing.invoiceDate.date,
  };
};

export default connect(mapStateToProps, {
  getStateCity,
  getCustomerDetails,
  addCustomerDetails,
  setGetUserId,
  viewModeOfPayment,
  addSerialNo,
  setCustomerDetails,
  setProductDetails,
  cancelInvoice,
  setBillingStatus,
  setModeOfPayment,
  setInvoiceDate,
})(CustomerDetails);
