import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { List, Paper } from '@material-ui/core';
import { toasterMessage } from '../../../../@utils/utils';
import coin from '../../../../assets/svg/coin.svg';

const useStyles = makeStyles(theme => ({
  history: {
    borderRadius: '1rem',
    padding: '0 1rem',
    marginBottom: '0.5rem',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.1fr auto 0.1fr',
  },
  details: {
    display: 'grid',
    gridTemplateRows: '0.5fr 0.5fr',
    padding: '1rem',
  },
  invoiceDetails: {
    fontSize: '0.875rem',
    fontWeight: '500',
    marginBottom: '0.5rem',
  },
  invoiceDate: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: theme.palette.primary.placeHolderActiveText,
  },
}));

const HistoryInfo = props => {
  const { historyData } = props;
  const [date, setDate] = useState(new Date());
  const classes = useStyles();
  const { status } = historyData;
  const [statusString, setStatusString] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (historyData.transactionType !== 'credit') {
      if (status === 0) setStatusString('Pending');
      else if (status === 1) setStatusString('Approved');
      else if (status === 2) setStatusString('Rejected');
    }
  }, [status]);

  useEffect(() => {
    let possibleTitle = '';
    if (historyData.transactionType === 'debit') {
      possibleTitle = 'Redeemed Voucher';
    } else if (historyData.rewardService) {
      possibleTitle = historyData.rewardService;
      if (historyData.rewardServiceId) {
        possibleTitle += ` #${historyData.rewardServiceId}`;
      }
    } else {
      possibleTitle = 'Refunded Points';
    }
    setTitle(possibleTitle);
  }, [historyData]);

  return (
    <Paper className={classes.history}>
      <img src={coin} alt="Reward Icon" />
      <div className={classes.details}>
        <p className={classes.invoiceDetails}>
          {title}
          <span className={classes.invoiceDate}>{` ${statusString}`}</span>
        </p>
        <p className={classes.invoiceDate}>
          {new Date(historyData.createdAt).toDateString()}
        </p>
      </div>
      <p
        className={
          historyData.transactionType === 'credit'
            ? 'text-success'
            : 'text-danger'
        }
        style={{
          alignSelf: 'center',
          justifySelf: 'center',
          marginBottom: '0',
        }}
      >
        {historyData.transactionType === 'credit'
          ? `+${historyData.points}`
          : `-${historyData.points}`}
      </p>
    </Paper>
  );
};

export default HistoryInfo;
