/* eslint-disable */
import React, { useEffect, Suspense } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { DASHBOARD_ROUTES } from '../../@constants/index';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItemIcon,
  Typography,
  Divider,
  IconButton,
  ListItemText,
  ListItem,
  Container,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Tooltip,
} from '@material-ui/core';
import {
  Menu,
  Assessment,
  ChevronLeft,
  Home,
  Storefront,
  Message,
  DynamicFeed,
  Settings,
  People,
  PersonAdd,
  AccountCircle,
  AssignmentTurnedIn,
  AirlineSeatReclineExtra,
  QuestionAnswer,
  EmojiPeople,
  LocalActivityOutlined,
  Book,
  CloudUploadRounded,
  AccountTree,
  Facebook,
  LocationSearching,
  Redeem,
} from '@material-ui/icons';
import LogoutDialog from '../../Common/Logout/ConfirmationDialog';
import Loader from '../Loader/Loader';
import * as CategoriesAction from '../../store/AdminActions/categoryAction';
import NotFound from '../../Common/Error/NotFound';
import NetworkDetector from '../../Common/NetworkDetector';
import { toastDismiss } from './../../@utils/utils';
//#region lazyload
const SurveyContainer = React.lazy(() => import('../Survey/surveyContainer'));
const UploadContainer = React.lazy(() => import('../Upload/uploadContainer'));
const RMDownloadContainer = React.lazy(() =>
  import('../RetailerMapping/firebaseDownload')
);
const RmTaskManagement = React.lazy(() =>
  import('../RmManagement/rmManagementContainer')
);
const HomePage = React.lazy(() => import('../Home/Home'));
const Vendors = React.lazy(() => import('../Retailers/Vendors'));
const Admin = React.lazy(() => import('../Admin/Admin'));
const FrequentlyAnswered = React.lazy(() =>
  import('../FrequentlyAnsweredQuestions/FAQ')
);
const ChangePassword = React.lazy(() =>
  import('../../Common/ChangePassword/ChangePassword')
);
const BrandManagement = React.lazy(() =>
  import('../BrandOffers/BrandManagement')
);
const RetailerManager = React.lazy(() =>
  import('../RetailerManager/RetailerManager')
);
const CustomerSupport = React.lazy(() => import('../CSE/CustomerSupport'));
const Leads = React.lazy(() => import('../Managers'));
const Rewards = React.lazy(() => import('../RewardsPoints'));
const RewardsApproval = React.lazy(() => import('../RewardsApproval'));
const MessageUsers = React.lazy(() => import('../MessageUsers/Users'));
const CategoriesHome = React.lazy(() => import('../Categories/CategoriesHome'));
const Reports = React.lazy(() => import('../Reports/Reports'));
const SystemConfiguration = React.lazy(() =>
  import('../SystemConfigurations/SystemConfiguration')
);
const GoogleCampaign = React.lazy(() => import('../GoogleCampaign'));
const RmMappingContainer = React.lazy(() =>
  import('../RetailerMapping/rmMappingContainer')
);
//#endregion
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  dashroot: {
    width: '100%',
    position: 'absolute',
    top: '50 %',
    transform: 'translateY(-50 %)',
  },
  toolbar: {
    color: '#fff',
    paddingRight: 10, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: '#df5a28',
    zIndex: 10000,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: '100px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100vh',
    width: drawerWidth,
    backgroundColor: '#df5a28',
    opacity: '0.9',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    maxWidth: '95%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const { token } = useParams();
  var today = new Date();
  var date = today.getDate() + ', ' + today.getFullYear();
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var monthin = today.getMonth();
  var dayin = today.getDay();
  const [open, setOpen] = React.useState(false);
  const [logoutOpen, setLogOutOpen] = React.useState(false);
  const [menuopen, setMenuOpen] = React.useState(false);
  const [component, setComponent] = React.useState('');
  const { user } = props;
  const anchorRef = React.useRef(null);
  const location = useLocation();
  ///////////////AVATAR MENU/////////////////////
  const handleToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }
  ////////////////////////////////////////////////

  //////////////////////////////
  ///////////DRAWER/////////////
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  ///////////////////////////////
  ////////////////////////////////

  ////////////LOGOUT////////////
  const handleLogoutOpen = () => {
    setLogOutOpen(true);
  };

  const handleLogoutClose = () => {
    setLogOutOpen(false);
  };
  ////////////////////////////////

  useEffect(() => {
    if (!props.isUserLoggedIn) {
      props.history.push('/');
    }
  }, [props.isUserLoggedIn, props.history]);

  ////////Component Viewing//////
  useEffect(() => {
    if (
      location.pathname
        ? location.pathname.includes(
            `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.download}`
          )
        : null
    ) {
      toastDismiss();
      user.userType === 'Admin'
        ? setComponent(<RMDownloadContainer path={location.pathname} />)
        : setComponent(<NotFound />);
    } else {
      switch (location.pathname) {
        case `/${DASHBOARD_ROUTES.dashboard}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<HomePage />)
              : user.userType === 'Cse' ||
                user.userType === 'floorManager' ||
                user.userType === 'Avp'
              ? setComponent(<CustomerSupport />)
              : setComponent(<RetailerManager />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.categories}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<CategoriesHome />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.changePassword}`:
          toastDismiss();
          setComponent(<ChangePassword />);
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.vendors}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<Vendors />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.leads}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<Leads />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerManager}`:
          toastDismiss();
          {
            user.userType === 'Admin' ||
            user.userType === 'subAdmin' ||
            user.userType === 'zonalManager' ||
            user.userType === 'stateManager' ||
            user.userType === 'Avp'
              ? // ? setComponent(<RetailerManager />)
                setComponent(<RmTaskManagement />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.brandManagement}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<BrandManagement />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.customerSupport}`:
          toastDismiss();
          {
            user.userType === 'Admin' ||
            user.userType === 'subAdmin' ||
            user.userType === 'Cse' ||
            user.userType === 'floorManager' ||
            user.userType === 'Avp'
              ? setComponent(<CustomerSupport />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.addAdmin}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<Admin />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.googleCampaign}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<GoogleCampaign />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.rewards}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<Rewards />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.rewardsApproval}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<RewardsApproval />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.faq}`:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<FrequentlyAnswered />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.reports}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<Reports />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.messageUsers}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<MessageUsers />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.configurations}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<SystemConfiguration />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.survey}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<SurveyContainer />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.upload}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<UploadContainer />)
              : setComponent(<NotFound />);
          }
          break;
        case `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerMapping}`:
          toastDismiss();
          {
            user.userType === 'Admin'
              ? setComponent(<RmMappingContainer />)
              : setComponent(<NotFound />);
          }
          break;
        default:
          toastDismiss();
          {
            user.userType === 'Admin' || user.userType === 'subAdmin'
              ? setComponent(<HomePage />)
              : user.userType === 'Cse' ||
                user.userType === 'floorManager' ||
                user.userType === 'Avp'
              ? setComponent(<CustomerSupport />)
              : setComponent(<RetailerManager />);
          }
      }
    }
  }, [location.pathname]);

  const drawerMenu = () => {
    if (user.userType === 'Admin') {
      return [
        { name: 'Home', Icon: Home, url: `/${DASHBOARD_ROUTES.dashboard}` },
        {
          name: 'Categories',
          Icon: DynamicFeed,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.categories}`,
        },
        {
          name: 'Retailers',
          Icon: People,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.vendors}`,
        },
        {
          name: 'Customer Support',
          Icon: AirlineSeatReclineExtra,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.customerSupport}`,
        },
        {
          name: 'RM Management',
          Icon: AssignmentTurnedIn,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerManager}`,
        },
        {
          name: 'Leads',
          Icon: EmojiPeople,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.leads}`,
        },
        {
          name: 'Brand Management',
          Icon: Storefront,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.brandManagement}`,
        },
        {
          name: 'Google Ad Campaign',
          Icon: Facebook,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.googleCampaign}`,
        },
        {
          name: 'Rewards',
          Icon: LocalActivityOutlined,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.rewards}`,
        },
        {
          name: 'Rewards Approval',
          Icon: Redeem,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.rewardsApproval}`,
        },
        {
          name: 'Reports',
          Icon: Assessment,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.reports}`,
        },
        {
          name: 'Frequently Asked Questions',
          Icon: QuestionAnswer,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.faq}`,
        },
        {
          name: 'Message Users',
          Icon: Message,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.messageUsers}`,
        },
        {
          name: 'Add Sub-Admin',
          Icon: PersonAdd,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.addAdmin}`,
        },
        {
          name: 'Retailer Mapping',
          Icon: AccountTree,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerMapping}`,
        },
        {
          name: 'Survey',
          Icon: Book,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.survey}`,
        },
        {
          name: 'Upload',
          Icon: CloudUploadRounded,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.upload}`,
        },
        {
          name: 'Settings',
          Icon: Settings,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.configurations}`,
        },
      ];
    } else if (user.userType === 'subAdmin') {
      return [
        { name: 'Home', Icon: Home, url: `/${DASHBOARD_ROUTES.dashboard}` },
        {
          name: 'Categories',
          Icon: DynamicFeed,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.categories}`,
        },
        {
          name: 'Retailers',
          Icon: People,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.vendors}`,
        },
        {
          name: 'Customer Support',
          Icon: AirlineSeatReclineExtra,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.customerSupport}`,
        },
        {
          name: 'Leads',
          Icon: EmojiPeople,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.leads}`,
        },
        {
          name: 'RM Management',
          Icon: AssignmentTurnedIn,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerManager}`,
        },
        {
          name: 'Brand Management',
          Icon: Storefront,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.brandManagement}`,
        },
        {
          name: 'Frequently Asked Questions',
          Icon: QuestionAnswer,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.faq}`,
        },
      ];
    } else if (user.userType === 'Avp') {
      return [
        {
          name: 'Customer Support',
          Icon: AirlineSeatReclineExtra,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.customerSupport}`,
        },
        {
          name: 'RM Management',
          Icon: AssignmentTurnedIn,
          url: `/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.retailerManager}`,
        },
      ];
    }
  };

  return (
    <div>
      <div className={classes.dashroot}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              {user.userType === 'Admin' ||
              user.userType === 'subAdmin' ||
              user.userType === 'Avp' ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClickCapture={handleDrawerOpen}
                  id="toggleHeaderButton"
                  className={clsx(
                    classes.menuButton,
                    open && classes.menuButtonHidden
                  )}
                >
                  <Menu />
                </IconButton>
              ) : (
                ''
              )}
              <Typography
                component="h1"
                id="headerMainHeading"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                Market XL
              </Typography>
              <span
                className="headerDate"
                style={{ marginTop: '5px', marginRight: '10px' }}
              >
                {' '}
                Date : {days[dayin]}, {months[monthin]} {date}{' '}
              </span>
              <NetworkDetector />
              <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                onClickCapture={handleToggle}
              >
                <AccountCircle style={{ color: '#ffffff' }} />
              </IconButton>
              <Popper
                open={menuopen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu"
                          onKeyDown={handleListKeyDown}
                          onClick={handleMenuClose}
                        >
                          <MenuItem className="text-decoration-none">
                            <h5 style={{ color: '#df5a28' }}>
                              Hi {user.firstName}
                            </h5>
                          </MenuItem>
                          <Link
                            to={`/${DASHBOARD_ROUTES.dashboard}`}
                            className="text-decoration-none text-dark"
                          >
                            <MenuItem>Home</MenuItem>
                          </Link>
                          <Link
                            to={`/${DASHBOARD_ROUTES.dashboard}/${DASHBOARD_ROUTES.changePassword}`}
                            className="text-decoration-none text-dark"
                          >
                            <MenuItem>Change Password</MenuItem>
                          </Link>
                          <MenuItem
                            onClickCapture={handleLogoutOpen}
                            className="text-decoration-none text-dark"
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Toolbar>
          </AppBar>
          {user.userType === 'Admin' ||
          user.userType === 'subAdmin' ||
          user.userType === 'Avp' ? (
            <Drawer
              variant="permanent"
              classes={{
                paper: clsx(
                  classes.drawerPaper,
                  !open && classes.drawerPaperClose
                ),
              }}
              open={open}
            >
              <div className={classes.toolbarIcon}>
                <IconButton onClickCapture={handleDrawerClose}>
                  <ChevronLeft style={{ color: '#ffffff' }} />
                </IconButton>
              </div>
              <div>
                <Divider />
              </div>
              <List>
                {drawerMenu().map(({ name, Icon, url }, index) => (
                  <React.Fragment key={index}>
                    <Link to={url} className="text-decoration-none text-light">
                      <Tooltip title={name}>
                        <ListItem button divider={true}>
                          <ListItemIcon>
                            <Icon style={{ color: '#ffffff' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={name}
                            style={{ color: '#ffffff' }}
                          />
                        </ListItem>
                      </Tooltip>
                    </Link>
                  </React.Fragment>
                ))}
              </List>
            </Drawer>
          ) : (
            ''
          )}
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <Suspense fallback={<Loader />}>{component}</Suspense>
            </Container>
          </main>
        </div>
      </div>
      {logoutOpen && <LogoutDialog handleLogoutClose={handleLogoutClose} />}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isUserLoggedIn: state.loginReducer.isUserLoggedIn,
    user: state.loginReducer.user.userData[0],
    allcategories: state.getCategoriesReducer.categories.categoryList,
    StateCities: state.getStateCityReducer.stateCity,
  };
};

export default connect(mapStateToProps, { ...CategoriesAction })(Dashboard);
