/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { sendSalesReport } from '../../../store/RetailerWebStore/RetailerAction/ledger';
import moment from 'moment';
import Loader from '../../Loader';
import { toastDismiss } from '../../../@utils/utils';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const SalesReport = ({ sendSalesReport, getNotificationCount }) => {
  const [fromDate, setFromDate] = useState(`01-01-${new Date().getFullYear()}`);
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNotificationCount();
    toastDismiss();
  }, []);

  const onSuccess = useCallback(() => {
    setLoading(false);
  }, []);

  const handleSubmit = useCallback(() => {
    analyticFunc(eventsPayload.Reports[7]);
    let payload = {
      fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : undefined,
      toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : undefined,
    };
    sendSalesReport(payload, onSuccess);
    setLoading(true);
  }, [fromDate, toDate]);

  return (
    <div className="w-100 d-flex justify-content-center">
      <Paper className="p-4 salesReport">
        <h4>Get Sales Report</h4>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ width: 350 }}
            variant="outlined"
            id="date-picker-dialog"
            className="headerDatePicker"
            label="From Date"
            format="yyyy/MM/dd"
            value={fromDate}
            disableFuture
            onChange={val => setFromDate(val)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <br />
          <br />
          <KeyboardDatePicker
            style={{ width: 350 }}
            variant="outlined"
            id="date-picker-dialog"
            className="headerDatePicker"
            label="To Date"
            format="yyyy/MM/dd"
            value={toDate}
            disableFuture
            onChange={val => setToDate(val)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <br />
        <br />
        {!loading ? (
          <button
            className="rounded-pill w-50 border-0 p-3 text-white text-uppercase bg-themeorange"
            onClick={handleSubmit}
          >
            Submit
          </button>
        ) : (
          <Loader />
        )}
      </Paper>
    </div>
  );
};

export default connect(null, { sendSalesReport, getNotificationCount })(
  SalesReport
);
