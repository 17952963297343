/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import { INVENTORY_OVERVIEW } from './types';

export const inventoryOverview = payload => async dispatch => {
  api
    .post('reports/inventoryOverview', payload)
    .then(response => {
      if (response.data.data.report.length < 1)
        return toasterMessage('error', 'No Sales Data present yet');
      dispatch({ type: INVENTORY_OVERVIEW, payload: response.data.data });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: INVENTORY_OVERVIEW, payload: error.response.data.data });
    })
    .catch(error => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
