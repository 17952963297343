import React from 'react';

const SummaryLine = props => {
  const { title, amount, subtitle } = props;
  return (
    <div
      style={{
        fontSize: '0.875rem',
        display: 'grid',
        gridTemplateColumns: '0.1fr 0.7fr 0.2fr',
        fontWeight: title === 'Total' ? 'bold' : 'normal',
      }}
    >
      <p
        style={{
          marginLeft: subtitle === 'true' ? '0.5rem' : '0rem',
          color: subtitle === 'true' ? '#A0A3BD' : 'inherit',
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: subtitle === 'true' ? '#A0A3BD' : 'inherit',
          marginRight: '2rem',
          marginLeft: '2rem',
          borderBottom: '0.02rem solid #D9DBE9',
        }}
      ></p>
      {/* <Divider fullWidth style={{ flexGrow: "1", borderBottom: "0.2rem solid black" }} orientation="horizontal" /> */}
      <p
        style={{
          color: subtitle === 'true' ? '#A0A3BD' : 'inherit',
          textAlign: 'right',
        }}
      >
        {'Rs ' + amount}
      </p>
    </div>
  );
};

export default SummaryLine;
