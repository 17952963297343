import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import {
  ADD_TO_SEARCH_LIST,
  ADD_TO_USERS_INVENTORY,
  SET_USERS_INVENTORY,
  SET_SEARCH_LIST,
  SET_LOADING_SEARCH_LIST,
} from './types';
import { scanIdToBarcode } from '../../../@utils/scanIdToBarcode';

export const searchInventory = payload => async dispatch => {
  let type = payload.page > 1 ? ADD_TO_SEARCH_LIST : SET_SEARCH_LIST;
  dispatch({ type: SET_LOADING_SEARCH_LIST, payload: true });
  api
    .post('inventory/searchProduct', payload)
    .then(response => {
      const { data, status } = response.data;
      if (status !== 204) {
        let payload = scanIdToBarcode(data);

        dispatch({ type: type, payload });
        dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
      } else {
        toasterMessage('error', 'No such Products');
        dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
      }
    })
    .catch(error => {
      console.log(error);
      toasterMessage('error', error.data.data);
      dispatch({ type: type, payload: [] });
      dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
      dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
    });
};

export const setSearchList = payload => async dispatch => {
  dispatch({ type: SET_SEARCH_LIST, payload: payload });
};

export const getUserInventory = payload => async dispatch => {
  dispatch({ type: SET_LOADING_SEARCH_LIST, payload: true });
  api
    .post('inventory/getModels', payload)
    .then(response => {
      if (response.data.status !== 204) {
        // scanId --> barcode
        let payload = scanIdToBarcode(response.data.data);

        dispatch({ type: SET_USERS_INVENTORY, payload });
      } else {
        dispatch({ type: SET_USERS_INVENTORY, payload: [] });
      }
      dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
    })
    .catch(error => {
      dispatch({ type: SET_USERS_INVENTORY, payload: [] });
      dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
    })
    .catch(() => {
      toasterMessage('error', 'something bad happend');
      dispatch({ type: SET_LOADING_SEARCH_LIST, payload: false });
    });
};

export const addProductToInventory = payload => async dispatch => {
  Object.keys(payload).forEach(key =>
    payload[key] === null ? delete payload[key] : {}
  );

  // barcode --> scanId
  if ('barcode' in payload) {
    payload['scanId'] = payload['barcode'];
    delete payload['barcode'];

    if ([null, undefined].includes(payload['scanId'])) {
      delete payload['scanId'];
    }
  }
  api
    .post('inventory/addModel', payload)
    .then(response => {
      const { data } = response;
      //! Notify user that product is add successfully
      dispatch({ type: 'ADD_TO_USERS_INVENTORY', payload: data.data });
      dispatch({ type: 'PRODUCT_ADDED_STATUS', payload: true });
    })
    .catch(error => {
      dispatch({ type: 'PRODUCT_ADDED_STATUS', payload: false });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};
