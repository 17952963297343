import {
  ADD_SUBUSER,
  UPDATE_SUBUSER,
  DELETE_SUBUSER,
  GET_SUBUSER,
  UPDATE_RETAILER_DETAIL,
} from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  subUserDetails: [],
  updateRetailerDetail: {},
};

const subUser = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBUSER: {
      return {
        ...state,
        subUserDetails: action.payload,
      };
    }
    case UPDATE_SUBUSER: {
      return {
        ...state,
        subUserDetails: action.payload,
      };
    }
    case DELETE_SUBUSER: {
      return {
        ...state,
        subUserDetails: action.payload,
      };
    }
    case GET_SUBUSER: {
      return {
        ...state,
        subUserDetails: action.payload,
      };
    }
    case UPDATE_RETAILER_DETAIL: {
      console.log('update retailer data--->)', action.payload);
      return {
        ...state,
        updateRetailerDetail: action.payload,
      };
    }
    default:
      return state;
  }
};
export default subUser;
