export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const GET_BRAND = 'GET_BRAND';
export const GET_MODEL = 'GET_MODEL';
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR';
export const GET_PRODUCTDETAILS = 'GET_PRODUCTDETAILS';
export const ADD_CATEGORY = 'ADD_CATEGORY';

export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const ADD_CUSTOMER_DETAILS = 'ADD_CUSTOMER_DETAILS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const SEARCHED_LIST = 'SEARCHED_LIST';
export const ADD_PRODUCT_DETAILS = 'ADD_PRODUCT_DETAILS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const MODE_OF_PAYMENT = 'MODE_OF_PAYMENT';
export const VIEW_INVOICE = 'VIEW_INVOICE';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const VIEW_MODE_OF_PAYMENT = 'VIEW_MODE_OF_PAYMENT';
export const SET_MODE_OF_PAYMENT = 'SET_MODE_OF_PAYMENT';
export const DELETE_INVOICE = 'DELETE_INVOICE';

export const BRAND_OFFER_OPTIONS = 'BRAND_OFFER_OPTIONS';
export const GST_OPTIONS = 'GST_OPTIONS';

export const SALES_DETAILS = 'SALES_DETAILS';

export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSE_CATEGORY = 'GET_EXPENSE_CATEGORY';

export const GET_PROFIT_LOSS_REPORT = 'GET_PROFIT_LOSS_REPORT';
export const SEARCH_INVOICE = 'SEARCH_INVOICE';

export const SET_LOADING = 'SET_LOADING';

export const INVENTORY_OVERVIEW = 'INVENTORY_OVERVIEW';

export const GET_FAQ = 'GET_FAQ';

export const SEARCH_CREDITS = 'SEARCH_CREDITS';

export const CREDIT_DETAILS = 'CREDIT_DETAILS';

export const CLEAR_CREDITS = 'CLEAR_CREDITS';

export const CLEAR_EXPENSE_CATEGORY = 'CLEAR_EXPENSE_CATEGORY';

export const GET_SPECIFIC_MODEL = 'GET_SPECIFIC_MODEL';

export const GET_SUPER_CATOGERY = 'GET_SUPER_CATOGERY';

export const ADD_SUBUSER = 'ADD_SUBUSER';
export const UPDATE_SUBUSER = 'UPDATE_SUBUSER';
export const DELETE_SUBUSER = 'DELETE_SUBUSER';
export const GET_SUBUSER = 'GET_SUBUSER';
export const UPDATE_RETAILER_DETAIL = 'UPDATE_RETAILER_DETAIL';

export const SET_BILLING_STATUS = 'SET_BILLING_STATUS';

export const SET_CREDIT_ID = 'SET_CREDIT_ID';

export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
