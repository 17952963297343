import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';

export const getCustomers = (payload, cb) => dispatch => {
  api
    .post('/paySMS/getCustomers', payload)
    .then(({ data }) => {
      !payload.typeBounced
        ? dispatch({ type: 'GET_CUSTOMERS', payload: data.data })
        : dispatch({ type: 'GET_BOUNCED_CUSTOMERS', payload: data.data });
    })
    .catch(error => {
      cb && cb(false);
      if (error.response.data.status == 404)
        !payload.typeBounced
          ? dispatch({ type: 'GET_CUSTOMERS', payload: [] })
          : dispatch({ type: 'GET_BOUNCED_CUSTOMERS', payload: [] });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const getOrder = (payload, cb) => dispatch => {
  api
    .post('/paySMS/payWallet', payload)
    .then(response => {
      cb && cb(response.data.data);
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const checkPaymentStatus = (payload, cb) => dispatch => {
  api
    .post('/paySMS/sendSmsCustomers', payload)
    .then(response => {
      toasterMessage('success', 'Message Send Successfully');
      cb && cb(true);
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
      cb && cb(false);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
      cb && cb(false);
    });
};

export const getDropdownData = () => dispatch => {
  api
    .get('/paySMS/getDropDownPaySms')
    .then(response => {
      dispatch({ type: 'DROPDOWN_DATA', payload: response.data.data });
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const getWallet = cb => dispatch => {
  api
    .get('/paySMS/getWallet')
    .then(response => {
      cb && cb(false);
      // console.log(response.data.data)
      dispatch({ type: 'GET_WALLET', payload: response.data.data });
    })
    .catch(err => {
      cb && cb(false);
      toasterMessage('error', err.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const confirmWallet = payload => dispatch => {
  api
    .post('/paySMS/confirmWallet', payload)
    .then(response => {
      dispatch({ type: 'UPDATE_WALLET', payload: response.data.data });
    })
    .catch(err => {
      toasterMessage('error', err.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
