import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useCallback } from 'react';

export const AddSms = ({
  handleModal,
  getOrderDetails,
  minAmount = 2000,
  getOrder,
  rateOfSms = 10,
  perSmsCharge,
}) => {
  const [sms, setSms] = useState('');

  const onSuccess = useCallback(
    data => {
      handleModal();
      getOrderDetails(data);
    },
    [handleModal, getOrderDetails]
  );

  useEffect(() => {
    setSms(minAmount / rateOfSms);
  }, [minAmount, rateOfSms]);

  return (
    <div className="p-2 m-2">
      <TextField
        label="Add SMS"
        style={{ width: 300 }}
        value={sms}
        onChange={e => {
          setSms(e.target.value.replace(/[^0-9]/g, ''));
        }}
      />
      <br />
      <br />
      <p>Cost per SMS : {perSmsCharge} Paise</p>
      <p>Minimum Recharge : {minAmount / rateOfSms} SMS</p>
      <button
        className="rounded-pill text-white text-uppercase border-0 w-100 p-3 bg-dark"
        onClick={e => {
          sms * rateOfSms >= minAmount
            ? getOrder({ amount: sms * rateOfSms }, onSuccess)
            : alert(
                `SMS should be greater than or equal to ${minAmount /
                  rateOfSms}`
              );
        }}
      >
        Submit
      </button>
    </div>
  );
};
