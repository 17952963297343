const initialState = {
  data: [],
  filters: [],
  graph: {},
  graphData: [],
};

const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_FILTER':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'HANDLE_GRAPH':
      return { ...state, graph: { ...action.payload } };
    case 'GRAPH_DATA':
      return { ...state, graphData: action.payload };
    case 'RESET_FILTER':
      return { ...state, filters: action.payload, graph: {} };
    default:
      return state;
  }
};
export default FilterReducer;
