import React from 'react';
import { Paper, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RewardScreen from './rewardScreen';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '1.5rem',
    backgroundSize: 'cover',
  },
  modal: {
    width: '40%',
    height: '50%',
    margin: '8em auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RewardModal = props => {
  const classes = useStyles();
  const { toggleModal, changeInPoints, showInvoice } = props;

  return (
    <Modal className={classes.modal} open={toggleModal}>
      <Paper className={classes.paper}>
        <RewardScreen
          changeInPoints={changeInPoints}
          showInvoice={showInvoice}
        />
      </Paper>
    </Modal>
  );
};

export default RewardModal;
