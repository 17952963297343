/* eslint-disable */
const initialState = {
  temporaryUsers: [],
};

const TemporaryUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_TEMPORARY_USERS': {
      return {
        ...state,
        temporaryUsers: action.payload,
      };
    }
    default:
      return state;
  }
};
export default TemporaryUsersReducer;
