/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import { GET_PROFIT_LOSS_REPORT } from './types';

export const getProfitLossReport = (payload, cb) => dispatch => {
  api
    .post('expenses/getProfitnLossReport', payload)
    .then(response => {
      cb && cb(true);
      dispatch({ type: GET_PROFIT_LOSS_REPORT, payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};

export const sendEmail = (payload, cb) => dispatch => {
  api
    .post('expenses/sendProfitnLossReport', payload)
    .then(response => {
      cb && cb(true);
      toasterMessage('success', 'Mail sent to Registered Email address');
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happened :(');
    });
};
