import {
  ADD_TO_SEARCH_LIST,
  ADD_TO_USERS_INVENTORY,
  SET_USERS_INVENTORY,
  SET_SEARCH_LIST,
  SET_LOADING_SEARCH_LIST,
} from './types';

const initialState = {
  searchList: [],
  usersInventory: [],
  loadingSearchList: false,
  productAdded: [false],
  fetchUsersInventory: [false],
};

const ManageInventoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_LIST: {
      return {
        ...state,
        searchList: payload,
      };
    }
    case ADD_TO_SEARCH_LIST: {
      return {
        ...state,
        searchList: [...state.searchList, ...payload],
      };
    }
    case SET_LOADING_SEARCH_LIST: {
      return {
        ...state,
        loadingSearchList: payload,
      };
    }
    case SET_USERS_INVENTORY: {
      return {
        ...state,
        usersInventory: payload,
      };
    }
    case ADD_TO_USERS_INVENTORY: {
      return {
        ...state,
        fetchUsersInventory: [true],
        // usersInventory: [payload, ...state.usersInventory]
      };
    }
    case 'PRODUCT_ADDED_STATUS': {
      return {
        ...state,
        productAdded: [payload],
      };
    }
    default:
      return state;
  }
};
export default ManageInventoryReducer;
