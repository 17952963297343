const initialState = {
  otpSent: false,
  customLogin: false,
  userContactNumber: '',
  userEmailId: '',
  isOnboarded: false,
  userToken: '',
  userId: '',
  user: {
    userData: [],
  },
  askGstDetails: -1,
  isGstUser: false,
  gstNumber: '',
  showOtpError: [false],
};

const otpLoginReducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'USER_LOGGED_IN': {
      let data = action.payload;
      return {
        ...state,
        user: data,
        userToken: data.userToken ?? '',
        isGstUser: data.userData[0].isGstNumber ? true : false,
      };
    }
    case 'SEND_OTP': {
      return {
        ...state,
        customLogin: false,
        otpSent: true,
        userContactNumber: data.userContactNumber,
      };
    }
    case 'LOGIN_VIA_GOOGLE': {
      return {
        ...state,
        customLogin: true,
        otpSent: false,
        isOnboarded: data.isOnboard !== 0 ? true : false,
        userEmailId: data.userEmailId,
        userToken: data.userToken,
        userId: data.userData[0].userId,
        user: data,
      };
    }
    case 'LOGIN_VIA_FACEBOOK': {
      return {
        ...state,
        customLogin: true,
        otpSent: false,
        isOnboarded: data.isOnboard !== 0 ? true : false,
        userEmailId: data.userEmailId,
        userToken: data.userToken,
        userId: data.userId,
        user: data,
      };
    }
    case 'VERIFY_OTP': {
      return {
        ...state,
        isOnboarded: data.isOnboard !== 0 ? true : false,
        userToken: data.userToken,
        userId: data.userData[0].userId,
        user: data,
        showOtpError: [false],
      };
    }
    case 'WRONG_OTP': {
      return {
        ...state,
        showOtpError: [action.data],
      };
    }
    case 'UPDATE_GST_DETAILS': {
      let payload = { isGstNumber: data.gst };
      if (data.gstNumber) payload = { ...payload, gstNumber: data.gstNumber };
      return {
        ...state,
        user: {
          ...state.user,
          userData: [{ ...state.user.userData[0], ...payload }],
        },
        isGstUser: data.gst ? true : false,
      };
    }
    case 'SET_ONBOARD':
      return {
        ...state,
        isOnboarded: action.payload,
        user: {
          ...state.user,
          userData: [{ ...state.user.userData[0], isOnboard: action.payload }],
        },
      };
    default:
      return state;
  }
};

export default otpLoginReducer;
