import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Container,
  Button,
  List,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../../CommonComponents/customInput';
import SearchIcon from '@material-ui/icons/Search';
import emptyCart from '../../../assets/svg/emptyCart.svg';
import InventoryInfo from './inventoryInfo';
import { useSelector, useDispatch } from 'react-redux';
import {
  searchInventory,
  setSearchList,
  getUserInventory,
} from './manageInventoryAction';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AddProductModal from '../../Billing/components/ProductComponents/addProductModal';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'grid',
    gridTemplateRows: '0.1fr 0.1fr auto',
    padding: '1rem',
    height: '91vh',
  },
  btn: {
    marginRight: '0.5rem',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },
  list: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));

const EmptyContainer = () => (
  <div style={{ justifySelf: 'center', alignSelf: 'center' }}>
    <img src={emptyCart} alt="Empty Cart Image" />
    <h6>Have no items in your inventory?</h6>
    <p className="subtitle text-center">Search to add/create products</p>
    <Button color="primary" fullWidth variant="contained">
      Ok
    </Button>
  </div>
);

const ManageInventoryContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showUsersInventory, setShowUsersInventory] = useState(true);
  const [toggleCreateProductModal, setToggleCreateProductModal] = useState(
    false
  );
  const [debouncingTime, setDebouncingTime] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchByBarcode, setSearchByBarcode] = useState(false);
  const usersInventory = useSelector(
    state => state.ManageInventoryReducer.usersInventory
  );
  const searchList = useSelector(
    state => state.ManageInventoryReducer.searchList
  );
  const loading = useSelector(
    state => state.ManageInventoryReducer.loadingSearchList
  );
  const productAdded = useSelector(
    state => state.ManageInventoryReducer.productAdded
  );
  const fetchUsersInventory = useSelector(
    state => state.ManageInventoryReducer.fetchUsersInventory
  );
  const [openToast, setOpenToast] = useState(false);
  const scrollref = useRef();

  useEffect(() => {
    setOpenToast(false);
    dispatch(getUserInventory({ numberOfItems: '50' }));
  }, []);

  useEffect(() => {
    setOpenToast(productAdded[0]);
  }, [productAdded]);

  useEffect(() => {
    if (fetchUsersInventory && fetchUsersInventory[0]) {
      dispatch(getUserInventory({ numberOfItems: '50' }));
    }
  }, [fetchUsersInventory]);

  const loadMore = useCallback(() => {
    if (searchByBarcode || searchList.length < pageNumber * 50) return;
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight >=
      scrollref.current.scrollHeight
    ) {
      let pg = pageNumber + 1;
      dispatch(
        searchInventory({ name: searchQuery, page: pg, numberOfItems: '50' })
      );
      setPageNumber(pg);
    }
  }, [loading, searchList]);

  const handleSearch = event => {
    let query = event.target.value;
    setSearchQuery(query);
    if (query) {
      if (query.length >= 3) {
        setShowUsersInventory(false);
        debouncingTime && clearTimeout(debouncingTime);
        let time = setTimeout(() => {
          let payload = {
            name: query,
            page: '1',
            numberOfItems: '50',
            isBarcode: false,
          };
          if (searchByBarcode) {
            payload = { ...payload, numberOfItems: '1', isBarcode: true };
          }
          dispatch(searchInventory(payload));
          try {
            if (scrollref) scrollref.current.scrollTo(0, 0);
          } catch (error) {
            // do notihing
          }
        }, 1000);
        setDebouncingTime(time);
      }
    } else {
      setShowUsersInventory(true);
      dispatch(setSearchList([]));
      setPageNumber(1);
    }
  };

  const handleToastClose = () => {
    setTimeout(() => {
      setOpenToast(false);
    }, 1000);
  };

  return (
    <Container className={classes.cont} maxWidth="sm">
      <CustomInput
        divStyle={{
          display: 'grid',
          padding: '0.4rem',
          gridTemplateColumns: '0.05fr auto 0.4fr',
        }}
        beforeTextField={<SearchIcon className="inputContainerIcon" />}
        inputLabel={searchByBarcode ? 'Search by code' : 'Search by name'}
        onChange={handleSearch}
        value={searchQuery}
        afterTextField={
          <Button
            variant="outlined"
            onClick={() => {
              analyticFunc(eventsPayload.ManageInventory[3]);
              setToggleCreateProductModal(!toggleCreateProductModal);
            }}
            className={`${classes.btn} mt-0`}
          >
            Create Product
          </Button>
        }
      />
      <FormControlLabel
        style={{ justifyContent: 'start' }}
        value="Search by barcode"
        control={
          <Checkbox
            checked={searchByBarcode}
            color="primary"
            onChange={e => setSearchByBarcode(e.target.checked)}
          />
        }
        label={<p className="formTitle">Search by Barcode</p>}
        labelPlacement="start"
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openToast}
        autoHideDuration={5000}
        onClose={handleToastClose}
      >
        <MuiAlert
          variant="filled"
          onClose={handleToastClose}
          severity={productAdded[0] ? 'success' : 'error'}
        >
          {productAdded[0]
            ? 'Product added to your Inventory!'
            : 'Unable to add the product'}
        </MuiAlert>
      </Snackbar>

      {loading && <LinearProgress />}
      {showUsersInventory && usersInventory.length === 0 && <EmptyContainer />}
      {showUsersInventory && usersInventory.length > 0 && (
        <List className={classes.list}>
          {usersInventory.map(userInventoryItem => (
            <InventoryInfo
              key={userInventoryItem.modelId}
              userInventoryItem={userInventoryItem}
            />
          ))}
        </List>
      )}

      {!showUsersInventory && (
        <List className={classes.list} ref={scrollref} onScroll={loadMore}>
          {searchList.map((searchedInventoryItem, index) => (
            <InventoryInfo
              key={searchedInventoryItem.modelId}
              searchedInventoryItem={searchedInventoryItem}
            />
          ))}
        </List>
      )}

      <AddProductModal
        toggleModal={toggleCreateProductModal}
        setToggleModal={setToggleCreateProductModal}
        from={'inventory'}
      />
    </Container>
  );
};

export default ManageInventoryContainer;
