/* eslint-disable */
const initialState = {
  settings: [],
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONFIGURATION': {
      return {
        ...state,
        settings: action.payload,
      };
    }
    default:
      return state;
  }
};
export default configReducer;
