/* eslint-disable */
const initialState = {
  faqs: [],
  faq: {},
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FAQs': {
      return {
        ...state,
        faqs: action.payload,
      };
    }
    case 'SHOW_FAQ': {
      return {
        ...state,
        faq: action.payload,
      };
    }
    case 'HANDLE_FAQ': {
      return {
        ...state,
        faq: { ...state.faq, ...action.payload },
      };
    }
    case 'RESET_FAQ': {
      return {
        ...state,
        faq: {},
      };
    }
    default:
      return state;
  }
};
export default faqReducer;
