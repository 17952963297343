import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'grid',
    gridTemplateRows: '0.3fr 0.3fr 0.4fr',
    gridTemplateColumns: '0.7fr 0.3fr',
    padding: '1rem',
    background: 'white',
    borderRadius: '1rem',
    marginBottom: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paid: {
    borderRadius: '1rem',
    marginBottom: '0.4rem',
    alignSelf: 'start',
    justifySelf: 'start',
    marginTop: '0.6rem',
    gridRow: '3',
    gridColumn: '1',
    padding: '0.3rem 0.8rem',
    border: '0.1rem solid #00BA88',
    color: '#00BA88',
  },
  unpaid: {
    borderRadius: '1rem',
    marginBottom: '0.4rem',
    alignSelf: 'start',
    justifySelf: 'start',
    marginTop: '0.6rem',
    gridRow: '3',
    gridColumn: '1',
    padding: '0.3rem 0.8rem',
    border: '0.1rem solid #C30052',
    background: '#C30052',
    color: 'white',
  },
  date: {
    color: '#6E7191',
    marginBottom: '0.4rem',
    gridRow: '2',
    gridColumn: '1',
  },
  amount: {
    fontWeight: '600',
    marginBottom: '0.4rem',
    fontSize: '1.5rem',
    paddingTop: '0.6rem',
    gridRow: '2 / -1',
    gridColumn: '2',
  },
  name: {
    fontWeight: '500',
    marginBottom: '0.4rem',
    gridRow: '1',
    gridColumn: '1',
  },
}));

const InvoiceInfo = props => {
  const classes = useStyles();
  const { invoice, onClick } = props;
  const { customerName, billId, invoiceDate, status, totalAmount } = invoice;

  return (
    <div onClick={onClick} className={classes.cont}>
      <p className={classes.name}>
        {customerName ? customerName : 'Name not found'}
      </p>
      <div>
        <span className={classes.date}>Invoice</span>
        <span style={{ fontWeight: 'bold' }}> #{billId}</span>
      </div>
      <p className={classes.date}>{invoiceDate}</p>
      <h3 className={classes.amount}>Rs {totalAmount} /-</h3>
      <p
        className={
          status === 1 || status === 12 ? classes.paid : classes.unpaid
        }
      >
        {status === 1 || status === 12 ? 'Completed' : 'Cancelled'}
      </p>
    </div>
  );
};

export default InvoiceInfo;
