/* eslint-disable */
import React from 'react';
import { Bar } from 'react-chartjs-2';
// import "./styles.css";

const ComboChart = React.memo(({ ComboData }) => {
  const data2 = {
    labels: ['0-30 Days', '30-60 Days', '60-90 Days', '90+ Days'],
    datasets: [
      {
        label: 'Value',
        type: 'line',
        data: ComboData.VData,
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
        yAxisID: 'y-axis-2',
      },
      {
        type: 'bar',
        label: 'Quantity',
        data: ComboData.QData,
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    responsive: true,
    tooltips: {
      mode: 'label',
    },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: false,
          },
          // labels: {
          //   show: true
          // }
        },
      ],
      yAxes: [
        {
          title: 'Quantity',
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false,
          },
          label: 'Quantity',
          labelString: 'lklksgh hgfhgf',
          labels: {
            show: true,
          },
        },
        {
          title: 'value',
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
  };

  return (
    <div>
      <Bar data={data2} options={options} />
    </div>
  );
});

export default ComboChart;
