import api from '../../api/api';
import axios from '../../../node_modules/axios';
import { toasterMessage } from '../../@utils/utils';

export const fetchLocation = pincode => async dispatch => {
  api
    .get(`user/pincode/${pincode}`)
    .then(response => {
      const { data: pincodeInfo } = response.data;
      const state = pincodeInfo.state ?? '';
      const city = pincodeInfo.city ?? '';

      if (city !== '' && state !== '') {
        const data = { State: state, District: city };

        dispatch({
          type: 'GET_LOCATION',
          data,
        });
      } else {
        dispatch({ type: 'INVALID_PINCODE' });
      }
    })
    .catch(error => {
      dispatch({
        type: 'INVALID_PINCODE',
      });
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const fetchSuperCategory = payload => async dispatch => {
  // dispatch({ type: 'SET_LOADING', payload: true })
  await api
    .get('/category/getSuperCategoryList')
    .then(response => {
      const { data } = response.data;
      if (response.status === 200) {
        dispatch({ type: 'GET_SUPER_CATEGORY', data: data });
      }
    })
    .catch(error => {
      // toasterMessage('error', error.response.data.error)
      console.log('SuperError', error);
    })
    .catch(() => {
      console.log('SuperError', 'error in SuperDuper');
      // toasterMessage('error', 'Something bad happenned')
    });
  // dispatch({ type: 'SET_LOADING', payload: false })
};

export const setTradeData = payload => async dispatch => {
  dispatch({ type: 'SET_TRADE_DATA', data: payload });
};

export const sendOnboardingDetails = (payload, cb) => async dispatch => {
  api
    .post('user/onboard', payload)
    .then(response => {
      dispatch({ type: 'SET_ONBOARD', payload: true });
      if (cb) cb();
      // dispatch({
      //   type: "SEND_ONBOARDING_DETAILS",
      // });
    })
    .catch(error => {
      console.log(error.response.data.error);
    })
    .catch(() => {
      //   toasterMessage('error', 'Something bad happened')
      console.log('Needs testing');
    });
};
