import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { toasterMessage } from '../../../@utils/utils';
import RewardPointsInfo from './components/rewardPointsInfo';
import RewardsHistory from './components/rewardsHistory';
import GiftCardSection from './components/giftCardSection';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'grid',
    height: '90vh',
    gridTemplateColumns: '0.3fr 0.7fr',
    // minWidth: "960px",
  },
  rewardsInfoSection: {
    display: 'grid',
    gridTemplateRows: '12rem auto',
    padding: '2rem 3rem 0 3rem',
  },
}));

const RewardsContainer = props => {
  const classes = useStyles();

  return (
    <div className={classes.cont}>
      <Container maxWidth="xs" className={classes.rewardsInfoSection}>
        <RewardPointsInfo />
        <RewardsHistory />
      </Container>
      <GiftCardSection />
    </div>
  );
};

const mapStateToProps = ({ RewardsReducer }) => {
  return {};
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(null, null)(RewardsContainer);
