import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Container } from '@material-ui/core';
import logo_small from './../../assets/svg/logoSmall.svg';
import {
  sendOtp,
  verifyOtp,
  wrongOtpSent,
  otpVerified,
  otpSentInform,
} from './../../store/AdminActions/otpLoginAction';
import TermsAndConditionModal from './termsAndConditionModal';
import { toasterMessage } from '../../@utils/utils';
import CustomInput from '../../CommonComponents/customInput';
import analyticFunc from '../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../GoogleAnalytics/events_payload';

const checkOtpAgainstRegex = enteredOtp => {
  var otpRegex = /^\d{6}$/;
  return otpRegex.test(enteredOtp);
};

const OtpVerify = props => {
  // Countdown Timer
  const [time, setTime] = useState(30);
  // Resend OTP button should be disabled or not
  const [buttonDisabled, setButtonDisabled] = useState(true);
  // Resend Button Text
  const [buttonText, setButtonText] = useState('Resend OTP in ');
  // Entered Otp
  const [enteredOtp, setEnteredOtp] = useState('');
  const [showOtpError, setShowOtpError] = useState(false);
  const [referrer_link, setReferrerLink] = useState(null);
  const [
    toggleTermsAndConditionModal,
    setToggleTermsAndConditionModal,
  ] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const index = url.indexOf('?');
    if (index !== -1) {
      setReferrerLink(url.substring(index + 1));
    }
    if (!props.userContactNumber) {
      props.history.replace('/');
    }
    return () => {
      props.wrongOtpSent(false);
    };
  }, []);

  useEffect(() => {
    setShowOtpError(props.showOtpError[0]);
  }, [props.showOtpError]);

  useEffect(() => {
    // exit early when we reach 0
    if (!time) {
      setTime(null);
      setButtonText('Resend OTP');
      setButtonDisabled(false);
      return;
    }

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add time as a dependency to re-rerun the effect when we update it
  }, [time]);

  const dispatchVerifyOtp = useCallback(async event => {
    analyticFunc(eventsPayload.Onboarding[4]);
    event.preventDefault();
    if (checkOtpAgainstRegex(enteredOtp)) {
      let payload = {
        loginType: 'Custom',
        contactNumber: props.userContactNumber,
        otp: enteredOtp,
      };
      if (referrer_link) {
        payload = {
          ...payload,
          referrer_link,
        };
      }

      props
        .verifyOtp(payload)
        .then(response => {
          const { data } = response.data;
          localStorage.setItem('token', data.userToken);
          props.otpVerified(data);
          props.history.replace(
            data.isOnboard === 0 ? '/onboarding' : '/dashboard'
          );
        })
        .catch(error => {
          props.wrongOtpSent(true);
        })
        .catch(() => {
          toasterMessage('error', 'Something bad happened');
        });
    }
  });

  const resendOtp = useCallback(async event => {
    analyticFunc(eventsPayload.Onboarding[3]);
    event.preventDefault();

    let payload = {
      contactNumber: props.userContactNumber,
      type: 'LOGIN',
    };

    await props
      .resendOtp(payload)
      .then(response => {
        const { data } = response.data;
        let pay = { ...data, userContactNumber: props.userContactNumber };
        props.otpSentInform(pay);
      })
      .catch(error => {
        toasterMessage('error', error.response.data.error);
      })
      .catch(() => {
        toasterMessage('error', 'Something bad happened');
      });

    // Reset the timer to 30 seconds
    setTime(30);
    // Button Should be disabled after resending OTP
    setButtonDisabled(true);
    setButtonText('Resend OTP in ');
  });

  return (
    <div className="flexedCenterDiv">
      <Container className="flexedCenterContainer" maxWidth="xs">
        <img
          style={{ height: '15%', width: '30%', margin: '0 auto' }}
          src={logo_small}
        />
        <div>
          <p className="formTitle">OTP sent to +91-{props.userContactNumber}</p>
          <CustomInput
            error={showOtpError}
            inputLabel={'Enter OTP'}
            autoFocus={true}
            onChange={e => {
              setEnteredOtp(e.target.value);
              setShowOtpError(false);
            }}
          />

          {showOtpError && (
            <p className="errorLine">Oops. Please check the OTP again</p>
          )}
          <Button
            onClick={dispatchVerifyOtp}
            fullWidth
            className="mt-3"
            color="primary"
            variant="contained"
            disabled={enteredOtp.length !== 6 ? true : false}
          >
            Verify OTP
          </Button>

          <div
            style={{
              display: 'inline',
              float: 'left',
              cursor: `${!buttonDisabled ? 'pointer' : 'default'}`,
              color: `${!buttonDisabled ? '#FE805C' : '#EEAF9F'}`,
              margin: '8px',
            }}
            onClick={buttonDisabled ? undefined : resendOtp}
          >
            {buttonText}
            {time}
          </div>
        </div>
        <div>
          <p
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: '0px',
            }}
          >
            By continuing you agree to our
          </p>
          <a
            style={{
              display: 'block',
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#FE805C',
              margin: '0 auto',
            }}
            target="_blank"
            href="https://mbillapp.com/terms-conditions/"
          >
            terms and conditions
          </a>
        </div>
      </Container>
      <TermsAndConditionModal
        toggleModal={toggleTermsAndConditionModal}
        setToggleModal={setToggleTermsAndConditionModal}
      />
    </div>
  );
};

OtpVerify.propTypes = {
  userContactNumber: PropTypes.string.isRequired,
};

const mapStateToProps = ({ OtpLoginReducer }) => {
  return {
    userContactNumber: OtpLoginReducer.userContactNumber,
    userToken: OtpLoginReducer.userToken,
    userId: OtpLoginReducer.userId,
    showOtpError: OtpLoginReducer.showOtpError,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    resendOtp: payload => dispatch(sendOtp(payload)),
    verifyOtp: payload => dispatch(verifyOtp(payload)),
    otpSentInform: payload => dispatch(otpSentInform(payload)),
    wrongOtpSent: payload => dispatch(wrongOtpSent(payload)),
    otpVerified: payload => dispatch(otpVerified(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerify);
