import { SEARCH_INVOICE } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  invoices: [],
};

const SearchInvoice = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_INVOICE: {
      return {
        ...state,
        invoices: payload ? [...state.invoices, ...payload] : [],
      };
    }
    case 'CLEAR_INVOICE': {
      return {
        ...state,
        invoices: [],
      };
    }
    default:
      return state;
  }
};
export default SearchInvoice;
