import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const TermsAndConditionModal = props => {
  const { toggleModal, setToggleModal } = props;
  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '30rem',
        maxWidth: '40%',
        height: '80%',
      }}
      open={toggleModal}
      onClose={() => setToggleModal(!toggleModal)}
    >
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '3rem',
          borderRadius: '1.5rem',
        }}
      >
        <p style={{ textAlign: 'center' }} className="formTitle">
          Terms & Conditions
        </p>
        <p>
          By using the mbill app you are agreeing to be bound by the following
          terms and conditions (“Terms of Service”). mbill is a product of
          PredictiVu Pvt Ltd. mbill reserves the right to update and change the
          Terms of Service from time to time without notice. Any new features
          that augment or enhance the current Service, including the release of
          new tools and resources, shall be subject to the Terms of Service.
          Continued use of the Service after any such changes shall constitute
          your consent to such changes. Violation of any of the terms below will
          result in the termination of your Account. While mbill prohibits such
          conduct and Content on the Service, you understand and agree that
          mbill cannot be responsible for the Content posted on the Service and
          you nonetheless may be exposed to such materials. You hereby agree to
          use the Service at your own risk. You hereby agree to share your sales
          data with the mbill for the specific purpose of making market reports.
        </p>
        <Button onClick={() => setToggleModal(!toggleModal)} color="primary">
          Ok
        </Button>
      </Paper>
    </Modal>
  );
};

export default TermsAndConditionModal;
