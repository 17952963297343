export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_SERIAL_NUMBER = 'GET_SERIAL_NUMBER';
export const ADD_PRODUCT_TO_LIST = 'ADD_PRODUCT_TO_LIST';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR';
export const CONTACT_SELECTED = 'CONTACT_SELECTED';
export const EDIT_BILL = 'EDIT_BILL';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const REMOVE_PRODUCT_FROM_LIST = 'REMOVE_PRODUCT_FROM_LIST';
export const GET_MORE_PRODUCTS = 'GET_MORE_PRODUCTS';
export const SET_LOADING_PRODUCT = 'SET_LOADING_PRODUCT_SEARCH';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_LOADING_CONTACT = 'SET_LOADING_CONTACT';
export const SET_LOADING_DISTRIBUTOR = 'SET_LOADING_DISTRIBUTOR';
export const VIEW_INVOICE = 'VIEW_INVOICE';
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID';
export const CLEAR_SELECTED_PRODUCTS = 'CLEAR_SELECTED_PRODUCTS';
