/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { Paper, Grid } from '@material-ui/core';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { getFAQ } from '../../../store/RetailerWebStore/RetailerAction/faq';
import SearchBar from '../../WebComponents/SearchBar';
import Loader from '../../Loader';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  expendedPanel: {
    background: 'grey',
  },
}));

const FAQ = React.memo(({ getFAQ, list, loading, getNotificationCount }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('');
  const [faqList, setFaqList] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const scrollref = useRef();

  useEffect(() => {
    getFAQ();
    getNotificationCount();
  }, []);

  useEffect(() => {
    setFaqList(list);
  }, [list]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getSearchKeyword = useCallback(value => {
    console.log('SEARCH VAL--->', value);
    let newList = [];

    list.map(item => {
      if (item.question.includes(value)) {
        list.push(item);
      }
    });
    setFaqList(newList);
    console.log('NEW FILTER LIST--->', newList);
  });

  return (
    <div className={classes.root}>
      {/* <div style={{textAlign:"left", marginLeft:'2%', marginTop:'1%'}}>
            <h3>FAQ'S</h3>
            </div> */}

      <Paper className="p-2 m-2 ">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="border-bottom pb-2 ">FAQ'S</h5>
          <SearchBar getSearchKeyword={getSearchKeyword} />
        </div>
        <br />
        <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
          {/* <OutlinedCard detailsPage={detailsPage}  data = {credits}/> */}
          {loading ? (
            <Loader />
          ) : (
            faqList &&
            faqList.map((item, i) => (
              <ExpansionPanel
                expanded={expanded === `panel${i + 1}`}
                onChange={handleChange(`panel${i + 1}`)}
                key={i}
                style={{ margin: '2% 1%' }}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={
                    expanded === `panel${i + 1}` ? classes.expendedPanel : null
                  }
                >
                  <Typography className={classes.heading}>
                    {item.question}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>{item.answer}</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          )}
        </div>
      </Paper>
    </div>
  );
});

const mapStateToProps = ({ FAQ, Loader }) => {
  return {
    list: FAQ.faqList,
    loading: Loader.loading,
  };
};

export default connect(mapStateToProps, { getFAQ, getNotificationCount })(FAQ);
