/* eslint-disable */
const initialState = {
  managers: [],
  designations: [],
  callerIds: [],
  higherEmployees: [],
};

const ManagerDesignationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MANAGER': {
      return {
        ...state,
        managers: action.payload,
      };
    }
    case 'SHOW_HIGHER_EMPLOYEES': {
      return {
        ...state,
        higherEmployees: action.payload,
      };
    }
    case 'SHOW_DESIGNATION': {
      return {
        ...state,
        designations: action.payload,
      };
    }
    case 'SHOW_CALLER_IDS': {
      return {
        ...state,
        callerIds: action.payload,
      };
    }
    default:
      return state;
  }
};
export default ManagerDesignationReducer;
