/* eslint-disable */
const initialState = {
  subcategories: [],
  subcategory: [],
};

const getSubCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIEW SUBCATEGORIES': {
      return {
        ...state,
        subcategories: action.payload,
      };
    }
    case 'SHOW SUBCATEGORY': {
      return {
        ...state,
        subcategory: action.payload,
      };
    }
    case 'HANDLE_SUBCATEGORY':
      return {
        ...state,
        subcategory: { ...state.subcategory, ...action.payload },
      };
    case 'RESET_SUBCATEGORY':
      return { ...state, subcategory: {} };
    case 'RESET_SUBCATEGORIES': {
      return {
        ...state,
        subcategories: [],
      };
    }
    case 'SEARCH_SUBCATEGORY':
      return {
        ...state,
        subcategories: action.payload.subcategories,
      };
    case 'RESET_SEARCH_SUBCATEGORY': {
      return { ...state, subcategories: [] };
    }
    default:
      return state;
  }
};
export default getSubCategoriesReducer;
