import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mbVouchersImage from '../../../../assets/svg/mobile-survey-vouchers.svg';
import webVouchersImage from '../../../../assets/svg/web-survey-vouchers.svg';
import InnerHTML from 'dangerously-set-html-content';
import { CircularProgress } from '@material-ui/core';
import api, { baseURL } from '../../../../api/api';
import { toasterMessage } from '../../../../@utils/utils';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  surveySvg: {
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: '55%',
    },
  },
  surveyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  surveyMainText: {
    fontSize: '3.1125rem',
    fontWeight: '800',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  link: {
    color: '#FE805C',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    width: '50%',
    display: 'block',
    margin: '0 auto',
    marginBottom: '1rem',
  },
}));

const FillSurveyStatic = props => {
  const classes = useStyles(props);
  const { response, fbCred } = props;

  const [staticUrl, setStaticUrl] = useState(
    'https://admin.gosurvey.in/survey#/YDRIERRW'
  );
  const [showButton, setShowButton] = useState(true);
  const [showRecommendations, setShowRecommendations] = useState(true);
  const [htmlLoading, setHtmlLoading] = useState(false);
  const [html, setHtml] = useState();

  const currWidth = window.innerWidth;
  const breakPoint = 500;

  const getHtml = () => {
    if (!html) {
      setHtmlLoading(true);
      const url = new URL(`${baseURL.baseURL}survey/getUserRecommendations`);
      url.searchParams.append('userId', fbCred.userId);
      url.searchParams.append('userToken', fbCred.userToken);
      api
        .get(url)
        .then(response => {
          const { data } = response.data;
          setHtmlLoading(false);
          setShowButton(false);
          setShowRecommendations(true);
          setHtml(data.html);
        })
        .catch(error => {
          setHtmlLoading(false);
          toasterMessage('error', 'Something From server happened');
        })
        .catch(() => {
          setHtmlLoading(false);
          toasterMessage('error', 'Something bad happened');
        });
    } else {
      setShowButton(false);
      setShowRecommendations(true);
    }
  };
  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div className={classes.surveyContainer}>
      <div
        style={{
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div className={classes.surveyMainText}>
          <span style={{ color: '#3F3D56' }}>
            Take a quick survey & <br />
          </span>
          <span style={{ color: '#FE805C' }}> win gift vouchers</span>{' '}
        </div>
        <div>
          <p
            style={{
              textAlign: 'center',
              margin: '0 auto 1rem auto',
              fontSize: '0.8rem',
              width: currWidth > breakPoint ? '65%' : '85%',
            }}
          >
            The voucher awarded are based on your gender, Facebook liked pages,
            tagged locations and current location{' '}
          </p>
          {/* {is_filled == 1 && (
            <p className="errorLine">You have already filled the survey.</p>
          )} */}
          <Button
            variant="contained"
            onClick={() => openInNewTab(staticUrl)}
            color="primary"
            className={classes.btn}
          >
            Let's Go
            {/* {is_filled ? "See Rewards" : "Let's Go"} */}
          </Button>
        </div>
      </div>
      <img
        src={currWidth > breakPoint ? webVouchersImage : mbVouchersImage}
        className={classes.surveySvg}
      />
      {fbCred && showButton && (
        <div
          style={{
            float: 'right',
            position: 'fixed',
            right: currWidth > breakPoint ? '1rem' : '0',
            bottom: '1rem',
            width: currWidth > breakPoint ? '25%' : 'auto',
            padding: '1rem',
            fontSize: '0.8rem',
            backgroundColor: 'white',
            borderRadius: '1rem',
          }}
        >
          <p>
            The recommender is a tool that gives you personalized
            recommendations from our news, products and services.
          </p>
          <Button
            style={{
              borderRadius: '0.5rem',
              padding: '0.41rem',
              fontSize: '0.2rem',
            }}
            variant="contained"
            color="primary"
            onClick={() => getHtml()}
          >
            Get Recommendations
            {htmlLoading ? (
              <CircularProgress
                style={{ marginLeft: '0.3rem' }}
                color="secondary"
                size="1.5rem"
              />
            ) : (
              ' '
            )}
          </Button>
        </div>
      )}
      {html && (
        <div
          style={{
            borderRadius: '0.5rem',
            width: currWidth > breakPoint ? '45%' : 'auto',
            backgroundColor: '#F7F7FC',
            boxShadow: '-0.1rem -0.1rem 1rem  1px #E5E5E5 ',
            position: 'fixed',
            right: showRecommendations
              ? currWidth > breakPoint
                ? '1rem'
                : '0'
              : '-120rem',
            bottom: '1rem',
          }}
        >
          <a
            role="button"
            style={{
              textAlign: 'right',
              display: 'block',
              marginRight: '1rem',
              marginTop: '0.5rem',
            }}
            onClick={() => {
              setShowButton(true);
              setShowRecommendations(false);
            }}
          >
            <ClearIcon />
          </a>
          <InnerHTML html={html} />
        </div>
      )}
    </div>
  );
};

export default FillSurveyStatic;
