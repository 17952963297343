/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const RenderList = React.memo(({ list, handleNext }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {list.map((item, i) => (
        <div key={i} className="list-hover hover-pointer">
          <ListItem
            onClick={e =>
              handleNext({
                id: item.id,
                name: item.name,
                categoryId: item.categoryId,
                brandId: item.brandId,
                HSN: item.HSN,
                MRP: item.MRP,
                add: item.add,
                superCategoryId: item.superCategoryId,
                static: item.static,
              })
            }
          >
            <div className="w-100" style={{ lineHeight: '5px' }}>
              <ListItemText primary={item.name} />
              <small className="secondary">{`Quantity: ${item.count} Days: ${item.days}`}</small>
            </div>
            <IconButton>
              <ChevronRight />
            </IconButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
});
