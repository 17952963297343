import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {
  fetchLocation,
  setTradeData,
} from './../../store/AdminActions/onboardingAction';
import locationIcon from '../../assets/svg/location.svg';
import CustomInput from '../../CommonComponents/customInput';

const useStyles = makeStyles(theme => ({
  welcomeTitle: {
    fontSize: '36px',
    fontWeight: '600',
    margin: '0px auto',
    color: theme.palette.primary.main,
    lineHeight: '54px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  divStyle: {
    display: 'flex',
    flexDirection: 'column',
    // border: "1px solid black",
    margin: '0 auto',
    marginTop: '10%',
  },
  container: { justifyContent: 'unset' },
  title2: { fontSize: '24px', fontWeight: '600', margin: '18px 0px' },
  button: { marginTop: '10%' },
}));

const Onboarding = props => {
  const classes = useStyles();
  const [shopName, setShopName] = useState('');
  const [pincodeText, setPincodeText] = useState('');
  const [showUserLocation, setShowUserLocation] = useState(false);
  const [showPincodeError, setShowPincodeError] = useState(false);

  useEffect(() => {
    if (!props.userToken) {
      props.history.replace('/');
    } else if (props.isOnboarded) {
      props.history.replace('/dashboard');
    } else if (props.userToken && props.isValidPincode && props.shopName) {
      props.history.push('/categorySelection');
    }
  }, []);

  useEffect(() => {
    setShowUserLocation(false);
    let input = Number(pincodeText);
    var pincodeRegex = /^\d{6}$/;
    setShowPincodeError(false);
    if (!isNaN(input)) {
      if (pincodeRegex.test(pincodeText)) {
        props.fetchLocation(pincodeText);
      }
    } else {
      setShowPincodeError(true);
    }
  }, [pincodeText]);

  useEffect(() => {
    setShowUserLocation(props.isValidPincode[0]);
    setShowPincodeError(pincodeText && !props.isValidPincode[0]);
  }, [props.isValidPincode]);

  const handleClick = () => {
    if (props.userState && shopName && showUserLocation) {
      let payload = {
        userPincode: pincodeText,
        userTradeName: shopName,
      };
      props.setTradeData(payload);
      // Navigate to category selection
      props.history.push('/categorySelection');
    }
  };

  return (
    <div className="flexedCenterDiv">
      <Container
        className={['flexedCenterContainer', classes.container].join(' ')}
        maxWidth="sm"
      >
        <p className={classes.welcomeTitle}>
          Welcome, <br />
          we are glad you are here!
        </p>
        <div className={classes.divStyle}>
          <p className={classes.title2}>Now, let’s setup your business.</p>
          <p className="formTitle">What’s your business called?*</p>
          <div className="inputContainer">
            <TextField
              fullWidth
              autoFocus={true}
              label="Shop/Business/Brand Name"
              onChange={e => setShopName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>

          <p className="formTitle">Where is it based at?*</p>
          <CustomInput
            error={showPincodeError}
            beforeTextField={
              <img
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: '0.4rem',
                }}
                src={locationIcon}
                alt="Location Icon"
              />
            }
            inputLabel={'Enter Pincode'}
            onChange={e => setPincodeText(e.target.value)}
          />
          {showPincodeError && (
            <p className="errorLine">Please, check your pincode</p>
          )}
          {/* <div className="inputContainer">
            <LocationOnOutlinedIcon className="inputContainerIcon" />
            <TextField
              fullWidth
              label="Enter Pincode"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div> */}

          {showUserLocation && (
            <p>{`${props.userDistrict}, ${props.userState}`}</p>
          )}
          <Button
            className={classes.button}
            onClick={handleClick}
            fullWidth
            color="primary"
            variant="contained"
            disabled={shopName && props.isValidPincode ? false : true}
          >
            Choose Categories
          </Button>
        </div>
      </Container>
    </div>
  );
};

Onboarding.propTypes = {
  shopName: PropTypes.string,
  showUserLocation: PropTypes.bool,
  pincodeText: PropTypes.number,
  userState: PropTypes.string,
  isValidPincode: PropTypes.bool,
  userDistrict: PropTypes.string,
};

const mapStateToProps = ({ OnboardingReducer, OtpLoginReducer }) => {
  return {
    userState: OnboardingReducer.userState,
    userDistrict: OnboardingReducer.userDistrict,
    isValidPincode: OnboardingReducer.isValidPincode,
    userToken: OtpLoginReducer.userToken,
    isOnboarded: OtpLoginReducer.isOnboarded,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    fetchLocation: pincode => dispatch(fetchLocation(pincode)),
    setTradeData: payload => dispatch(setTradeData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
