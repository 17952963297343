import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Container,
  List,
  Divider,
  MenuItem,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CustomInput from '../../../CommonComponents/customInput';

const paymentMethods = [
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'card',
    label: 'Card (Debit/Credit)',
  },
  {
    value: 'finance',
    label: 'Finance / Credit',
  },
  {
    value: 'upi',
    label: 'UPI',
  },
  {
    value: 'net-banking',
    label: 'Net Banking',
  },

  {
    value: 'other',
    label: 'Other',
  },
];

const PaymentMethod = props => {
  const {
    from,
    payments,
    handleInputChange,
    handleSelection,
    financeError,
    monthsError,
    remarks,
    setRemarks,
  } = props;
  const { paymentMode1Amount: p1a, paymentMode1: p1m } = payments[0];
  const { paymentMode2Amount: p2a, paymentMode2: p2m } = payments[1];

  const {
    financeAmount: fa,
    emiAmount: fea,
    downPayment: fdp,
    numberOfMonths: fnm,
    startDateEmi: fsd,
  } = payments[2];

  let method = 'xyz';
  let amount = -99999;
  if (from === 'first') {
    method = p1m;
    amount = p1a;
  } else {
    method = p2m;
    amount = p2a;
  }

  const handlePaymentChange = event => {
    handleSelection(event.target.value, from);
  };
  const nonNum = ['remark', 'startDateEmi'];

  const handleChange = (e, section) => {
    let value = 'xxxx';
    if (nonNum.includes(section)) {
      value =
        section == 'remark' ? e.target.value : e.toISOString().split('T')[0];
    } else if (!isNaN(e.target.value)) {
      value = e.target.value;
    }
    handleInputChange(value, from, section);
  };

  return (
    <div className="mr-4">
      <div className="inputContainer mb-2 ">
        <TextField
          select
          label="Select Payment Method"
          value={method}
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          onChange={handlePaymentChange}
        >
          {paymentMethods.map(paymentOption => (
            <MenuItem key={paymentOption.value} value={paymentOption.value}>
              {paymentOption.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {method !== 'finance' && (
        <>
          <CustomInput
            inputLabel={'Enter Amount'}
            disabled
            value={amount}
            onChange={e =>
              handleChange(e, `paymentMode${from == 'first' ? 1 : 2}Amount`)
            }
          />
          <CustomInput
            value={remarks}
            onChange={e => handleChange(e, 'remark')}
            inputLabel="Remarks"
          />
        </>
      )}
      {method == 'finance' && (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '0.8rem',
            }}
          >
            <CustomInput
              error={financeError}
              inputLabel={'Down Payment'}
              value={fdp}
              onChange={e => handleChange(e, 'downPayment')}
            />
            <CustomInput
              inputLabel={'Finance Amount'}
              disabled={true}
              value={fa}
            />
          </div>
          {financeError && (
            <p className="errorLine">Amount should be less than total Amount</p>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '0.8rem',
            }}
          >
            <CustomInput
              inputLabel={'No. of Months'}
              error={monthsError}
              value={fnm}
              onChange={e => handleChange(e, 'numberOfMonths')}
            />
            <div className="inputContainer mb-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="dd-MM-yyyy"
                  margin="normal"
                  value={fsd}
                  autoOk
                  InputProps={{
                    disableUnderline: true,
                  }}
                  allowKeyboardControl
                  id="date-picker-inline"
                  onChange={e => handleChange(e, 'startDateEmi')}
                  label="Starting From"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          {monthsError && (
            <p className="errorLine">
              Number of months should be greater than 0
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentMethod;
