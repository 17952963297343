import React, { useState, useEffect } from 'react';
import { Paper, Modal, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '2em',
    marginTop: '5em',
    maxWidth: '30vw',
    borderRadius: '1.5rem',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
  text: {
    fontFamily: 'poppins',
    textAlign: 'center',
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.5em',
  },
  btn: {
    width: '13em',
  },
}));

const DeleteUserModal = ({
  toggleModal,
  setToggleModal,
  handleDeleteUser,
  subUser,
}) => {
  const classes = useStyles();
  return (
    <Modal className={classes.modal} open={toggleModal}>
      <Paper className={classes.paper}>
        <Typography className={classes.text}>
          Are you sure you want to delete{' '}
          <span style={{ fontWeight: '600' }}>{subUser.name}</span> from
          sub-users?
        </Typography>
        <div className={classes.btnGroup}>
          <Button
            variant="outlined"
            className={classes.btn}
            onClick={() => {
              handleDeleteUser(subUser.userId);
              setToggleModal(false);
            }}
            style={{ color: '#CA0B00', borderColor: '#CA0B00' }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => setToggleModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default DeleteUserModal;
