import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { toastDismiss } from '../../../@utils/utils';
import '../../../styles/3dIcons.css';
import {
  setActivePage,
  setCustomerDetails,
  setProductDetails,
  setModeOfPayment,
  setGetUserId,
  setBillingStatus,
  setInvoiceDate,
} from '../../../store/RetailerWebStore/RetailerAction/billing';
import { getProductDetails } from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import { getProfile } from '../../../store/RetailerWebStore/RetailerAction/myProfile';
import billing from '../../../assets/svg/billing.svg';
import credits from '../../../assets/svg/credits.svg';
import inventory from '../../../assets/svg/inventory.svg';
import reports from '../../../assets/svg/reports.svg';
import sms from '../../../assets/svg/sms.svg';
import unknown from '../../../assets/svg/bills.svg';
import expensesIcon from '../../../assets/svg/expensesIcon.svg';
import rewardLogo from '../../../assets/svg/rewardLogo.svg';
import eventsPayload from '../../../GoogleAnalytics/events_payload';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';

class Home extends Component {
  componentDidMount() {
    toastDismiss();
    this.props.getNotificationCount();
    this.props.setActivePage(0);
    this.props.setCustomerDetails('');
    this.props.setProductDetails('');
    this.props.setModeOfPayment('');
    this.props.setGetUserId('');
    this.props.setBillingStatus('');
    this.props.setInvoiceDate('');

    // This will be called only when user onboards
    if (!this.props.userData.tradeName) this.props.getProfile();
  }

  breakPoint = 700;
  width = window.innerWidth;

  render1() {
    return [
      {
        title: 'Manage Inventory',
        src: inventory,
        config: 'manageInventory',
        url: 'inventoryManage',
        id: 'iphone',
        event: eventsPayload.ManageInventory[0],
      },
      {
        title: 'Billing',
        src: billing,
        config: 'billing',
        url: 'billing',
        id: 'macbookpro',
        event: eventsPayload.Billing[0],
      },
      {
        title: 'Expenses',
        src: expensesIcon,
        config: 'expenses',
        url: 'Expenses',
        id: 'expenses',
        event: eventsPayload.Expenses[0],
      },
      {
        title: 'Search Invoices',
        src: unknown,
        config: 'searchInvoice',
        url: 'Search Invoice',
        id: 'macbook',
        event: eventsPayload.SearchInvoice[0],
      },
      {
        title: 'Rewards',
        src: rewardLogo,
        config: 'rewardPoints',
        url: 'rewardPoints',
        id: 'ipod',
        event: eventsPayload.Reward[0],
      },
      // {
      //   title: 'Send SMS',
      //   src: sms,
      //   config: 'sendSms',
      //   url: 'Send SMS',
      //   id: 'ipod',
      // },
      // {
      //   title: `Credits & Debits`,
      //   src: credits,
      //   config: 'profitNloss',
      //   url: 'Profit & Loss Details',
      //   id: 'macmini',
      // },
      {
        title: `Credit book`,
        src: credits,
        config: 'creditBook',
        url: 'creditBook',
        id: 'creditbook',
        event: eventsPayload.CreditBook[0],
      },
    ].map(({ title, src, config, url, event, id }, index) => {
      if (!this.props.configuration[config]) return <></>;
      return (
        <Link
          to={this.props.configuration[config] ? url : '#'}
          key={index}
          className={
            !this.props.configuration[config] ? 'hover-notallowed' : ''
          }
          onClick={() => {
            analyticFunc(event);
          }}
        >
          <Paper
            id={id}
            style={{
              display: 'grid',
              justifyItems: 'center',
              paddingTop: '2.5rem',
              paddingBottom: '2.5rem',
              gridTemplateRows: '0.9fr 0.1fr',
              borderRadius: '1rem',
              gridTemplateColumns: '1fr',
              rowGap: '1.5rem',
            }}
          >
            <img src={src} alt="iPhone" height="50px" width="50px" />
            <span
              style={
                [].includes(index)
                  ? {
                      whiteSpace: 'nowrap',
                      color: '#6E7191',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                    }
                  : {
                      color: '#6E7191',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                    }
              }
            >
              {title}
            </span>
          </Paper>
        </Link>
      );
    });
  }

  render() {
    return (
      // <div id="bg">
      //   <div className="home pr-4 d-flex flex-wrap" style={{ position: "relative" }}>
      //     <div className="p-1 col-12 d-flex justify-content-end">
      //       <div id="wrapper">
      //         <ul id="bar">{this.render1()}</ul>
      //       </div>
      //     </div>
      //     {/* {this.width > this.breakPoint ? (
      //       <div className="carousel-container" style={{ minHeight: 500, maxHeight: 500, position: "fixed" }}>
      //         <ImgSlider childRef={(ref) => (this.child = ref)} />
      //       </div>
      //     ) : (
      //       <div className="small-carousel">
      //         <SmallImgSlider childRef={(ref) => (this.child = ref)} />
      //       </div>
      //     )} */}
      //   </div>
      // </div>
      <div
        style={{
          height: '90vh',
          display: 'grid',
          alignItems: 'center',
          background: '#F7F7FC',
          padding: '0 20%',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
            columnGap: '2rem',
            rowGap: '2rem',
            padding: '2rem',
          }}
        >
          {this.render1()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  configuration: loginReducer.user.configuration,
  userData: loginReducer.user.userData[0],
});

export default connect(mapStateToProps, {
  setActivePage,
  setCustomerDetails,
  setProductDetails,
  setModeOfPayment,
  getProductDetails,
  setGetUserId,
  setBillingStatus,
  setInvoiceDate,
  getNotificationCount,
  getProfile,
})(Home);
