import {
  GET_EXPENSES,
  GET_EXPENSE_CATEGORY,
  CLEAR_EXPENSE_CATEGORY,
} from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  totalExpenses: 0,
  expenseCategory: [],
  expensesList: [],
};

const Expenses = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EXPENSE_CATEGORY: {
      return {
        ...state,
        expenseCategory: payload.expenseCategory
          ? [...state.expenseCategory, ...payload.expenseCategory]
          : [],
        // expenseCategory: payload.expenseCategory,
        totalExpenses: payload.totalExpenses
          ? payload.totalExpenses
          : state.totalExpenses,
        // totalExpenses: payload.totalExpenses,
      };
    }
    case CLEAR_EXPENSE_CATEGORY: {
      return {
        ...state,
        expenseCategory: [],
        totalExpenses: 0,
      };
    }
    case GET_EXPENSES: {
      return {
        ...state,
        expensesList: payload,
      };
    }
    default:
      return state;
  }
};
export default Expenses;
