import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const DataDeletion = React.memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.cont}>
      <h2>
        Dear User,
        <br /> <br />
        Your data privacy is of utmost importance to us and we follow strict
        GDPR guidelines. For deletion of data please send an email on
        <a href="mailto:info@mbillapp.com" style={{ color: '#FE805C' }}>
          {' '}
          info@mbillapp.com{' '}
        </a>
        and your request will be processed within 1 working day
      </h2>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  cont: {
    height: '100vh',
    width: '75%',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
  },
}));

export default DataDeletion;
