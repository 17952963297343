/* eslint-disable */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Paper, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
/* eslint-disable */
import ReactPlayer from 'react-player';

const VideoTutorial = React.memo(({}) => {
  return (
    <div>
      <div style={{ textAlign: 'left', marginLeft: '2%', marginTop: '1%' }}>
        <h3>Video Tutorial</h3>
      </div>
      <div style={{ marginLeft: '20%', marginTop: '7%' }}>
        <ReactPlayer
          url="https://storage.googleapis.com/mbillbasket/gallery/1/videos/Taylor_Swift_-_Love_Story_(Lyrics).mp4"
          playing={false}
          controls={true}
        />
      </div>
    </div>
  );
});

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, {})(VideoTutorial);
