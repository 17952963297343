/* eslint-disable */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import getStore from './store/store';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/globalStyles.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './@utils/muiTheme';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './@utils/serviceWorker';
import firebase from 'firebase/app';
import 'firebase/analytics';

const { store, persistor } = getStore();

const firebaseConfig = {
  apiKey: 'AIzaSyDxHCIqsiVAUkMByinhjKnu8OJFZexPHYE',
  authDomain: 'mbillnew.firebaseapp.com',
  databaseURL: 'https://mbillnew.firebaseio.com',
  projectId: 'mbillnew',
  storageBucket: 'mbillnew.appspot.com',
  messagingSenderId: '255003100684',
  appId: '1:255003100684:web:484ba61b677c38755d5029',
  measurementId: 'G-ZDJHR0Q4QD',
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
