/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';

const ChangeBillId = React.memo(
  ({ handleModal, btnTitle, data, onChnageID }) => {
    const [text, setText] = useState('');
    const [superCategoryId, setSuperCategoryId] = useState('');

    useEffect(() => {
      console.log(data, '-----DATA TEXT');
      setText(data);
    }, []);

    const onSuccess = useCallback(() => {
      handleModal();
    }, []);

    const onSubmit = e => {
      e.preventDefault();
      console.log('DATA IN MODEL--->', text);
      onChnageID(text);
      onSuccess();
    };

    return (
      <div style={{ margin: '1%', marginTop: '7%' }}>
        <TextField
          autoFocus
          variant="outlined"
          label={'Change ID'}
          style={{ marginLeft: '20%' }}
          value={text}
          onChange={e => setText(e.target.value.replace(/[^\d]/, ''))}
        />
        <br />
        <br />
        <p style={{ textAlign: 'center' }}>
          <h6>You Can Select The Starting Serial Number Of Your Invoice</h6>
        </p>
        <button
          style={{ marginBottom: '5%', marginLeft: '12%' }}
          className="rounded-pill w-75 text-white text-uppercase p-2 border-0 bg-themeorange"
          type="submit"
          onClick={onSubmit}
        >
          submit
        </button>
      </div>
    );
  }
);

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, {})(ChangeBillId);
