/* eslint-disable */
const initialState = {
  categories: [],
  category: [],
};

const getSuperCategories = (state = initialState, action) => {
  switch (action.type) {
    case 'SUPER CATEGORIES': {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case 'RESET_CATEGORIES': {
      return {
        ...state,
        categories: [],
      };
    }
    case 'SHOW CATEGORY': {
      return {
        ...state,
        category: action.payload,
      };
    }
    case 'HANDLE_CATEGORY':
      return { ...state, category: { ...state.category, ...action.payload } };
    case 'RESET_CATEGORY':
      return { ...state, category: {} };
    case 'SEARCH_CATEGORY':
      return {
        ...state,
        categories: action.payload.categories,
      };
    case 'RESET_SEARCH_CATEGORY': {
      return { ...state, categories: [] };
    }
    default:
      return state;
  }
};
export default getSuperCategories;
