import { GET_PROFIT_LOSS_REPORT } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  profitLossReport: [],
};

const Expenses = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFIT_LOSS_REPORT: {
      return {
        profitLossReport: payload,
      };
    }
    default:
      return state;
  }
};
export default Expenses;
