import 'date-fns';
/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import { makeStyles } from '@material-ui/core/styles'
import { validateUser } from '../../store/RetailerWebStore/RetailerAction/signup';
import Loader from '../../RetailerWebPortal/Loader';
import { toastDismiss } from '../../@utils/utils';

const PersonalDetails = React.memo(
  ({ handleNext, validateUser, data, socialLogin }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
      toastDismiss();
    }, []);

    useEffect(() => {
      if (data) {
        console.log(data);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setContactNumber(data.contactNumber);
        setUserName(data.userName);
        setEmail(data.email);
        setPassword(data.password);
        setConfirmPassword(data.password);
      }
    }, [data]);

    const onSucess = useCallback(
      bool => {
        setLoading(false);
        bool &&
          handleNext({
            firstName,
            lastName,
            contactNumber,
            userName,
            email,
            password: !socialLogin ? password : undefined,
          });
      },
      [
        firstName,
        lastName,
        contactNumber,
        userName,
        email,
        password,
        handleNext,
        socialLogin,
      ]
    );

    const checkNext = useCallback(() => {
      if (
        firstName &&
        lastName &&
        contactNumber &&
        userName &&
        email &&
        (!socialLogin ? password && confirmPassword : true)
      ) {
        if (password !== confirmPassword) {
          return setError('password and confirm Password does not match');
        }
        validateUser(
          {
            firstName,
            lastName,
            contactNumber,
            userName,
            email,
            socialId: data.socialId,
            loginType: data.loginType,
          },
          onSucess
        );
      } else {
        setError('Please fill all * Fields');
      }
    }, [
      firstName,
      lastName,
      contactNumber,
      userName,
      email,
      password,
      confirmPassword,
      onSucess,
      socialLogin,
      validateUser,
      data,
    ]);

    return (
      <div className="d-flex justify-content-center">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="field-container">
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="First Name *"
                value={firstName || ''}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="Last Name *"
                value={lastName || ''}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="field-container">
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="Contact Number *"
                value={contactNumber || ''}
                onChange={e => {
                  setContactNumber(
                    e.target.value
                      .replace(/[^0-9]/g, '')
                      .toString()
                      .slice(0, 10)
                  );
                }}
              />
            </div>
            <div className="signup-fields">
              <TextField
                className="w-100"
                label="User Name *"
                value={userName || ''}
                onChange={e => setUserName(e.target.value)}
              />
            </div>
          </div>
          <div className="m-1 w-100">
            <TextField
              className="w-100"
              label="Email *"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div
            className="field-container"
            style={socialLogin ? { display: 'none' } : { display: 'flex' }}
          >
            <div className="signup-fields">
              <TextField
                type="password"
                className="w-100"
                label="Password *"
                type="password"
                value={password || ''}
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="signup-fields">
              <TextField
                type="password"
                className="w-100"
                type="password"
                label="Confirm Password *"
                value={confirmPassword || ''}
                type="password"
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <p className="text-themeorange">{error}</p>
          {!loading ? (
            <button
              className="w-50 rounded-pill text-white bg-dark border-0 p-3 mt-2"
              onClick={checkNext}
            >
              NEXT
            </button>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
);

export default connect(null, { validateUser })(PersonalDetails);
