import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const AddDecrement = props => {
  const { handleIncrement, handleDecrement, toDisplay, style, inStyle } = props;
  return (
    <div style={style}>
      <div
        style={
          inStyle
            ? inStyle
            : { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }
        }
      >
        <RemoveCircleOutlineIcon
          fontSize="large"
          className="mr-3"
          color="primary"
          onClick={handleDecrement}
        />
        <p style={{ textAlign: 'center', margin: 'auto' }}>{toDisplay}</p>
        <AddCircleOutlineIcon
          fontSize="large"
          className="ml-3"
          color="primary"
          onClick={handleIncrement}
        />
      </div>
    </div>
  );
};

export default AddDecrement;
