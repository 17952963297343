/* eslint-disable */
const initialState = {
  catalogs: {},
  rewardData: {},
};

const rewardPoints = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CATALOGS': {
      return {
        ...state,
        catalogs: payload,
      };
    }
    case 'REWARD_DATA': {
      return {
        ...state,
        rewardData: {
          ...payload.retailerData,
          conversionRate: parseFloat(
            payload.clientCredentials[4].metaData.split('_')[0]
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default rewardPoints;
