import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Paper, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { editBill } from '../billingAction';
import CustomInput from '../../../CommonComponents/customInput';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const EditBill = props => {
  const { setToggleEditModal, toggleEditModal, number } = props;
  const [billNumber, setBillNumber] = useState(-1);
  const [date, setDate] = useState(new Date());
  const [disabled, setDisabled] = useState(true);
  const [showBillNumberError, setShowBillNumberError] = useState(false);

  const handleInputChange = (input, from) => {
    let digitRegex = /^[0-9]*$/;
    if (from === 'billNumber') {
      if (digitRegex.test(input)) {
        setBillNumber(input);
        setDisabled(false);
        setShowBillNumberError(false);
      }
      if (!input && input === '') {
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (props.invoiceDetails) {
      setBillNumber(props.invoiceDetails.number);
      setDate(props.invoiceDetails.date);
    }
  }, [props.invoiceDetails]);

  useEffect(() => {
    if (props.billNumberAvailable[0]) {
      setToggleEditModal(false);
      setShowBillNumberError(false);
    } else if (!props.billNumberAvailable[0]) {
      setShowBillNumberError(true);
    }
  }, [props.billNumberAvailable]);

  const handleSubmit = () => {
    analyticFunc(eventsPayload.Billing[3]);
    if (billNumber && billNumber !== -1) {
      let payload = {
        billId: billNumber,
        billDate: date,
      };
      props.editBill(payload);
    }
  };

  const handleDateChange = date => {
    analyticFunc(eventsPayload.Billing[2]);
    setDate(date.toISOString());
  };

  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '30rem',
        maxWidth: '25%',
        height: '50%',
      }}
      open={toggleEditModal}
    >
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4rem',
          borderRadius: '1.5rem',
        }}
      >
        <p style={{ textAlign: 'center' }} className="formTitle">
          Edit Invoice Number
        </p>
        <CustomInput
          onChange={e => handleInputChange(e.target.value, 'billNumber')}
          inputLabel={'Bill Number'}
          autoFocus={true}
          value={billNumber}
          error={showBillNumberError}
        />
        {showBillNumberError && (
          <p className="errorLine">Bill Number not available.</p>
        )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={date}
            autoOk
            allowKeyboardControl
            id="date-picker-inline"
            onChange={handleDateChange}
            label="Date"
          />
        </MuiPickersUtilsProvider>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '1rem',
          }}
        >
          <Button
            onClick={() => setToggleEditModal(!toggleEditModal)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

const mapStateToProps = ({ BillingReducer }) => {
  return {
    billNumberAvailable: BillingReducer.billNumberAvailable,
    invoiceDetails: BillingReducer.invoiceDetails,
  };
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    editBill: payload => dispatch(editBill(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBill);
