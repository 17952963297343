/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { connect, useDispatch } from 'react-redux';
import {
  getCategory,
  getProductDetails,
  getDistributor,
  addModel,
  getBrand,
  getModel,
  updateQty,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { Edit } from '@material-ui/icons';
import MyModal from '../Modal';
import AddDistributor from './addDistributor';
import ChangeQty from './changeQty';
import Loader from '../../Loader';
import BarcodeScannerComponent from '../BarcodeScannerComponent';

const AddModel = React.memo(
  ({
    // categoryList,
    // brands,
    models,
    distributors,
    productDetails,
    getDistributor,
    getBrand,
    getCategory,
    getModel,
    addModel,
    handleModal,
    getProductDetails,
    updateQty,
    edit,
    data,
  }) => {
    // const imgRef = useRef()
    const qtyRef = useRef();
    const distRef = useRef();

    const [categoryId, setCategoryId] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [brandId, setBrandId] = useState('');
    const [brands, setBrands] = useState([]);
    const [brandName, setBrandName] = useState('');
    const [modelId, setModelId] = useState('');
    const [modelName, setModelName] = useState('');
    const [distributorId, setDistributorId] = useState('');
    const [distributorName, setDistributorName] = useState('');
    const [modelQuantity, setModelQuantity] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [productImage, setProductImage] = useState('');
    const [MRP, setMRP] = useState(0);
    const [HSN, setHSN] = useState('');
    const [barcodeScan, setBarcodeScan] = useState(false);
    const [loading, setLoading] = useState(false);
    const [qtyReason, setQtyReason] = useState({
      other: false,
      returnDistributor: false,
      stockLossQuantity: false,
    });
    const [scanId, setScanId] = useState('');
    const [modelDesc, setModelDesc] = useState('');

    console.log(data);
    useEffect(() => {
      if (distributors && !distributors.length) {
        getDistributor(setLoading);
        setLoading(true);
      }
      if (data.category) {
        setCategoryName(data.category.name);
        setCategoryId(data.category.id);
        setBrandId(data.brand.id);
        setBrandName(data.brand.name);
        setCategoryList([{ categoryId: 1, categoryName: 'Unknown' }]);
        setBrands([{ brandId: 1, brandName: 'Unknown' }]);
        setModelName(data.productName);
      }
    }, [edit]);

    useEffect(() => {
      data.modelId &&
        getProductDetails(
          { modelId: data.modelId, type: !edit ? 'ADD' : undefined },
          setLoading
        );
      data.modelId && setLoading(true);
    }, []);

    useEffect(() => {
      if (productDetails) {
        const {
          productImage,
          brandId,
          brandName,
          categoryName,
          categoryId,
          modelId,
          modelName,
          modelQuantity,
          purchasePrice,
          sellingPrice,
          HSN,
          MRP,
          distributorId,
          name,
          scanId,
          modelDesc,
        } = productDetails;
        setCategoryId(categoryId);
        setModelDesc(modelDesc);
        setCategoryList([{ categoryId, categoryName }]);
        setCategoryName(categoryName);
        setBrands([{ brandId, brandName }]);
        setBrandId(brandId);
        setBrandName(brandName);
        setModelId(modelId);
        setModelName(modelName);
        modelQuantity && setModelQuantity(modelQuantity);
        productImage && setProductImage(productImage);
        distributorId ? setDistributorId(distributorId) : setDistributorId('');
        distributorId ? setDistributorName(name) : setDistributorName('');
        setPurchasePrice(purchasePrice);
        setSellingPrice(sellingPrice);
        setHSN(HSN);
        setMRP(MRP);
        setScanId(scanId);
      }
    }, [productDetails]);

    const resetValues = useCallback(
      (type, value) => {
        if (type == 'category') {
        } else {
          getModel({ numberOfItems: 50 }, setLoading);
          setLoading(true);
        }
      },
      [categoryId, brandId]
    );

    const handleClose = useCallback(
      bool => {
        bool && getDistributor(setLoading);
        bool && setLoading(true);
      },
      [getDistributor]
    );

    // const pickImage = useCallback((file) => {
    //   if (file && file.type.split('/')[0] === 'image') {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.onload = () => {
    //       setProductImage(reader.result)
    //     }
    //   }
    // }, [])

    const onSuccess = useCallback(() => {
      handleModal(true);
    }, []);

    const successUpdate = useCallback(
      bool => {
        bool && getProductDetails({ modelId }, setLoading);
        !bool && setLoading(false);
      },
      [modelId]
    );

    const handleSubmit = useCallback(
      e => {
        if (edit) {
          let payload = {
            modelId,
            distributorId: distributorId || undefined,
            modelQuantity,
            sellingPrice,
            ...qtyReason,
            scanId: scanId || undefined,
            categoryId,
            brandId,
            modelName,
            purchasePrice,
            modelDesc: modelDesc || undefined,
            HSN: HSN || undefined,
            MRP: MRP || undefined,
          };
          updateQty(payload, successUpdate);
          return setLoading(true);
        }
        e.preventDefault();
        if (
          !(
            categoryId &&
            brandId &&
            modelName &&
            modelQuantity &&
            purchasePrice &&
            sellingPrice
          )
        ) {
          return window.alert('please fill all the fields.');
        }
        if (parseInt(sellingPrice) < parseInt(purchasePrice))
          return window.alert(
            'Selling Price should be greater than Purchase Price'
          );

        if (parseInt(sellingPrice) > parseInt(MRP))
          return window.alert('Selling Price should be less than MRP');

        let payload = {
          categoryId,
          brandId,
          modelName,
          distributorId: distributorId ? distributorId : undefined,
          modelQuantity,
          MRP: MRP ? MRP : undefined,
          HSN: HSN ? HSN.toString() : undefined,
          purchasePrice,
          sellingPrice,
          // productImage: productImage.split(',')[1],
          // scanId: scanId ? scanId : undefined,
        };

        addModel(payload, onSuccess);
      },
      [
        categoryId,
        brandId,
        modelName,
        distributorId,
        modelQuantity,
        purchasePrice,
        sellingPrice,
        productImage,
        qtyReason,
        modelDesc,
        modelId,
        scanId,
        HSN,
        MRP,
      ]
    );

    const handleQty = useCallback(() => {
      qtyRef.current.click();
    }, []);

    const getQty = useCallback(payload => {
      const { other, returnDistributor, stockLossQuantity } = payload;
      setModelQuantity(payload.modelQuantity);
      setQtyReason({ other, returnDistributor, stockLossQuantity });
    }, []);

    const productScan = val => {
      setBarcodeScan(false);
      if (val) {
        setScanId(val);
      }
    };

    return (
      <React.Fragment>
        {barcodeScan ? (
          <BarcodeScannerComponent productScan={productScan} />
        ) : (
          <div className="m-2">
            <h5 className="text-center">
              {edit ? 'Edit Product' : 'Add Product'}
            </h5>
            <FormControl variant="outlined">
              <InputLabel id="category">Category</InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="category"
                id="category"
                value={categoryId && categoryId + ',,,' + categoryName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setCategoryName(arr[1]);
                  setCategoryId(() => {
                    resetValues('category', arr[0]);
                    return arr[0];
                  });
                }}
                label="Category"
              >
                {categoryList &&
                  categoryList.map(item => (
                    <MenuItem
                      key={item.categoryId}
                      value={item.categoryId + ',,,' + item.categoryName}
                    >
                      {item.categoryName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl variant="outlined">
              <InputLabel id="brand">Brand</InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="brand"
                id="brand"
                value={brandId && brandId + ',,,' + brandName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setBrandName(arr[1]);
                  setBrandId(() => {
                    resetValues('brand', arr[0]);
                    return arr[0];
                  });
                }}
                label="Brand"
              >
                {brands &&
                  brands.map(item => (
                    <MenuItem
                      key={item.brandId}
                      value={item.brandId + ',,,' + item.brandName}
                    >
                      {item.brandName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            {/* {edit ? (
              <>
                <FormControl variant="outlined">
                  <InputLabel id="Product">Products</InputLabel>
                  <Select
                    style={{ width: 350 }}
                    labelId="Product"
                    id="Product"
                    disabled={edit}
                    value={modelId && modelId + ',,,' + modelName}
                    onChange={(e) => {
                      const arr = e.target.value.split(',,,')
                      setModelName(arr[1])
                      setModelId(() => {
                        getProductDetails({ modelId: arr[0] }, setLoading)
                        setLoading(true)
                        return arr[0]
                      })
                    }}
                    label="Product"
                  >
                    {models &&
                      models.map((item) => (
                        <MenuItem key={item.modelId} value={item.modelId + ',,,' + item.modelName}>
                          {item.modelName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <br />
              </>
            ) : (
              <> */}
            <TextField
              style={{ width: '100%' }}
              label="Product"
              value={modelName}
              onChange={e => setModelName(e.target.value)}
            />
            {/* 
              </>
            )} */}
            <br />
            <br />
            <FormControl variant="outlined">
              <InputLabel id="Distributor">Distributor</InputLabel>
              <Select
                style={{ width: 350 }}
                labelId="Distributor"
                id="Distributor"
                value={distributorId && distributorId + ',,,' + distributorName}
                onChange={e => {
                  const arr = e.target.value.split(',,,');
                  setDistributorName(arr[1]);
                  setDistributorId(arr[0]);
                }}
                label="Distributor"
              >
                {distributors &&
                  distributors.map(item => (
                    <MenuItem
                      key={item.distributerId}
                      value={item.distributerId + ',,,' + item.distributedName}
                    >
                      {item.distributedName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <TextField
                  label="Enter MRP(Rs.)"
                  value={MRP}
                  disabled={edit}
                  onChange={e => setMRP(e.target.value.replace(/[^0-9]/g, ''))}
                />
              </div>
              <div className="w-50 p-1">
                <TextField
                  label="Enter HSN Code"
                  value={HSN}
                  disabled={edit}
                  onChange={e => setHSN(e.target.value.replace(/[^0-9]/g, ''))}
                />
              </div>
            </div>
            <br />
            <div>
              <TextField
                onChange={e =>
                  !edit &&
                  setModelQuantity(e.target.value.replace(/[^0-9]/g, ''))
                }
                style={{ width: 119 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {edit && <EditOutlined />}
                    </InputAdornment>
                  ),
                }}
                label="Quantity"
                value={modelQuantity}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="hover-pointer">
                      {edit && <Edit onClick={handleQty} />}
                    </InputAdornment>
                  ),
                }}
              />
              <div className="d-none">
                <MyModal ref={qtyRef}>
                  <ChangeQty data={{ modelQuantity }} getQty={getQty} />
                </MyModal>
              </div>
              <TextField
                style={{ width: 119 }}
                label="Purchase Price"
                // disabled={edit}
                type="number"
                value={purchasePrice}
                onChange={e =>
                  setPurchasePrice(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
              <TextField
                style={{ width: 119 }}
                label="Selling Price"
                type="number"
                value={sellingPrice}
                onChange={e =>
                  setSellingPrice(e.target.value.replace(/[^0-9]/g, ''))
                }
              />
            </div>
            <br />
            {/* <div style={{ display: 'flex' }}> */}
            {/* <div>
                <input
                  className="d-none"
                  disabled={edit}
                  onChange={(e) => pickImage(e.target.files[0])}
                  ref={imgRef}
                  accept="image/*"
                  type="file"
                />
                <img
                  style={{ width: 100 }}
                  onClick={(e) => imgRef.current.click()}
                  src={productImage ? productImage : 'assets/Profile.jpg'}
                  alt="product-img"
                />
                <br />
                {!edit && <small>Add Product Image</small>}
              </div> */}
            {/* {!edit && <div style={{ marginLeft: '44%', marginTop: '14%', cursor: 'pointer' }}
                onClick={() => {
                  setBarcodeScan(true)
                }}>
                  <div style={{paddingLeft: '27%'}}>
                <CropFreeIcon color="action" fontSize="large" /> <br />
                </div>
                 Scan Product
                 </div>
              } */}

            {/* </div>
            <br /> */}
            <div className="pl-2">{scanId && <p>Scan ID: {scanId}</p>}</div>
            <br />
            <button
              className="rounded-pill w-100 text-white p-3 border-0 text-capitalize bg-warning"
              onClick={e => distRef.current.click()}
            >
              add distributor
            </button>
            <MyModal
              ref={distRef}
              style={{ width: '100%', padding: 15 }}
              btnTitle={'Add Distributor'}
              close={handleClose}
            >
              <AddDistributor />
            </MyModal>
            <br />
            {!loading ? (
              <button
                className="rounded-pill w-100 text-white p-3 border-0 text-capitalize bg-themeorange"
                onClick={handleSubmit}
              >
                Submit
              </button>
            ) : (
              <Loader />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
);

const mapStateToProps = ({ ManageInventory }) => {
  const {
    categoryList,
    brands,
    models,
    distributors,
    productDetails,
  } = ManageInventory;
  return { categoryList, brands, models, distributors, productDetails };
};

export default connect(mapStateToProps, {
  getDistributor,
  getModel,
  addModel,
  getCategory,
  getBrand,
  getProductDetails,
  updateQty,
})(AddModel);
