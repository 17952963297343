/* eslint-disable */
const initialState = {
  isUserLoggedIn: false,
  user: '',
};

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'USER_LOGGED_IN': {
      return {
        ...state,
        isUserLoggedIn: true,
        user: payload,
      };
    }
    case 'UPDATE_REGISTRATION': {
      return {
        ...state,
        user: {
          ...state.user,
          userData: [
            { ...state.user.userData[0], isRegister: payload.isRegister },
          ],
        },
      };
    }
    case 'USER_UPDATED': {
      return {
        ...state,
        user: {
          ...state.user,
          isRefuge: payload.isRefuge,
          userData: [
            {
              ...payload,
              userImage: payload.userImage
                ? `${payload.userImage}?${Date.now()}`
                : '',
              isRegister: 3,
            },
          ],
        },
      };
    }

    case 'PRIVACY_UPDATED':
      return {
        ...state,
        user: {
          ...state.user,
          userData: [
            {
              ...state.user.userData[0],
              haveSharedData:
                state.user.userData[0].haveSharedData === 1 ? 0 : 1,
            },
          ],
        },
      };
    default:
      return state;
  }
};
export default loginReducer;
