const initialState = {
  // For displaying details
  userState: '',
  userDistrict: '',
  userPincode: '',
  userTradeName: '',
  isValidPincode: [false],
  superCategories: [],
};

const onboardingReducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'GET_LOCATION': {
      return {
        ...state,
        userState: data.State,
        userDistrict: data.District,
        isValidPincode: [true],
      };
    }
    case 'INVALID_PINCODE': {
      return {
        ...state,
        userPincode: '',
        userDistrict: '',
        userState: '',
        isValidPincode: [false],
      };
    }
    case 'SET_TRADE_DATA': {
      return {
        ...state,
        userPincode: data.userPincode,
        userTradeName: data.userTradeName,
      };
    }
    case 'GET_SUPER_CATEGORY': {
      console.log(data);
      return {
        ...state,
        superCategories: data,
      };
    }

    default:
      return state;
  }
};

export default onboardingReducer;
