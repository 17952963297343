import firebase from 'firebase/app';
import 'firebase/analytics';
import { ALLOW_ANALYTICS } from '../@constants';

const sendToGoogleAnalytics = async (event, eventPayload = '') => {
  const analytics = firebase.analytics();
  if (ALLOW_ANALYTICS) {
    analytics.logEvent(event, eventPayload);
  }
};

const analyticFunc = eventPayload => {
  const regexForSpace = /\s/g;
  const regexForUnderscore = /_/g;

  // event = CATEGORY_ACTION (without spaces between)
  const event = `${eventPayload.CATEGORY.replace(regexForSpace, '').replace(
    regexForUnderscore,
    ''
  )}_${eventPayload.ACTION.replace(regexForSpace, '').replace(
    regexForUnderscore,
    ''
  )}`;
  sendToGoogleAnalytics(event, eventPayload);
};

export default analyticFunc;
