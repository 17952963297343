import { barcodeToScanId, scanIdToBarcode } from '../../@utils/scanIdToBarcode';
import { toasterMessage } from '../../@utils/utils';
import api from '../../api/api';
import {
  GET_CONTACTS,
  GET_PRODUCTS,
  GET_SERIAL_NUMBER,
  ADD_PRODUCT_TO_LIST,
  UPDATE_CART_QUANTITY,
  GET_DISTRIBUTOR,
  CONTACT_SELECTED,
  EDIT_BILL,
  EDIT_PRODUCT,
  REMOVE_PRODUCT_FROM_LIST,
  SET_LOADING_CONTACT,
  GET_MORE_PRODUCTS,
  SET_LOADING_PRODUCT,
  SET_PRODUCT_LIST,
  SET_LOADING_DISTRIBUTOR,
  CLEAR_SELECTED_PRODUCTS,
} from './types';

export const getContacts = payload => async dispatch => {
  dispatch({ type: SET_LOADING_CONTACT, data: true });
  api
    .post('billing/getCustomerDetails', payload)
    .then(response => {
      const { data } = response;
      if (data.status !== 204) {
        dispatch({ type: GET_CONTACTS, data: data.data });
        dispatch({ type: SET_LOADING_CONTACT, data: false });
      } else {
        toasterMessage('error', 'No such contact');
        dispatch({ type: SET_LOADING_CONTACT, data: false });
      }
    })
    .catch(error => {
      dispatch({ type: SET_LOADING_CONTACT, data: false });
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
      dispatch({ type: SET_LOADING_CONTACT, data: false });
    });
};

export const setContactList = payload => async dispatch => {
  dispatch({
    type: 'SET_CONTACT_LIST',
    data: payload,
  });
};

export const getProducts = payload => dispatch => {
  let type = payload.page > 1 ? GET_MORE_PRODUCTS : GET_PRODUCTS;
  dispatch({ type: SET_LOADING_PRODUCT, data: true });
  api
    .post('inventory/searchProduct', payload)
    .then(response => {
      const { data, status } = response.data;
      if (status !== 204) {
        // newData: scanId --> barcode
        const newData = scanIdToBarcode(data);
        dispatch({ type, data: newData });
      } else {
        toasterMessage('error', 'No product found');
      }
      dispatch({ type: SET_LOADING_PRODUCT, data: false });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
      dispatch({ type: SET_LOADING_PRODUCT, data: false });
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
      dispatch({ type: SET_LOADING_PRODUCT, data: false });
    });
};

export const setProductList = payload => async dispatch => {
  dispatch({ type: SET_PRODUCT_LIST, data: payload });
};

export const getSerialNumber = () => async dispatch => {
  api
    .get('billing/getSerialNo')
    .then(response => {
      const { data } = response.data;
      let formatedDate = new Date(data.date);
      let string = formatedDate.toLocaleString('en-GB').split(',')[0];
      dispatch({
        type: GET_SERIAL_NUMBER,
        data: { ...data, dateFormated: string },
      });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const getUnits = () => async dispatch => {
  api
    .get('billing/getUnits')
    .then(response => {
      const { data } = response.data;
      dispatch({
        type: 'GET_UNITS',
        data: data.unitList,
      });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};
export const addProductToSelectedList = product => async dispatch => {
  product['inCartQuantity'] = 1;
  dispatch({
    type: ADD_PRODUCT_TO_LIST,
    data: product,
  });
};

export const editProduct = product => async dispatch => {
  dispatch({
    type: EDIT_PRODUCT,
    data: product,
  });
};

export const increaseOrDecrease = (product, by) => async dispatch => {
  dispatch({
    type: UPDATE_CART_QUANTITY,
    data: { product, by },
  });
};

export const removeProductFromList = product => async dispatch => {
  dispatch({
    type: REMOVE_PRODUCT_FROM_LIST,
    data: product,
  });
};

export const addProduct = (payload, mode) => async dispatch => {
  // barcode --> scanId
  if ('barcode' in payload) {
    payload['scanId'] = payload['barcode'];
    delete payload['barcode'];
    if ([null, undefined].includes(payload['scanId'])) delete payload['scanId'];
  }
  let imei = payload['imei'];

  if (mode === 0) {
    delete payload['imei'];
  }

  Object.keys(payload).forEach(key =>
    payload[key] === null ? delete payload[key] : {}
  );
  api
    .post('inventory/addModel', payload)
    .then(response => {
      const { data } = response;
      //! Notify user that product is add successfully
      data.data['inCartQuantity'] = 1;
      if (mode === 0 && imei != undefined) data.data['imei'] = imei;

      dispatch({
        type: ADD_PRODUCT_TO_LIST,
        data: data.data,
      });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const getDistributor = (payload, cb) => async dispatch => {
  cb && cb(true);
  api
    .post('distributor/getDistributor', payload)
    .then(response => {
      const { data } = response;
      if (data.status !== 204) {
        dispatch({ type: GET_DISTRIBUTOR, data: data.data });
      } else {
        toasterMessage('error', 'No distributor found');
      }
      cb && cb(false);
    })
    .catch(error => {
      cb && cb(false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something bad happened');
    });
};

export const setDistributorList = payload => async dispatch => {
  dispatch({ type: 'SET_DISTRIBUTOR_LIST', data: payload });
};

export const addDistributor = (payload, cb) => async dispatch => {
  api
    .post('distributor/addDistributor', payload)
    .then(response => {
      const { data } = response.data;
      cb && cb(data[0], true);
      // dispatch({ type: "SET_DISTRIBUTOR", data: data[0] });
    })
    .catch(error => {
      cb && cb({}, false);
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      cb && cb({}, false);
      toasterMessage('error', 'Something bad happened');
    });
};

export const contactSelected = payload => async dispatch => {
  dispatch({
    type: CONTACT_SELECTED,
    data: payload,
  });
};

export const addNewContact = payload => async dispatch => {
  api
    .post('billing/addCustomer', payload)
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: CONTACT_SELECTED,
          data: { ...payload, customerId: response.data.data.insertId },
        });
      }
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const editBill = payload => async dispatch => {
  let formatedDate = new Date(payload.billDate);
  let string = formatedDate.toLocaleString('en-GB').split(',')[0];

  var payloadToPost = {
    billId: payload.billId,
  };
  var invoicePayload = {
    date: payload.billDate,
    dateFormated: string,
    billNo: payload.billId,
  };
  api
    .post('billing/editSerialNo', payloadToPost)
    .then(response => {
      const { data } = response;
      dispatch({ type: EDIT_BILL, data: { ...data, payload: invoicePayload } });
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const updateGstStatus = payload => async dispatch => {
  api
    .post('billing/editGstType', payload)
    .then(response => {
      const { data } = response;
      dispatch({ type: 'UPDATE_GST_DETAILS', data: payload });
      toasterMessage('success', data.message);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const sendBillingDetails = payload => async dispatch => {
  const newPayload = { ...payload };
  // delete remark, if not exist
  if (
    'remark' in newPayload &&
    [null, undefined, ''].includes(newPayload.remark)
  ) {
    delete newPayload.remark;
  }

  // change barcode --> scanId
  if ('productDetails' in payload) {
    newPayload.productDetails = barcodeToScanId(payload.productDetails);
  }
  return api.post('billing/saveInvoice', newPayload);
};

export const sendEditBillingDetails = payload => async dispatch => {
  const newPayload = { ...payload };
  // delete remark, if not exist
  if (
    'remark' in newPayload &&
    [null, undefined, ''].includes(newPayload.remark)
  ) {
    delete newPayload.remark;
  }

  // change barcode --> scanId
  if ('productDetails' in payload) {
    newPayload.productDetails = barcodeToScanId(payload.productDetails);
  }
  return api.post('billing/editInvoice', newPayload);
};

export const resetBillingState = () => async dispatch => {
  dispatch({ type: 'RESET_STATE', data: [] });
};

export const editInvoiceDetails = payload => async dispatch => {
  return api.post('billing/getInvoiceDetails', payload);
};

export const clearSelectedProducts = () => dispatch => {
  dispatch({ type: CLEAR_SELECTED_PRODUCTS, data: [] });
};

export const prefillBillingDetails = payload => async dispatch => {
  dispatch({ type: 'PREFILL_DETAILS', data: payload });
};

export const isValidIMEI = (payload, cb) => async dispatch => {
  try {
    const res = await api.post('/inventory/imei', payload);
    const { status, message, error } = res?.data;
    if (status === 200) {
      cb && cb(true);
    }
    if (status !== 200) {
      throw new Error(message || error || 'Something bad happened');
    }
  } catch (error) {
    cb && cb(false);

    let errMsg = '';

    if (error.response) {
      errMsg = error.response.data.message;
    } else if (error.message) {
      errMsg = error.message;
    }
    errMsg = errMsg || 'Something bad happened';
    toasterMessage('error', errMsg);
  }
};
