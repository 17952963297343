/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  Container,
  Paper,
  TextField,
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
/* eslint-disable */
import api from '../../../api/api';
import { connect, useDispatch } from 'react-redux';
import { verifyOtp } from '../../../store/RetailerWebStore/RetailerAction/ledger';
import Loader from '../../Loader';
import { history } from '../../../routes';
import link from '../../../images/splash-screen.png';
import logo from '../../../images/logo2.png';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#df5a28',
      main: '#df5a28',
      dark: '#df5a28',
      contrastText: '#000',
    },
  },
});

function Ledger({
  verifyOtp,
  isVerified,
  loading,
  fromSignup,
  fromForgotPassword,
  getNotificationCount,
}) {
  const dispatch = useDispatch(),
    [otp, setOtp] = useState(''),
    [count, setCount] = useState(0);
  // [salesReport, setSalesReport] = useState(false)

  useEffect(() => {
    // getNotificationCount()
    toastDismiss();
    // window.location.pathname.toLocaleLowerCase().includes('report') && setSalesReport(true)
    // !fromSignup && sendOtp()
    return () => dispatch({ type: 'VERIFY_OTP', payload: false });
  }, []);

  const setTimer = useCallback(() => {
    setTimeout(() => {
      setCount(0);
    }, 30000);
    setCount(30);
  }, []);

  useEffect(() => {
    if (fromSignup && isVerified) history.push('/dashboard');
  }, [isVerified, fromSignup]);

  const sendOtp = useCallback(() => {
    api
      .get(`user/resendOtp/${fromSignup && 'REGISTRATIONOTP'}`)
      .then(() => {
        toasterMessage('success', 'OTP has been sent to your registerd number');
        setTimer();
      })
      .catch(error => {
        toasterMessage('error', error.response.data.error);
      });
  }, [setTimer]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (fromForgotPassword) {
        return fromForgotPassword({ otp });
      }
      verifyOtp({ otp }, fromSignup);
    },
    [otp, fromSignup, fromForgotPassword, verifyOtp]
  );

  return (
    <div
      className="w-100 d-flex justify-content-center p-4"
      style={{ minHeight: '90vh' }}
    >
      <div className="d-flex align-items-center">
        <ThemeProvider theme={theme}>
          <Container
            component="main"
            maxWidth="xs"
            className="d-flex justify-content-center align-items-center"
          >
            <CssBaseline />
            <div>
              <Paper className="d-flex flex-column aling-items-center shadow-lg">
                <div style={{ position: 'relative' }}>
                  <img src={link} alt="alt-img" height="200px" />
                  <img
                    src={logo}
                    alt="alt-img"
                    width="150px"
                    className="rounded mx-auto d-block"
                    style={{ position: 'absolute', top: 40, left: '33%' }}
                  />
                </div>
                <div className="p-4">
                  <div className="w-100 text-left">
                    {/* <Typography className="font-weight-bold" component="h1" variant="h5">
                        {!fromSignup && 'Sales Report'}
                      </Typography> */}
                    {/* {!fromSignup ? (
                        <p className="medium">Welcome back, Kindly enter your Sales Analysis details</p>
                      ) : ( */}
                    <p className="font-weight-bold text-secondary">
                      A one-time password (OTP) has been sent to your registered
                      phone number.
                    </p>
                    {/* )} */}
                  </div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Enter OTP"
                    id="OTP"
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                  />
                  {!loading ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="rounded-pill p-3 text-white bg-themeorange"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Loader />
                  )}
                  <br />
                  <br />
                  {!fromForgotPassword && (
                    <Button disabled={count !== 0} onClick={e => sendOtp()}>
                      Resend OTP
                    </Button>
                  )}
                </div>
              </Paper>
            </div>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Ledger, Loader }) => {
  return { isVerified: Ledger.isVerified, loading: Loader.loading };
};
export default connect(mapStateToProps, { verifyOtp, getNotificationCount })(
  Ledger
);
