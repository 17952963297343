import {
  SEARCH_CREDITS,
  CREDIT_DETAILS,
  CLEAR_CREDITS,
  SET_CREDIT_ID,
} from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  credits: [],
  creditDetails: '',
  creditId: '',
};

const SearchCredits = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_CREDITS: {
      return {
        ...state,
        credits: payload ? [...state.credits, ...payload] : [],
      };
    }
    case CLEAR_CREDITS: {
      return {
        ...state,
        credits: [],
      };
    }
    case CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: payload,
      };
    }
    case SET_CREDIT_ID: {
      return {
        ...state,
        creditId: payload,
      };
    }
    default:
      return state;
  }
};

export default SearchCredits;
