/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IconButton, ListItem, ListItemText } from '@material-ui/core';
import { Create, PostAdd } from '@material-ui/icons';
import MyModal from '../Modal';
import { connect } from 'react-redux';
import AddExpense from './addExpense';
import { getExpensesList } from '../../../store/RetailerWebStore/RetailerAction/expenses';
import Loader from '../../Loader';
import moment from 'moment';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const ExpenseDetails = ({
  getExpensesList,
  expensesList,
  expenseCategoryId,
  fromDate,
  toDate,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  // const [expenseImage, setExpenseImage] = useState('')
  // const imgRef = useRef()
  const myRef = useRef();
  const addExpenseRef = useRef();

  const onSuccess = useCallback(() => {
    setLoading(false);
  }, []);

  const apiCall = useCallback(() => {
    getExpensesList(
      {
        expenseCategoryId,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
      },
      onSuccess
    );
    setLoading(true);
  }, [expenseCategoryId]);

  useEffect(() => {
    apiCall();
  }, []);

  // const pickImage = useCallback((file) => {
  //   if (file && file.type.split('/')[0] == 'image') {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(file)
  //     reader.onload = () => {
  //       setExpenseImage(reader.result)
  //     }
  //   }
  // }, [])

  const handleClose = useCallback(() => {
    apiCall();
  }, [apiCall]);

  const renderExpense = useCallback(() => {
    return (
      <React.Fragment>
        {expensesList && expensesList.length > 0 ? (
          expensesList.map((item, i) => (
            <ListItem
              key={i}
              className="expense-box m-1 d-flex justify-content-between align-items-center"
              style={{ width: 350, border: '1px solid', borderRadius: 5 }}
            >
              <div style={{ lineHeight: '5px' }}>
                <ListItemText
                  primary={item.expenseRetailerName}
                  className="font-weight-bold"
                />
                <small className="secondary">{item.Date}</small>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div style={{ lineHeight: '5px' }}>
                  <ListItemText
                    primary={item.amount}
                    className="text-themeorange font-weight-bold"
                  />
                  <small className="secondary">Amount</small>
                </div>
                <IconButton
                  onClick={e => {
                    analyticFunc(eventsPayload.Expenses[6]);
                    setSelectedCategory(item);
                    addExpenseRef.current.click();
                  }}
                >
                  <Create />
                </IconButton>
              </div>
            </ListItem>
          ))
        ) : (
          <div>
            <h5>No Expense Added Yet</h5>
          </div>
        )}
      </React.Fragment>
    );
  }, [expensesList]);

  return (
    <div className="p-1 d-flex flex-column justify-content-center align-items-center">
      <div className="w-100 d-flex justify-content-end mr-4 mt-2 align-items-center">
        {/* Add Expense&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        {/* <input
          className="d-none"
          onChange={(e) => pickImage(e.target.files[0])}
          ref={imgRef}
          accept="image/*"
          type="file"
        />
        <img
          className="hover-pointer"
          onClick={(e) => imgRef.current.click()}
          src={expenseImage ? expenseImage : 'assets/Profile.jpg'}
          alt="ProfilePic"
          height="50"
        /> */}
        {/* <PostAdd onClick={(e) => myRef.current.click()} fontSize="large" className="hover-pointer mr-4" /> */}
        <MyModal ref={myRef} close={handleClose}>
          <AddExpense edit={true} expenseCatId={expenseCategoryId} />
        </MyModal>
      </div>
      <br />
      {!loading ? renderExpense() : <Loader />}
      <MyModal ref={addExpenseRef} close={handleClose}>
        <AddExpense
          edit={true}
          data={selectedCategory}
          expenseCatId={expenseCategoryId}
        />
      </MyModal>

      <button
        className="m-2 p-3 border-0 rounded-pill bg-dark text-white text-capitalize"
        onClick={e => {
          analyticFunc(eventsPayload.Expenses[4]);
          myRef.current.click();
        }}
      >
        add expense
      </button>
    </div>
  );
};
const mapStateToProps = ({ Expenses }) => {
  return { expensesList: Expenses.expensesList };
};
export default connect(mapStateToProps, { getExpensesList })(ExpenseDetails);
