/* eslint-disable */
const initialState = {
  success: false,
};

const SuccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUCCESS': {
      return {
        ...state,
        success: action.payload,
      };
    }
    default:
      return state;
  }
};
export default SuccessReducer;
