/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Paper,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Chart from '../Charts';
import {
  getCategory,
  getBrand,
  getModel,
  getSpecificModel,
  getSuperCategory,
} from '../../../store/RetailerWebStore/RetailerAction/manageInventory';
import { getSalesAnalysis } from '../../../store/RetailerWebStore/RetailerAction/ledger';
import moment from 'moment';
import { toasterMessage, toastDismiss } from '../../../@utils/utils';

const SalesDetails = React.memo(
  ({
    categoryList,
    brands,
    specificModels,
    getCategory,
    getBrand,
    getModel,
    getSpecificModel,
    getSuperCategory,
    superCategoryList,
    getSalesAnalysis,
    salesDetails,
    fromSignup,
  }) => {
    const [superCategoryId, setSuperCatogeryId] = useState('');
    const [superCategoryName, setSuperCatogeryName] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [brandId, setBrandId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [modelId, setModelId] = useState('');
    const [modelName, setModelName] = useState('');
    const [fromDate, setFromDate] = useState(
      `01-01-${new Date().getFullYear()}`
    );
    const [toDate, setToDate] = useState(new Date());
    const [disableSelect, setDissbleSelect] = useState(false);

    useEffect(() => {
      toastDismiss();
      !fromSignup && getSuperCategory();
    }, []);

    useEffect(() => {
      if (superCategoryList && superCategoryList.length > 0) {
        setSuperCatogeryName(superCategoryList);
      }
    }, [superCategoryList]);

    useEffect(() => {
      if (superCategoryId <= 0) {
        setDissbleSelect(true);
      } else {
        setDissbleSelect(false);
      }
      superCategoryId &&
        superCategoryId > 0 &&
        getCategory({ superCategoryId });
      setCategoryId('');
      setCategoryName('');
      setBrandId('');
      setBrandName('');
      setModelId('');
      setModelName('');
    }, [superCategoryId]);

    useEffect(() => {
      categoryId && categoryId > 0 && getBrand({ categoryId });
      setBrandId('');
      setBrandName('');
      setModelId('');
      setModelName('');
    }, [categoryId]);

    useEffect(() => {
      brandId && getSpecificModel({ categoryId, brandId });
      setModelId('');
      setModelName('');
    }, [brandId]);

    const getMonths = useCallback(() => {
      let months = [];
      let start = new Date(fromDate).getMonth();
      let end = new Date(toDate).getMonth();
      months.push(end);
      while (start !== end || start > 12) {
        months.unshift(start);
        start = (start + 1) % 12;
      }
      return months;
    }, [fromDate, toDate]);

    const monthDiff = useCallback(
      (fromDate, toDate) => {
        let to = new Date(toDate);
        let from = new Date(fromDate);
        return (
          to.getMonth() -
          from.getMonth() +
          12 * (to.getFullYear() - from.getFullYear())
        );
      },
      [fromDate, toDate]
    );

    const formatData = useCallback(() => {
      let arr = [['Month', 'Inventory', 'Sales']];
      let totalInventory = 0,
        totalSales = 0;
      let month = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      if (salesDetails) {
        let arr1 = getMonths().map((item, i) => {
          let v = salesDetails.filter(
            sale => sale.month.toLowerCase() == month[item].toLowerCase()
          )[0];
          if (v) {
            totalInventory = parseInt(v.inventory);
            totalSales += parseInt(v.sales);
            return [v.month, v.inventory, v.sales];
          }
          return [month[item], 0, 0];
        });
        arr.push(...arr1);
        arr.push(['Total', totalInventory, totalSales]);
      }
      return arr;
    }, [salesDetails]);

    const handleSubmit = useCallback(() => {
      if (monthDiff(fromDate, toDate) > 11) {
        return toasterMessage('error', 'Range exceeds 12 months');
      }
      let payload = {
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        superCategoryId: parseInt(superCategoryId),
        categoryId: categoryId || undefined,
        brandId: brandId || undefined,
        modelId: modelId || undefined,
      };
      getSalesAnalysis(payload);
    });

    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Paper className="p-4 sales-detail">
          <h3 color="primary">Sales Analysis Details</h3>
          <br />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="outlined"
              id="date-picker-dialog"
              className="headerDatePicker"
              label="From Date"
              format="yyyy/MM/dd"
              value={fromDate}
              minDate={`${(new Date(toDate).getMonth() + 2) % 12}-01-${new Date(
                toDate
              ).getFullYear() - 1}`}
              disableFuture
              onChange={date => setFromDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="outlined"
              id="date-picker-dialog"
              className="headerDatePicker"
              label="To Date"
              format="yyyy/MM/dd"
              value={toDate}
              disableFuture
              onChange={date => setToDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <br />
          <FormControl variant="outlined">
            <InputLabel id="category">Category</InputLabel>
            <Select
              style={{ width: 450 }}
              labelId="SuperCategory"
              id="SuperCategory"
              value={
                superCategoryId && superCategoryId + ',,,' + superCategoryName
              }
              onChange={e => {
                const arr = e.target.value.split(',,,');
                setSuperCatogeryName(arr[1]);
                setSuperCatogeryId(arr[0]);
              }}
              label="Category"
            >
              {superCategoryList &&
                superCategoryList.map(item => (
                  <MenuItem
                    key={item.superCategoryId}
                    value={
                      item.superCategoryId + ',,,' + item.superCategoryName
                    }
                  >
                    {item.superCategoryName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl variant="outlined">
            <InputLabel id="category">Sub Category</InputLabel>
            <Select
              disabled={disableSelect}
              style={{ width: 450 }}
              labelId="category"
              id="category"
              value={categoryId && categoryId + ',,,' + categoryName}
              onChange={e => {
                const arr = e.target.value.split(',,,');
                setCategoryName(arr[1]);
                setCategoryId(arr[0]);
              }}
              label="Sub Category"
            >
              {categoryList &&
                categoryList.map(item => (
                  <MenuItem
                    key={item.categoryId}
                    value={item.categoryId + ',,,' + item.categoryName}
                  >
                    {item.categoryName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl variant="outlined">
            <InputLabel id="brand">Brand</InputLabel>
            <Select
              disabled={disableSelect}
              style={{ width: 450 }}
              labelId="brand"
              id="brand"
              value={brandId && brandId + ',,,' + brandName}
              onChange={e => {
                const arr = e.target.value.split(',,,');
                setBrandName(arr[1]);
                setBrandId(arr[0]);
              }}
              label="Brand"
            >
              {brands &&
                brands.map(item => (
                  <MenuItem
                    key={item.brandId}
                    value={item.brandId + ',,,' + item.brandName}
                  >
                    {item.brandName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl variant="outlined">
            <InputLabel id="Model">Model</InputLabel>
            <Select
              disabled={disableSelect}
              style={{ width: 450 }}
              labelId="Model"
              id="Model"
              value={modelId && modelId + ',,,' + modelName}
              onChange={e => {
                const arr = e.target.value.split(',,,');
                setModelName(arr[1]);
                setModelId(arr[0]);
              }}
              label="Model"
            >
              {specificModels &&
                specificModels.map(item => (
                  <MenuItem
                    key={item.modelId}
                    value={item.modelId + ',,,' + item.modelName}
                  >
                    {item.modelName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <button
            className="rounded-pill p-3 text-white text-uppercase w-75 border-0 bg-themeorange"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <br />
          <br />

          <div
            className="w-100 d-flex flex-column align-items-center justify-content-center"
            style={{ width: 500, overflow: 'auto' }}
          >
            {salesDetails && (
              <>
                {superCategoryName} {categoryName ? ' > ' : null} {categoryName}{' '}
                {brandName ? ' > ' : null} {brandName}{' '}
                {modelName ? ' > ' : null} {modelName}
                <Chart
                  data={formatData()}
                  vAxis="Amount in Rupee"
                  hAxis={`${moment(fromDate).format('DD-MM-YYYY')} to ${moment(
                    toDate
                  ).format('DD-MM-YYYY')}`}
                />
              </>
            )}
          </div>
        </Paper>
      </div>
    );
  }
);

const mapStateToProps = ({ ManageInventory, Ledger }) => {
  const {
    superCategoryList,
    categoryList,
    brands,
    specificModels,
  } = ManageInventory;
  return {
    superCategoryList,
    categoryList,
    brands,
    specificModels,
    salesDetails: Ledger.salesDetails.report,
  };
};

export default connect(mapStateToProps, {
  getCategory,
  getBrand,
  getModel,
  getSpecificModel,
  getSuperCategory,
  getSalesAnalysis,
})(SalesDetails);
