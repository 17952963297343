import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

const CustomInput = React.memo(props => {
  const {
    error,
    value,
    autoFocus,
    beforeTextField,
    inputLabel,
    afterTextField,
    divStyle,
    onChange,
    disabled,
    size,
    multiline,
    defaultValue,
  } = props;
  return (
    <div
      style={divStyle}
      className={error ? 'divError mb-3' : 'inputContainer mb-3'}
    >
      {beforeTextField}
      <TextField
        disabled={disabled}
        multiline={multiline}
        autoFocus={autoFocus}
        value={value}
        size={size}
        label={inputLabel}
        fullWidth
        InputProps={{
          disableUnderline: true,
        }}
        error={error}
        onChange={onChange}
      />
      {afterTextField}
    </div>
  );
});

export default CustomInput;
