import jwtDecode from 'jwt-decode';
import axios from '../../node_modules/axios';
import { store } from '../store/store';
import { history } from '../routes';

export const baseURL = {
  baseURL: `${process.env.REACT_APP_URL}api/v3/`,
  // baseURL: 'http://localhost:3005/api/v3/',
  // baseURL = 'https://stagingapi.mbillapp.com/api/v3/',
  // baseURL = 'https://preprodapi.mbillapp.com/api/v3/',
  // baseURL = 'https://api.mbillapp.com/api/v3/',
};

const logoutUser = () => {
  const { dispatch } = store;

  localStorage.removeItem('token');
  dispatch({ type: 'USER_LOGOUT' });
  history.push('/');
};

const api = props => {
  if (navigator.onLine) {
    const defaultOptions = {
      baseURL: baseURL.baseURL,
      headers: {
        'Content-Type': 'application/json',
        'x-mb-d': 'bWJpbGwtd2Vi',
      },
    };

    // Create instance
    const instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(
      config => {
        // const token =
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6NDM3OTksInJvbGUiOiJSZXRhaWxlciIsImlhdCI6MTYxMjI0Nzg3OSwiZXhwIjoxNjE0ODM5ODc5fQ.geDD6Gd6fwg516HQN7sozouyE5k0X6OeD98ukxS4DG0";
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6NDM4MDQsInJvbGUiOiJSZXRhaWxlciIsImlhdCI6MTYxMTkxMzk4NCwiZXhwIjoxNjE0NTA1OTg0fQ.a3iu_t_juGWhQPg-lS67lpwlQ5xSgDAyVuLU5krDtA8";
        const token = localStorage.getItem('token');
        if (token) {
          const { exp } = jwtDecode(token);

          // ==== check if token is expired ====
          if (exp * 1000 < Date.now()) {
            logoutUser();
            console.log('token expired....');
            throw new axios.Cancel('Token expired please login again!');
          } else {
            config.headers['x-auth-token'] = token;
          }
        }
        return config;
      },
      err => Promise.reject(err)
    );

    const UNAUTHORIZED_STATUS = 401;

    instance.interceptors.response.use(
      response => response,
      err => {
        const { status, error } = err.response.data;
        if (status === UNAUTHORIZED_STATUS) {
          logoutUser();
        }
        return Promise.reject(err);
      }
    );
    return instance;
  }
};

export default api();
