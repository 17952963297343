/* eslint-disable */
import api from '../../../api/api';
import { toasterMessage } from '../../../@utils/utils';
import {
  SEARCH_CREDITS,
  SET_LOADING,
  CREDIT_DETAILS,
  CLEAR_CREDITS,
  SET_CREDIT_ID,
} from './types';

export const searchCredits = (payload, cb) => async dispatch => {
  await api
    .post('creditbook/searchCredits', payload)
    .then(response => {
      cb && cb(true);
      dispatch({ type: SEARCH_CREDITS, payload: response.data.data });
    })
    .catch(error => {
      cb && cb(false);
      dispatch({ type: SEARCH_CREDITS, payload: [] });
    })
    .catch(() => {
      cb && cb(false);
      toasterMessage('error', 'Something Bad Happend :(');
    });
};

export const clearSearchCredits = () => async dispatch => {
  dispatch({ type: CLEAR_CREDITS, payload: [] });
};

export const getCreditDetails = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('creditbook/getCreditDetails', payload)
    .then(response => {
      dispatch({ type: CREDIT_DETAILS, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const creditPayment = payload => async dispatch => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .post('creditbook/postPayment', payload)
    .then(response => {
      dispatch({ type: CREDIT_DETAILS, payload: response.data.data });
      // toasterMessage('success', response.data.message)
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    });
  dispatch({ type: SET_LOADING, payload: false });
};

export const notifyCustomer = payload => async dispatch => {
  api
    .post('creditbook/creditSendSms', payload)
    .then(response => {
      toasterMessage('success', response.data.data);
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(error => {
      toasterMessage('error', 'SOMETHING BAD HAPPENED :(');
    });
};

export const setCreditId = payload => async dispatch => {
  dispatch({ type: SET_CREDIT_ID, payload });
};
