/* eslint-disable */
const initialState = {
  categories: { list: [] },
};

const getCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CATEGORIES': {
      return {
        ...state,
        categories: action.payload,
      };
    }
    default:
      return state;
  }
};
export default getCategoriesReducer;
