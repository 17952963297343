import React, { useState } from 'react';
import { useEffect } from 'react';
import { getPdf } from '../../../store/RetailerWebStore/RetailerAction/earnReward';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toasterMessage } from '../../../@utils/utils';
import { history } from '../../../routes';
import Loader from '../../Loader';

const FillSurvey = ({ getPdf, socialData, gmail, isGoogle }) => {
  const [url, setUrl] = useState({});
  const { invoiceToken } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let payload = {
      invoiceToken,
      googleId: isGoogle ? socialData : undefined,
      facebookId: !isGoogle ? socialData : undefined,
      gmail: gmail || undefined,
    };
    getPdf(payload)
      .then(response => {
        setUrl(response.data.data);
        setLoading(false);
        !response.data.data.surveyUrl &&
          history.push(`/downloadPdf/${invoiceToken}`);
      })
      .catch(err => {
        toasterMessage('error', err.response.data.error);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toasterMessage('error', 'Something Bad Happened :{');
      });
  }, []);

  return (
    <div>
      {!loading ? (
        <>
          <button
            className="text-white bg-danger rounded-pill p-3 m-2 border-0"
            style={{ width: 150, minWidth: 100 }}
            onClick={e => history.push(`/downloadPdf/${invoiceToken}`)}
          >
            Skip To Download Pdf
          </button>
          <a href={url && url.surveyUrl}>
            <button
              className="text-white bg-success rounded-pill p-3 m-2 border-0"
              style={{ width: 150, minWidth: 100 }}
            >
              Fill Survey
            </button>
          </a>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default connect(null, { getPdf })(FillSurvey);
