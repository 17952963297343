/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@material-ui/icons';

const ChangeQty = React.memo(({ data, handleModal, getQty }) => {
  const [modelQuantity, setModelQuantity] = useState(0);
  const [reason, setReason] = useState('');
  const [returnDistributor, setReturnDistributor] = useState(false);
  const [stockLossQuantity, setStockLossQuantity] = useState(false);
  const [other, setOther] = useState(false);

  const handleReason = value => {
    setReason(value);
    switch (value) {
      case 'Returned to Distributor':
        setReturnDistributor(true);
        setStockLossQuantity(false);
        setOther(false);
        break;
      case 'Stock loss quantity':
        setReturnDistributor(false);
        setStockLossQuantity(true);
        setOther(false);
        break;
      case 'Others':
        setReturnDistributor(false);
        setStockLossQuantity(false);
        setOther(true);
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    data && setModelQuantity(data.modelQuantity);
  }, [data]);

  const handleSubmit = e => {
    e.preventDefault();
    let payload = {
      modelQuantity,
      returnDistributor,
      stockLossQuantity,
      other,
    };
    getQty(payload);
    handleModal();
  };
  return (
    <div className="d-flex justify-content-center align-items-center flex-column m-4">
      <h3 className="text-themeorange">Edit Model Inventory</h3>
      <div className="d-flex justify-content-between align-items-center">
        <div>Revised Quantity</div>
        <div className="d-flex align-items-center">
          <ArrowLeftOutlined
            className="hover-pointer"
            fontSize="large"
            onClick={e => {
              modelQuantity > 0 &&
                setModelQuantity(parseInt(modelQuantity) - 1);
            }}
          />
          {modelQuantity}
          <ArrowRightOutlined
            className="hover-pointer"
            fontSize="large"
            onClick={e => setModelQuantity(parseInt(modelQuantity) + 1)}
          />
        </div>
      </div>
      {data.modelQuantity > modelQuantity && (
        <FormControl
          component="fieldset"
          style={{ width: 350, lineHeight: '.5px' }}
        >
          <FormGroup aria-label="position" column>
            {['Returned to Distributor', 'Stock loss quantity', 'Others'].map(
              item => (
                <FormControlLabel
                  className="d-inline-flex justify-content-between"
                  value={item}
                  control={<Checkbox color="primary" />}
                  label={item}
                  checked={reason == item ? true : false}
                  labelPlacement="start"
                  onChange={e => handleReason(e.target.value)}
                />
              )
            )}
          </FormGroup>
        </FormControl>
      )}
      <button
        className="p-2 text-white rounded-pill text-uppercase border-0 w-50 bg-success"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
});

export default ChangeQty;
