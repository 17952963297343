import React from 'react';
import Contact from './contact';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, List } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '8px',
    borderRadius: '1rem',
    position: 'absolute',
    zIndex: '100',
    width: '100%',
    maxHeight: '200%',
    overflow: 'auto',
    scrollbarWidth: 'thin',
  },
  scrollableList: {
    // maxHeight: "40%",
    // overflow: "auto",
    // scrollbarWidth: "thin",
  },
}));

const ContactList = props => {
  const { handleSelection, contactList } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <List className={classes.scrollableList}>
        {contactList.map(contact => (
          <Contact
            key={contact.mobileNumber}
            name={contact.customerName}
            phoneNumber={contact.mobileNumber}
            handleSelection={() => handleSelection(contact)}
          />
        ))}
      </List>
    </Paper>
  );
};

export default ContactList;
