/* eslint-disable */
/* eslint-disable */
const initialState = {
  admins: [],
  admin: [],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ALL_ADMINS': {
      return {
        ...state,
        admins: action.payload,
      };
    }
    case 'SHOW_ADMIN': {
      return {
        ...state,
        admin: action.payload,
      };
    }
    case 'RESET_ADMINS': {
      return {
        ...state,
        admins: [],
      };
    }
    case 'HANDLE_ADMIN':
      return { ...state, admin: { ...state.admin, ...action.payload } };
    case 'RESET_ADMIN':
      return { ...state, admin: {} };
    default:
      return state;
  }
};
export default AdminReducer;
