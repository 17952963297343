import * as _ from 'lodash';

const initialState = {
  nonBouncedCustomers: [],
  bouncedCustomers: [],
  wallet: {},
  superCategories: [],
  categories: [],
  models: [],
  brands: [],
};

const SendSms = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'GET_CUSTOMERS': {
      return {
        ...state,
        nonBouncedCustomers: payload,
      };
    }
    case 'GET_BOUNCED_CUSTOMERS': {
      return {
        ...state,
        bouncedCustomers: payload,
      };
    }
    case 'GET_WALLET': {
      return {
        ...state,
        wallet: payload,
      };
    }
    case 'UPDATE_WALLET': {
      return {
        ...state,
        wallet: { ...state.wallet, final_msgs: payload.final_msgs },
      };
    }
    case 'DROPDOWN_DATA': {
      return {
        ...state,
        ...sorter(payload),
      };
    }
    default:
      return state;
  }
};
export default SendSms;

const sorter = data => {
  let superCategories = [],
    categories = [],
    brands = [],
    models = [];
  if (data) {
    superCategories = _.uniqBy(data, 'superCategoryId');
    categories = _.uniqBy(data, 'categoryId');
    brands = _.uniqBy(data, 'brandId');
    models = _.uniqBy(data, 'modelId');
  }
  return { superCategories, categories, brands, models };
};
