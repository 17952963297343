import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import api from '../../../api/api'
import { toasterMessage, toastDismiss } from '../../../@utils/utils';
import {
  Paper,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import imageCompression from 'browser-image-compression';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  getProfile,
  updateProfile,
} from '../../../store/RetailerWebStore/RetailerAction/myProfile';
import { getStateCity } from '../../../store/AdminActions/allStateCityAction';
import Loader from '../../Loader';
import { ChevronLeft } from '@material-ui/icons';
import { userLogout } from '../../../store/AdminActions/logoutAction';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    width: '40%',
    minWidth: '400px',
  },
  image: {
    borderRadius: '50%',
    width: 130,
    height: 130,
    border: '3px lightgrey solid',
  },
}));

const MyProfile = React.memo(
  ({
    getProfile,
    updateProfile,
    getStateCity,
    user,
    stateList,
    userLogout,
    getNotificationCount,
  }) => {
    const classes = useStyles();
    const imgRef = useRef();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [tradeName, setTradeName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [countryId, setCountryId] = useState('');
    const [country, setCountry] = useState('');
    const [stateId, setStateId] = useState('');
    const [state, setState] = useState('');
    const [cityId, setCityId] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [isGstNumber, setisGstNumber] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [legalName, setLegalName] = useState('');
    const [shopStatus, setShopStatus] = useState('');
    const [shopType, setShopType] = useState('');
    const [financeFacility, setFinanceFacility] = useState('');
    const [userImage, setUserImage] = useState('');
    const [edit, setEdit] = useState(false);
    const [imgKey, setImgKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [inputCity, setInputCity] = useState('');
    const [cinNumber, setCinNumber] = useState('');

    useEffect(() => {
      getNotificationCount();
      toastDismiss();
      getProfile();
    }, []);

    useEffect(() => {
      if (user) {
        setFirstName(user.firstName ? user.firstName : '');
        setLastName(user.lastName ? user.lastName : '');
        setEmail(user.email);
        setContactNumber(user.contactNumber);
        setTradeName(user.tradeName);
        setAddressLine1(user.addressLine1);
        setAddressLine2(user.addressLine2);
        setCountryId(user.countryId);
        setCountry(user.country);
        setStateId(user.stateId);
        setState(user.state);
        setCityId(user.cityId);
        setCity(user.city);
        setPincode(user.pincode);
        setLegalName(user.legalName);
        setShopStatus(user.shopStatus);
        setShopType(user.shopType);
        setFinanceFacility(user.financeFacility);
        setUserImage(user.userImage);
        setisGstNumber(user.isGstNumber);
        setGstNumber(() => (user.gstNumber ? user.gstNumber : ''));
        setCinNumber(user.cinNumber);
      }
    }, [user]);

    const onSuccess = useCallback(
      bool => {
        if (bool) {
          imgRef.current.value = '';
          getProfile();
          setImgKey(Date.now());
          setEdit(false);
        }
        setLoading(false);
      },
      [edit]
    );

    // const validateGst = useCallback(() => {
    // toastDismiss()

    //   api
    //     .post('user/gstValidator', { gstNumber })
    //     .then((response) => {
    //       const { address, tradeNam } = response.data.data
    //       const { pncd, stcd, dst } = address
    //       setLegalName(tradeNam)
    //       setPincode(pncd)
    //       let arr = stateList.filter((item) => item.stateName.toLowerCase() == stcd.toLowerCase())
    //       if (arr.length > 0) {
    //         setState(stcd)
    //         setStateId(arr[0].stateId)
    //         let arr1 = arr[0].cityList.filter((item) => item.City.toLowerCase() == dst.toLowerCase())
    //         if (arr1.length > 0) {
    //           setCity(dst)
    //           setCityId(arr1[0].cityId)
    //         } else {
    //           toasterMessage('error', 'City not present in list')
    //         }
    //       } else {
    //         toasterMessage('error', 'State not present in list')
    //       }
    //     })
    //     .catch((error) => {
    //       toasterMessage('error', error.response.data.error)
    //     })
    //     .catch(() => {
    //       toasterMessage('error', 'something bad happened :(')
    //     })
    // },[gstNumber])

    const editUpdateButton = useCallback(() => {
      if (edit) {
        let payload = {
          firstName,
          lastName,
          email,
          // contactNumber,
          tradeName,
          addressLine1,
          addressLine2: addressLine2 ? addressLine2 : undefined,
          // countryId: country ? "1".toString() : undefined,
          // stateId: stateId ? stateId.toString() : undefined,
          // cityId: cityId ? cityId.toString() : undefined,
          pincode,
          isGstNumber: gstNumber && gstNumber.length > 0 ? 'yes' : 'No',
          gstNumber: gstNumber && gstNumber.length > 0 ? gstNumber : undefined,
          legalName: gstNumber && gstNumber.length > 0 ? legalName : undefined,
          cinNumber: cinNumber && cinNumber.length > 0 ? cinNumber : undefined,
          shopStatus: shopStatus || undefined,
          shopType: shopType || undefined,
          financeFacility: financeFacility || undefined,
          userImage: userImage == null ? undefined : userImage.split(',')[1],
        };
        updateProfile(payload, onSuccess);
        setLoading(true);
      } else {
        stateList.length < 1 && getStateCity();
        setEdit(true);
      }
    });

    const pickImage = useCallback(file => {
      if (file && file.type.split('/')[0] == 'image') {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 200,
          };
          imageCompression(file, options).then(compressedFile => {
            imageCompression
              .getDataUrlFromFile(compressedFile)
              .then(base64Data => setUserImage(base64Data))
              .catch(err => {
                toasterMessage(
                  'error',
                  'Something bad happened in uploading photo'
                );
              });
          });
        } catch (error) {
          toasterMessage('error', 'Something bad happened in uploading photo');
        }
      }
    }, []);

    const checkEmail = () => {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!email) {
        return setEmailError('email is required');
      }
      if (!pattern.test(email)) {
        return setEmailError('email is not valid');
      }
      setEmailError('');
    };

    return (
      <div style={{ position: 'relative' }}>
        {user.isRefuge ? (
          <IconButton
            onClick={e => userLogout()}
            style={{ position: 'absolute', left: 10 }}
          >
            <ChevronLeft />
          </IconButton>
        ) : (
          <></>
        )}
        <div className={classes.root}>
          <Paper elevation={3} className={classes.paper}>
            <div className="d-flex flex-column align-items-center w-50">
              <div>
                <input
                  className="d-none"
                  onChange={e => pickImage(e.target.files[0])}
                  disabled={!edit}
                  ref={imgRef}
                  accept="image/*"
                  type="file"
                />
                <img
                  className={classes.image}
                  src={
                    userImage
                      ? userImage.length < 100
                        ? `${userImage}?${imgKey}`
                        : userImage
                      : 'assets/Profile.jpg'
                  }
                  alt="ProfilePic"
                  height="100"
                  style={edit ? { transform: 'translateX(15px)' } : {}}
                />
                {edit && (
                  <EditOutlinedIcon
                    onClick={e => {
                      imgRef.current.click();
                    }}
                    className="p-1 btn rounded-circle text-white"
                    style={{
                      transform: 'translate(-10px, 25px)',
                      background: 'coral',
                      width: 35,
                      height: 35,
                      border: '2px white solid',
                    }}
                  />
                )}
              </div>
              <br />
              <div style={{ display: edit ? 'none' : 'block' }}>
                <div>{firstName + ' ' + lastName}</div>
                <div>{email}</div>
              </div>
              <div
                className="justify-content-between"
                style={{ display: edit ? 'flex' : 'none' }}
              >
                <TextField
                  style={{ width: 170 }}
                  id="FirstName"
                  label="FirtsName"
                  disabled={!edit}
                  value={firstName || ''}
                  onChange={e => setFirstName(e.target.value)}
                />
                <TextField
                  style={{ width: 170 }}
                  id="LastName"
                  label="LastName"
                  disabled={!edit}
                  value={lastName || ''}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
              <br />
              <TextField
                style={{ width: 350, display: edit ? 'flex' : 'none' }}
                id="Email"
                label="Email"
                type="email"
                onFocus={e => setEmailError('')}
                disabled={!edit}
                onChange={e => setEmail(e.target.value)}
                onBlur={checkEmail}
                value={email || ''}
              />
              <small className="text-themeorange">{emailError}</small>
              <br />
              <TextField
                style={{ width: 350 }}
                id="Mobile Number"
                label="Mobile Number"
                type="number"
                disabled={true}
                value={contactNumber || ''}
                onChange={e => setContactNumber(e.target.value)}
              />
              <br />
              <TextField
                style={{ width: 350 }}
                id="Shop Name"
                label="Shop Name"
                disabled={!edit}
                value={tradeName || ''}
                onChange={e => setTradeName(e.target.value)}
              />
              <br />
              <TextField
                style={{ width: 350 }}
                id="Address Line 1"
                label="Address Line 1"
                disabled={!edit}
                value={addressLine1 || ''}
                onChange={e => setAddressLine1(e.target.value)}
              />
              <br />
              <TextField
                style={{ width: 350 }}
                id="Address Line 2"
                label="Address Line 2"
                disabled={!edit}
                value={addressLine2 || ''}
                onChange={e => setAddressLine2(e.target.value)}
              />
              <br />
              <Autocomplete
                id="combo-box-demo"
                disabled={!edit}
                options={['India' + ',' + '1']}
                value={country + ',' + '1'}
                onChange={(e, newValue) => {
                  if (newValue) {
                    let arr = newValue.split(',');
                    setCountry(arr[0]);
                    setCountryId(arr[1]);
                  } else {
                    country && setCountry('');
                    countryId && setCountryId('');
                  }
                }}
                disableClearable
                getOptionLabel={option => (option ? option.split(',')[0] : '')}
                style={{ width: 350 }}
                renderInput={params => (
                  <TextField {...params} label="Country" variant="outlined" />
                )}
              />
              <br />
              <Autocomplete
                id="combo-box-demo"
                value={state + ',' + stateId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    let arr = newValue.split(',');
                    setState(arr[0]);
                    setStateId(arr[1]);
                  } else {
                    state && setState('');
                    stateId && setStateId('');
                  }
                  cityId && setCityId('');
                  inputCity && setInputCity('');
                  city && setCity('');
                }}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                disableClearable
                disabled={!edit}
                options={stateList.map(
                  item => item.stateName + ',' + item.stateId
                )}
                getOptionLabel={option => (option ? option.split(',')[0] : '')}
                style={{ width: 350 }}
                renderInput={params => (
                  <TextField {...params} label="State" variant="outlined" />
                )}
              />
              <br />
              <Autocomplete
                id="combo-box-demo"
                value={city + ',' + cityId}
                onChange={(e, newValue) => {
                  if (newValue) {
                    let arr = newValue.split(',');
                    setCity(arr[0]);
                    setCityId(arr[1]);
                  } else {
                    setCity('');
                    setCityId('');
                  }
                }}
                inputValue={inputCity}
                onInputChange={(e, newInputValue) => {
                  setInputCity(newInputValue);
                }}
                disabled={!edit}
                disableClearable
                options={
                  stateList &&
                  stateList.length > 0 &&
                  stateList.filter(item => item.stateName == state)[0]
                    ? stateList
                        .filter(item => item.stateName == state)[0]
                        .cityList.map(c => c.City + ',' + c.cityId)
                    : ['']
                }
                getOptionLabel={option => (option ? option.split(',')[0] : '')}
                style={{ width: 350 }}
                renderInput={params => (
                  <TextField {...params} label="City" variant="outlined" />
                )}
              />
              <br />
              <TextField
                style={{ width: 350 }}
                id="Pincode"
                label="Pincode"
                type="number"
                disabled={!edit}
                value={pincode || ''}
                onChange={e =>
                  setPincode(
                    e.target.value
                      .replace(/[^0-9]/g, '')
                      .toString()
                      .slice(0, 6)
                  )
                }
              />
              <br />
              <TextField
                id="GST Number"
                style={{ width: 350 }}
                label="GST Number"
                disabled={!edit}
                value={gstNumber ? gstNumber : ''}
                onChange={e =>
                  setGstNumber(e.target.value.toString().slice(0, 15))
                }
                // onBlur={validateGst}
              />
              <br />
              <TextField
                id="Legal Name"
                style={{ width: 350 }}
                label="Legal Name"
                disabled={edit && gstNumber.length > 0 ? false : true}
                value={legalName ? legalName : ''}
                onChange={e => setLegalName(e.target.value)}
              />
              <br />
              <TextField
                id="CIN Number"
                style={{ width: 350 }}
                label="CIN Number"
                disabled={!edit}
                value={cinNumber ? cinNumber : ''}
                onChange={e =>
                  setCinNumber(e.target.value.replace(/[^0-9]/g, '').toString())
                }
              />
              <br />
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Shop Status
                </InputLabel>
                <Select
                  style={{ width: 350 }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={shopStatus ? shopStatus.toLowerCase() : 'None'}
                  disabled={!edit}
                  onChange={e => setShopStatus(e.target.value)}
                  label="Shop Status"
                >
                  <MenuItem style={{ display: 'none' }} key="None" value="None">
                    None
                  </MenuItem>
                  {['Partnership', 'Private Limited', 'Proprietorship'].map(
                    (item, i) => (
                      <MenuItem value={item.toLowerCase() || ''} key={i}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <br />
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  ShopType
                </InputLabel>
                <Select
                  style={{ width: 350 }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={shopType ? shopType.toLowerCase() : 'None'}
                  disabled={!edit}
                  onChange={e => setShopType(e.target.value)}
                  label="ShopType"
                >
                  <MenuItem style={{ display: 'none' }} key="None" value="None">
                    None
                  </MenuItem>
                  {[
                    'Stand Alone Store',
                    'Local Chain Store',
                    'National Chain Store',
                  ].map((item, i) => (
                    <MenuItem value={item.toLowerCase() || ''} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl component="fieldset" style={{ width: 350 }}>
                <FormLabel component="legend">Finace/EMI Facility?</FormLabel>
                <FormGroup aria-label="position" column="true">
                  {[
                    'Provided by us',
                    'Tie up with a finance Company/Bank',
                    'No finance facility',
                  ].map((item, i) => (
                    <FormControlLabel
                      key={i}
                      style={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                      }}
                      value={item ? item.toLowerCase() : ''}
                      disabled={!edit}
                      control={<Checkbox color="primary" />}
                      label={item}
                      checked={
                        financeFacility &&
                        financeFacility.toLowerCase() == item.toLowerCase()
                          ? true
                          : false
                      }
                      labelPlacement="start"
                      onChange={e => setFinanceFacility(e.target.value)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <br />
              {!loading ? (
                <button
                  className="p-3 text-white text-capitalize bg-themeorange rounded-pill border-0 w-75"
                  onClick={editUpdateButton}
                  disabled={user.userType === 'subRetailer'}
                >
                  {edit ? 'Update' : 'Edit'}
                </button>
              ) : (
                <Loader />
              )}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({
  loginReducer: { user },
  getStateCityReducer: { stateCity },
  Profile,
}) => {
  return {
    user:
      user.userType.toLowerCase() === 'retailer'
        ? user.userData[0]
        : Profile.userData,
    stateList: stateCity,
  };
};

export default connect(mapStateToProps, {
  getProfile,
  updateProfile,
  getStateCity,
  userLogout,
  getNotificationCount,
})(MyProfile);
