import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from '../../node_modules/redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from '../../node_modules/redux-persist/lib/storage';
import rootReducer from './index';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'loginReducer',
    'OtpLoginReducer',
    'AddSubUser',
    'BillingReducer',
    'Billing',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [ReduxThunk];
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
export const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

export default () => ({ store, persistor });
