/* eslint-disable */
import React from 'react';
import Chart from 'react-google-charts';
import Loader from '../../Loader';

const CustomChart = React.memo(({ data, hAxis, vAxis, title }) => {
  const breakPoint = 600;
  const width = window.innerWidth;
  return (
    <Chart
      width="100%"
      // minWidth=''
      height="400px"
      chartType="ColumnChart"
      loader={<Loader />}
      data={data ? data : []}
      title={title || ''}
      align="center"
      options={{
        chartArea: { width: '80%', height: '65%' },
        backgroundColor: 'whitesmoke',
        axisTitlesPosition: 'out',
        vAxis: {
          title: vAxis,
          format: 'short',
          titleTextStyle: {
            italic: false,
            fontSize: 16,
            bold: true,
          },
        },
        hAxis: {
          // slantedText: true,
          maxAlternation: 2,
          maxTextLines: 3,
          showTextEvery: 1,
          format: 'short',
          title: hAxis,
          titleTextStyle: {
            italic: false,
            fontSize: 16,
            bold: true,
          },
        },
        // legend: width < breakPoint ? { position: 'none' } : { position: 'right' },
        enableInteractivity: false,
        legend: { position: 'top' },
      }}
      rootProps={{ 'data-testid': '2' }}
    />
  );
});

export default CustomChart;
