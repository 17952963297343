/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import MyChart from '../Charts';
import { Paper, RootRef } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MyModal from '../Modal';
import Details from './profitLossDetails';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { getProfitLossReport } from '../../../store/RetailerWebStore/RetailerAction/profit&Loss';
import moment from 'moment';
import { toastDismiss, toasterMessage } from '../../../@utils/utils';
import _ from 'lodash';
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const ProfitLoss = React.memo(
  ({ getProfitLossReport, data, getNotificationCount }) => {
    console.log('profit loss');
    const myRef = useRef();
    const fromRef = useRef();
    const toRef = useRef();
    const [fromDate, setFromDate] = useState(
      `01-01-${new Date().getFullYear()}`
    );
    const [toDate, setToDate] = useState(Date.now());
    const [loading, setLoading] = useState(false);
    const [call, setCall] = useState(false);

    const onSuccess = useCallback(() => {
      getNotificationCount();
      setLoading(false);
    }, []);

    useEffect(() => {
      toastDismiss();
    }, []);

    useEffect(() => {
      if (fromDate && toDate) {
        handleSubmit();
      }
    }, [fromDate, toDate]);

    const getMonths = useCallback(() => {
      let months = [];
      let start = new Date(fromDate).getMonth();
      let end = new Date(toDate).getMonth();

      if (start === end) {
        months.push(end);
        return months;
      }
      while (start !== end || start > 12) {
        months.push(start);
        start = (start + 1) % 12;
        if (start === end) months.push(end);
      }
      return months;
    }, [fromDate, toDate]);

    const monthDiff = useCallback(
      (fromDate, toDate) => {
        let to = new Date(toDate);
        let from = new Date(fromDate);
        return (
          to.getMonth() -
          from.getMonth() +
          12 * (to.getFullYear() - from.getFullYear())
        );
      },
      [fromDate, toDate]
    );

    const renderData = useCallback(() => {
      if (data) {
        let arr = [['Month', 'Income', 'Expenses']];
        let totalIncome = 0,
          totalExpense = 0;
        let month = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        let arr1 = getMonths().map((item, i) => {
          let v = data.filter(
            sale => sale.month.toLowerCase() == month[item].toLowerCase()
          )[0];
          if (v) {
            totalIncome = parseInt(v.income);
            totalExpense += parseInt(v.expenses);
            return [v.month, v.income, v.expenses];
          }
          return [month[item], 0, 0];
        });
        arr.push(...arr1);
        arr.push(['Total', totalIncome, totalExpense]);
        return arr;
      }
      return ['Month', 'Income', 'Expenses'];
    }, [data]);

    const handleSubmit = useCallback(() => {
      if (monthDiff(fromDate, toDate) > 11) {
        return toasterMessage('error', 'Range exceeds 12 months');
      }
      let payload = {
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
      };
      // call && getProfitLossReport(payload, onSuccess)
      getProfitLossReport(payload, onSuccess);
    }, [fromDate, toDate]);

    return (
      <div
        className="d-flex align-items-start justify-content-center w-100 p-4"
        style={{ minHeight: '89vh', height: '100%' }}
      >
        <Paper className="p-2 profitLos">
          <h4 className="text-themeorange">Profit and Loss</h4>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <RootRef rootRef={fromRef}>
              <KeyboardDatePicker
                style={{ width: '50%' }}
                variant="outlined"
                id="date-picker-dialog"
                className="headerDatePicker"
                label="From Date"
                minDate={`${(new Date(toDate).getMonth() + 2) %
                  12}-01-${new Date(toDate).getFullYear() - 1}`}
                format="yyyy/MM/dd"
                value={fromDate}
                disableFuture
                onClick={e => setCall(true)}
                onChange={val => setFromDate(val)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </RootRef>
            <KeyboardDatePicker
              style={{ width: '50%' }}
              variant="outlined"
              id="date-picker-dialog"
              className="headerDatePicker"
              label="To Date"
              format="yyyy/MM/dd"
              value={toDate}
              disableFuture
              onClose={e => fromRef.current.click()}
              onClick={e => setCall(true)}
              onChange={val => setToDate(val)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
          <br />
          {!loading ? (
            <>
              <div
                className="w-100 d-flex flex-column align-items-center justify-content-center"
                style={{ width: 500, overflow: 'auto' }}
              >
                {data && data.length > 0 && (
                  <MyChart
                    data={renderData()}
                    vAxis="Amount in Rupee"
                    hAxis={`Date`}
                  />
                )}
              </div>
              <br />
              <button
                className="rounded-pill w-75 border-0 p-3 text-white text-uppercase bg-dark"
                onClick={e => {
                  analyticFunc(eventsPayload.Reports[4]);
                  myRef.current.click();
                }}
              >
                Summary
              </button>
            </>
          ) : (
            <Loader />
          )}
          <MyModal ref={myRef}>
            <Details toDate={toDate} fromDate={fromDate} />
          </MyModal>
        </Paper>
      </div>
    );
  }
);

const mapStateToProps = ({ ProfitLoss }) => {
  return { data: ProfitLoss.profitLossReport.reports };
};

export default connect(mapStateToProps, {
  getProfitLossReport,
  getNotificationCount,
})(ProfitLoss);
