import {
  GET_CATEGORY,
  GET_BRAND,
  GET_MODEL,
  GET_DISTRIBUTOR,
  GET_PRODUCTDETAILS,
  GET_SUBCATEGORY,
  SEARCHED_LIST,
  GET_SPECIFIC_MODEL,
  GET_SUPER_CATOGERY,
} from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  superCategoryList: [],
  categoryList: [],
  subCategoryList: [],
  brands: [],
  models: [],
  specificModels: [],
  distributors: [],
  productDetails: null,
  searchedList: [],
};

const manageInventory = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPER_CATOGERY: {
      return {
        ...state,
        superCategoryList: action.payload,
      };
    }
    case GET_CATEGORY: {
      return {
        ...state,
        categoryList: action.payload,
      };
    }
    case GET_SUBCATEGORY: {
      return {
        ...state,
        subCategoryList: action.payload,
      };
    }
    case GET_BRAND: {
      return {
        ...state,
        brands: action.payload,
      };
    }
    case GET_SPECIFIC_MODEL: {
      return {
        ...state,
        specificModels: action.payload,
      };
    }
    case GET_MODEL: {
      return {
        ...state,
        models: action.payload,
      };
    }
    case GET_DISTRIBUTOR: {
      return {
        ...state,
        distributors: action.payload,
      };
    }
    case GET_PRODUCTDETAILS: {
      return {
        ...state,
        productDetails: action.payload,
      };
    }
    case SEARCHED_LIST: {
      return {
        ...state,
        searchedList: action.payload,
      };
    }
    default:
      return state;
  }
};
export default manageInventory;
