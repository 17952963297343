import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { resetBillingState } from './billingAction';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem',
    borderRadius: '1.5rem',
  },
}));

const CancelModal = props => {
  const classes = useStyles();
  const history = useHistory();

  const { toggleModal, setToggleModal } = props;

  const handleYes = () => {
    props.resetBillingState();
    setToggleModal(!toggleModal);
  };

  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '20rem',
        maxWidth: '30%',
        height: '60%',
      }}
      open={toggleModal}
      onClose={() => setToggleModal(!toggleModal)}
    >
      <Paper className={classes.paper}>
        <p className="formTitle">Your changes won't be saved.</p>
        <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr' }}>
          <Button onClick={handleYes} variant="outlined" color="primary">
            Ok
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setToggleModal(!toggleModal)}
          >
            Cancel
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    resetBillingState: () => dispatch(resetBillingState()),
  };
};

export default connect(null, mapDispatchToProps)(CancelModal);
