import api from '../../api/api';
import { toasterMessage } from '../../@utils/utils';
// Action Creator
export const sendOtp = payload => async dispatch => {
  return api.post('user/sendOtp', payload);
};

export const sendOtpForAddSubuser = (payload, cb) => async dispatch => {
  api
    .post('user/sendOtp', payload)
    .then(response => {
      toasterMessage('success', 'OTP sent successfully');
      if (cb) cb();
    })
    .catch(error => {
      toasterMessage('error', error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'something bad happened');
    });
};

export const otpSentInform = payload => async dispatch => {
  dispatch({ type: 'SEND_OTP', data: payload });
};

export const customLogin = (payload, platform) => async dispatch => {
  let dispatchType = platform === 'Google' ? 'GOOGLE' : 'FACEBOOK';

  api
    .post('user/login', payload)
    .then(response => {
      const { data } = response.data;
      localStorage.setItem('token', data.userToken);
      dispatch({ type: 'USER_LOGGED_IN', payload: data });
      dispatch({
        type: 'LOGIN_VIA_' + dispatchType,
        data: { ...data, userEmailId: payload.email },
      });
    })
    .catch(error => {
      toasterMessage(error.response.data.error);
    })
    .catch(() => {
      toasterMessage('error', 'Something bad happened');
    });
};

export const verifyOtp = payload => async dispatch => {
  return api.post('user/login', payload);
  // .then((response) => {
  //   const { data } = response.data;
  //   localStorage.setItem("token", data.userToken);
  //   dispatch({
  //     type: "USER_LOGGED_IN",
  //     payload: data,
  //   });
  //   dispatch({
  //     type: "VERIFY_OTP",
  //     data: data,
  //   });
  // })
  // .catch((error) => {
  //   dispatch({
  //     type: "WRONG_OTP",
  //     data: true,
  //   });
  //   console.log("VERIFY" + error.response.data.error);
  // })
  // .catch(() => {
  //   //   toasterMessage('error', 'Something bad happened')
  //   console.log("Needs testing in Verify OTP");
  // });
};

export const wrongOtpSent = payload => async dispatch => {
  dispatch({ type: 'WRONG_OTP', data: payload });
};

export const otpVerified = payload => async dispatch => {
  dispatch({ type: 'USER_LOGGED_IN', payload: payload });
  dispatch({ type: 'VERIFY_OTP', data: payload });
};
