import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  TextField,
  Container,
  Divider,
  List,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { connect, useSelector, useDispatch } from 'react-redux';
import ProductInfo from './productInfo';
import CreateProduct from './createProduct';
import {
  getProducts,
  addProductToSelectedList,
  increaseOrDecrease,
  setProductList,
} from '../../billingAction';
import CustomInput from '../../../../CommonComponents/customInput';
import { getUserInventory } from '../../../WebComponents-v3/ManageInventory/manageInventoryAction';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  cont: {
    // display: "flex",
    // flexDirection: "column",
    // padding: "2rem",
    // height: "100%",
    display: 'grid',
    gridTemplateRows: '0.1fr 0.1fr auto',
    padding: '1rem',
    height: '91vh',
    borderLeft: '1px solid #D9DBE9',
  },
}));

const ProductSearch = props => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [debouncingTime, setDebouncingTime] = useState(0);
  const productList = useSelector(state => state.BillingReducer.productList);
  const [localProductList, setLocalProductList] = useState([]);
  const [renderCreateProduct, setRenderCreateProduct] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const scrollref = useRef();
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchByBarcode, setSearchByBarcode] = useState(false);

  useEffect(() => {
    if (props.loadingProducts) setLoadingProducts(props.loadingProducts[0]);
  }, [props.loadingProducts]);

  // useEffect(() => {
  //   if (props.productList.length > 0) {
  //     setProductList(props.productList);
  //   }
  // }, [props.productList, props.selectedProducts]);

  useEffect(() => {
    props.setProductList([]);
    setLocalProductList([]);
    props.getUserInventory({ numberOfItems: '50' });
  }, []);

  useEffect(() => {
    props.setProductList(typeof productList === 'string' ? [] : productList);
    setLocalProductList(typeof productList === 'string' ? [] : productList);
  }, [productList]);

  useEffect(() => {
    setLocalProductList([]);
  }, [searchByBarcode]);

  const handleSearch = query => {
    analyticFunc(eventsPayload.Billing[7]);
    setSearchQuery(query);
    if (query && query.length >= 3) {
      debouncingTime && clearTimeout(debouncingTime);
      const time = setTimeout(() => {
        // dispatch product search request
        let payload = {
          name: query,
          page: 1,
          numberOfItems: '50',
          isBarcode: false,
        };
        if (searchByBarcode) {
          payload = { ...payload, numberOfItems: '1', isBarcode: true };
        }
        props.getProducts(payload);
        try {
          if (scrollref) scrollref.current.scrollTo(0, 0);
        } catch (error) {
          // do notihing
        }
      }, 1000);
      setDebouncingTime(time);
    } else {
      setPageNumber(1);
      props.setProductList([]);
    }
  };

  const handleAddProduct = product => {
    if (props.selectedProducts.indexOf(product) === -1) {
      if (props.isGstUser) product['gst'] = 12;
      props.addProductToSelectedList(product);
    } else {
      props.increaseOrDecrease(product, 1);
    }
  };

  const loadMore = useCallback(() => {
    if (searchByBarcode || productList.length < pageNumber * 50) return;
    if (
      scrollref.current.scrollTop + scrollref.current.clientHeight >=
      scrollref.current.scrollHeight
    ) {
      let pg = pageNumber + 1;
      dispatch(
        getProducts({ name: searchQuery, page: pg, numberOfItems: '50' })
      );
      setPageNumber(pg);
    }
  }, [loadingProducts, productList]);

  return (
    <Container className={classes.cont} maxWidth="md">
      <CustomInput
        divStyle={{
          display: 'grid',
          gridTemplateColumns: '0.03fr 0.8fr 0.17fr',
          justifyContent: 'center',
        }}
        onChange={e => {
          analyticFunc(eventsPayload.Billing[8]);
          handleSearch(e.target.value);
          setRenderCreateProduct(false);
        }}
        inputLabel={
          searchByBarcode === false ? 'Search by name' : 'Search by code'
        }
        beforeTextField={<SearchIcon className="inputContainerIcon" />}
        afterTextField={
          <Button
            style={{ whiteSpace: 'nowrap' }}
            variant="outlined"
            className="mt-0"
            onClick={() => {
              analyticFunc(eventsPayload.Billing[7]);
              setRenderCreateProduct(!renderCreateProduct);
            }}
          >
            Create Product
          </Button>
        }
      />
      <FormControlLabel
        style={{ justifyContent: 'start' }}
        value="Search by barcode"
        control={
          <Checkbox
            checked={searchByBarcode}
            color="primary"
            onChange={e => setSearchByBarcode(e.target.checked)}
          />
        }
        label={<p className="formTitle">Search by Barcode</p>}
        labelPlacement="start"
      />
      {loadingProducts && <LinearProgress />}
      {!renderCreateProduct && (
        <List
          ref={scrollref}
          onScroll={loadMore}
          style={{
            overflow: 'auto',
            scrollbarWidth: 'thin',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          {localProductList.length === 0 &&
            props.usersInventory.map(product => (
              <ProductInfo
                key={product.modelId}
                product={product}
                handleAdd={handleAddProduct}
                isGstUser={props.isGstUser}
                from={props.from}
              />
            ))}

          {localProductList.length > 0 &&
            localProductList.map(product => (
              <ProductInfo
                key={product.modelId}
                product={product}
                handleAdd={handleAddProduct}
                isGstUser={props.isGstUser}
                from={props.from}
              />
            ))}
        </List>
      )}
      {renderCreateProduct && (
        <CreateProduct
          from={props.from}
          setRenderCreateProduct={setRenderCreateProduct}
        />
      )}
    </Container>
  );
};

const mapStateToProps = ({
  BillingReducer,
  OtpLoginReducer,
  ManageInventoryReducer,
}) => ({
  productList: BillingReducer.productList,
  isGstUser: OtpLoginReducer.isGstUser,
  selectedProducts: BillingReducer.selectedProducts,
  usersInventory: ManageInventoryReducer.usersInventory,
  loadingProducts: BillingReducer.loadingProducts,
});

// For Dispatching Action
const mapDispatchToProps = dispatch => ({
  getProducts: payload => dispatch(getProducts(payload)),
  addProductToSelectedList: product =>
    dispatch(addProductToSelectedList(product)),
  increaseOrDecrease: product => dispatch(increaseOrDecrease(product)),
  setProductList: payload => dispatch(setProductList(payload)),
  getUserInventory: payload => dispatch(getUserInventory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
