/* eslint-disable */
import React from 'react';
import Logo from 'react-loader-spinner';

const Loader = React.memo(() => {
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bolder',
  };
  return (
    <div style={styles}>
      <Logo type="Oval" color="#DF5A28" height={50} width={100} />
    </div>
  );
});

export default Loader;
