import { SALES_DETAILS } from '../RetailerAction/types';

/* eslint-disable */
const initialState = {
  isVerified: false,
  salesDetails: [],
};

const Expenses = (state = initialState, { type, payload }) => {
  switch (type) {
    case SALES_DETAILS: {
      return {
        ...state,
        salesDetails: payload,
      };
    }
    case 'VERIFY_OTP': {
      return {
        ...state,
        isVerified: payload,
      };
    }
    default:
      return state;
  }
};
export default Expenses;
