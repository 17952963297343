/* eslint-disable */
import React from 'react';
import { Paper } from '@material-ui/core';
import link from '../../images/splash-screen.png';
import logo from '../../images/logo2.png';
import { toastDismiss } from '../../@utils/utils';
import { useEffect } from 'react';

const TermsCondition = React.memo(({ handleAccept }) => {
  useEffect(() => {
    toastDismiss();
  }, []);
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-4">
      <div className="col-md-8 col-sm-12 ">
        <div className="p-0">
          <img src={link} alt="alt-img" width="100%" height="250px" />
          <img src={logo} alt="alt-img" className="termlogo" />
          <div style={{ position: 'absolute', top: '180px', left: '2%' }}>
            <h3 className="text-secondary termheading border-bottom pb-1">
              Terms and Conditions
            </h3>
          </div>
        </div>
        <Paper className="p-2 shadow termsConditions">
          <blockquote className="text-left text-secondary font-weight-bold">
            "TERMS & CONDITIONS:"
          </blockquote>
          <p className="text-justify text-secondary">
            By using the mbill app you are agreeing to be bound by the following
            terms and conditions (“Terms of Service”).
            <br />
            <br />
            mbill is a product of PredictiVu Pvt Ltd. mbill reserves the right
            to update and change the Terms of Service from time to time without
            notice. Any new features that augment or enhance the current
            Service, including the release of new tools and resources, shall be
            subject to the Terms of Service. Continued use of the Service after
            any such changes shall constitute your consent to such changes.
            <br />
            <br />
            Violation of any of the terms below will result in the termination
            of your Account. While mbill prohibits such conduct and Content on
            the Service, you understand and agree that mbill cannot be
            responsible for the Content posted on the Service and you
            nonetheless may be exposed to such materials. You hereby agree to
            use the Service at your own risk. You hereby agree to share your
            sales data with the mbill for the specific purpose of making market
            reports.
            <br />
            <br />
          </p>
          <blockquote className="text-left text-secondary font-weight-bold">
            "Terms of Use"
          </blockquote>
          <ol className="text-justify text-secondary">
            <li>
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted.
            </li>
            <li>
              You must provide your legal full name, a valid email address, and
              any other information requested in order to complete the signup
              process.
            </li>
            <li>
              You are responsible for maintaining the security of your account
              and password. mbill cannot and will not be liable for any loss or
              damage from your failure to comply with this security obligation.
            </li>
            <li>
              You are responsible for all Content posted and activity that
              occurs under your account (even when Content is posted by others
              who have accounts under your account).
            </li>
            <li>
              One person or legal entity may not maintain more than one free
              account.
            </li>
            <li>
              You may not use the Service for any illegal or unauthorized
              purpose. You must not, in the use of the Service, violate any laws
              in your jurisdiction(including but not limited to copyright laws).
            </li>
            <li>
              We claim no intellectual property rights over the material you
              provide to the Service. Your profile and materials uploaded remain
              yours. However, by setting your pages to be shared, you hereby
              agree to allow mbill to view and utilise your Content to make
              market reports.
            </li>
            <li>
              The look and feel of the Service is copyright of mbill. All rights
              reserved. You may not duplicate, copy, or reuse any portion of the
              HTML/CSS or visual design elements without express written
              permission from mbill.
            </li>
            <li>
              Your use of the service is at your sole risk. The service is
              provided on an “as is” and “as available” basis.
            </li>
            <li>
              Technical support is only provided to paying account holders and
              is only available via email.
            </li>
            <li>
              You understand that mbill uses third party vendors and hosting
              partners to provide the necessary hardware, software, networking,
              storage, and related technology required to run the Service.
            </li>
            <li>
              You must not modify, adapt or hack the Service or modify another
              website so as to falsely imply that it is associated with the
              Service, mbill, or any other mbill service.
            </li>
            <li>
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the Service, use of the Service, or access
              to the Service without the express written permission by mbill.
            </li>
            <li>
              We may, but have no obligation to, remove Content and Accounts
              containing Content that we determine in our sole discretion are
              unlawful, offensive, threatening, libellous, defamatory,
              pornographic, obscene or otherwise objectionable or violates any
              party’s intellectual property or these Terms of Service.
            </li>
            <li>
              Verbal, physical, written or other abuse (including threats of
              abuse or retribution) of any mbill customer, employee, member, or
              officer will result in immediate account termination.
            </li>
            <li>
              You must not upload, post, host, or transmit unsolicited email,
              SMS, or “spam” messages.
            </li>
            <li>
              You must not transmit any worms or viruses or any code of a
              destructive nature.
            </li>
            <li>
              You expressly understand and agree that mbill shall not be liable
              for any direct, indirect, incidental, special, consequential or
              exemplary damages, including but not limited to, damages for loss
              of profits, goodwill, use, data or other intangible losses (even
              if mbill has been advised of the possibility of such damages),
              resulting from: (i) the use or the inability to use the service;
              (ii) the cost of procurement of substitute goods and services
              resulting from any goods, data, information or services purchased
              or obtained or messages received or transactions entered into
              through or from the service; (iii) unauthorized access to or
              alteration of your transmissions or data; (iv) statements or
              conduct of any third party on the service; (v) or any other matter
              relating to the service.
            </li>
            <li>
              As part of the normal operation of our Services, we collect, use
              and, in some cases, disclose information about you to third
              parties. Accordingly, we have developed this Privacy Policy in
              order for you to understand how we collect, use, communicate and
              disclose and make use of your personal information when you use
              the services on the Mobile Application:-
              <ol type="a">
                <li>
                  Before or at the time of collecting personal information, we
                  will identify the purposes for which information is being
                  collected.
                </li>
                <li>
                  We will collect and use of personal information solely with
                  the objective of fulfilling those purposes specified by us and
                  for other compatible purposes, unless we obtain the consent of
                  the individual concerned or as required by law.
                </li>
                <li>
                  We will only retain personal information as long as necessary
                  for the fulfilment of those purposes.
                </li>
                <li>
                  Personal information should be relevant to the purposes for
                  which it is to be used, and, to the extent necessary for those
                  purposes, should be accurate, complete, and up-to-date.
                </li>
                <li>
                  We will protect personal information by reasonable security
                  safeguards against loss or theft, as well as unauthorized
                  access, disclosure, copying, use or modification.
                </li>
                <li>
                  We are committed to conducting our business in accordance with
                  these principles in order to ensure that the confidentiality
                  of personal information is protected and maintained.
                </li>
              </ol>
            </li>
            <li>
              We do not give any warranty that the services and the Mobile
              Application are free from viruses or anything else which may have
              a harmful effect on any technology.
            </li>
            <li>
              You are solely responsible for properly cancelling you account.
              You can cancel your account anytime by clicking on the Cancel
              Account link in Account Settings.
            </li>
            <li>
              We reserve the right to block access to and/or to edit or remove
              any material which in our reasonable opinion may give rise to a
              breach of these Terms and Conditions of Use.
            </li>
          </ol>

          <blockquote className="text-left text-secondary font-weight-bold">
            "Mbilllicable Law and Jurisdiction"
          </blockquote>
          <ol className="text-justify text-secondary">
            <li>
              The Mobile Application can be accessed from all countries around
              the world where the local technology permits. As each of these
              places have differing laws, by accessing the Mobile Application
              both you and we agree that the laws of the India, without regard
              to the conflicts of laws principles thereof, will apply to all
              matters relating to the use of the Mobile Application.
            </li>
            <li>
              You accept and agree that both you and we shall submit to the
              exclusive jurisdiction of the Arbitrator (appointed by the mbill),
              seat in Delhi, India, to be governed by the Indian Arbitration
              Act, in respect of any dispute arising out of and/or in connection
              with these Terms and Conditions of Use.
            </li>
            <li>
              The failure of mbill to exercise or enforce any right or provision
              of the Terms of Service shall not constitute a waiver of such
              right or provision. The Terms of Service constitutes the entire
              agreement between you and mbill and govern your use of the
              service, superseding any prior agreements between you and mbill
              (including, but not limited to, any prior versions of the Terms of
              Service).
            </li>
            <li>
              Questions about the Terms of service should be sent to
              info@mbillapp.com.
            </li>
          </ol>
        </Paper>
        <button
          className="col-md-4 col-sm-8 rounded-pill text-white bg-themeorange border-0 p-3 mt-2"
          value={true}
          onClick={e => handleAccept(true)}
        >
          I Agree
        </button>
      </div>
    </div>
  );
});

export default TermsCondition;
