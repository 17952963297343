import React, { useState, useEffect } from 'react';
import { Button, TextField, Container, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import emptyCart from './../../../../assets/svg/emptyCart.svg';
import ProuductInfo from './productInfo';
import { connect } from 'react-redux';
import {
  addProductToSelectedList,
  clearSelectedProducts,
  increaseOrDecrease,
  removeProductFromList,
} from '../../billingAction';

const useStyles = makeStyles(theme => ({
  cont: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    height: '80%',
    justifyContent: 'space-between',
  },
  img: {
    display: 'block',
    margin: '0 auto',
  },
  headingWithButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
}));

const EmptyContainer = ({ imgStyle }) => (
  <div>
    <img className={imgStyle} src={emptyCart} alt="Empty Cart Image" />
    <h6 className="text-center">No items added</h6>
    <p className="subtitle text-center">All the items will be added here</p>
  </div>
);

const SelectedProductList = props => {
  const classes = useStyles();
  const {
    selectedProducts,
    handleClickEvent,
    showProdSearchComp,
    removeSelectedProducts,
    setShowConfirmation,
    from,
  } = props;

  const handleIncreaseOrDecrease = payload => {
    if (payload.product.inCartQuantity === 1 && payload.by === -1) {
      props.removeProductFromList(payload.product);
    } else {
      props.increaseOrDecrease(payload.product, payload.by);
    }
  };

  return (
    <div className={classes.cont}>
      <div className={classes.headingWithButton}>
        <p className="formTitle">ITEMS</p>
        {selectedProducts.length !== 0 && (
          <Button
            color="primary"
            variant="text"
            onClick={() => setShowConfirmation(true)}
          >
            Remove All Products
          </Button>
        )}
      </div>
      {selectedProducts.length === 0 && (
        <EmptyContainer imgStyle={classes.img} />
      )}
      {selectedProducts.length > 0 && (
        <List
          style={{
            overflow: 'auto',
            scrollbarWidth: 'thin',
          }}
        >
          {selectedProducts.map(product => (
            <ProuductInfo
              from={from}
              key={product.modelId}
              product={product}
              handleIncreaseOrDecrease={handleIncreaseOrDecrease}
              isSelected={'true'}
            />
          ))}
        </List>
      )}
      <Button
        color="primary"
        fullWidth
        variant="contained"
        className="mt-3"
        onClick={() => {
          props.handleClickEvent();
        }}
      >
        {showProdSearchComp ? 'Done' : 'Go Back to Product Search'}
      </Button>
    </div>
  );
};

const mapStateToProps = ({ BillingReducer, OtpLoginReducer }) => ({
  selectedProducts: BillingReducer.selectedProducts,
  askGstDetails: OtpLoginReducer.askGstDetails,
  isGstUser: OtpLoginReducer.isGstUser,
  gstNumber: OtpLoginReducer.gstNumber,
});

// For Dispatching Action
const mapDispatchToProps = dispatch => ({
  addProductToSelectedList: product =>
    dispatch(addProductToSelectedList(product)),
  increaseOrDecrease: (product, by) =>
    dispatch(increaseOrDecrease(product, by)),
  removeProductFromList: product => dispatch(removeProductFromList(product)),
  removeSelectedProducts: () => dispatch(clearSelectedProducts()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedProductList);
