export const scanIdToBarcode = listOfObjs => {
  const newListOfObjs = listOfObjs.map(obj => {
    if ('scanId' in obj && obj.scanId != null) {
      obj['barcode'] = obj.scanId;
    }
    if ('scanId' in obj) delete obj.scanId;
    return obj;
  });
  return newListOfObjs;
};

export const barcodeToScanId = listOfObjs => {
  const newListOfObjs = listOfObjs.map(obj => {
    if ('barcode' in obj && obj.barcode != null) {
      obj['scanId'] = obj.barcode;
    }
    if ('barcode' in obj) delete obj.barcode;
    return obj;
  });
  return newListOfObjs;
};
