/* eslint-disable */
/* eslint-disable */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
// import Button from '@material-ui/core/Button'
// import { Paper, Grid } from '@material-ui/core'
// import { Link } from 'react-router-dom'
import { getNotificationCount } from '../../../store/RetailerWebStore/RetailerAction/notifications';

const Help = React.memo(({ getNotificationCount }) => {
  useEffect(() => {
    getNotificationCount();
  }, []);

  return (
    <div>
      <div style={{ textAlign: 'left', marginLeft: '2%', marginTop: '1%' }}>
        <h3>Help & Support</h3>
      </div>
      {/* <div style={{textAlign:'left', padding:'2%'}}>
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      
      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

    <div>
        <br /> <br />
        Have to have a <a href="#">VIDEO TUTORIAL</a> option as well.<br/>
        Need Help ? <Link to="/faq"><strong>Chat With Us...</strong></Link>
    </div>
      </div>
     */}

      <div style={{ marginTop: '5%' }}>
        <h6>
          For assistance Whatsapp us on +91 9319595452
          <br /> <br />
          Available Monday - Saturday 9:00 am - 6:00 pm
        </h6>
      </div>
    </div>
  );
});

const mapStateToProps = ({}) => {
  return {};
};

export default connect(mapStateToProps, { getNotificationCount })(Help);
