/* eslint-disable */
import React, { useCallback, useState, memo } from 'react';
import { connect } from 'react-redux';
import { history } from '../../routes';
import SendOtp from '../../RetailerWebPortal/WebComponents/Ledger';
import SelectCategories from '../../RetailerWebPortal/WebComponents-v3/ManageCategory';
import TermsCondition from './terms&condition';
// import MyProfile from '../../RetailerWebPortal/WebComponents/ProfilePage'
import { IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

const mapStateToProps = ({ loginReducer: { user } }) => {
  return { userStage: user.userData[0].isRegister };
};

const PartialUser = memo(({ userStage, handleBack }) => {
  const [accept, setAccept] = useState(false);

  const renderComponent = useCallback(() => {
    if (userStage) {
      switch (userStage) {
        case 1:
          history.push('/');
          return (
            <>
              {!accept ? (
                <TermsCondition handleAccept={setAccept} />
              ) : (
                <SelectCategories fromSignup={true} />
              )}
            </>
          );
        case 2:
          history.push('/');
          return <SendOtp fromSignup={true} />;
        case 3:
          history.push('/dashboard');
          return <></>;
        default:
          history.push('/');
          return <></>;
      }
    }
  }, [userStage, accept]);

  return (
    <div>
      <IconButton
        onClick={() => handleBack()}
        style={{ position: 'absolute', left: 10, zIndex: 999 }}
      >
        <ChevronLeft fontSize="large" />
      </IconButton>
      {renderComponent()}
    </div>
  );
});

export default connect(mapStateToProps, {})(PartialUser);
