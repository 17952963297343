/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import analyticFunc from '../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    border: '1px solid grey',
    margin: 5,
    textAlign: 'left',
    display: 'flex',
  },
});

const OutlinedCard = React.memo(({ detailsPage, data }) => {
  const classes = useStyles();

  return (
    <Grid>
      {data &&
        data.length > 0 &&
        data.map((item, i) => (
          <Card
            className={classes.root}
            variant="outlined"
            onClick={() => {
              analyticFunc(eventsPayload.CreditBook[2]);
              detailsPage({ item });
            }}
            style={{ cursor: 'pointer' }}
            key={i}
          >
            <div className="w-100 d-flex justify-content-between align-items-center p-2">
              <div className="d-flex flex-column">
                <h5>{item.customerName}</h5>
                <span>Mobile No. : {item.mobileNumber}</span>
                <span>Invoice No. : {item.billId}</span>
              </div>
              <div className="d-flex flex-column align-items-center">
                <h4 style={{ color: 'red' }}>{item.dueBalance}</h4>
                <span>Due Amount</span>
              </div>
            </div>
          </Card>
        ))}
    </Grid>
  );
});

export default OutlinedCard;
