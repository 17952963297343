import React, { useState, useEffect } from 'react';
import { TextField, Paper, Button, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { addNewContact } from '../../billingAction';
import CustomInput from '../../../../CommonComponents/customInput';
import analyticFunc from '../../../../GoogleAnalytics/analytic_func';
import eventsPayload from '../../../../GoogleAnalytics/events_payload';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem',
    borderRadius: '1.5rem',
  },
}));

const CreateContactModal = props => {
  const classes = useStyles();
  const { toggleCreateContact, setToggleCreateContact, preFilledName } = props;
  const [toggleMoreInfo, setToggleMoreInfo] = useState(false);
  const [name, setName] = useState('');
  const [number, setNumber] = useState(0);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [gst, setGst] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showEmptyNameError, setShowEmptyNameError] = useState(false);
  const [showNumberError, setShowNumberError] = useState(false);

  useEffect(() => {
    setName(preFilledName);
  }, [preFilledName]);

  useEffect(() => {
    setDisabled(!(name.length > 2 && number.length === 10));
  }, [name, number]);

  const handleInputChange = (input, from) => {
    setNumber(input);
    setShowNumberError(false);
    input = Number(input);
    let digitRegex = /^\d{10}$/;
    if (!isNaN(input)) {
      if (digitRegex.test(input)) {
        setShowNumberError(false);
      }
    } else {
      setShowNumberError(true);
    }
  };

  const handleSubmit = () => {
    analyticFunc(eventsPayload.Billing[6]);
    if (name && name !== '' && number !== 0) {
      let payload = {
        customerName: name, //required
        mobileNumber: number.toString(), //required
      };

      var customerEmailKV = { customerEmail: email };
      var customerAddressKV = { customerAddress: address };
      var gstNumberKV = { gstNumber: gst };
      if (email && email !== '') payload = { ...payload, ...customerEmailKV };
      if (address && address !== '')
        payload = { ...payload, ...customerAddressKV };
      if (gst && gst !== '') payload = { ...payload, ...gstNumberKV };

      props.addNewContact(payload);
      setToggleCreateContact(!toggleCreateContact);
    }
  };

  return (
    <Modal
      style={{
        margin: 'auto',
        minWidth: '30rem',
        maxWidth: '25%',
        height: toggleMoreInfo ? '80%' : '50%',
      }}
      open={toggleCreateContact}
      onClose={() => setToggleCreateContact(!toggleCreateContact)}
    >
      <Paper className={classes.paper}>
        <p
          style={{ textAlign: 'center', fontStyle: 'bold' }}
          className="formTitle"
        >
          Add New Customer
        </p>
        <CustomInput
          inputLabel={'Customer Name*'}
          autoFocus={true}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <CustomInput
          error={showNumberError}
          inputLabel={'Mobile Number*'}
          onChange={e => handleInputChange(e.target.value, 'number')}
        />

        {showNumberError && (
          <p className="errorLine">Please enter 10 digit Number</p>
        )}

        <Button
          style={{ alignSelf: 'start' }}
          color="primary"
          onClick={() => setToggleMoreInfo(!toggleMoreInfo)}
        >
          +more info
        </Button>
        {toggleMoreInfo && (
          <>
            <div className="inputContainer mb-3">
              <TextField
                label="Email"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <div className="inputContainer mb-3">
              <TextField
                label="Address"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={e => setAddress(e.target.value)}
              />
            </div>

            <div className="inputContainer mb-3">
              <TextField
                label="GST Number"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={e => setGst(e.target.value)}
              />
            </div>
          </>
        )}

        <Button
          disabled={disabled}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </Paper>
    </Modal>
  );
};

// For Dispatching Action
const mapDispatchToProps = dispatch => {
  return {
    addNewContact: payload => dispatch(addNewContact(payload)),
  };
};

export default connect(null, mapDispatchToProps)(CreateContactModal);
