/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
// import { ChevronRight } from '@material-ui/icons'
// import AddCircleIcon from '@material-ui/icons/AddCircle'
// import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid grey',
    width: '80%',
    marginLeft: '10%',
    marginBottom: '2%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    // color: theme.palette.text.secondary,
  },
}));

export const RenderProductList = React.memo(({ list, Delete, edit }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {list.map(item => (
        <>
          <Grid className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <div className={classes.paper}>
                  Product Name:{' '}
                  <span style={{ width: 100, overflow: 'auto' }}>
                    {item.modelName}
                  </span>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.paper}>Quantity: {item.quantity}</div>
              </Grid>

              <Grid
                item
                xs={2}
                style={{
                  textAlign: 'right',
                  paddingRight: '2%',
                  paddingTop: '2%',
                }}
              >
                {/* <buttononClick={() => {edit({ item })}}> */}
                <EditIcon
                  fontSize="small"
                  onClick={() => {
                    edit({ item });
                  }}
                  style={{ cursor: 'pointer' }}
                />
                {/* </button> */}
              </Grid>
            </Grid>

            <Divider />

            <Grid className="d-flex justify-content-between p-1">
              <div>Total Amount: {item.Amount}</div>
              <DeleteIcon
                fontSize="small"
                onClick={() => Delete({ item })}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
});
